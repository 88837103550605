import React from "react";
import Chip from "@mui/material/Chip";

import { LanguageContext } from "../../context/languageContext";
import languageSheet from "../../language";
import Footer from "../../Components/Footer";
import DialogPop from "../../Components/DialogPop";
import { bgColor, primeBlue, textBlue } from "../../env";
import { useLocation, useNavigate } from "react-router-dom";
import ScheduleMaker from "../../Components/ScheduleMaker";
import { Loader, Message, useToaster } from "rsuite";
import { useContext } from "react";
import MessageUserExpired from "../../Components/MessageUserExpired";
import { NavbarContext } from "../../context/navbarContext";
import { components_langsheet } from "../../Components/Language/ComponentsLangsheet";
import ReactGA from "react-ga4";
import { useFiles } from "../../App";
const ariaLabel = { "aria-label": "description" };

// TODO: redirect on post ad success

const apiHost = process.env.REACT_APP_BACKEND_URL;

const steps = ["Inprocess", "Certified"];
export default function PostAdsPreview({ previewAd, user, setProNew }) {
  const { lang, setLang } = useContext(LanguageContext);
  const toaster = useToaster();
  const navigation = useNavigate();
  const { curUser, setUser } = useContext(NavbarContext);
  const onChangeDate = (date) => {};

  // stepper funcs
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [postAdSuccess, setPostAdSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { state } = useLocation();
  const { base64Files } = useFiles();

  const handleClose = () => {
    setPostAdSuccess(false);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const postAdToServer = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    myHeaders.append("Content-Type", "application/json");

    setIsLoading(true);
    fetch(apiHost + "api/create-adpost", {
      headers: myHeaders,
      method: "POST",
      body: JSON.stringify({
        ...previewAd,
        professional_degree: base64Files.professionalDegree,
        photo_id_proof: base64Files.photoIdProof,
        other_documents: base64Files.otherDocuments,
        profession: previewAd.professionId,
        countryId: previewAd.countryId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    })
      .then((res) => {
        if (res.status === 201) {
          setIsLoading(false);
          return res;
        } else if (res.status == 401) {
          setIsLoading(false);
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          setIsLoading(false);
          toaster.push(
            <Message type="error" showIcon>
              something went wrong!
            </Message>,
            { placement: "bottomEnd" }
          );
          return Error("something went wrong!");
        }
      })
      .then((res) => res.json())
      .then((res) => {
        setUser({ ...curUser, is_professional: true });
        setProNew(res);
        ReactGA.event({
          category: "User",
          action: "Ad Posted",
        });
        setIsLoading(false);
        toaster.push(
          <Message type="success">Details Submitted Successfully</Message>,
          { placement: "bottomEnd" }
        );
        navigation("/subsDetails");
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        } else if (err.code === 413) {
          toaster.push(
            <Message type="error">
              Please Check the file size before moving forward
            </Message>,
            { placement: "bottomEnd" }
          );
        } else {
          toaster.push(
            <Message type="error">
              Something went wrong, please try again
            </Message>,
            { placement: "bottomEnd" }
          );
        }
      });
  };

  const fakeSetter = (obj) => {};

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{ backgroundColor: bgColor, height: "calc(100vh - 90px)" }}
    >
      <div>
        {isLoading ? (
          <div
            className="w-full h-100 d-flex mt-4 justify-content-center align-items-center "
            style={{ height: "400px" }}
          >
            <Loader size="lg" className="" />
          </div>
        ) : (
          <div className="container my-5">
            <h4
              className="text-uppercase sliderHead"
              style={{ color: primeBlue }}
            >
              {/* POST AD */}
              {languageSheet[39][lang]}
            </h4>

            <div className="row ">
              <div className="col-md-7">
                <div className="row">
                  {/* user details */}
                  <div className="col-md-12 col-lg-8 my-3">
                    <div className="row align-items-center">
                      <div className="col-md-4 label">
                        {/* NAME * */}
                        {languageSheet[57][lang]}*
                      </div>
                      <div className="col-md-8 textSecondary">
                        <p className="mb-0">{previewAd.name}</p>
                      </div>
                    </div>

                    <div className="row my-3 align-items-center">
                      <div className="col-md-4 label">
                        {/* COUNTRY * */}
                        {languageSheet[58][lang]}*
                      </div>
                      <div className="col-md-8 textSecondary">
                        <p className="mb-0">{previewAd.country}</p>
                      </div>
                    </div>

                    <div className="row my-3 align-items-center">
                      <div className="col-md-4 label">
                        {/* CITY */}
                        {languageSheet[59][lang]}
                      </div>
                      <div className="col-md-8 textSecondary">
                        <p className="mb-0">{previewAd.city}</p>
                      </div>
                    </div>

                    <div className="row my-3 align-items-center">
                      <div className="col-md-4 label">
                        {/* PROFESSION */}
                        {languageSheet[60][lang]}
                      </div>
                      <div className="col-md-8 textSecondary">
                        <p className="mb-0">
                          {components_langsheet[previewAd.profession][lang]}
                        </p>
                      </div>
                    </div>

                    <div className="row my-3 align-items-center">
                      <div className="col-md-4 label">
                        {/* LANGUAGES */}
                        {languageSheet[0][lang]}
                      </div>
                      <div className="col-md-8 textSecondary">
                        <p className="mb-0">
                          {previewAd.languages.map((item) => {
                            return (
                              <Chip
                                key={item}
                                tabIndex={-1}
                                label={
                                  item.charAt(0).toUpperCase() + item.slice(1)
                                }
                                style={{ marginRight: "5px" }}
                              />
                            );
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="row my-3 align-items-center">
                      <div className="col-md-4 label">
                        {/* DNI */}
                        {languageSheet[79][lang]}
                      </div>
                      <div className="col-md-8 textSecondary">
                        <p className="mb-0">{previewAd?.dni}</p>
                      </div>
                    </div>

                    <p className="littleHead mt-4">
                      {/* Short Description */}
                      {languageSheet[141][lang]}
                    </p>
                    <div
                      className="row mb-3 "
                      style={{ width: "97%", wordWrap: "break-word" }}
                    >
                      <p className="mb-0 textSecondary">
                        {previewAd.short_description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div
                    className="col-md-12"
                    style={{ width: "61%", wordWrap: "break-word" }}
                  >
                    <p className="littleHead">
                      {/* DESCRIPTION: */}
                      {languageSheet[66][lang]}
                    </p>
                    <p
                      className=""
                      style={{ color: textBlue, textAlign: "justify" }}
                    >
                      {previewAd.long_description}
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div
                    className="col-md-12"
                    style={{ width: "61%", wordWrap: "break-word" }}
                  >
                    <p className="littleHead">
                      {/* Files Uploaded: */}
                      {languageSheet[157][lang]}:
                    </p>
                    {/* Professional Degree */}
                    <div className="label mb-3 mt-2">
                      {" "}
                      {languageSheet[154][lang]}:{" "}
                    </div>
                    <p
                      className=""
                      style={{ color: textBlue, textAlign: "justify" }}
                    >
                      {previewAd.professionalDegreeName}
                    </p>
                    {/* Photo ID Proof */}
                    <div className="label mb-3 mt-4">
                      {" "}
                      {languageSheet[155][lang]}:{" "}
                    </div>
                    <p
                      className=""
                      style={{ color: textBlue, textAlign: "justify" }}
                    >
                      {previewAd.photoIdProofName}
                    </p>

                    {/* Other Documents */}
                    {previewAd?.otherDocumentsNames && (
                      <div className="label mb-3 mt-4">
                        {" "}
                        {languageSheet[156][lang]}:{" "}
                      </div>
                    )}
                    {previewAd?.otherDocumentsNames?.map((name, index) => (
                      <p
                        key={index}
                        className="mb-0 mt-1"
                        style={{ color: textBlue, textAlign: "justify" }}
                      >
                        {name}
                      </p>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-md-5 my-3 ">
                {/* <button className="primaryButton2 py-3 px-5">POST AD</button> */}

                <p className="littleHead">
                  {/* SCHEDULE: */}
                  {languageSheet[35][lang].split(",")[0]}
                </p>
                {/* <button className=" primaryButton2 py-3 px-5">
              MY APPOINTMENTS
            </button> */}
                <ScheduleMaker
                  schedule={previewAd.trueSchedule}
                  setSchedule={fakeSetter}
                  mode={"view"}
                />
                <div className="row justify-content-center">
                  <div className="col my-3 ">
                    {/* <DatePicker onChange={onChangeDate} className="dateRowBox mx-auto" /> */}

                    <div>
                      {/* {previewAd.available_days.map((day) => {
                    return <Chip key={day} label={day} style={{}} className="m-1"/>
                  })} */}
                    </div>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col my-3">
                    {/* <p>{previewAd.available_time}</p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-5">
              <div className="buttons d-flex justify-content-end align-items-center flex-wrap">
                <buttons
                  className="primaryButton2 py-2 px-3 mx-2"
                  onClick={postAdToServer}
                >
                  {/* POST AD */}

                  {languageSheet[39][lang]}
                </buttons>

                <buttons
                  className="secondaryButton py-2 px-3 mx-2"
                  onClick={(e) => {
                    navigation("/createEditAds");
                  }}
                >
                  {/* CANCEL */}
                  {languageSheet[35][lang].split(",")[1]}
                </buttons>
              </div>
            </div>
          </div>
        )}

        {/* Handling of Success and failed should be dynamic */}
        <DialogPop
          open={postAdSuccess}
          onClose={handleClose}
          selectedValue={"success"}
          title={languageSheet[116][lang].split(",")[0]} // "AD POSTED!"
          imageToShow="professionalSuccess.png"
          content={languageSheet[116][lang].split(",")[1]} // "Your ad is successfully posted."
          btnName={languageSheet[116][lang].split(",")[2]} //BACK TO DASHBOARD"
        />
      </div>
      <Footer />
    </div>
  );
}
