import React from 'react'

const FileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
  <path d="M13.5 15.75H3.5C2.81075 15.75 2.25 15.1893 2.25 14.5V1.5C2.25 0.810755 2.81075 0.250005 3.5 0.250005H8.58587C8.75007 0.249557 8.91273 0.281687 9.06443 0.344533C9.21613 0.407379 9.35385 0.499693 9.46963 0.61613L14.3839 5.53038C14.5003 5.64616 14.5926 5.78388 14.6555 5.93558C14.7183 6.08727 14.7504 6.24993 14.75 6.41413V14.5C14.75 15.1893 14.1892 15.75 13.5 15.75ZM3.5 0.750005C3.08637 0.750005 2.75 1.08638 2.75 1.5V14.5C2.75 14.9136 3.08637 15.25 3.5 15.25H13.5C13.9136 15.25 14.25 14.9136 14.25 14.5V6.41413C14.2494 6.21537 14.1705 6.02486 14.0304 5.88388L9.11613 0.96963C9.04665 0.899781 8.96402 0.844405 8.873 0.806706C8.78198 0.769008 8.68439 0.749735 8.58587 0.750005H3.5Z" fill="#FD4233"/>
  <path d="M12.4 10.04C12.3913 9.97625 12.34 9.89625 12.285 9.8425C12.1275 9.6875 11.7813 9.60625 11.2538 9.59875C10.8963 9.595 10.4663 9.62625 10.0138 9.69C9.8113 9.57375 9.60255 9.44625 9.4388 9.2925C8.99755 8.88125 8.63005 8.31 8.40005 7.68125C8.41505 7.6225 8.42755 7.57125 8.44005 7.51875C8.44005 7.51875 8.68755 6.10875 8.62255 5.63125C8.6138 5.56625 8.60755 5.5475 8.59005 5.49625L8.5688 5.44125C8.5013 5.285 8.3688 5.12 8.1613 5.12875L8.0388 5.125H8.0363C7.8038 5.125 7.6163 5.24375 7.5663 5.42C7.4163 5.975 7.5713 6.80625 7.85255 7.88125L7.78005 8.05625C7.5788 8.54875 7.3263 9.04375 7.1038 9.48L7.07505 9.53625C6.84005 9.995 6.62755 10.385 6.4338 10.715L6.23505 10.82C6.22005 10.8275 5.8788 11.0087 5.7988 11.0562C5.12005 11.4625 4.67005 11.9225 4.59505 12.2875C4.5713 12.405 4.5888 12.5538 4.71005 12.6225L4.90255 12.72C4.9863 12.7613 5.07505 12.7825 5.16505 12.7825C5.6488 12.7825 6.21005 12.18 6.9838 10.83C7.8763 10.54 8.8938 10.2975 9.78505 10.165C10.4638 10.5462 11.2988 10.8125 11.8263 10.8125C11.92 10.8125 12.0013 10.8038 12.0663 10.7863C12.1675 10.7587 12.2525 10.7012 12.305 10.6237C12.4075 10.47 12.4275 10.2575 12.4 10.04ZM5.06005 12.4163C5.14755 12.175 5.49755 11.6975 6.0138 11.275C6.0463 11.2487 6.1263 11.1738 6.1988 11.1038C5.6588 11.965 5.29755 12.3075 5.06005 12.4163ZM8.11755 5.375C8.27255 5.375 8.3613 5.76625 8.3688 6.13375C8.3763 6.50125 8.29005 6.75875 8.1838 6.95C8.09505 6.6675 8.05255 6.2225 8.05255 5.93125C8.05255 5.93125 8.0463 5.375 8.11755 5.375ZM7.20505 10.3925C7.3138 10.1988 7.4263 9.99375 7.5413 9.7775C7.82255 9.24625 8.00005 8.83125 8.1313 8.49C8.3938 8.9675 8.7213 9.37375 9.10505 9.69875C9.1538 9.73875 9.20505 9.78 9.25755 9.82125C8.4763 9.975 7.80005 10.1637 7.20505 10.3925ZM12.1338 10.3488C12.0863 10.3775 11.95 10.395 11.8625 10.395C11.58 10.395 11.2288 10.2663 10.7375 10.055C10.9263 10.0413 11.1 10.0337 11.255 10.0337C11.5388 10.0337 11.6225 10.0325 11.9013 10.1038C12.1788 10.175 12.1825 10.3187 12.1338 10.3488Z" fill="#FF402F"/>
</svg>
  )
}

export default FileIcon