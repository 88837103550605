import React from "react";

const FbFooter = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2137 13.2233L17.8356 9.45146L13.9452 9.45146L13.9452 7.00384C13.9452 5.97193 14.4888 4.96602 16.2312 4.96602L18 4.96602L18 1.75485C18 1.75485 16.3948 1.5 14.86 1.5C11.6559 1.5 9.56164 3.30641 9.56164 6.5767L9.56164 9.45146L6 9.45146L6 13.2233L9.56164 13.2233L9.56164 22.3415C10.2759 22.4457 11.0077 22.5 11.7534 22.5C12.4992 22.5 13.231 22.4457 13.9452 22.3415L13.9452 13.2233L17.2137 13.2233Z"
        fill="white"
      />
    </svg>
  );
};

export default FbFooter;
