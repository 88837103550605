import React from 'react'

const StarIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.74992 1.5C9.65892 1.5 9.43492 1.525 9.31592 1.763L7.48992 5.414C7.20092 5.991 6.64392 6.392 5.99992 6.484L1.91192 7.073C1.64192 7.112 1.54992 7.312 1.52192 7.396C1.49692 7.477 1.45692 7.683 1.64292 7.861L4.59892 10.701C5.06992 11.154 5.28392 11.807 5.17192 12.446L4.47592 16.456C4.43292 16.707 4.58992 16.853 4.65992 16.903C4.73392 16.959 4.93192 17.07 5.17692 16.942L8.83192 15.047C9.40792 14.75 10.0939 14.75 10.6679 15.047L14.3219 16.941C14.5679 17.068 14.7659 16.957 14.8409 16.903C14.9109 16.853 15.0679 16.707 15.0249 16.456L14.3269 12.446C14.2149 11.807 14.4289 11.154 14.8999 10.701L17.8559 7.861C18.0429 7.683 18.0029 7.476 17.9769 7.396C17.9499 7.312 17.8579 7.112 17.5879 7.073L13.4999 6.484C12.8569 6.392 12.2999 5.991 12.0109 5.413L10.1829 1.763C10.0649 1.525 9.84092 1.5 9.74992 1.5ZM4.94692 18.5C4.53392 18.5 4.12392 18.37 3.77292 18.114C3.16692 17.67 2.86992 16.937 2.99892 16.199L3.69492 12.189C3.72092 12.04 3.66992 11.889 3.55992 11.783L0.603916 8.943C0.0599161 8.422 -0.135084 7.652 0.0949161 6.937C0.326916 6.214 0.940916 5.697 1.69792 5.589L5.78592 5C5.94392 4.978 6.07992 4.881 6.14792 4.743L7.97492 1.091C8.31192 0.418 8.99192 0 9.74992 0C10.5079 0 11.1879 0.418 11.5249 1.091L13.3529 4.742C13.4219 4.881 13.5569 4.978 13.7139 5L17.8019 5.589C18.5589 5.697 19.1729 6.214 19.4049 6.937C19.6349 7.652 19.4389 8.422 18.8949 8.943L15.9389 11.783C15.8289 11.889 15.7789 12.04 15.8049 12.188L16.5019 16.199C16.6299 16.938 16.3329 17.671 15.7259 18.114C15.1109 18.565 14.3099 18.626 13.6309 18.272L9.97792 16.379C9.83492 16.305 9.66392 16.305 9.52092 16.379L5.86792 18.273C5.57592 18.425 5.26092 18.5 4.94692 18.5Z" fill="#1D2347"/>
    </svg>
    
  )
}

export default StarIcon