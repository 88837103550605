import Footer from "../../Components/Footer";
import {
  bgColor,
  primeBlue,
  textSecondary,
  whiteBg,
} from "../../env";
const ariaLabel = { "aria-label": "description" };

const steps = ["Inprocess", "Certified"];

const profiles = [
  {
    id: 1,
    name: "Erdo Klein",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    pic: './assets/imgs/testProfilePic.png',
  },
  {
    id: 2,
    name: "Danial Luis",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    pic: './assets/imgs/testProfilePic.png',
  },
  {
    id: 3,
    name: "Ester Exposito",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing.",
    pic: './assets/imgs/testProfilePic.png',
  },
  {
    id: 4,
    name: "Isabella Lopaz",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    pic: './assets/imgs/testProfilePic.png',
  },
  {
    id: 1,
    name: "Erdo Klein",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    pic: './assets/imgs/testProfilePic.png',
  },
  {
    id: 2,
    name: "Danial Luis",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    pic: './assets/imgs/testProfilePic.png',
  },
  {
    id: 3,
    name: "Ester Exposito",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing.",
    pic: './assets/imgs/testProfilePic.png',
  },
  {
    id: 4,
    name: "Isabella Lopaz",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    pic: './assets/imgs/testProfilePic.png',
  },
];

export default function Testimonials() {
  return (
    <div style={{ backgroundColor: bgColor }}>
      <div className="my-5 mx-5">
        <div className="d-flex row justify-content-center text-center ">
          <div
            className="sliderHead text-uppercase"
            style={{ color: primeBlue }}
          >
            testimonials
          </div>
          <div style={{ color: textSecondary }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry
          </div>

          <div className="row gx-5">
            {profiles.map((profile) => (
              <div key={profile.id} className="col-lg-3 col-md-4 ">
                <div className="testPic">
                  <img src='./assets/imgs/testProfilePic.png' alt={profile.name} width="120px" />
                </div>
                <div
                  className="testCard d-flex flex-column p-3 mb-5 rounded" // Min Width is controlled by the css testCard.
                  style={{ backgroundColor: whiteBg }}
                >
                  <img
                    src="./assets/block-quote.svg"
                    alt="quote"
                    width={"28px"}
                    style={{position: "relative", bottom: "-40px"}}
                  />
                  <div className="pt-4 subtitle5 text-center">
                    {profile.name}
                  </div>
                  <p className="m-2 textInter">{profile.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
