import { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import {
  bgColor,
  meetingUrl,
  primeBlue
} from "../../env";
import AppointmentsTable from "../../Components/AppointmentsTable";
import { Navigate, useNavigate } from "react-router-dom";
import { useInterval } from "usehooks-ts";
import { Message, useToaster } from 'rsuite';
import { useContext } from "react";
import { UserContext } from "../../context/userContext";
import MessageUserExpired, { AppointmentUpdated } from "../../Components/MessageUserExpired";
import { LanguageContext } from "../../context/languageContext";
import languageSheet from "../../language";
import { isUserLoggedIn } from "../../utils/Utils";
const backendURL = process.env.REACT_APP_BACKEND_URL;
const ariaLabel = { "aria-label": "description" };
const steps = ["Inprocess", "Certified"];

export default function AppointmentsUser({ user, setCurrentMeetingData }) {
  const [appointments, setAppointments] = useState([]);
  const { lang, setLang } = useContext(LanguageContext);
  const [toggle, setToggle] = useState(false);
  const [profJoin, setProfJoin] = useState(false);
  const [notif, setNotif] = useState(false)
  const [type, setType] = useState('info')
  const [placement, setPlacement] = useState('topEnd');;
  const toaster = useToaster();

  const { setUser } = useContext(UserContext);
  const navigation = useNavigate()

  if (user === null) {
    navigation('/signIn')
  } 


  useEffect(() => {
    getAppointments();
  }, [user, toggle]);


  const getAppointments = () => {
    if (user) {
      fetch(`${backendURL}api/appointment/user`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.tokens.access,
        },
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setAppointments(data);
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
            setUser(null);
          }
        });
    }
  };

  const getMeetingData = () => {
    fetch(backendURL + "api/get-signature/" + appointments[firstOccurance]?.id, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setCurrentMeetingData(res);
        let params = new URLSearchParams({
          signature: res.signature,
          appointment_id: res.appointment_id,
          username: res.username,
          token: user.tokens.access,
          lang: lang
        }).toString();
        window.location.href = meetingUrl + "?" + params;
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };
  let currentDate = new Date();
  const [firstOccurance, setFirstOc] = useState(null);
  useEffect(() => {
    let toSetFi = null;
    let minDate = {};
    let currentDate = new Date();
    findAndSetLatest();
  }, [appointments]);

  const findAndSetLatest = () => {
    let minDate = {};
    let currentDate = new Date();

    for (let i = 0; i < appointments.length; i++) {
      let appointmentDate = new Date(
        appointments[i].date + " " + appointments[i].start_time
      );
      let appointmentDateEnd = new Date(
        appointments[i].date + " " + appointments[i].start_time
      );
      appointmentDateEnd.setMinutes(appointmentDate.getMinutes() + 14);
      if (
        appointments[i].meeting_status == "confirmed" &&
        appointmentDateEnd >= currentDate
      ) {
        if (Object.keys(minDate).length == 0) {
          minDate.i = i;
          minDate.date = new Date(appointments[i].date);
          minDate.start_time = appointments[i].start_time.split(":");
          minDate.appointmentDate = appointmentDate;
        } else if (minDate.appointmentDate > appointmentDate) {
          minDate.i = i;
          minDate.date = new Date(appointments[i].date);
          minDate.start_time = appointments[i].start_time.split(":");
          minDate.appointmentDate = appointmentDate;
        }
      }
    }
    if (Object.keys(minDate).length != 0) {
      setFirstOc(minDate.i);
      setBtnDisabled(false);
    } else {
      setFirstOc(null);
    }
  };
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [intervalState, setIntervalState] = useState(null);

  const getHost = () => {
    fetch(
      backendURL + "api/appointment/" + appointments[firstOccurance].id + "/",
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.tokens.access,
        },
      }
    )
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((result) => {
        setProfJoin(result.is_prof_joined);
        return result.is_prof_joined;
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };

  useInterval(
    () => {
      getHost();
      if (
        appointments[firstOccurance]?.start_time.split(":")[0] ==
        new Date().getHours() &&
        appointments[firstOccurance]?.start_time.split(":")[1] <=
        new Date().getMinutes() &&
        profJoin
      ) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
      }
    },
    // Delay in milliseconds or null to stop it
    !btnDisabled ? 1000 : null
  );

  useInterval(
    () => {
      getAppointments();
    },
    !null ? 60000 : null
  );

  return (
    <div style={{ backgroundColor: bgColor }}>
      <div className="container my-5" style={{ minHeight: "60vh" }}>
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {languageSheet[43][lang].split(",")[0]}
        </h4>

        <div className="row ">
          <AppointmentsTable
            isproff={false}
            appointments={appointments}
            user={user}
            setToggle={setToggle}
            toggle={toggle}
            setAppointments={setAppointments}
            setNotif={setNotif}
            getAppointments={getAppointments}
          />
          <div className="col-md-4  mt-4 ">
            {firstOccurance !== null && (
              <div className="container" style={{ paddingLeft: 0 }}>
                <p className="littleHead">
                  {/* JOIN MEETING: */}
                  {languageSheet[108][lang].split(",")[0]}
                </p>
                <p className="textSecondary">
                  {/* Join now to attend your meeting. */}
                  {/* {languageSheet[108][lang].split(",")[1]} */}
                </p>
                {appointments.length != 0 && (
                  <p className="textSecondary dflex">
                    {languageSheet[119][lang]?.split(",")[0]}: {appointments[firstOccurance].date}
                    &nbsp;&nbsp;&nbsp;
                    {languageSheet[119][lang]?.split(",")[1]}:{" "} 
                    {appointments[firstOccurance].start_time.split(":")[0]}:
                    {appointments[firstOccurance].start_time.split(":")[1]}
                  </p>
                )}

                {appointments.length != 0 &&
                  (btnDisabled ? (
                    <buttons
                      className="primaryButton2 py-2 px-4  my-2"
                      onClick={() => {
                        getMeetingData();
                      }}
                    >
                      {/* JOIN NOW */}
                      {languageSheet[71][lang]}
                    </buttons>
                  ) : (
                    <span
                      className="primaryButton2 py-2 px-4  my-2 disabled"
                      style={{ lineHeight: '40px' }}
                      onClick={() => { }}
                    >
                      {/* JOIN NOW */}
                      {languageSheet[139][lang]}
                    </span>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
