import React, { useEffect, useState, useRef, useContext } from "react";
import DialogPop from "../../Components/DialogPop";
import { grey, primeBlue } from "../../env";
import OtpInput from 'react-otp-input';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { LanguageContext } from '../../context/languageContext'
import languageSheet from '../../language'
import { Message, useToaster } from 'rsuite';
import { pages_langsheet } from "../Languages/PagesLangsheet";
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function VerifyEmail({ joinEmail }) {
  const { lang, setLang } = useContext(LanguageContext)
  const toaster = useToaster();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const navigation = useNavigate()
  if (!joinEmail) {
    navigation('/join')
  }

  const [otp, setOtp] = React.useState('')

  const verifyOtp = () => {
    if (otp !== '') {
      fetch(apiHost + 'auth/register/' + joinEmail, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ otp })
      })
        .then(res => res.json())
        .then(res => {
          if (res.success) {
            handleClickOpen()
          }
          else {
            toaster.push(<Message type="error" showIcon>{pages_langsheet?.EnterCorrectOTP?.[lang]}</Message>, { placement: "topEnd", duration: 2500 });
          }

        })
        .catch(err => {
          console.log({ err })
        })
    } else {
      toaster.push(<Message type="error" showIcon>{pages_langsheet?.OTPfieldCantBeEmpty?.[lang]}</Message>, { placement: "topEnd", duration: 2500 });
    }
  }
  const resendOtp = () => {
    if (resend == true) {
      fetch(apiHost + 'auth/register/' + joinEmail)
        .then(res => {
          if (res.status == 208) {
            toaster.push(<Message showIcon>{pages_langsheet?.OTPIsAlreadySent?.[lang]}</Message>, { placement: "topEnd", duration: 2500 });
          }
          return res.json()
        })
        .then(res => {
          if (res.success) {
            setCounter(30)
            setResend(false)
            toaster.push(<Message type="success" showIcon>{pages_langsheet?.OTPSentSuccessfully?.[lang]}</Message>, { placement: "topEnd", duration: 2500 });
          } else if (res.error) {

          }
        })
        .catch(err => console.log(err))
    }
    else {
      toaster.push(<Message showIcon>{pages_langsheet?.WaitForSomeTime?.[lang]}</Message>, { placement: "topEnd", duration: 2500 });
    }
  }

  const [counter, setCounter] = useState(30)
  const [resend, setResend] = useState(false)

  useInterval(() => {
    if (counter > 0) {
      setCounter(counter - 1);
    } else {
      setResend(true)
    }
  }, !resend ? 1000 : null);


  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }


  return (
    <div className="verifyEmail blueGradient p-3 pb-5">
      <Link to="/">
        <img src="./assets/LogoWhite.png" alt="Logo" />
      </Link>

      <div
        className="w-100 p-3 border rounded shadow mx-auto my-4"
        style={{ maxWidth: "430px", backgroundColor: "white" }}
      >
        <h4
          className="sliderHead text-uppercase text-center pt-3"
          style={{ color: primeBlue, fontSize: "1.7rem" }}
        >
          {/* VERIFY YOUR EMAIL */}
          {languageSheet[48][lang]}
        </h4>
        <div className="d-flex py-4 mb-3 justify-content-center">
          <img
            src="./assets/verifyEmail.svg"
            alt="VerifyEmailIcon"
            width={"120px"}
          />
        </div>
        <div
          className="textSecondary px-5 pt-2 text-center"
          style={{ color: grey }}
        >
          {/* Please enter the 6 digit code sent to */}
          {languageSheet[47][lang]} {joinEmail}
        </div>
        <div className="container mt-3">
          <div className="contaier-fluid mt-3"></div>
          <div className="mt-3 px-4 pt-3">
            <form
              className="text-center digit-group d-flex justify-content-center"
              data-group-name="digits"
              data-autosubmit="false"
              autocomplete="off"
            >
              <OtpInput
                value={otp}
                onChange={(otp) => setOtp(otp)}
                numInputs={6}
                separator={<span>-</span>}
              />
            </form>

            {/* <div
              className="subtitle text-center pt-3 pb-1"
              style={{ fontSize: "16px", color: grey, cursor: 'pointer' }}
            > */}
            {/* Resend Code in 00: */}
            {/* {languageSheet[49][lang]} 00:{counter < 10 ? 0 : null}{counter}
            </div> */}

            <div
              className="subtitle text-center pb-2 pt-1"
              style={{
                fontSize: "16px",
                color: resend ? primeBlue : grey,
                cursor: "pointer",
              }}
            >
              {/* Resend OTP */}
              {counter !== 0 ? (
                <Tooltip
                  title={
                    <Typography color="inherit">
                      {languageSheet[142][lang]} 00:
                      {counter < 10 ? `0${counter}` : counter}
                    </Typography>
                  }
                  placement="top"
                >
                  <button
                    className="btn resend"
                    style={{
                      backgroundColor: "white",
                      color: resend ? primeBlue : grey,
                    }}
                    onClick={resendOtp}
                  >
                    {languageSheet[49][lang]}
                  </button>
                </Tooltip>
              ) : ( 
                <button
                  className="btn resend"
                  style={{
                    backgroundColor: "white",
                    color: resend ? primeBlue : grey,
                  }}
                  onClick={resendOtp}
                >
                  {languageSheet[49][lang]}
                </button>
              )}
            </div>
            <button
              className="btn btn-secondary text-uppercase py-2 w-100"
              style={{ textAlign: "center", backgroundColor: primeBlue }}
              onClick={verifyOtp}
            >
              {/* Confirm */}
              {languageSheet[36][lang].split(",")[1]}
            </button>
            <DialogPop
              state={{ from: location.state ? location.state.from : null }}
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              title={languageSheet[101][lang]} // "VERIFIED!"
              imageToShow="profileSuccess.png"
              content={languageSheet[51][lang]} //"Your account is successfully verified."
              btnName={
                languageSheet[46][lang]
                  .split(",")[0]
                  .split(" ")[0]
                  .split("/")[0] +
                " " +
                languageSheet[46][lang].split(",")[0].split(" ")[1]
              } // "CREATE PASSWORD"
              redirectOnSuccess="/passwordNew"
            />
          </div>
        </div>
        <hr />
        <div className="signin" style={{ textAlign: "center" }}>
          <p className="textSecondary mb-0" style={{ color: grey }}>
            <button
              className="ce"
              style={{ backgroundColor: "white" }}
              onClick={() => navigation("/signIn")}
            >
              {/* Change Email */}
              {languageSheet[50][lang]}
            </button>
          </p>
        </div>
      </div>
      <br />
      <br />
      <div className="text-center position-relative bottom-0 whiteTextLinks">
        © {currentYear} &nbsp;&nbsp;
        <a className="whiteTextLinks" href="https://mdbootstrap.com/">
          konsarutanto.com
        </a>
      </div>
    </div>
  );
}
