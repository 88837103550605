import { Notification } from 'rsuite'
import { useContext } from 'react';
import { components_langsheet } from './Language/ComponentsLangsheet';
import { LanguageContext } from '../context/languageContext';

const MessageUserExpired = () => {
  const { lang, setLang } = useContext(LanguageContext)
  return (
    <Notification type='info' header='Session Expired' closable>
      <p>{components_langsheet.userHasBeenLoggedOutLoginAgain[lang]}</p>
    </Notification>
  );
}

export default MessageUserExpired


export function AppointmentUpdated() {
  const { lang, setLang } = useContext(LanguageContext)
  return (
    <Notification type='info' header='Session Expired' closable>
      <p>Appointment Updated Succesfully</p>
    </Notification>
  );
}