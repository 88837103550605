export const components_langsheet = {
  Engineer: {
    en: "Engineer",
    es: "Ingeniero",
    pr: "Engenheiro",
  },
  Lawyer: {
    en: "Lawyer",
    es: "Abogado",
    pr: "Advogado",
  },
  Architect: {
    en: "Architect",
    es: "Arquitecto",
    pr: "Arquiteto",
  },
  Accountant: {
    en: "Accountant",
    es: "Contador",
    pr: "Contador",
  },
  Doctor: {
    en: "Doctor",
    es: "Médico",
    pr: "Médico",
  },
  Sunday: {
    en: "Sunday",
    es: "Domingo",
    pr: "Domingo",
  },
  Monday: {
    en: "Monday",
    es: "Lunes",
    pr: "Segunda",
  },
  Tuesday: {
    en: "Tuesday",
    es: "Martes",
    pr: "Terça",
  },
  Wednesday: {
    en: "Wednesday",
    es: "Miércoles",
    pr: "Quarta",
  },
  Thursday: {
    en: "Thrusday",
    es: "Jueves",
    pr: "Quinta",
  },
  Friday: {
    en: "Friday",
    es: "Viernes",
    pr: "Sexta",
  },
  Saturday: {
    en: "Saturday",
    es: "Sábado",
    pr: "Sábado",
  },
  userHasBeenLoggedOutLoginAgain: {
    en: "User has been logged out please login again",
    es: "El usuario ha sido desconectado, vuelva a iniciar sesión",
    pr: "O usuário foi desconectado, faça o login novamente",
  },
  PROFESSION: {
    en: "PROFESSION",
  },
  COUNTRY: {
    en: "COUNTRY",
  },
  CITY: {
    en: "CITY",
  },
  LANGUAGE: {
    en: "LANGUAGE",
  },
  English: {
    en: "English",
  },
  Spanish: {
    en: "Spanish",
  },
  Portugese: {
    en: "Portugese",
  },
  German: {
    en: "German",
  },
  Japanese: {
    en: "Japanese",
  },
  French: {
    en: "French",
  },
  Chinese: {
    en: "Chinese",
  },
  done: {
    en: "Done",
    es: "Realizado",
    pr: "Realizado",
  },
  cancelled: {
    en: "Cancelled",
    es: "Cancelado",
    pr: "Cancelado",
  },
  not_done: {
    en: "Not Done",
    es: "No realizado",
    pr: "Não realizado",
  },
  confirmed: {
    en: "Confirmed",
    es: "Confirmado",
    pr: "Confirmado",
  },
  plans: {
    en: "Plans",
    es: "Planes",
    pr: "Planos",
  },
  standardPlanAddedSuccessfully: {
    en: "Standard plan has been added successfully",
    es: "El plan estándar se ha agregado con éxito",
    pr: "O plano padrão foi adicionado com sucesso",
  },
  profileImage: {
    en: "Profile Image",
    es: "Imagen de perfil",
    pr: "Imagem de perfil",
  },
  home: {
    en: "Home",
    es: "Inicio",
    pr: "Página inicial",
  },
  profile: {
    en: "Profile",
    es: "Perfil",
    pr: "Perfil",
  },
  userProfile: {
    en: "User Profile",
    es: "Perfil usuario",
    pr: "Perfil usuario",
  },
  logout: {
    en: "Logout",
    es: "Cerras sesión",
    pr: "Sair",
  },
  search: {
    en: "Search",
    es: "Buscar",
    pr: "Buscar",
  },
  applyFilter: {
    en: "APPLY FILTER",
    es: "Aplicar filtros",
    pr: "APLICAR FILTRO"
  },
  clearFilter: {
    en: "CLEAR FILTER",
    es: "Limpiar filtros",
    pr: "FILTRO LIMPO"
  },
  changeYourSlot: {
    en: "Update Your Slot",
    es: "Actualiza tu disponibilidad",
    pr: "Atualize seu slot"
  },
  currentAppointment: {
    en: "Current Appointment",
    es: "Cita actual",
    pr: "Compromisso atual"
  },
  professionalName: {
    en: "Professional Name:",
    es: "Nombre profesional:",
    pr: "Nome profissional"
  },
  date: {
    en: "Date:",
    es: "Fecha:",
    pr: "Data:"
  },
  time: {
    en: "Time:",
    es: "Hora:",
    pr: "Hora:"
  },
  reschedule: {
    en: "Reschedule",
    es: "Reagendar",
    pr: "Remarcar"
  }
};
