export default function SetIcon({ iconName }) {
  switch (iconName.toLowerCase()) {
    case "profile":
      return <img src="./assets/shieldSuccess.png" alt="success image" />;
    case "password":
      return <img src="./assets/shieldSuccess.png" alt="success image" />;
    case "deleted":
      return <img src="./assets/bin.svg" alt="bin image" />;
    case "appointment":
      return <img src="./assets/calendarCircle.svg" alt="calendar image" />;
    default:
      return <img src="./assets/success.svg" alt="success image" />;
  }
}
