import { useContext, useState, useEffect, lazy, Suspense } from "react";
import { LanguageContext } from '../../../context/languageContext'
import languageSheet from '../../../language'
import { bgColor } from "../../../env";
import { useToaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/userContext";
import MessageUserExpired from "../../../Components/MessageUserExpired";
import Loading from '../../../Components/Loading'
import { Cards, HowItWork, Footers, UserLandingPage } from '../../../Components/Loading'
const Footer = lazy(() => import('../../../Components/Footer'))
const HeroSection = lazy(() => import('../../../Components/HeroSection'))
const WantToBeProfessional = lazy(() => import('../../../Components/WantToBeProfessional'))
const DisplaySwiperProfession = lazy(() => import('../../../Components/DisplaySwiper/DisplaySwiper').then(module => ({ default: module.DisplaySwiperProfession })));
const DisplaySwiperProfessional = lazy(() => import('../../../Components/DisplaySwiper/DisplaySwiper').then(module => ({ default: module.DisplaySwiperProfessional })));
const backendURL = process.env.REACT_APP_BACKEND_URL;

export default function LangingPageUserFirstTime({ user }) {
  const [professions, setProfessions] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const { lang, setLang } = useContext(LanguageContext)
  const toaster = useToaster();
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [loadingProfessional, setLoadingProfessional] = useState(false)


  const fetchData = async () => {
    setLoading(true);
    setLoadingProfessional(true)

    fetch(`${backendURL}api/profession`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        setLoading(false);
        setProfessions([...res.results]);
      }).catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      });
    fetch(`${backendURL}api/popular-professionals`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        setLoadingProfessional(false)
        setProfessionals([...res.results]);
      }).catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="firstlandingpage d-flex flex-column justify-content-between">
      <Suspense fallback={<UserLandingPage />}>
        <div>
          {/* <SwiperLanding/> */}
          <HeroSection name={user?.name} />

          <div className="mb-3">
            <Suspense fallback={<Cards />}>
              <DisplaySwiperProfession
                color={bgColor}
                heading={languageSheet[12][lang]}   // professions
                viewLink={"/popularprofessionals"}
                data={professions}
                user={user}
                loading={loading}
              />
            </Suspense>
          </div>

          {/* <HowItWorks/> */}
          <Suspense fallback={<HowItWork />}>
            <WantToBeProfessional />
          </Suspense>

          <Suspense fallback={<Cards />}>
            <DisplaySwiperProfessional
              color={bgColor}
              heading={languageSheet[56][lang]}  // Popular professionals
              viewLink={"/popularprofessionals"}
              data={professionals}
              user={user}
              loading={loadingProfessional}
            />
          </Suspense>
        </div>

        <Suspense fallback={<Footers />}>
          <Footer />
        </Suspense>
      </Suspense>
    </div >
  );
}
