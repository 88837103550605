import React from "react";

const GoogleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6484 10.3687H12.1804V13.8492H17.0179C16.8093 14.9742 16.1765 15.9258 15.2226 16.5633C14.4164 17.1023 13.3875 17.4211 12.1781 17.4211C9.83667 17.4211 7.8562 15.8391 7.14839 13.7133C6.97026 13.1742 6.86714 12.5977 6.86714 12.0047C6.86714 11.4117 6.97026 10.8352 7.14839 10.2961C7.85854 8.17266 9.83901 6.59063 12.1804 6.59063C13.5 6.59063 14.6835 7.04531 15.6164 7.93594L18.1945 5.35547C16.6359 3.90234 14.6039 3.01172 12.1804 3.01172C8.66714 3.01172 5.6273 5.02734 4.14839 7.96641C3.53901 9.18047 3.19214 10.5539 3.19214 12.007C3.19214 13.4602 3.53901 14.8312 4.14839 16.0453C5.6273 18.9844 8.66714 21 12.1804 21C14.6085 21 16.6429 20.1938 18.1289 18.8203C19.8281 17.2547 20.8101 14.9484 20.8101 12.2086C20.8101 11.5711 20.7539 10.9594 20.6484 10.3687Z"
        fill="#1D2347"
      />
    </svg>
  );
};

export default GoogleIcon;
