import { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import { City,State } from 'country-state-city';
import Footer from "../../../Components/Footer";
import { bgColor, primeBlue, textBlue } from "../../../env";
import Input from "@mui/material/Input";
import { useNavigate, useLocation } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ScheduleMaker from "../../../Components/ScheduleMaker";
import { ListItemText } from "@mui/material";
import { Message, useToaster } from "rsuite";
import { LanguageContext } from "../../../context/languageContext";
import languageSheet from "../../../language";
import './CreateAndEditAds.css'
import { UserContext } from "../../../context/userContext";
import MessageUserExpired from "../../../Components/MessageUserExpired";
import { components_langsheet } from "../../../Components/Language/ComponentsLangsheet";
import { pages_langsheet } from "../../Languages/PagesLangsheet";
import { validateProData } from "../../../utils/validateProData";
import FileUploader from "./FileUploader";
import { useFiles } from "../../../App";
const apiHost = process.env.REACT_APP_BACKEND_URL;

const ariaLabel = { "aria-label": "description" };

export default function CreateAndEditAds({ user, previewAd, setPreviewAd }) {

  const toaster = useToaster();
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext);
  const location = useLocation();
  const { lang, setLang } = useContext(LanguageContext);
  const startTime = "8:00"
  const endTime = "10:00"

  const [schErr, setSchErr] = useState("");

  const [professionalDegree, setprofessionalDegree] = useState("");
const [photoIdProof, setphotoIdProof] = useState("");
const [otherDocuments, setotherDocuments] = useState([]);
const [professionalDegreeSuccess, setProfessionalDegreeSuccess] = useState(false);
const [photoIdProofSuccess, setPhotoIdProofSuccess] = useState(false);
const [otherDocumentsSuccess, setOtherDocumentsSuccess] = useState(false);

const [fileErrors, setFileErrors] = useState({
  professionalDegreeError: '',
  photoIdProofError: '',
  otherDocumentsError: '',
});
const { setBase64Files } = useFiles();
  // stepper funcs
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [allCountries, setAllCountries] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [allStates, setAllStates] = useState([]);

  const proCountries = require('../../Professional/proCountries.json')
  let puertoRicoState = require("../../Professional/stateData.json");

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [userProfile, setUserProfile] = useState(null);
  //gettting user profile
  useEffect(() => {

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    fetch(apiHost + "auth/user-profile", {
      headers: myHeaders,
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setProfileData(res);
        setUserProfile(res);
        setCity(res.user.city);
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  }, []);

  //data states
  const [name, setName] = useState(previewAd?.name ? previewAd.name : "");
  const [country, setCountry] = useState(
    previewAd?.country ? previewAd.country : ""
  );
  const [countryId, setCountryId] = useState(
    previewAd?.countryId ? previewAd.countryId : ""
  );
  const [city, setCity] = useState(previewAd?.city ? previewAd.city : "");
  const [profession, setProfession] = useState(
    previewAd?.profession ? previewAd.profession : ""
  );
  const [professionId, setProfessionId] = useState(
    previewAd?.professionId ? previewAd.professionId : ""
  );

  const [dni, setDni] = useState(previewAd?.dni ? previewAd.dni : "");
  const [short_description, setSD] = useState(
    previewAd?.short_description ? previewAd.short_description : ""
  );
  const [long_description, setLD] = useState(
    previewAd?.long_description ? previewAd.long_description : ""
  );
  const [err, setErr] = useState(null);

  //converting the uploaded file to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};


  //save data to preview
  const saveDataToPreview = async() => {
    
   const validationResult = validateProData(
     country,
     city,
     profession,
     selectedLang,
     dni,
     short_description,
     long_description,
     schedule,
     pages_langsheet,
     lang
   );
    // Check for required files and their associated errors.
    if (!professionalDegree) {
      
      setErr("Professional Degree is required.")
      return; 
  }
  if (!photoIdProof) {
    
      setErr("Photo ID Proof is required.")
      return; 
  }
  if (fileErrors.professionalDegreeError || fileErrors.photoIdProofError || fileErrors.otherDocumentsError) {
      // One of the files has an error, don't proceed.
      setErr("Make sure you're uploading the file with the allowed file type and size")
      return;
  }
   if (!validationResult) {
     let genSchedule = {};

     for (let day in schedule) {
       // If validation passes (no error)
       if (schedule[day].available) {
         genSchedule[day.toLowerCase()] = schedule[day].timeIntervals.map(
           (interval) => {
             return `[${interval}]`;
           }
         );
       }
     }
     const professionalBase64Data = await convertToBase64(professionalDegree);
     const photoIdProofBase64Data = await convertToBase64(photoIdProof);
     const otherDocsBase64Data = await Promise.all(otherDocuments.map(file => convertToBase64(file)));
 
     setBase64Files({
         professionalDegree: professionalBase64Data,
         photoIdProof: photoIdProofBase64Data,
         otherDocuments: otherDocsBase64Data,
     });

     setPreviewAd({
       name,
       country,
       countryId,
       city,
       profession,
       professionId,
       languages: selectedLang,
       dni,
       short_description,
       long_description,
       schedule: { ...genSchedule },
       trueSchedule: schedule,
       professionalDegreeName: professionalDegree?.name,
       photoIdProofName: photoIdProof?.name,
       otherDocumentsNames: otherDocuments?.map((file) => file?.name),
        });
     navigation("/previewAds");
   }else {
     // If validation error exists, set the error
     setErr(validationResult);
   }
  };

  //set function
  const setProfileData = (profile) => {
    setName(profile.user.username);
    setCountry(profile.user.country);
    setCity(profile.user.city);
  };

  const [allProfessions, setAllProfessions] = useState([]);
  useEffect(() => {
    fetch(apiHost + "api/profession")
      .then((res) => res.json())
      .then((res) => setAllProfessions(res.results));
  }, []);

  const [allLanguages, setAllLang] = useState([
    "english",
    "portuguese",
    "Español",
    "chinese",
    "french",
    "german",
    "japanese",
  ]);
  const [selectedLang, setSelectedLang] = useState(
    previewAd?.languages ? previewAd.languages : []
  );

  const handleChangeLang = (event) => {
    const {
      target: { value },
    } = event;

    let newValue = typeof value === "string" ? value.split(",") : value;

    // Checking if the default placeholder (in the current language) is still in the array
    const defaultPlaceholderIndex = newValue.indexOf(languageSheet[147][lang]);
    if (defaultPlaceholderIndex !== -1) {
      newValue.splice(defaultPlaceholderIndex, 1);
    }

    setSelectedLang(newValue);
  };

  const [schedule, setSchedule] = useState(
    previewAd?.trueSchedule
      ? previewAd.trueSchedule
      : {
        Sunday: {
          available: false,
          timeIntervals: [[startTime, endTime]],
        },
        Monday: {
          available: false,
          timeIntervals: [[startTime, endTime]],
        },
        Tuesday: {
          available: false,
          timeIntervals: [[startTime, endTime]],
        },
        Wednesday: {
          available: false,
          timeIntervals: [[startTime, endTime]],
        },
        Thursday: {
          available: false,
          timeIntervals: [[startTime, endTime]],
        },
        Friday: {
          available: false,
          timeIntervals: [[startTime, endTime]],
        },
        Saturday: {
          available: false,
          timeIntervals: [[startTime, endTime]],
        },
      }
  );

  const handleKeyPress = (event, len) => {
    if (event.target.value.length >= len) {
      event.preventDefault(); // Prevent further input beyond len characters
    }
  };

  useEffect(() => {
    fetch(apiHost + "api/country")
      .then((res) => res.json())
      .then((res) =>{
        //Professional should be able to change country according to selectedCountries
        //The cities will be displayed accordingly
        
        const proCountryList = res.filter((countryObj) =>
        proCountries.includes(countryObj.country)
        );
        setAllCountries(proCountryList);
      })
      .catch((error) => console.error('Error fetching countries:', error));
    }, []); 

  const getCities = (event) => {
    setCity("");
    const country = allCountries.find(
      (country) => country.id === event.target.value
    )
    setCountry(country.country);
    setCountryId(country.id)
      
    var data = require('../../User/data.json')
    var getCode = data.filter(function (el) {
      return el.name == country.country;
    }
    );


    let newArray = [];
    // Created a separate file for the states of Puerto rico
   

    if (getCode[0].code === "PR") {
      newArray = puertoRicoState;
    } else {
      newArray = State.getAllStates().filter(function (el) {
        return el.countryCode == getCode[0].code;
      });
    }
 
    const states = [];
    newArray.forEach(function (arrayItem) {
      states.push(arrayItem.name);
    });
    const res = states.sort();
    setAllStates(res);

  };

  const cityList = () => {
    var data = require('../../User/data.json')
    var getCode = data.filter(function (el) {
      return el.name == country;
    }
    );
    let newArray = [];
    // Condition to check for the states of Puerto rico
    if (getCode[0].code === "PR") {
      newArray = puertoRicoState;
    } else {
      newArray = State.getAllStates().filter(function (el) {
        return el.countryCode == getCode[0].code;
      });
    }
    const states = [];
    newArray.forEach(function (arrayItem) {
      states.push(arrayItem.name);
    });
    const res = states.sort();
    setAllStates(res);
    setAllCities(res)

  };
  const handleprofessionalDegreeChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      // If no file is selected, simply return without doing anything.
      return;
  }
    setprofessionalDegree({
      name: file?.name,
      size: file?.size
    })
    if (file && file.size <= 5 * 1024 * 1024) {  // Check for 5MB
      setprofessionalDegree(file);
      setFileErrors(prev => ({ ...prev, professionalDegreeError: '' }));
    
      setProfessionalDegreeSuccess(true)
    } else {
      setFileErrors(prev => ({ ...prev, professionalDegreeError: 'File size exceeds 5MB' }));
      
      setProfessionalDegreeSuccess(false)
    }
  }
  
  const handlephotoIdProofChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      // If no file is selected, simply return without doing anything.
      return;
  }
    setphotoIdProof({
      name: file?.name,
      size: file?.size
    })
    if (file && file.size <= 3 * 1024 * 1024) {  // Check for 3MB
      setphotoIdProof(file);
      setFileErrors(prev => ({ ...prev, photoIdProofError: '' }));
     
      setPhotoIdProofSuccess(true);
    } else {
      setFileErrors(prev => ({ ...prev, photoIdProofError: 'File size exceeds 3MB' }));
      
      setPhotoIdProofSuccess(false);
    }
  }
  
  const handleotherDocumentsChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (event.target.files.length === 0) {
      return;
  }
 
  //  check for the total size
  const totalNewFilesSize = newFiles.reduce((sum, file) => sum + file.size, 0);
  const totalCurrentFilesSize = otherDocuments.reduce(
    (sum, file) => sum + file.size,
    0
  );
  setotherDocuments((prevOtherDocuments) => [
    ...prevOtherDocuments,
    ...newFiles,
  ]);
  const totalSize = totalNewFilesSize + totalCurrentFilesSize;
    if (totalSize <= 15 * 1024 * 1024) {  // Check for 15MB in total
      setFileErrors(prev => ({ ...prev, otherDocumentsError: '' }));
      
      setOtherDocumentsSuccess(true)
    } else {
      setFileErrors(prev => ({ ...prev, otherDocumentsError: 'Total file size exceeds 15MB' }));
     
      setOtherDocumentsSuccess(false)
    }
  }
  const removeProfessionalDegree = () => {
    setprofessionalDegree(null); // Reset the file state
    setProfessionalDegreeSuccess(false); // Indicate that no file is uploaded now
    setFileErrors(prev => ({ ...prev, professionalDegreeError: '' })); 
    document.getElementById('professionalDegreeInput').value = ''; // Clear the file input field
}

  const removePhotoIdProof = () => {
    setphotoIdProof(null); // Reset the file state
    setPhotoIdProofSuccess(false); // Indicate that no file is uploaded now
    setFileErrors(prev => ({ ...prev, photoIdProofError: '' })); 
    document.getElementById('photoIdProofInput').value = ''; // Clear the file input field
}
  const removeOtherDocuments = (index) => {
    let updatedDocs = [...otherDocuments];
    
    // Remove the document at the specified index.
    updatedDocs.splice(index, 1);
    
    // Update the state with the modified array.
    setotherDocuments(updatedDocs);
    
     
  // Recalculate the total size after removing the file.
  const totalCurrentFilesSize = updatedDocs.reduce(
    (sum, file) => sum + file.size,
    0
  );
  
  // Check if the total size is within the limit.
  if (totalCurrentFilesSize <= 15 * 1024 * 1024) {
    // If within the limit, reset the error and set success flag.
    setFileErrors(prev => ({ ...prev, otherDocumentsError: '' }));
    setOtherDocumentsSuccess(true);
  } else {
    // If exceeds the limit, set the error and reset success flag.
    setFileErrors(prev => ({ ...prev, otherDocumentsError: 'Total file size exceeds 15MB' }));
    setOtherDocumentsSuccess(false);
  }
    
    // Clear the file input field.
    document.getElementById('otherDocumentsInput').value = '';
};

  return (
    <div style={{ backgroundColor: bgColor }}>
      <div className="container my-5">
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {/* POST AD */}
          {languageSheet[39][lang]}
        </h4>

        <div className="row ">
          <div className="col-md-7">
            <div className="row">
              {/* user details */}
              <div className="col-md-12 col-lg-8 my-3">
                {err && <p className="alert alert-danger">{err}</p>}
                <div className="row align-items-center">
                  <div className="col-md-4 label">
                    {/* NAME* */}
                    {languageSheet[57][lang]}*
                  </div>
                  <div className="col-md-8 textSecondary">
                    <Input
                      inputProps={ariaLabel}
                      className="w-100"
                      value={userProfile?.user.username}
                      disabled
                      style={{ paddingLeft: "10px" }}
                    />
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* COUNTRY* */}
                    {languageSheet[58][lang]}*
                  </div>
                  <div className="col-md-8 textSecondary">
                    <FormControl fullWidth>
                      <NativeSelect
                        defaultValue={30}
                        onChange={getCities}
                        inputProps={{
                          style: {
                            paddingLeft: "10px",
                          },
                        }}
                        disabled
                      >
                        <option value="">
                          {country === "" ? languageSheet[144][lang] : country}
                        </option>
                        {allCountries.map((countryOption,index) => (
                          <option value={countryOption.id} key={index}>
                            {countryOption.country}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* CITY* */}
                    {languageSheet[59][lang]}*
                  </div>
                  <div className="col-md-8 textSecondary">
                    {/* <Input
                      inputProps={ariaLabel}
                      className="w-100"
                      value={userProfile?.user.city}
                      
                      style={{ paddingLeft: "10px" }}
                    /> */}
                    {/* CITY */}
                    {/* {languageSheet[59][lang]} */}
                    <FormControl fullWidth>
                      <NativeSelect
                        defaultValue={30}
                        onClick={cityList}
                        inputProps={{
                          style: {
                            paddingLeft: "10px",
                          },
                        }}
                        onChange={(e) => {
                          setCity(e.target.value);
                          cityList();
                        }}
                      >
                        <option value="">
                          {city == "" ? languageSheet[143][lang] : city}
                        </option>
                        {allStates.map((cityOption) => {
                          return (
                            <option value={cityOption.city}>
                              {/* {cityOption.city} */}
                              {cityOption}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* PROFESSION* */}
                    {languageSheet[60][lang]}*
                  </div>
                  <div className="col-md-8 textSecondary">
                    <FormControl fullWidth>
                      {/* Age*/}
                      <NativeSelect
                        defaultValue={30}
                        onChange={(e) => {
                          let cc = allProfessions.filter(
                            (c) => c.id == e.target.value
                          );

                          setProfessionId(e.target.value);
                          setProfession(cc[0].profession);
                        }}
                        value={professionId}
                        inputProps={{
                          style: {
                            paddingLeft: "10px",
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              paddingLeft: "10px",
                            },
                          },
                        }}
                      >
                        <option value="">
                          {/* Select Profession */}
                          {languageSheet[122][lang]}
                        </option>
                        {allProfessions.map((profession) => {
                          return (
                            <option value={profession.id}>
                              {
                                components_langsheet[profession.profession][
                                  lang
                                ]
                              }
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* LANGUAGES* */}
                    {languageSheet[32][lang].split(",")[5]}*
                  </div>
                  <div className="col-md-8 textSecondary">
                    <FormControl fullWidth>
                      <Select
                        inputProps={ariaLabel}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={
                          selectedLang.length === 0
                            ? [languageSheet[147][lang]]
                            : selectedLang
                        }
                        onChange={handleChangeLang}
                        input={
                          <Input
                            inputProps={ariaLabel}
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => {
                          if (
                            selected.length === 1 &&
                            selected[0] === languageSheet[147][lang]
                          ) {
                            return (
                              <span style={{ paddingLeft: "10px" }}>
                                {languageSheet[147][lang]}
                              </span>
                            );
                          } else {
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value, index) => (
                                  <Chip
                                    key={index}
                                    label={
                                      value.charAt(0).toUpperCase() +
                                      value.slice(1)
                                    }
                                  />
                                ))}
                              </Box>
                            );
                          }
                        }}
                      >
                        {allLanguages.map((name, index) => (
                          <MenuItem
                            key={index}
                            day={name}
                            value={name}
                            className="language-menu"
                          >
                            {name !== "Select Language" && (
                              <Checkbox
                                checked={selectedLang.indexOf(name) > -1}
                              />
                            )}
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* DNI* */}
                    {languageSheet[79][lang]}*
                  </div>
                  <div className="col-md-8 textSecondary">
                    <Input
                      placeholder="72803494-K"
                      inputProps={ariaLabel}
                      className="w-100"
                      value={dni}
                      onChange={(e) => setDni(e.target.value)}
                      style={{ paddingLeft: "10px" }}
                    />
                  </div>
                </div>
                {/* File Upload */}
                <h4 className="mt-4 mb-4">{languageSheet[157][lang]}</h4>

                {/* Professional Degree */}
                <FileUploader
                  idPrefix="professionalDegree"
                  // Professional Degree
                  label={languageSheet[154][lang]+"*"}
                  maxFileSizeMB={5}
                  file={professionalDegree}
                  fileError={fileErrors.professionalDegreeError}
                  success={professionalDegreeSuccess}
                  handleFileChange={handleprofessionalDegreeChange}
                  removeFile={removeProfessionalDegree}

                />

                  {/* Photo Id Proof */}
                <FileUploader
                  idPrefix="photoIdProof"
                  label={languageSheet[155][lang]+"*"}
                  maxFileSizeMB={3}
                  file={photoIdProof}
                  fileError={fileErrors.photoIdProofError}
                  success={photoIdProofSuccess}
                  handleFileChange={handlephotoIdProofChange}
                  removeFile={removePhotoIdProof}
                  infoMessage={languageSheet[161][lang]}

                />

                    {/* Other Documents */}
                <FileUploader
                  idPrefix="otherDocuments"
                  label={languageSheet[156][lang]}
                  maxFileSizeMB={15}
                  file={otherDocuments}
                  fileError={fileErrors.otherDocumentsError}
                  success={otherDocumentsSuccess}
                  handleFileChange={handleotherDocumentsChange}
                  removeFile={removeOtherDocuments}
                  isMultiple={true}
                  infoMessage={languageSheet[162][lang]}
                />


                <p className="littleHead mt-5">
                  {/* Short Description* */}
                  {languageSheet[141][lang]}*
                </p>
                <div className="row my-3 px-2">
                  <Input
                    placeholder={languageSheet[145][lang]}
                    inputProps={{ ariaLabel, maxLength: 140 }}
                    className="w-100"
                    value={short_description}
                    onKeyPress={(e) => {
                      handleKeyPress(e, 150);
                    }}
                    onChange={(e) => setSD(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <p className="littleHead">
                  {/* DESCRIPTION: */}
                  {languageSheet[81][lang]}*
                </p>
                <TextField
                  id="standard-multiline-static"
                  multiline
                  rows={4}
                  placeholder={languageSheet[146][lang]}
                  variant="standard"
                  className="w-100"
                  style={{ color: textBlue, textAlign: "justify" }}
                  inputProps={{ maxLength: 500 }}
                  value={long_description}
                  onKeyPress={(e) => {
                    handleKeyPress(e, 500);
                  }}
                  onChange={(e) => setLD(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="col-md-5 my-3 ">
            <p className="littleHead">
              {/* SCHEDULE: */}
              {languageSheet[64][lang]}*
            </p>
            <p id="availability">
              {pages_langsheet?.pleaseSelectDaysAndTime?.[lang]}
            </p>

            <div className="row justify-content-center">
              <ScheduleMaker
                schedule={schedule}
                setSchedule={setSchedule}
                schErr={schErr}
                setSchErr={setSchErr}
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="buttons d-flex justify-content-end">
            <buttons
              className="primaryButton2 py-2 px-3 mx-2"
              onClick={() => {
                saveDataToPreview();
              }}
            >
              {/* PREVIEW POST */}
              {languageSheet[123][lang]}
            </buttons>
            <buttons
              onClick={() => navigation(location.state.from)}
              className="secondaryButton py-2 px-3 mx-2"
            >
              {/* CANCEL */}
              {languageSheet[35][lang].split(",")[1]}
            </buttons>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
