import { useState, useContext } from 'react'
import { useToaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import MessageUserExpired from "../../Components/MessageUserExpired";
import { findFlagUrlByCountryName } from 'country-flags-svg';
import profileNoImage from "../../assests/images/profileNoImage.png";
import { NavbarContext } from "../../context/navbarContext";
import './ProfessionalCard.css'
const apiHost = process.env.REACT_APP_BACKEND_URL;


export default function ProfessionalCard({
  id,
  name,
  image,
  user,
  isfav,
  isProfession,
  profession,
  description,
  country
}) {
  let navigate = useNavigate()
  const linkOpt = isProfession ? "/professionals/" : "/ppp/";
  const [favT, setFavT] = useState(user ? true : false);
  const toaster = useToaster();
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext);
  const [isF, setFav] = useState(isfav);
  const { favourites, setFavourites } = useContext(NavbarContext);


  const addFav = (e) => {
    e.stopPropagation()
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({   //it is the id of professional
      professional: [id],
    });

    let newFav = {
      id: id,
      is_favourite: true,
      name: name,
      profession: profession,
      profile_image: image,
    };

    var requestOptions = {      //it is used to get all the favourites of a professional
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiHost + "api/favourite/", requestOptions)
      .then((response) => {

        if (response.status == 200) {
          setFav(true);
          setFavourites([...favourites, newFav])
        } else if (response.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };

  const removeFav = (e) => {
    e.stopPropagation()

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      professional: id,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(apiHost + "api/remove-favourite", requestOptions)
      .then((response) => {
        if (response.status == 200) {
          setFav(false);

          setFavourites((current) =>
            current.filter((fav) => fav.id != id)
          )

        } else if (response.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };

  const trimDesc = (description) => {
    if (description.length > 140) {
      return description.slice(0, 140);
    } else {
      return description;
    }
  }

  return (
    <div className="card-body" onClick={() => { navigate(`${linkOpt + id}`) }}>
      <div className="image">
        <img
          src={image || profileNoImage}
          alt="ProfileImage" />
        <div className="country-flag">
          <div className="rect-flag">
            <img src={findFlagUrlByCountryName(country)}
              alt={country} />
          </div>
        </div>
      </div>

      <div id="card-content">
        <div id='card-right-body'>
          <div id='card-title'>
            <span id='name' className='no-underline'>{name}</span>
            <p id='profession'>{profession}</p>
          </div>
          {favT && !isProfession ? (
            isF ? (
              <img
                src="./assets/filledFav.svg"
                alt='FavIcon'
                onClick={(e) => { removeFav(e) }}
                id='fav'
              />
            ) : (
              <img
                src="./assets/favourite.svg"
                alt='EmptyFavIcon'
                onClick={(e) => { addFav(e) }}
                id='selectedFav'
              />
            )
          ) : null}
        </div>

        <div className='description-container'>
          <span>{trimDesc(description)}</span>
        </div>
      </div>
    </div >
  )
}
