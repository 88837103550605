export const HowItWorksLang = {
  howItWorks: {
    en: "HOW IT WORKS",
    es: "CÓMO FUNCIONA?",
    pr: "COMO FUNCIONA?",
  },

  khonsuIsPlatformThatAllowYouTo: {
    en: `
    <br/>
    Konsarutanto is the platform that allows you to find the professional you are looking for and immediately schedule a first approach virtual meeting with him. With that you ensure if it is the professional you need quickly and efficiently.
    <br/><br/>
    I am a user and I am looking for a professional <a href='/faq'> (FAQ What is Konsarutanto?) </a>
    <br/><br/>
    I want to publish my profile as a professional <a href='/faq/#whySignMeUp'>  ( FAQ I'm professional. Why sign me up? )</a> `,
    es: `
    <br/>
    Konsarutanto es la plataforma que permite encontrar al profesional que estas buscando y agendar inmediatamente una reunión virtual de primera aproximación con el. Con eso aseguras si es el profesional que necesitas de forma rápida y eficiente.
    <br/><br/>
    Soy usuario y busco a un profesional <a href='/faq'> ( FAQ ¿Qué es Konsarutanto? ) </a>
    <br/><br/>
    Quiero publicar mi perfil como profesional <a href='/faq/#whySignMeUp'> ( FAQ Soy un profesional. ¿Por qué incribirme? ) </a>`,
    pr: `
    <br/>
    Konsarutanto é a plataforma que permite encontrar o profissional que procura e agendar de imediato uma primeira reunião virtual com ele. Com isso você garante se é o profissional que você precisa com rapidez e eficiência.
    <br/><br/>
    Sou usuária e procuro um profissional <a href='/faq'>LINK  </a>
    <br/><br/>
    Quero publicar meu perfil como profissional <a href='/faq/#whySignMeUp'> LINK </a>`,
  },
};

// "HOW IT WORKS
