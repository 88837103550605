import { useEffect, useState, useRef, lazy, Suspense } from "react";
import { bgColor, primeBlue } from "../env";
import GridOfCards from "../Components/GridOfCards";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useToaster } from "rsuite";
import { useContext } from "react";
import { UserContext } from "../context/userContext";
import languageSheet from "../language";
import { LanguageContext } from "../context/languageContext";
import { CircularProgress } from '@mui/material'
import '../App.css'
import { FilterSkeleton, Footers } from '../Components/Loading'
const Filter = lazy(() => import('../Components/Filter/Filter'))
const Footer = lazy(() => import('../Components/Footer'))
const apiHost = process.env.REACT_APP_BACKEND_URL;

const page_size = 12;



export default function Professionals({ user }) {
  const { id } = useParams()
  const toaster = useToaster();
  const navigation = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { setUser } = useContext(UserContext)
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const [page, setPage] = useState(1)

  const [professionals, setProfessionals] = useState([])             //Stores all professionals and is passed to the cards for mapping
  const [next, setNext] = useState('')
  const [loading, setLoading] = useState(false)

  const [url, setUrl] = useState("")
  const [profession, setProfession] = useState("")                    //Stores all professions
  const [isLoading, setIsLoading] = useState(true)
  const { lang, setLang } = useContext(LanguageContext);
  const isMounted = useRef(false)

  const professionData = searchParams.get("profession");
  const country = searchParams.get("country");
  const city = searchParams.get("city");
  const language = searchParams.get("language");

  const params = {
    key: "asdkfñlaskdGE",
    address: "evergreen avenue",
    city: "New York"
  };

  useEffect(() => {
    const apiUrl = new URL(`${backendURL}api/professional?page_size=${page_size}`);

    if (professionData) {
      apiUrl.searchParams.set("profession", professionData);
    }
    if (country) {
      apiUrl.searchParams.set("country", country);
    }
    if (city) {
      apiUrl.searchParams.set("city", city);
    }
    if (language) {
      apiUrl.searchParams.set("language", language);
    }

    {
      id ? id && (
        user ? fetch(`${apiUrl}&profession=${id}`, {
          headers: {
            Authorization: 'Bearer ' + user.tokens.access,
          },

        })
          .then(res => {

            if (res.status == 401) {
              const error = new Error('unauth')
              error.code = 401
              throw error
            } else {
              return res.json()
            }
          })
          .then(res => {
            setProfessionals([...res.results]);
            setNext(res.next)
            setLoading(false)
            setIsLoading(false)
          }) : fetch(`${apiUrl}&profession=${id}`)
            .then(res => {
              if (res.status == 401) {
                const error = new Error('unauth')
                error.code = 401
                throw error
              } else {
                return res.json()
              }
            })
            .then(res => {
              setProfessionals([...res.results]);
              setNext(res.next)
              setLoading(false)
              setIsLoading(false)
            })
      ) : fetch(apiUrl)
        .then(res => {
          return res.json();
        })
        .then((res) => {
          setProfessionals([...res.results]);
          setNext(res.next)
          setLoading(false)
          setIsLoading(false)
        })

    }

  }, [location, url])

  const onScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      fetchMoreData();
    } else {
      isMounted.current = true;
    }
  }, [page]);

  const fetchMoreData = () => {    //gets more data when user reaches bottom of the scroll bar

    setLoading(true)
    fetch(next)
      .then(res => {
        return res.json();
      })
      .then((res) => {

        setProfessionals([...professionals, ...res.results]);
        setNext(res.next)

        setLoading(false)
      });

  }

  return (
    <div>
      <div style={{ backgroundColor: bgColor }}>
        <div className="container my-5" style={{ minHeight: '60vh' }}>

          {
            <>

              <div className="mx-auto">
                <div className="title" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <h4
                    className="text-uppercase sliderHead"
                    style={{ color: primeBlue }}
                  >
                    {/* No Professionals Available in */}            {/* PROFESSIONalS */}
                    {professionals.length == 0 && isLoading == false ? languageSheet[30][lang] : languageSheet[7][lang]}
                  </h4>
                </div>
                <div>
                  <Suspense fallback={<FilterSkeleton />}>
                    <Filter page={"professionals"} Url={url} id={id} first={professionals} setUrl={(data) => { setUrl(data) }} />
                  </Suspense>

                  <GridOfCards data={id ? professionals : professionals} user={user} isProfession={false} isAllProfessional={true} />
                  {loading ? next ? <div className="d-flex justify-content-center"><CircularProgress /></div> : <></> : <></>}

                </div>
              </div>
            </>

          }
        </div>

        <Suspense fallback={<Footers />}>
          <Footer />
        </Suspense>
      </div>
    </div >
  );
}
