import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import languageSheet from "../language";
import { LanguageContext } from "../context/languageContext";
import Typography from "@mui/material/Typography";
import FbFooter from '../Components/Icons/FbFooter';
import InstagramIcon from '../Components/Icons/InstagramIcon'
export default function Footer() {
  const { lang, setLang } = useContext(LanguageContext);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();


  return (
    <div>
      <footer
        className=" text-lg-start text-muted blueGradient"
        style={{
          color: "white",
          position: "static",
          bottom: "0",
          width: "100%",
        }}
      >
        {/* Section: Social media */}
        <section className="d-flex justify-content-center justify-content-lg-between p-2">
          {/* Left */}

          {/* Right */}
        </section>

        <section className>
          <div className="container text-md-start mt-5">
            {/* Grid row */}
            <div className="row mt-3">
              <div className="col-md-4 col-lg-4 col-xl-2 mb-4">
                {/* LOGO */}
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <img src="./assets/LogoWhite-footer.svg" alt="Logo" />
                </Typography>
              </div>
              {/* Grid column */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase yellowHeads mb-4">
                  {languageSheet[134][lang]}
                  {/*About*/}
                </h6>
                <p>
                  <Link to="/tc" className="whiteTextLinks">
                    {languageSheet[16][lang]}
                    {/* Terms & Conditions */}
                  </Link>
                </p>
                <p>
                  <Link to="/privacy" className="whiteTextLinks">
                    {languageSheet[18][lang]}
                    {/* Privacy & Policy */}
                  </Link>
                </p>
                <p>
                  <Link to="/faq#FAQ" className="whiteTextLinks">
                    {languageSheet[17][lang]}
                    {/*FAQ*/}
                  </Link>
                </p>
              </div>
              {/* Grid column */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase yellowHeads mb-4">
                  {/* Services */}
                  {languageSheet[19][lang].split(",")[1]}
                </h6>
                <p>
                  <Link to="/faq#whatIsKonsarutanto" className="whiteTextLinks">
                    {languageSheet[13][lang]}
                    {/*How it Works*/}
                  </Link>
                </p>
                <p>
                  <Link
                    to="/faq#whatAreAvailablePlans"
                    className="whiteTextLinks"
                  >
                    {languageSheet[117][lang].split(",")[0]}
                    {/*Pricing Plans*/}
                  </Link>
                </p>
              </div>
              {/* Grid column */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase yellowHeads mb-4">
                  {languageSheet[137][lang]}
                  {/* Social */}
                </h6>
                <p className="whiteTextLinks">
                 <InstagramIcon/>
                  {languageSheet[20][lang].split(",")[1]}
                  {/* Instagram */}
                </p>
                <p className="whiteTextLinks">
                 <FbFooter/>
                  {languageSheet[20][lang].split(",")[2]}
                  {/* Facebook */}
                </p>
              </div>
              {/* Grid column */}

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* Links */}
                <h6 className="text-uppercase yellowHeads mb-4">
                  {/*Contact Us*/}
                  {languageSheet[136][lang]}
                </h6>
                <p>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@konsarutanto.com"
                    className="whiteTextLinks"
                  >
                    contact@konsarutanto.com
                  </a>
                </p>
              </div>
              {/* Grid column */}
            </div>
            {/* Grid row */}
          </div>
        </section>
        {/* Section: Links  */}
        {/* Copyright */}
        <div
          className="text-center p-4 whiteTextLinks"
        >
          © {currentYear} &nbsp;&nbsp;
          <a className="whiteTextLinks" href="https://mdbootstrap.com/">
            konsarutanto.com
          </a>
        </div>
        {/* Copyright */}
      </footer>
    </div>
  );
}
