export const validateUserData = (
  first_name,
  last_name,
  gender,
  languageForEmail,
  dob,
  maxDate,
  country,
  pages_langsheet,
  lang
) => {
  const date1 = new Date(dob);

  if (!first_name) return pages_langsheet?.validFirstName?.[lang];
  if (!last_name) return pages_langsheet?.validLastName?.[lang];
  if (!gender) return pages_langsheet?.validGender?.[lang];
  if (!languageForEmail) return pages_langsheet?.validLanguage?.[lang];
  if (!dob) return pages_langsheet?.enterValidDob?.[lang];
  if (date1 > maxDate) return pages_langsheet?.minValidAge?.[lang];
  if (!country) return pages_langsheet?.validCountry?.[lang];
  // Validating day, month, and year components of DOB
  const day = date1.getDate();
  const month = date1.getMonth() + 1;
  const year = date1.getFullYear();

  if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1900) {
    return pages_langsheet?.enterValidDob?.[lang];
  }

  // Return null if all inputs are valid
  return null;
};
