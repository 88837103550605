import React from "react";
import FileUploadError from "../../../Components/Icons/FileUploadError";
import FileUploadSuccess from "../../../Components/Icons/FileUploadSuccess";
import FileUploadIcon from "../../../Components/Icons/FileUploadIcon";
import "./CreateAndEditAds.css";
import FileIcon from "../../../Components/Icons/FileIcon";
import FileUploadInfo from "../../../Components/Icons/FileUploadInfo";
import { Message, toaster, useToaster } from "rsuite";
import { useEffect } from "react";
import languageSheet from "../../../language";
import { LanguageContext } from "../../../context/languageContext";
import { useContext } from "react";


const FileUploader = ({
  idPrefix,
  label,
  maxFileSizeMB,
  file,
  fileError,
  success,
  handleFileChange,
  removeFile,
  isMultiple = false,
  infoMessage,
  
}) => {
  const { lang, setLang } = useContext(LanguageContext)
  const toaster = useToaster()
  useEffect(() => {
    
    if(fileError){
        toaster.push(
          <Message showIcon type="error">
            {fileError}
          </Message>,
          { placement: "bottomEnd", duration: 3000 }
        );
      }
    
  }, [fileError]);
 
  return (
    <>
      <div className=" my-3 align-items-center ">
        <div className="col-md-4 w-100 mb-4 mt-4 littleHead">{label}</div>
        <div
          className="col-md-8 w-100 p-3 file-upload-container"
          onClick={() => document.getElementById(`${idPrefix}Input`).click()}
        >
          <label className="custom-file-upload cursor-pointer">
            {fileError ? (
              <FileUploadError />
            ) : success ? (
              <FileUploadSuccess />
            ) : (
              <FileUploadIcon />
            )}

            {fileError ? (
              <div>
                {/*  The uploaded file had an error. Please replace the file and */}
                {languageSheet[159][lang]}{" "}

                {/* try again */}
                <span className="cursor-pointer text-danger">{languageSheet[160][lang]}.</span>
              </div>
            ) : success ? (
              // File successfully uploaded
              <div>{languageSheet[158][lang]}.</div>
            ) : (
              <>
              {/* Click here to upload */}
                <div className="cursor-pointer upload-font">{languageSheet[152][lang]}</div>
                <div className="upload-font mt-0">
                  {/* Max upload file size */}
                  ({languageSheet[153][lang]}: {maxFileSizeMB}MB, Type: pdf, jpg, png)
                </div>
              </>
            )}
          </label>
          <input
            id={`${idPrefix}Input`}
            type="file"
            accept=".pdf,.jpg,.png"
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple={isMultiple}
          />

          {/* Error and success messages */}
        </div>
        {!fileError &&
          !success &&
          ((!isMultiple && !file) ||
            (isMultiple && (!file || file.length === 0))) && (
            <div className="info-message d-flex gap-1 mt-1">
              <div>{infoMessage && <FileUploadInfo />}</div>
              {infoMessage}
            </div>
          )}
        {fileError &&!isMultiple && (
          <div
            id={`${label}Input`}
            className="alert alert-danger file-upload-prompt text-danger d-flex justify-content-between align-items-center mt-2"
          >
            <div className="d-flex align-items-center gap-3">
              <FileIcon />
              {file?.name}
            </div>
            <div className="d-flex align-items-center gap-3">
              {(file?.size / 1024 / 1024).toFixed(2)} MB
              <button className="btn btn-sm text-danger" onClick={removeFile}>
                X
              </button>
            </div>
          </div>
        )}

        {!isMultiple && !fileError && file && (
          <div
            id={`${label}Input`}
            className="alert file-upload-success-prompt d-flex justify-content-between align-items-center mt-2"
          >
            <div className="d-flex align-items-center gap-3">
              <FileIcon />
              {file?.name}
            </div>
            <div className="d-flex align-items-center gap-3">
              {(file?.size / 1024 / 1024).toFixed(2)} MB
              <button className="btn btn-sm" onClick={removeFile}>
                X
              </button>
            </div>
          </div>
        )}
        {isMultiple &&
          file &&
          file.map((individualFile, index) => (
            <React.Fragment key={index}>
              {fileError && (
                <div
                  id={`${label}Input-${index}`}
                  className="alert alert-danger file-upload-prompt text-danger d-flex justify-content-between align-items-center mt-2"
                >
                  <div className="d-flex align-items-center gap-3">
                    <FileIcon />
                    {individualFile?.name}
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    {(individualFile?.size / 1024 / 1024).toFixed(2)} MB
                    <button
                      className="btn btn-sm text-danger"
                      onClick={() => removeFile(index)}
                    >
                      X
                    </button>
                  </div>
                </div>
              )}

              {!fileError && (
                <div
                  id={`${label}Input-${index}`}
                  className="alert file-upload-success-prompt d-flex justify-content-between align-items-center mt-2"
                >
                  <div className="d-flex align-items-center gap-3">
                    <FileIcon />
                    {individualFile?.name}
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    {(individualFile?.size / 1024 / 1024).toFixed(2)} MB
                    <button
                      className="btn btn-sm"
                      onClick={() => removeFile(index)}
                    >
                      X
                    </button>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
    </>
  );
};

export default FileUploader;
