import { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { grey, primeBlue, textBlue, textSecondary } from "../env";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import languageSheet from "../language";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { LanguageContext } from "../context/languageContext";
import "../App.css";
import { components_langsheet } from "./Language/ComponentsLangsheet";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import moment from "moment";
import dayjs from "dayjs";
import { Message, useToaster } from "rsuite";
import MessageUserExpired, { AppointmentUpdated } from "./MessageUserExpired";
import ClockIcon from "./Icons/ClockIcon";
const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function DialogPop(props) {
  const navigate = useNavigate();
  const { onClose, selectedValue, open, redirectOnSuccess, state } = props;
  const handleClose = (reason) => {
    if (reason === "backdropClick") return;
    onClose();
  };

  return (
    <Dialog onClose={(event, reason) => handleClose(reason)} open={open}>
      <div
        className="d-flex row justify-content-center text-center m-0 diapop_up"
        style={{ width: "350px" }}
      >
        <div className="pt-4">
          {selectedValue === "success" ? (
            <img src={"./assets/" + props.imageToShow} alt="error image" />
          ) : (
            <img src={"./assets/" + props.imageToShow} alt="error image" />
          )}
        </div>
        <div className="littleHead py-3">{props.title}</div>
        <div style={{ color: grey, width: "209px" }}>{props.content}</div>
        <div>
          <button
            className="btn btn-secondary text-uppercase py-2 px-5 my-4 shadow-lg primaryButton"
            style={{ textAlign: "center", backgroundColor: primeBlue }}
            onClick={() => {
              navigate(redirectOnSuccess, { state: { from: state.from } });
            }}
          >
            {props.btnName}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

/**
 * This will return 1, -1, 2
 *
 * Where 1 - is for first button, -1 - is for second button, 2 - is for third button
 *
 * Third is optional and can be omitted
 */
export function DialogWithOptions(props) {
  const { onClose, selectedValue, open, redirectOnSuccess, image } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleItemClick = (selectedValue) => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div
        className="d-flex row justify-content-center text-center m-0 py-3 dialog_box"
        style={{ width: "325px" }}
      >
        <div className="pt-4">
          {image && <img src={image} alt="shield image" />}
        </div>
        <div className="littleHead py-3">{props.title}</div>
        <div style={{ color: textSecondary, width: "209px" }}>
          {props.content && props.content}
          {props.dateObj && (
            <div className="content" style={{ textAlign: "start" }}>
              <p>
                <img
                  src={window.location.origin + "/assets/calenderIcon.svg"}
                  style={{ marginRight: "10px" }}
                  alt="calendarIcon"
                />
     
                {/* {moment(props.dateObj.date).format('LL')} */}
                {props?.dateObj?.date
                  ? new Date(props?.dateObj?.date).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "2-digit",
                    })
                  : ""}
              </p>
              <p>
                <img
                  src={window.location.origin + "/assets/timeIcon.svg"}
                  style={{ marginRight: "10px" }}
                  alt="TimeIcon"
                />

                {/* {moment(props.dateObj.slot.slice(0,-3)).format('LL')} */}
                {props.dateObj.slot?.slice(0, -3)}
              </p>
            </div>
          )}
        </div>
        <div className="col my-3">
          <button
            className="text-uppercase mx-2 px-4 py-1 shadow-lg primaryButton primary_btn confirm_btn"
            style={{ textAlign: "center", backgroundColor: primeBlue }}
            onClick={() => {
              handleItemClick(1);
            }}
          >
            {props.btnFirst}
          </button>
          <button
            className="text-uppercase mx-2 px-4 py-1  shadow-lg secondaryButton secondary_btn"
            style={{ textAlign: "center" }}
            onClick={() => {
              handleItemClick(-1);
            }}
          >
            {props.btnSecond}
          </button>
        </div>
        {props.btnThird && (
          <>
            <Divider className="align-items-start p-0 mutedFont">OR</Divider>
            <div className="my-3">
              <button
                className="text-uppercase mx-2 px-4 py-1  shadow-lg primaryButton"
                style={{ textAlign: "center", backgroundColor: primeBlue }}
                onClick={() => {
                  handleItemClick(2);
                }}
              >
                {props.btnThird}
              </button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

export function DialogSuccess(props) {
  const navigation = useNavigate();
  const { lang, setLang } = useContext(LanguageContext);
  const { onClose, success, redirectOnSuccess, error } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={success}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        className="d-flex row justify-content-center align-content-center text-center m-0 container_div"
        style={{ width: "330px", height: "330px" }}
      >
        {error ? (
          ""
        ) : (
          <div className="pt-4">
            <img
              src={window.location.origin + "/assets/" + props.imageToShow}
              alt="success image"
            />
          </div>
        )}
        <div className="littleHead py-3" style={{ width: "200px" }}>
          {props?.title}
        </div>
        {error ? (
          " "
        ) : (
          <div className="pt-4">
            <button
              className="text-uppercase mx-2 px-2 py-1 shadow-lg primaryButton"
              style={{ textAlign: "center", backgroundColor: primeBlue }}
              onClick={() => {
                navigation("/");
              }}
            >
              {languageSheet[26][lang].split(",")[1]}
              {/* Continue */}
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export function DialogWithDateTime(props) {
  const { onClose, selectedValue, open, redirectOnSuccess } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleItemClick = (selectedValue) => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        className="d-flex row justify-content-center text-center m-0 py-5"
        style={{ width: "250px" }}
      >
        <div
          className="text-uppercase"
          style={{ color: textSecondary, width: "209px" }}
        >
          {props.proName}
        </div>
        <div className="popupHead py-3">{props.title}</div>

        <div className="row align-items-center">
          <div className="col p-0">
            <img src="./assets/calendar.svg" alt="calendar icon" />
          </div>
          <div className="col-9 text-start textSecondary p-0">{props.date}</div>
        </div>
        <div className="row align-items-center">
          <div className="col p-0">
           <ClockIcon/>
          
          </div>
          <div className="col-9 text-start textSecondary p-0">{props.time}</div>
        </div>

        <div className="col mt-4">
          <button
            className="btn text-uppercase mx-2 shadow-lg primaryButton"
            style={{ textAlign: "center", backgroundColor: primeBlue }}
            onClick={() => {
              handleItemClick(1);
            }}
          >
            {props.btnFirst}
          </button>
          <button
            className="btn text-uppercase mx-2 shadow-lg secondaryButton"
            style={{ textAlign: "center" }}
            onClick={() => {
              handleItemClick(-1);
            }}
          >
            {props.btnSecond}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export function CancelSubscription(props) {
  const navigation = useNavigate();
  const { lang, setLang } = useContext(LanguageContext);
  const { onClose, open, cancelSubscriptionFunc } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        className="d-flex row justify-content-center align-content-center text-center m-0 container_div"
        style={{ width: "330px", height: "330px" }}
      >
        <div className="pt-4">
          <img
            src={window.location.origin + "/assets/" + props.imageToShow}
            alt="success image"
          />
        </div>
        <div className="littleHead py-3" style={{ width: "200px" }}>
          {props?.title}
        </div>

        <div
          className="d-flex gap-3 pt-4 justify-content-center"
          style={{ width: "100%" }}
        >
          <button
            className="text-uppercase mx-2 px-2 py-1 shadow-lg primaryButton"
            style={{
              textAlign: "center",
              backgroundColor: primeBlue,
              width: "30%",
            }}
            onClick={() => {
              handleClose();
            }}
          >
            {"NO"}
            {/* Continue */}
          </button>
          <button
            className="text-uppercase mx-2 px-2 py-1 shadow-lg primaryButton"
            style={{
              textAlign: "center",
              backgroundColor: primeBlue,
              width: "30%",
            }}
            onClick={() => {
              cancelSubscriptionFunc();
            }}
          >
            {"YES"}
            {/* Continue */}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export function FreePlan({ open, handleClose }) {
  let navigation = useNavigate();
  const { lang, setLang } = useContext(LanguageContext);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="text-center">
            {/* <img src="./assets/paymentSuccess.svg" alt="payment success" /> */}
            <div
              className="mt-3"
              style={{ color: textBlue, fontWeight: "500" }}
            >
              <div>
                {/* Amount Paid: 0 */}
                {languageSheet[115][lang].split(",")[1]}: 0
              </div>
              <div>
                {/* Free plan has been added successfully */}
                {components_langsheet?.standardPlanAddedSuccessfully?.[lang]}
              </div>
            </div>
            <div style={{ color: textSecondary }}>
              {/* Please check your email for order details. */}
              {languageSheet[115][lang].split(",")[2]}
            </div>
            <button
              className="primaryButton py-3 mt-3 "
              onClick={() => navigation("/userLandingPage")}
            >
              {/* BACK TO DASHBOARD */}
              {languageSheet[114][lang].split(",")[3]}
            </button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export function RescheduleAppointment({
  open,
  handleClose,
  user,
  id,
  appointment,
  rescheduleAppointmentID,
  setToggle,
  toggle,
  getAppointments,
}) {
  let navigation = useNavigate();
  const { lang, setLang } = useContext(LanguageContext);

  const [date, setDate] = useState(dayjs());
  const [slots, setSlots] = useState([]);
  const [activeSlot, setActSlot] = useState(null);
  const [daysUA, setDUA] = useState([]);
  const [schedule, setSchedule] = useState(null);
  const [loader, setLoader] = useState(false);
  const [type, setType] = useState("info");
  const [placement, setPlacement] = useState("topEnd");
  const toaster = useToaster();

  useEffect(() => {
    let myHeaders = new Headers();
    if (user) {
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    }
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(
      apiHost +
        "api/schedule/" +
        id +
        `/?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      requestOptions
    )
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then(async (result) => {
        delete result.id;
        delete result.professional;

        let tempDUA = [];
        for (let key in result) {
          if (result[key].length == 0) {
            tempDUA.push(key);
          }
        }
        let bookedSlots = await getBookedAppointment(date);

        setDUA([...tempDUA]);
        setSchedule(result);

        let currentDay = moment(date).format("dddd").toLowerCase();

        //removing bookedslots from result[currentDay] slots
        let finalSlots = result[currentDay];

        finalSlots = finalSlots.filter(function (el) {
          return !bookedSlots.includes(el);
        });
        //if currentDay == today removing slots which have already pased
        let today = new Date();
        if (
          date.$d.getDate() == today.getDate() &&
          date.$d.getMonth() == today.getMonth() &&
          date.$d.getFullYear() == today.getFullYear()
        ) {
          finalSlots = finalSlots.filter((slot) => {
            let slotTime = slot.split(":");
            let now = new Date();
            if (parseInt(slotTime[0]) <= now.getHours()) {
              if (
                parseInt(slotTime[1]) > now.getMinutes() &&
                now.getHours() == parseInt(slotTime[0])
              ) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          });
        }
        setSlots([...finalSlots]);
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });

          // setUser(null)
        } else {
        }
      });
  }, [id]);

  const message = (
    <Message showIcon type={type} closable>
      {type}Appointment Rescheduled Succesfully{placement}.
    </Message>
  );

  const updateSchedule = () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    myHeaders.append("Content-Type", "application/json");

    let y = `${date.$y}`;
    let m = date.$M < 9 ? `0${date.$M + 1}` : `${date.$M + 1}`;
    let d = date.$D < 10 ? `0${date.$D}` : `${date.$D}`;
    let dateStringKey = y + "-" + m + "-" + d;

    fetch(apiHost + "api/appointment/" + `${rescheduleAppointmentID}` + "/", {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        start_time: activeSlot.slice(0, -3),
        date: dateStringKey,
      }),
    })
      .then((res) => {
        if (res?.status == 200) {
          getAppointments();
          return toaster.push(
            <Message showIcon type={type} closable>
              {" "}
              {type}  Appointment Rescheduled Succesfully.
            </Message>,
            { placement: "bottomEnd" }
          );
         
        } else
          return res.json().then((response) => {
            throw new Error(response.error);
          });
      })
      .catch((error) =>
        toaster.push(
          <Message className="z-100" type="error" showIcon>
            {error.message}
          </Message>,
          { placement: "bottomEnd" }
        )
      );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
    height: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const getBookedAppointment = async (date) => {
    let myHeaders = new Headers();

    if (user) {
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    }
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    let slotsOfDate = await fetch(
      apiHost + "api/booked-appointments/" + id,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        let y = `${date.$y}`;
        let m = date.$M < 9 ? `0${date.$M + 1}` : `${date.$M + 1}`;
        let d = date.$D < 10 ? `0${date.$D}` : `${date.$D}`;
        let dateStringKey = y + "-" + m + "-" + d;
        let arrtoReturn;
        if (res[dateStringKey]) {
          arrtoReturn = res[dateStringKey];
        } else {
          arrtoReturn = [];
        }

        //converting time from utc to local
        for (let i = 0; i < arrtoReturn.length; i++) {
          let t = arrtoReturn[i].split(":");
          let local = moment
            .utc(`${dateStringKey} ${arrtoReturn[i]}`)
            .local()
            .format("YYYY-MM-DD HH:mm:ss");
          arrtoReturn[i] = local.split(" ")[1];
        }
        if (arrtoReturn) {
          return arrtoReturn;
        } else {
          return [];
        }
      })
      .catch((err) => {
        return [];
      });
    return slotsOfDate;
  };

  const onChangeDate = async (newDate) => {
    setLoader(true);

    let bookedSlots = await getBookedAppointment(newDate);
    let currentDay = moment(newDate.$d).format("dddd").toLowerCase();

    setDate(newDate);
    let finalSlots = schedule[currentDay];
    finalSlots = finalSlots.filter(function (el) {
      return !bookedSlots.includes(el);
    });
    //if currentDay == today removing slots which have already pased
    let today = new Date();
    if (
      newDate.$d.getDate() == today.getDate() &&
      newDate.$d.getMonth() == today.getMonth() &&
      newDate.$d.getFullYear() == today.getFullYear()
    ) {
      finalSlots = finalSlots.filter((slot) => {
        let slotTime = slot.split(":");
        let now = new Date();
        if (parseInt(slotTime[0]) <= now.getHours()) {
          if (
            parseInt(slotTime[1]) > now.getMinutes() &&
            now.getHours() == parseInt(slotTime[0])
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }
    setSlots([...finalSlots]);
    setActSlot(null);
    setLoader(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <box>
            <span className="littleHead">
              {components_langsheet?.currentAppointment?.[lang]}
            </span>
            <div className="mt-4">
              <div className="professional d-flex gap-3">
                <span className="label">
                  {components_langsheet?.professionalName?.[lang]}
                </span>
                <p style={{ lineHeight: "16px" }}>
                  {appointment?.professional?.[0]?.fname +
                    " " +
                    appointment?.professional?.[0]?.lname}
                </p>
              </div>
              <div className="d-flex gap-4">
                <div className="date d-flex gap-2">
                  <span className="label">
                    {components_langsheet?.date?.[lang]}
                  </span>
                  <p style={{ lineHeight: "16px" }}>
                    {appointment?.date?.split("-")[2] +
                      "-" +
                      appointment?.date?.split("-")[1] +
                      "-" +
                      appointment?.date?.split("-")[0]}
                  </p>
                </div>
                <div className="time d-flex gap-2">
                  <span className="label">
                    {components_langsheet?.time?.[lang]}
                  </span>
                  <p style={{ lineHeight: "16px" }}>
                    {appointment?.start_time}
                  </p>
                </div>
              </div>
            </div>
          </box>

          <p className="littleHead">
            {/* SCHEDULE: */}
            {languageSheet[64][lang]}:
          </p>
          <div className="d-md-flex justify-content-between ">
            <div className="col-lg-5 col-md-12 my-3 " style={{ width: "60%" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                className="calenderMain mx-0"
              >
                <CalendarPicker
                  disablePast={true}
                  className="calenderMain mx-0"
                  date={date}
                  onChange={onChangeDate}
                  dayOfWeekFormatter={(day) =>
                    day.substring(0, 3).toUpperCase()
                  }
                  shouldDisableDate={(day) => {
                    let dddd = moment(day.$d).format("dddd").toLowerCase();
                    let rr = false;

                    daysUA.forEach((d) => {
                      if (d === dddd) {
                        rr = true;
                      }
                    });

                    return rr;
                  }}
                />
              </LocalizationProvider>
            </div>

            <div
              className="col-lg-5 col-md-12  my-3 dateRowBox mx-3 appointmentsBox"
              style={{
                backgroundColor: "white",
                textAlign: "center",
                minHeight: "334px",
                width: "200px",
              }}
            >
              {/* appointments scroll */}
              <div className=" p-3">
                <p className="mb-2 labelSecondary">
                  {languageSheet[34][lang].split(",")[0]}

                  {/* SELECT TIME */}
                </p>
                <p className="mb-2 labelSecondary">
                  {languageSheet[34][lang].split(",")[1]}

                  {/* AVAILABLE SLOTS */}
                </p>
              </div>
              {slots.length == 0 ? (
                <p>
                  {/* No slots are available for selected date */}
                  {languageSheet[109][lang]}
                </p>
              ) : null}
              <div className="scrollItems">
                {slots.map((interval) => {
                  return (
                    <div
                      className={`py-2 scrollItem mb-2 ${
                        interval == activeSlot ? "activeSlot" : null
                      }`}
                      style={{
                        border: " 1px solid #0B2435",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setActSlot(interval);
                      }}
                    >
                      <img
                        src={
                          window.location.origin + "/assets/statusCircle.svg"
                        }
                        className="statusCircle d-inline-block "
                        style={{ verticalAlign: "initial" }}
                        alt="StatusCircle"
                      />
                      <p style={{ display: "contents", cursor: "pointer" }}>
                        {interval.slice(0, -3)}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="my-3 d-flex flex-wrap">
            <buttons
              className="primaryButton2 py-2 px-3 mx-2 my-2"
              onClick={() => {
                if (id && activeSlot) {
                  updateSchedule();
                  handleClose();
                } else {
                }
              }}
            >
              {components_langsheet?.changeYourSlot?.[lang]?.toUpperCase()}

              {/* UPDATE YOUR SLOT */}
            </buttons>
            {user && (
              <buttons
                className="secondaryButton py-2 px-3 mx-2 my-2"
                onClick={() => {
                  handleClose();
                }}
              >
                {languageSheet[35][lang].split(",")[1]}

                {/* CANCEL */}
              </buttons>
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
