import React from 'react'

const MarkAsReadIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.38783 2.91797C4.27949 2.91797 2.91699 4.36214 2.91699 6.59797V13.4046C2.91699 15.6405 4.27949 17.0846 6.38783 17.0846H13.6112C15.7203 17.0846 17.0837 15.6405 17.0837 13.4046V6.59797C17.0837 4.36214 15.7203 2.91797 13.612 2.91797H6.38783ZM13.6112 18.3346H6.38783C3.56366 18.3346 1.66699 16.353 1.66699 13.4046V6.59797C1.66699 3.64964 3.56366 1.66797 6.38783 1.66797H13.612C16.4362 1.66797 18.3337 3.64964 18.3337 6.59797V13.4046C18.3337 16.353 16.4362 18.3346 13.6112 18.3346Z" fill="#123A55"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.01133 12.6032C8.85216 12.6032 8.69133 12.5424 8.56966 12.4199L6.59133 10.4424C6.34716 10.1982 6.34716 9.80323 6.59133 9.55906C6.83549 9.3149 7.23049 9.3149 7.47466 9.55906L9.01133 11.0941L12.5247 7.58156C12.7688 7.3374 13.1638 7.3374 13.408 7.58156C13.6522 7.82573 13.6522 8.22073 13.408 8.4649L9.45299 12.4199C9.33133 12.5424 9.17133 12.6032 9.01133 12.6032Z" fill="#123A55"/>
    </svg>
    
  )
}

export default MarkAsReadIcon