import React, { useState, useContext, useRef, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import languageSheet from "../../language";
import { primeBlue } from "../../env";
import { LanguageContext } from "../../context/languageContext";
import Cropper from 'react-easy-crop'
import { getCroppedImg } from './CropFunction'
import './CropImage.css'
import { components_langsheet } from '../Language/ComponentsLangsheet';
const apiHost = process.env.REACT_APP_BACKEND_URL;


function CropImage(props) {
  const { lang, setLang } = useContext(LanguageContext);
  const uploader = useRef(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [blob, setBlob] = useState(null)
  const [inputImg, setInputImg] = useState('')


  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      inputImg,
      croppedAreaPixels
    )
    setBlob(croppedImage)
  }

  const onInputChange = (e) => {

    const file = props.image
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      setInputImg(reader.result)
    }, false)

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    onInputChange()
  }, [props.closeState])


  function blobToFile(blob, fileName) {
    const fileType = blob.type.split('/')[1];
    const file = new File([blob], fileName + '.' + fileType, { type: blob.type });
    return file;
  }

  const handleSubmitImage = (e) => {
    var file = blobToFile(blob, "image1")
    props.getFinalImage(file)
    props.close()
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className='d-flex flex-column' id='crop-popup'>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h6
              className="text-uppercase sliderHead"
              style={{ color: primeBlue }}
            >
              {/* Profile */}
              {components_langsheet?.profileImage?.[lang]}
            </h6>
          </Typography>
          {inputImg ? <Box className='d-flex flex-column' id='image-box'>
            
            <div id='cropper-div' style={{width:'100%'}} className='d-flex justify-content-center alignt-item-center'>
              {inputImg ?
                <Cropper
                
                  image={inputImg}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                /> : <></>}
            </div>
          </Box> : <img style={{ aspectRatio: "1/1" }} src={props?.userProfile?.user?.profile_image} alt='ProfileImg' />
            ? <img style={{ aspectRatio: "1/1" }} src={apiHost.slice(0, -1) + props?.userProfile?.user?.profile_image} alt='ProfileImg' />
            : <img src="./assets/profileImg.svg" alt='ProfileImg' />}
          <Box>
                                                                                                                                            {/* save your photo */}
            <Button variant="contained" className='d-flex justify-content-center mt-4' id='submit-btn' onClick={handleSubmitImage}>{languageSheet[140][lang]}</Button>
            <input
              type="file"
              id="imgupload"
              accept="image/jpeg, image/png"
              style={{ display: "none" }}
              ref={uploader}
              onChange={onInputChange}
            />
          </Box>
        </Box>
      </Modal>
    </div >
  )
}

export default CropImage