import { TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import { grey, primeBlue } from "../../env";
import { useNavigate, Link } from "react-router-dom";
import { LanguageContext } from '../../context/languageContext'
import languageSheet from '../../language'
import ForgotPasswordImage from "../../Components/Icons/ForgotPasswordImage";

const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function PasswordForgot({ setJoinEmail, setFP }) {

  const [email, setEmail] = useState('')
  const navigation = useNavigate()
  const { lang, setLang } = useContext(LanguageContext)
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [isGoogleUser, setIsGoogleUser] = useState(null)
  const genCode = (e) => {
    e.preventDefault()

    fetch(apiHost + "auth/check-social-user?email=" + email, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        setIsGoogleUser(result.true ? true : false);
        if (result.false)
          emailVerification()
      });
  }

  const emailVerification = () => {
    fetch(apiHost + 'auth/forget-password/' + email)
      .then((res) => {
        if (res.status === 200) {
          return res
        }
      })
      .then(res => res.json())
      .then((res) => {
        setJoinEmail(email)
        setFP(true)
        navigation('/verifyEmail')
      })
  }
  return (
    <div className="forgot-password blueGradient p-3 pb-5 vh-100">
      <Link to="/">
        <img src="./assets/LogoWhite.png" alt="Logo" />
      </Link>

      <div
        className="w-100 p-3 border rounded shadow mx-auto my-4"
        style={{ maxWidth: "430px", backgroundColor: "white" }}
      >
        <h4
          className="sliderHead text-uppercase text-center pt-3"
          style={{ color: primeBlue, fontSize: "1.7rem" }}
        >
          {/* Forgot password */}
          {languageSheet[24][lang]}
        </h4>
        <div className="d-flex py-4 mb-3 justify-content-center">
         
          
          <ForgotPasswordImage/>
          
        </div>
        <div className="textSecondary px-5 pt-2" style={{ color: grey }}>
          
          {/* Please enter the 4 digit code sent to yourmail@example.com */}
          {languageSheet[99][lang]}
        </div>
        <div className="container mt-3">
          <form className="row mt-3 px-4 pt-3">
            <TextField
              required
              id="email"
              label="Enter your email"
              variant="outlined"
              size="small"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            {isGoogleUser ?
              <div
                className="subtitle"
                style={{ fontSize: "16px", color: "red" }}

              >
                {/* Password cannot be reset for accounts created using Google or Facebook account */}
                {languageSheet[100][lang]}
              </div> : <></>
            }

            <button
              className="btn btn-secondary text-uppercase py-2 w-100 my-4"
              style={{ textAlign: "center", backgroundColor: primeBlue }}
              onClick={(e) => { genCode(e) }}
            >
              {/* Confirm */}
              {languageSheet[36][lang].split(',')[1]}
            </button>

          </form>
        </div>
        <hr />
        <div className="signin" style={{ textAlign: "center", cursor: "pointer" }} onClick={() => { navigation('/signIn') }}>
          <p className="textSecondary mb-0" style={{ color: grey }}>
            {/* Change Email */}
            {languageSheet[50][lang]}
          </p>
        </div>
      </div>
      <br />
      <br />
      <div
        className="text-center position-relative bottom-0 whiteTextLinks"
      >
        © {currentYear} &nbsp;&nbsp;
        <a className="whiteTextLinks" href="https://mdbootstrap.com/">
          konsarutanto.com
        </a>
      </div>
    </div>
  );
}
