import React, { useState, useContext } from "react";
import { primeBlue } from "../../env";
import { Link, useNavigate, useLocation } from "react-router-dom";
import validator from "validator";
import { useGoogleLogin } from "@react-oauth/google";
import { Message, useToaster } from 'rsuite';
import MessageUserExpired from "../../Components/MessageUserExpired";
import DialogPop from "../../Components/DialogPop";
import languageSheet from "../../language";
import { LanguageContext } from "../../context/languageContext";
import './Join.css'
import GoogleIcon from "../../Components/Icons/GoogleIcon";
const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function Join(props) {
  const { setJoinEmail, setUser } = props;
  const toaster = useToaster();
  const navigation = useNavigate();
  const { lang, setLang } = useContext(LanguageContext);
  const location = useLocation();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const joinContinue = () => {
    if (email !== "") {
      fetch(apiHost + "auth/register/" + email.toLowerCase() + `?language=${languageSheet[132][lang]}`)
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((res) => {
          if (res.success) {
            setJoinEmail(email);
            navigation("/verifyEmail", {
              state: { from: location.state ? location.state.from : null },
            });
          } else if (res.error) {
            setError(res.error);
          }
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
            setUser(null);
          }
        });
    }
  };

  const validateEmail = (e) => {
    if (!validator.isEmail(e.target.value)) {
      setError("Not a valid Email");
    } else {
      setError(null);
    }
  };


  //social
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      successGoogle(codeResponse);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const successGoogle = (response) => {
    fetch(apiHost + "auth/google/", {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        access_token: response.access_token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (res?.error) {
          toaster.push(<Message >{res?.error}</Message>, { placement: "topEnd", duration: 2500 });
        }
        let newUser = {
          tokens: {
            access: res.access_token,
            refresh: res.refresh_token,
          },
          email: res.user.email,
          id: res.user.pk,
          name: res.user.first_name.concat(" ", res.user.last_name),
        };
        if (res.new_user) {
          setJoinEmail(res.user.email);
          navigation("/createProfile", {
            state: { from: location.state ? location.state.from : null },
          });
        } else {
          navigation(
            location.state ? location.state.from : "/userLandingPage"
          );
        }

        setUser(newUser);
        sessionStorage.setItem("user", JSON.stringify(newUser));
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };
  const failGoogle = (response) => {

  };

  const fbResponse = (response) => {
    fetch(apiHost + "auth/facebook/", {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        access_token: response.accessToken,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        //success logic
        let newUser = {
          tokens: {
            access: res.access_token,
            refresh: res.refresh_token,
          },
          email: res.user.email,
        };
        setUser(newUser);
        sessionStorage.setItem("user", JSON.stringify(newUser));
        fetch(apiHost + "auth/update-social-user/" + res.user.pk)
          .then((ress) => ress.json())
          .then((ress) => {
            if (ress.new_user) {
              setJoinEmail(res.user.email);
              navigation("/createProfile", {
                state: { from: location.state ? location.state.from : null },
              });
            } else {
              navigation("/userLandingPage");
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };

  return (
    <>
      <DialogPop />
      <div className="join-body blueGradient p-3 pb-5">
        <Link to="/">
          <img src="./assets/LogoWhite.png" alt="Logo" />
        </Link>
        <div
          className="w-100 p-3 border mx-auto my-4"
          style={{ maxWidth: "400px", backgroundColor: "white" }}
        >
          <h4
            className="sliderHead text-uppercase text-center"
            style={{ color: primeBlue }}
          >
            {languageSheet[2][lang]} Konsarutanto
          </h4>
          <div className="container mt-3">

            <button
              onClick={() => googleLogin()}
              className="p-2 w-100 my-2 rounded border border-dark"
              style={{ textAlign: "left" }}
            >
          <GoogleIcon/>
              <span className="ml-2" style={{ marginLeft: "10px" }}>
                {languageSheet[28][lang].split(",")[0]}
                {/* Continue with Google */}
              </span>
            </button>

            <div className="contaier-fluid mt-3">
              <div className="row">
                <div className="col-5">
                  <hr />
                </div>
                <div className="col-2" style={{ textAlign: "center" }}>
                  OR
                </div>
                <div className="col-5">
                  <hr />
                </div>
              </div>
            </div>
            <div className="emailBox mt-3">
              <input
                type="text"
                className="w-100 rounded border border-dark p-2"
                // email
                placeholder={languageSheet[27][lang].split(",")[0]}
                value={email}
                onChange={(e) => {
                  validateEmail(e);
                  setEmail(e.target.value);
                }}
              />
              {error && (
                <p className="text-danger text-capitalize mb-0">{error}</p>
              )}

              <button
                className="btn btn-secondary w-100 mt-3"
                style={{ textAlign: "center", backgroundColor: primeBlue }}
                onClick={error ? null : () => joinContinue()}
              >
                {/* Continue  */}
                {languageSheet[26][lang].split(",")[1]}
              </button>
            </div>
            <div className="txt mt-2">
              <p className="policy text-muted" style={{ textAlign: "center" }}>
                {/* By joining I agree to receive emails from Konsarutanto */}
                {languageSheet[29][lang]}
              </p>
            </div>
          </div>
          <hr />
          <div className="signin" style={{ textAlign: "center" }}>
            <p className="already mb-0">
              {/* Already a member?  */}
              {languageSheet[84][lang]}{" "}
              <Link to={"/signIn"} style={{ textDecoration: "none" }}>
                {/* Sign In */}
                <span className="mutedFont">{languageSheet[3][lang]}</span>{" "}
              </Link>
            </p>
          </div>
        </div>
        <br />
        <br />
        <div
          className="text-center p-4 pb-0 whiteTextLinks"
        >
          © {currentYear} &nbsp;&nbsp;
          <a className="whiteTextLinks" href="https://mdbootstrap.com/">
            konsarutanto.com
          </a>
        </div>
      </div>
    </>
  );
}
