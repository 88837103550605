import { useEffect, useState, useContext } from 'react'

import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

import Footer from '../../Components/Footer'
import { bgColor, primeBlue, textBlue, textSecondary, whiteBg } from '../../env'
import { useNavigate } from 'react-router-dom'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { LanguageContext } from '../../context/languageContext'
import languageSheet from '../../language'
import { Message, useToaster } from 'rsuite';
import { pages_langsheet } from '../Languages/PagesLangsheet'
import ReactGA from "react-ga4";

const ariaLabel = { 'aria-label': 'description' }

const apiHost = process.env.REACT_APP_BACKEND_URL

const steps = ['Inprocess', 'Certified']
export default function SubscriptionDetails({ proNew, profile, setSubPlan, user, setPaymentCompleted, paymentCompleted }) {
  const navigation = useNavigate()
  const toaster = useToaster();
  const { lang, setLang } = useContext(LanguageContext)
  const [plans, setPlans] = useState([])
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [planType, setPlanType] = useState('Yearly')
  const [planSwitch, setPlanSwitch] = useState(true)
  const [currentPlan, setCurrentPlan] = useState("")

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  const onClickPurchase = (plan, type) => {
    setSubPlan({ ...plan, "selected": type })

    if (plan && type) {

      if (currentPlan && currentPlan === 'Basic' && plan?.name && plan?.name == 'Basic') {
        toaster.push(<Message style={{ marginTop: '90px', position: "fixed" }}>{pages_langsheet?.cannotBasicPlan?.[lang]}</Message>, { placement: "topEnd" });
      }
      else if (plan?.name && plan?.name == 'Basic') {     //if the plan selected is basic then it will directly add it using free-plan api
        freePurchase(plan, type);
      }

      if (plan?.name && plan?.name == 'Standard') {   //else if it is not basic then it will take us to payment page
        navigation('/paymentPage')
      }

      if (plan?.name && plan?.name == 'Premium') {
        navigation('/paymentPage')
      }
    }
  }

  const currPlan = () => {
    fetch(apiHost + 'api/current-subscription', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.tokens.access}`,
        'Content-Type': 'application/json',
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setCurrentPlan(res.name)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const freePurchase = (plan, type) => {

    if (plan?.id) {
      fetch(apiHost + 'api/free-plan', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.tokens.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscription_plan: plan.id,
          professional: proNew?.id ? proNew?.id : profile?.id,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            ReactGA.event("purchase", {
              transaction_id: res?.paymentId,
              value: 0,
              currency: "USD",
              professionalId: `${proNew?.id ? proNew?.id : profile?.id}`,
              items: [{
                item_id: `${plan.id}`,
                item_name: "Basic Plan",
              }]
            });
            setPaymentCompleted(!paymentCompleted)
            sessionStorage.setItem("professional", true)
            handleOpen()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  useEffect(() => {
    currPlan()
    fetch(apiHost + 'api/subscription-plans')
      .then((res) => res.json())
      .then((res) => {
        setPlans(res)
      })
      .catch((err) => console.log(err))
  }, [])

  return (
    <div className='subscriptionDetails d-flex flex-column justify-content-between' style={{ backgroundColor: bgColor }}>
      <div className="container my-5" style={{ minHeight: '60vh' }}>
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {/* PRICING PLANS */}
          {languageSheet[117][lang].split(',')[0]}
        </h4>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                defaultChecked
                onChange={(e) => {
                  setPlanSwitch(!planSwitch)
                  setPlanType(planSwitch ? 'Monthly' : 'Yearly')
                }}
              />
            }
            label={pages_langsheet?.[planType]?.[lang].toUpperCase()}
            labelPlacement="start"
          />
        </FormGroup>
        <div className="row gx-3 justify-content-center">
          {plans.map((plan, i) => {
            return (
              <div className="col-md-4 mt-4" key={i + plan?.name}>
                <div
                  className="d-flex flex-column boxShadow"
                  style={{
                    backgroundColor: i + (1 % 2) == 0 ? whiteBg : 'white',
                    height: '100%',
                  }}
                >
                  <p
                    className="text-center textSecondary mt-3 mb-0 text-uppercase"
                    style={{ fontSize: '25px', fontWeight: '500' }}
                  >
                    {pages_langsheet?.[plan.name]?.[lang]}
                  </p>
                  <p className="pricingText mb-0 text-center">
                    <img
                      src="./assets/dollar.svg"
                      style={{ verticalAlign: 'super' }}
                      alt='DollorIcon'
                    />

                    {planType == 'Monthly' ? (
                      <>{plan?.mothly_price}/{pages_langsheet?.month?.[lang]}</>
                    ) : plan.name == 'Basic' ? (
                      <>{plan?.mothly_price}/{pages_langsheet?.year?.[lang]}</>
                    ) : (
                      <>{plan?.annual_price}/{pages_langsheet?.year?.[lang]}</>
                    )}
                  </p>

                  <p className="textSecondary text-center mt-0">
                    {planType == 'Monthly' ? (
                      <>
                        {plan?.number_of_short_meet && plan?.name == 'Premium'
                          ? 'UNLIMITED'
                          : plan?.number_of_short_meet}{' '}
                        {/* SESSIONS PER MONTH */}
                        {languageSheet[117][lang].split(',')[1]}
                      </>
                    ) : plan.name == 'Basic' ? (

                      <>{plan?.number_of_short_meet} {languageSheet[117][lang].split(',')[1]} {/* SESSIONS PER MONTH */}</>
                    ) : plan.name == 'Standard' ? (
                      <>{plan?.number_of_short_meet} {languageSheet[117][lang].split(',')[1]} {/* SESSIONS PER MONTH */}</>
                    ) : (
                      <>
                        {plan?.number_of_short_meet && plan?.name == 'Premium'
                          ? 'UNLIMITED'
                          : plan?.number_of_long_meet}{' '}
                        {/* SESSIONS PER MONTH */}
                        {languageSheet[117][lang].split(',')[1]}
                      </>
                    )}
                  </p>

                  <div className="callBtn mb-3 mt-auto  container">
                    {/* <Link to={"/paymentPage"}> */}
                    <button
                      className="w-100 primaryButton2 py-2 mt-3"
                      onClick={() => onClickPurchase(plan, planType)}
                    >
                      {/* Purchase */}
                      {languageSheet[117][lang].split(',')[2]}
                    </button>
                    {/* </Link> */}
                  </div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <Box sx={style}>
                        <div className="text-center">
                          <img
                            src="./assets/paymentSuccess.svg"
                            alt="payment success"
                          />
                          <div
                            className="mt-3"
                            style={{ color: textBlue, fontWeight: '500' }}
                          >
                            <div>
                              {/* Amount Paid: 0 */}
                              {languageSheet[115][lang].split(',')[1]}: 0
                            </div>
                            <div>
                              {/* Free plan has been added successfully */}
                              {languageSheet[118][lang]}
                            </div>
                          </div>
                          <div style={{ color: textSecondary }}>
                            {/* Please check your email for order details. */}
                            {languageSheet[115][lang].split(',')[2]}
                          </div>
                          <button
                            className="primaryButton py-3 mt-3 "
                            onClick={() => navigation('/proLandingPage')}
                          >
                            {/* BACK TO DASHBOARD */}
                            {languageSheet[114][lang].split(',')[3]}
                          </button>
                        </div>
                      </Box>
                    </Fade>
                  </Modal>
                </div>
              </div>
            )
          })}


        </div>
      </div>
      <Footer />
    </div>
  )
}
