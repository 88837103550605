import React, { useState, useContext } from "react";
import TimeRange from "react-time-range";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import languageSheet from "../language";
import { components_langsheet } from "./Language/ComponentsLangsheet";
import { LanguageContext } from "../context/languageContext";
export default function ScheduleMaker({
  schedule,
  setSchedule,
  schErr,
  setSchErr,
  mode,
}) {
  const [schKey, setSchKey] = useState(-1);
  const [schDay, setSchDay] = useState(null);
  const [plusDontDisplayarr, setPlusDontDisplayarr] = useState([]);
  const { lang, setLang } = useContext(LanguageContext);

  const addOrRemove = (array, item, operation) => {
    const exists = array.includes(item);
    if (operation == "add" && !exists) {
      const result = array;
      result.push(item);
      setPlusDontDisplayarr(result);
    } else if (exists && operation == "remove") {
      const result = array.filter((c) => {
        return c !== item;
      });
      setPlusDontDisplayarr(result);
    }
  };

  const convertToIso = (time) => {
    const fullDate = new Date();
    const d = moment(fullDate).format("L");
    return moment(d + " " + time).format();
  };

  const onTimeRangeUpdate = (range, schedule, key, i) => {
    setSchErr("");
    setSchKey(-1);
    setSchDay(null);
    let startTime = "";
    let endTime = "";
    if (range.startTime) {
      startTime = new Date(range.startTime).toLocaleTimeString("en", {
        timeStyle: "short",
        hour12: false,
      });
      let hr=startTime.split(":")[0]
      if(hr==24){
        startTime="00"+":"+startTime.split(":")[1]
      }
    }
    if (range.endTime) {
      endTime = new Date(range.endTime).toLocaleTimeString("en", {
        timeStyle: "short",
        hour12: false,
      });
      let hr=endTime.split(":")[0]
      if(hr==24){
        endTime="00"+":"+endTime.split(":")[1]
      }
    }

    if (i != 0 && startTime < schedule[key].timeIntervals[i - 1][1]) {
      setSchErr(
        "Start time must start after the end of the latest end time previously registered"
      );
      setSchKey(i);
      setSchDay(key);
    } else if (endTime < startTime) {
      setSchErr(
        "Please enter a valid time. End time cannot be before start time."
      );
      setSchKey(i);
      setSchDay(key);
    } else if (endTime == startTime) {
      setSchErr(
        "Please enter a valid time. Start and End times cannot be equal."
      );
      setSchKey(i);
      setSchDay(key);
    }

    let tempSchedule = schedule;

    tempSchedule[key].timeIntervals[i] = [startTime, endTime];
    setSchedule({ ...tempSchedule });
  };

  return (
    <div>
      <div className="days">
        {Object.keys(schedule).map((key) => {
          return (
            <div className="day row align-items-center lightBorder">
              <div className="col-1">
                {mode != "previewProfile" && (
                  <input
                    type="checkbox"
                    value={schedule[key].available}
                    checked={schedule[key].available}
                    onChange={(e) => {
                      let tempSchedule = schedule;
                      tempSchedule[key].available = !schedule[key].available;

                      setSchedule({ ...tempSchedule });
                    }}
                  ></input>
                )}
              </div>
              <div
                className={`col-3 ${schedule[key].available ? "" : "greyText"}`}
              >
                {components_langsheet[key][lang]}
              </div>
              <div className="col-8 ">
                <div className="">
                  {mode == "previewProfile" &&
                  schedule[key].timeIntervals.length == 0 ? (
                    <div className="d-flex align-items-center">
                      <p className="ms-3 mt-3">
                        {/* Not Available */}
                        {languageSheet[98][lang]}
                      </p>
                    </div>
                  ) : (
                    <div>
                      {schedule[key].timeIntervals.map((interval, i) => {
                        const fullDate = new Date();
                        const d = moment(fullDate).format("L");
                        let startTime = moment(d + " " + interval[0]).format();
                        let endTime = moment(d + " " + interval[1]).format();
                        return (
                          <div>
                            <div className="d-flex align-items-center">
                              {mode != "edit" && !schedule[key].available ? (
                                <div className="d-flex align-items-center">
                                  <p className="ms-3 mt-3">
                                    {/* Not Available */}
                                    {languageSheet[98][lang]}
                                  </p>
                                </div>
                              ) : (
                                <div className="">
                                  <TimeRange
                                    startLabel={""}
                                    endLabel={"-"}
                                    use24Hours={true}
                                    sameIsValid={false}
                                    showErrors={false}
                                    className={`${
                                      schedule[key].available &&
                                      i + 1 >=
                                        schedule[key].timeIntervals.length &&
                                      schErr == ""
                                        ? ""
                                        : schDay == key && schKey == i
                                        ? ""
                                        : "timeRangeDisable"
                                    }
                            ${
                              mode == "view" || mode == "previewProfile"
                                ? "previewRangeDisable"
                                : ""
                            }`}
                                    startMoment={startTime}
                                    endMoment={endTime}
                                    minuteIncrement={15}
                                    onChange={(range) => {
                                      if (mode == "edit") {
                                        onTimeRangeUpdate(
                                          range,
                                          schedule,
                                          key,
                                          i
                                        );
                                      }
                                      var len =
                                        schedule[key].timeIntervals.length;
                                      var last =
                                        schedule[key].timeIntervals[len - 1][1];
                                      addOrRemove(
                                        plusDontDisplayarr,
                                        key,
                                        last == "23:45" || last == "23:30"
                                          ? "add"
                                          : "remove"
                                      );
                                    }}
                                  />
                                </div>
                              )}
                              <div className="">
                                {mode == "edit" && (
                                  <DeleteOutlineIcon
                                    className={`${
                                      schedule[key].available && schErr == ""
                                        ? ""
                                        : schDay == key && schKey == i
                                        ? ""
                                        : "deleteIconDiable"
                                    }`}
                                    onClick={() => {
                                      if (!schedule[key].available) return;
                                      if (schErr != "") {
                                        setSchErr("");
                                        setSchDay(null);
                                        setSchKey(-1);
                                      }
                                      let tempSchedule = schedule;
                                      tempSchedule[key].timeIntervals.splice(
                                        i,
                                        1
                                      );
                                      if (
                                        tempSchedule[key].timeIntervals
                                          .length == 0
                                      ) {
                                        tempSchedule[key].available = false;
                                        tempSchedule[key].timeIntervals.push([
                                          "8:00",
                                          "10:00",
                                        ]);
                                      }

                                      var len =
                                        tempSchedule[key].timeIntervals.length;
                                      var last =
                                        tempSchedule[key].timeIntervals[
                                          len - 1
                                        ][1];
                                      if (last != "23:45" || last != "23:30") {
                                        addOrRemove(
                                          plusDontDisplayarr,
                                          key,
                                          "remove"
                                        );
                                      }
                                      setSchedule({ ...tempSchedule });
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            {schErr != "" && schKey == i && schDay == key && (
                              <div
                                className="d-flex align-items-center"
                                style={{ marginLeft: "0.5em", color: "red" }}
                              >
                                {schErr}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                {mode == "edit" &&
                  schedule[key].timeIntervals.length != 0 &&
                  !plusDontDisplayarr.includes(key) && (
                    <div className="  addBtnSchedule">
                      <button
                        className=""
                        style={{ width: "182px" }}
                        onClick={() => {
                          if (!schedule[key].available || schErr != "") return;
                          let tempSchedule = schedule;
                          let tStart =
                            schedule[key].timeIntervals[
                              schedule[key].timeIntervals.length - 1
                            ];

                          var st = "";
                          if (
                            tStart[1].substring(0, 2) == 23 &&
                            (tStart[1].substring(3, 5) == "45" ||
                              tStart[1].substring(3, 5) == "30")
                          ) {
                          } else if (tStart[1].substring(3, 5) == "45") {
                            var befDec =
                              parseInt(tStart[1].substring(0, 2)) + 1;
                            st = befDec.toString().concat(":00");
                            tempSchedule[key].timeIntervals.push([st, "23:45"]);
                          } else if (tStart[1].substring(3, 5) != "45") {
                            var aftDec =
                              parseInt(tStart[1].substring(3, 5)) + 15;
                            st = tStart[1]
                              .substring(0, 2)
                              .concat(":" + aftDec.toString());
                            tempSchedule[key].timeIntervals.push([st, "23:45"]);
                          }

                          var len = tempSchedule[key].timeIntervals.length;
                          var last =
                            tempSchedule[key].timeIntervals[len - 1][1];
                          if (last == "23:45" || last == "23:30") {
                            addOrRemove(plusDontDisplayarr, key, "add");
                          }
                          setSchedule({ ...tempSchedule });
                        }}
                      >
                        +
                      </button>
                    </div>
                  )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

ScheduleMaker.defaultProps = {
  mode: "edit",
};
