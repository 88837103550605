import React from 'react'

const FileUploadError = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
    <path d="M13.9995 19.125C14.2623 19.125 14.4828 19.0361 14.6609 18.8583C14.8391 18.6805 14.9281 18.4602 14.9281 18.1974C14.9281 17.9345 14.8392 17.7141 14.6614 17.5359C14.4836 17.3578 14.2633 17.2687 14.0005 17.2687C13.7377 17.2687 13.5172 17.3576 13.3391 17.5354C13.1609 17.7132 13.0719 17.9335 13.0719 18.1964C13.0719 18.4592 13.1608 18.6797 13.3386 18.8578C13.5164 19.0359 13.7367 19.125 13.9995 19.125ZM14.0893 14.8219C14.3298 14.8219 14.5297 14.741 14.6891 14.5793C14.8484 14.4176 14.9281 14.2172 14.9281 13.9781V8.55C14.9281 8.31094 14.8468 8.11055 14.6841 7.94883C14.5215 7.78711 14.3199 7.70625 14.0795 7.70625C13.839 7.70625 13.6391 7.78711 13.4797 7.94883C13.3203 8.11055 13.2406 8.31094 13.2406 8.55V13.9781C13.2406 14.2172 13.322 14.4176 13.4846 14.5793C13.6473 14.741 13.8488 14.8219 14.0893 14.8219ZM14.0075 24.75C12.4562 24.75 10.9984 24.4547 9.63404 23.8641C8.26968 23.2734 7.07656 22.4672 6.05469 21.4453C5.03281 20.4234 4.22656 19.2296 3.63594 17.8638C3.04531 16.4981 2.75 15.0388 2.75 13.4859C2.75 11.9331 3.04531 10.4738 3.63594 9.10803C4.22656 7.74226 5.03281 6.55312 6.05469 5.54062C7.07656 4.52813 8.27038 3.72656 9.63615 3.13594C11.0019 2.54531 12.4612 2.25 14.0141 2.25C15.5669 2.25 17.0262 2.54531 18.392 3.13594C19.7577 3.72656 20.9469 4.52813 21.9594 5.54062C22.9719 6.55312 23.7734 7.74375 24.3641 9.1125C24.9547 10.4812 25.25 11.9413 25.25 13.4925C25.25 15.0438 24.9547 16.5016 24.3641 17.866C23.7734 19.2303 22.9719 20.4217 21.9594 21.4401C20.9469 22.4586 19.7562 23.2648 18.3875 23.8589C17.0187 24.453 15.5587 24.75 14.0075 24.75Z" fill="#D64E42"/>
  </svg>
  )
}

export default FileUploadError