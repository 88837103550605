import { useState, useEffect, useContext } from "react";
import Footer from "../../Components/Footer";
import AppointmentsTable from "../../Components/AppointmentsTable";
import {
  apiHost,
  bgColor,
  meetingUrl,
  primeBlue
} from "../../env";
import DatePicker from "sassy-datepicker";
import { Navigate, useNavigate } from "react-router-dom";
import { useInterval } from "usehooks-ts";
import { useToaster } from "rsuite";
import { UserContext } from "../../context/userContext";
import MessageUserExpired from "../../Components/MessageUserExpired";
import { LanguageContext } from "../../context/languageContext";
import languageSheet from "../../language";
import { pages_langsheet } from "../Languages/PagesLangsheet";
import ReactGA from "react-ga4";
import { isUserLoggedIn } from "../../utils/Utils";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export default function AppointmentsPro({ user }) {
  const toaster = useToaster();
  const navigation = useNavigate();

  // if (user === null) {
  //   navigation('/signIn')
  //   return
  // }

  const { setUser } = useContext(UserContext);
  const { lang, setLang } = useContext(LanguageContext);
  const [appointments, setAppointments] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [planDetails, setPlanDetails] = useState({
    currentPlan: "",
    sessionsLeft: 0
  });

   

  //for filtering appointments
  const month = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const handleDateSelect = (d) => {
    const date = d.toString();
    const dm =
      date.substring(11, 15) +
      "-" +
      month[date.substring(4, 7)] +
      "-" +
      date.substring(8, 10);
    if (user) {
      fetch(`${backendURL}api/appointment/professional?date=${dm}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.tokens.access,
        },
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setAppointments(data);
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
            setUser(null);
          }
        });
    }
  };

  const displayAll = () => {
    if (user) {
      fetch(`${backendURL}api/appointment/professional`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.tokens.access,
        },
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setAppointments(data);
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
            setUser(null);
          }
        });
    }
  };

  useEffect(() => {
    currPlan()
    getAppointments();
  }, [user, toggle]);

  const currPlan = () => {
    fetch(apiHost + 'api/current-subscription', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.tokens.access}`,
        'Content-Type': 'application/json',
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setPlanDetails(prevState => ({
          ...prevState,
          currentPlan: res.name,
          sessionsLeft: res.sessions_left
        }));
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getAppointments = () => {
    if (user) {
      fetch(`${backendURL}api/appointment/professional`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.tokens.access,
        },
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((data) => {
          
          setAppointments(data);
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
            setUser(null);
          }
        });
    }
  };

  const getMeetingData = () => {
    fetch(backendURL + "api/get-signature/" + appointments[firstOccurance].id, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        let params = new URLSearchParams({
          signature: res.signature,
          appointment_id: res.appointment_id,
          username: res.username,
          token: user.tokens.access,
          lang: lang
        }).toString();

        ReactGA.event({
          category: 'User',
          action: 'Meeting Started'
        });

        window.location.href = meetingUrl + "?" + params;
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };
  let currentDate = new Date();
  const [firstOccurance, setFirstOc] = useState(null);
  useEffect(() => {
    let toSetFi = null;
    let minDate = {};
    findAndSetLatest();
  }, [appointments]);
  const findAndSetLatest = () => {
    let minDate = {};
    let currentDate = new Date();

    for (let i = 0; i < appointments.length; i++) {
      let appointmentDate = new Date(
        appointments[i].date + " " + appointments[i].start_time
      );
      let appointmentDateEnd = new Date(
        appointments[i].date + " " + appointments[i].start_time
      );
      appointmentDateEnd.setMinutes(appointmentDate.getMinutes() + 14);
      if (
        appointments[i].meeting_status == "confirmed" &&
        appointmentDateEnd >= currentDate
      ) {
        if (Object.keys(minDate).length == 0) {
          minDate.i = i;
          minDate.date = new Date(appointments[i].date);
          minDate.start_time = appointments[i].start_time.split(":");
          minDate.appointmentDate = appointmentDate;
        } else if (minDate.appointmentDate > appointmentDate) {
          minDate.i = i;
          minDate.date = new Date(appointments[i].date);
          minDate.start_time = appointments[i].start_time.split(":");
          minDate.appointmentDate = appointmentDate;
        }
      }
    }
    if (Object.keys(minDate).length != 0) {
      setFirstOc(minDate.i);
      setBtnDisabled(false);
    } else {
      setFirstOc(null);
    }
  };
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [intervalState, setIntervalState] = useState(null);

  useInterval(
    () => {
      if (
        appointments[firstOccurance]?.start_time.split(":")[0] ==
        new Date().getHours() &&
        appointments[firstOccurance]?.start_time.split(":")[1] <=
        new Date().getMinutes()
      ) {
        setBtnDisabled(true);
      } else {
        setBtnDisabled(false);
      }
    },
    // Delay in milliseconds or null to stop it
    !btnDisabled ? 1000 : null
  );

  useInterval(
    () => {
      getAppointments();
    },
    !null ? 60000 : null
  );

  return (
    <div style={{ backgroundColor: bgColor }}>
      <div className="container my-5" style={{ minHeight: "60vh" }}>
     <div className="d-flex flex-wrap">
        <h4 className="text-uppercase sliderHead me-5" style={{ color: primeBlue }}>
          {/* Appointments */}
          {languageSheet[43][lang].split(",")[0]}
        </h4>
           <div className="d-flex flex-wrap gap-4 align-items-end justify-center mt-3">
            <div>
            <p className="text-dark littleHead text-uppercase">
              {/* SESSION LEFT */}
              {languageSheet[148][lang].split(",")[0]}
              </p>
            <div className="border border-dark rounded p-2 ps-3 bg-white fw-bold">{planDetails.currentPlan.toLowerCase()==='premium' ? 'Unlimited' : planDetails.sessionsLeft}</div>
            </div>
            <div>

            <p className="text-dark littleHead text-uppercase">
              {/* CURRENT PLAN */}
              {languageSheet[149][lang].split(",")[0]}
              </p>
            <div className="border border-dark rounded p-2 ps-3 bg-white fw-bold">{planDetails.currentPlan}</div>
            </div>
            <button className="px-4 py-2 rounded text-uppercase bg-dark text-light " 
            onClick={() => {
                navigation('/subsDetails')
              }}>
                {/* Upgrade */}
                {languageSheet[150][lang].split(",")[0]}
                </button>
           </div>
           </div>
        <div className="row ">
        
          <div className="col-md-4  mt-4 ">
            {/* this is due to filter feature which is not applied yet on appointments */}

            <DatePicker onChange={handleDateSelect} weekStartsFrom="Monday" />
            <div className="mt-3">
              {firstOccurance !== null && (
                <div className="container" style={{ paddingLeft: 0 }}>
                  <p className="littleHead">
                    {/* JOIN MEETING: */}
                    {languageSheet[108][lang].split(",")[0]}
                  </p>
                  <p className="textSecondary">
                 
                    {/* Join now to attend your meeting. */}
                    {languageSheet[108][lang].split(",")[1]}
                  </p>
                  {appointments.length != 0 && (
                    <p className="textSecondary">
                      Date: {appointments[firstOccurance].date}
                      <br /> Time:{" "}
                      {appointments[firstOccurance].start_time.split(":")[0]}:
                      {appointments[firstOccurance].start_time.split(":")[1]}
                    </p>
                  )}

                  {appointments.length != 0 &&
                    (btnDisabled ? (
                      <div>
                        <p className="textSecondary" style={{ color: "green" }}>
                          {pages_langsheet?.theMeetingHasStarted?.[lang]}
                        </p>
                        <buttons
                          className="primaryButton2 py-2 px-4 my-2"
                          onClick={() => {
                            getMeetingData();
                          }}
                        >
                          {/* JOIN NOW */}
                          {languageSheet[71][lang]}
                        </buttons>
                      </div>
                    ) : (
                      <buttons
                        className="primaryButton2 py-2 px-4  my-2 disabled"
                        onClick={() => { }}
                      >
                        {/* JOIN NOW */}
                        {languageSheet[71][lang]}
                      </buttons>
                    ))}
                </div>
              )}
            </div>
          </div>
          <AppointmentsTable
            isproff={true}
            appointments={appointments}
            user={user}
            setToggle={setToggle}
            toggle={toggle}
            setAppointments={setAppointments}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
