import React, { useEffect, useState, useContext } from "react";
import Footer from "../../Components/Footer";
import { primeBlue } from "../../env";
import { pages_langsheet } from "../Languages/PagesLangsheet";
import { DialogSuccess, DialogWithOptions } from "../../Components/DialogPop";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useToaster } from "rsuite";
import MessageUserExpired from "../../Components/MessageUserExpired";
import { LanguageContext } from "../../context/languageContext";
import languageSheet from "../../language";
import { fetchCountryDataFromTaxApi, isUserLoggedIn } from "../../utils/Utils";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
const apiHost = process.env.REACT_APP_BACKEND_URL;
const ariaLabel = { "aria-label": "description" };

export default function UserProfile({ user, setUser }) {
  const toaster = useToaster();
  const navigation = useNavigate();
  const { lang, setLang } = useContext(LanguageContext);
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [ondeleteProf, setOnDeleteProf] = useState(false);
  const [ondeleteUser, setOnDeleteUser] = useState(false);
  const [deactivateProf, setDeactivateProf] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    if (user === null) {
      navigation("/signIn");
    } else {
      fetchCountryDataFromTaxApi(`${apiHost}api/tax`, user.tokens.access)
        .then((res) => {
          let countries = res.map((item) => {
            return item.country;
          });
          if (countries && countries?.length > 0) {
            console.log(countries);
            setCountryList(countries);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (user === null) {
      navigation("/signIn");
    } else {
      setLoading(true);
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      fetch(apiHost + "auth/user-profile", {
        headers: myHeaders,
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((res) => {
          setLoading(false);
          setUserProfile(res);
        })
        .catch((err) => {
          setLoading(false);
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
            setUser(null);
          }
        });
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const notSuccess = (err) => {
    if (err.code == 401) {
      sessionStorage.removeItem("otherInfo");
      sessionStorage.removeItem("professional");
      navigation("/");
      toaster.push(MessageUserExpired, { placement: "bottomEnd" });
      setUser(null);
    }
  };

  const handleClose = (value) => {
    setOpen(false);
    if (value === 1 && userProfile?.user?.is_professional) {
      //if the first button (delete) is clicked and user is professional then professional account will be deleted
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(apiHost + "auth/delete-user/professional", requestOptions)
        .then((res) => {
          if (res.status === 202) {
            setOnDeleteProf(true);
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            setUser(null);
          } else if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          }
        })
        .catch((err) => {
          notSuccess(err);
        });
    } else if (value === 1 && !userProfile?.user?.is_professional) {
      //if the first button (delete) is clicked and user is not professional then user account will be deleted
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(apiHost + "auth/delete-user/user", requestOptions)
        .then((res) => {
          if (res.status === 202) {
            setOnDeleteUser(true);
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            setUser(null);
          } else if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          }
        })
        .catch((err) => {
          notSuccess(err);
        });
    } else if (value === 2 && userProfile?.user?.is_professional_active) {
      //if the second button (deactivate) is clicked and user's professional account is active then professional account will be deactivated
      //deactivate
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(apiHost + "auth/deactivate-user/professional", requestOptions)
        .then((res) => {
          if (res.status === 202) {
            setDeactivateProf(true);
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            setUser(null);
          } else if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          }
        })
        .catch((err) => {
          notSuccess(err);
        });
    } else if (value === 2 && !userProfile?.user?.is_professional_active) {
      //if the second button (deactivate) is clicked and user's professional account is not active then user account will be deactivated
      //deactivate
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(apiHost + "auth/deactivate-user/user", requestOptions)
        .then((res) => {
          if (res.status === 202) {
            setDeactivateUser(true);
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            setUser(null);
          } else if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          }
        })
        .catch((err) => {
          notSuccess(err);
        });
    }
  };

  const reActivateAccount = () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiHost + "auth/activate-prof-account", requestOptions)
      .then((res) => {
        if (res.status === 200) {
          setDeactivateUser(true);
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          setUser(null);
        } else if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        }
      })
      .catch((err) => {
        notSuccess(err);
      });
  };

  const handleDelete = () => {
    setOnDeleteProf(false);
    setOnDeleteUser(false);
    navigation("/");
  };

  const handleDeactivate = () => {
    setDeactivateProf(false);
    setDeactivateUser(false);
    navigation("/");
  };

  console.log(userProfile?.user?.sex);

  return (
    <div>
      <div className="container my-5 vh-80" style={{ minHeight: "60vh" }}>
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {languageSheet[8][lang].split(" ")[1]} {/* Profile */}
        </h4>

        <div className="row ">
          <div className="col-lg-3 col-md-4 my-3">
            {loading ? (
              <Box sx={{ width: 160, height: 160 }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={160}
                  height={160}
                />
              </Box>
            ) : userProfile?.user?.profile_image ? (
              <img
                src={userProfile.user.profile_image}
                width={160}
                alt="ProfileImage"
              />
            ) : userProfile?.user?.sex === "Male" ? (
              <img src="./assets/male.jpg" alt="ProfileImage" width={"150px"} />
            ) : (
              <img
                src="./assets/female.jpg"
                alt="ProfileImage"
                width={"150px"}
              />
            )}

            <p className="status ms-1">
              <img
                src="./assets/statusActive.svg"
                className=""
                alt="StatusActive"
              />
              {languageSheet[45][lang].split(",")[4]}
              {/* USER ACTIVE */}
            </p>
          </div>

          <div className="col-lg-5 col-md-12 my-3">
            <div className="row align-items-center ">
              <div className="col-md-4 label">
                {languageSheet[45][lang].split(",")[0].split("/")[0]} *
                {/* FIRST NAME * */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.user?.first_name}</p>
              </div>
            </div>

            <div className="row my-4 align-items-center">
              <div className="col-md-4 label">
                {languageSheet[45][lang].split(",")[0].split("/")[1]} *
                {/* Last NAME * */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.user?.last_name}</p>
              </div>
            </div>
            <div className="row my-4 align-items-center">
              <div className="col-md-4 label">
                {languageSheet[86][lang].split(",")[0]}* {/* Gender */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.user?.sex}</p>
              </div>
            </div>
            <div className="row my-4 align-items-center">
              <div className="col-md-4 label">
                {languageSheet[76][lang]} *{/* Birthday */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">
                  {userProfile &&
                  userProfile?.user &&
                  userProfile?.user?.dob &&
                  userProfile?.user?.dob.includes("/")
                    ? userProfile.user.dob.split("/")[0] +
                      "-" +
                      userProfile.user.dob.split("/")[1] +
                      "-" +
                      userProfile.user.dob.split("/")[2]
                    : userProfile && userProfile?.user && userProfile?.user?.dob
                    ? userProfile?.user?.dob
                    : ""}
                </p>
              </div>
            </div>
            <div className="row my-4 align-items-center">
              <div className="col-md-4 label">
                {languageSheet[86][lang].split(",")[1]}* {/* Gender */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0" style={{ textTransform: "capitalize" }}>
                  {userProfile?.user?.default_lang == "spanish"
                    ? "Español"
                    : userProfile?.user?.default_lang}
                </p>
              </div>
            </div>
            <div className="row my-4 align-items-center">
              <div className="col-md-4 label">
                {" "}
                {languageSheet[45][lang].split(",")[1]} *{/* email * */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.user?.email}</p>
              </div>
            </div>

            <div className="row my-4 align-items-center">
              <div className="col-md-4 label">
                {" "}
                {languageSheet[45][lang].split(",")[2]} *{/* Country * */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.user?.country}</p>
              </div>
            </div>

            {/* Not showing city for normal user */}
            {/* <div className="row my-4  align-items-center">
              <div className="col-md-4 label">
                {languageSheet[45][lang].split(',')[3]}* */}
            {/* City * */}
            {/* </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.user?.city}</p>
              </div>
            </div> */}
          </div>

          <div className="col-md-4 my-3 ">
            {countryList &&
            countryList.length > 0 &&
            userProfile?.user?.country &&
            countryList.includes(userProfile?.user.country) &&
            userProfile &&
            userProfile?.user &&
            userProfile?.user?.is_professional == false ? (
              <>
                <p className="label">
                  {languageSheet[42][lang]}
                  {/* Want to be a Professional? */}
                </p>
                <button
                  className="primaryButton2 py-3 px-5"
                  onClick={() => {
                    navigation("/createEditAds", {
                      state: { from: location.pathname },
                    });
                  }}
                >
                  {languageSheet[39][lang].toUpperCase()}
                  {/* POST AD */}
                </button>
              </>
            ) : (
              ""
            )}
            <div className="d-flex flex-column" style={{ width: "60%" }}>
              <p className="label mt-4">
                {languageSheet[43][lang].split(",")[0]}
                {/* Appointments */}:
              </p>
              <button
                className=" primaryButton2 py-3 px-5"
                onClick={() => {
                  navigation("/appointments");
                }}
              >
                {languageSheet[43][lang].split(",")[1].toUpperCase()}
                {/* MY APPOINTMENTS */}
              </button>
              {userProfile &&
              userProfile?.user &&
              userProfile?.user?.is_professional == true &&
              userProfile?.user?.is_professional_active == false ? (
                <button
                  className="primaryButton2 py-2 px-2 mt-3"
                  onClick={() => {
                    reActivateAccount();
                  }}
                >
                  {languageSheet?.reActivateAccount?.[lang]}
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="buttons d-flex justify-content-start flex-wrap ">
            <buttons
              className="primaryButton2 py-2 px-5 mx-2 my-1"
              onClick={() => {
                navigation("/editUserProfile");
              }}
            >
              {languageSheet[32][lang]
                .split(",")[0]
                .split("/")[1]
                .toUpperCase()}
              {/* EDIT PROFILE */}
            </buttons>
            <buttons
              className="secondaryButton py-2 px-5 mx-2 my-1"
              onClick={handleClickOpen}
              style={{ cursor: "pointer" }}
            >
              {languageSheet[15][lang].toUpperCase().toUpperCase()}
              {/* DELETE ACCOUNT */}
            </buttons>
            <DialogWithOptions
              open={open}
              onClose={handleClose}
              title={languageSheet[126][lang]} //"ARE YOU SURE?"
              content={languageSheet[127][lang]} //"Do you really want to delete this account?"
              image="./assets/bin.svg"
              btnFirst={languageSheet[128][lang].split(",")[0]} //"Delete"
              btnSecond={languageSheet[35][lang].split(",")[1]} //"Cancel"
              btnThird={languageSheet[128][lang].split(",")[1]} //"Deactivate"
            />
            <DialogSuccess
              success={ondeleteUser ? ondeleteUser : ondeleteProf}
              onClose={handleDelete}
              imageToShow="profileDelete.png"
              title={
                ondeleteUser
                  ? pages_langsheet?.UserDeleted?.[lang]
                  : pages_langsheet?.ProfessionalDeleted?.[lang]
              } //"User Account Deleted Successfully"
              error={false}
            />
            <DialogSuccess
              success={deactivateUser ? deactivateUser : deactivateProf}
              onClose={handleDeactivate}
              imageToShow="profileDelete.png"
              title={
                deactivateUser
                  ? pages_langsheet?.UserDeactivated?.[lang]
                  : pages_langsheet?.ProfessionalDeactivated?.[lang]
              } //"User Account Deactivated Successfully"
              error={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
