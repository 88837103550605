export const validateProData = (
  country,
  city,
  profession,
  selectedLang,
  dni,
  short_description,
  long_description,
  schedule,
  pages_langsheet,
  lang
) => {
  if (!country) return pages_langsheet?.validCountry?.[lang];
  if (!city) return pages_langsheet?.validCity?.[lang];
  if (!profession) return pages_langsheet?.validProfession?.[lang];
  if (selectedLang.length === 0) return pages_langsheet?.validLanguage?.[lang];
  if (!dni) return pages_langsheet?.validDNI?.[lang];
  if (dni.length > 25)
    return pages_langsheet?.theLengthOfDNICantBeError?.[lang];
  if (!short_description) return pages_langsheet?.validShortDescription?.[lang];
  if (!long_description) return pages_langsheet?.validDescription?.[lang];

  //Checking whether the schedule is set properly or not
  const anyDaySelected = Object.values(schedule).some((day) => day.available);
  if (!anyDaySelected) return pages_langsheet?.scheduleShouldBeCorrect?.[lang];

  return null; // No validation error
};
