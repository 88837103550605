import { useState, useContext, useEffect, lazy, Suspense } from "react";
import { bgColor, primeBlue } from "../env";
import GridOfCards from "../Components/GridOfCards";
import languageSheet from "../language";
import { LanguageContext } from "../context/languageContext";
import { Footers } from '../Components/Loading'
const Footer = lazy(() => import('../Components/Footer'))


const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function PopularProfessionals({ user }) {

  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { lang, setLang } = useContext(LanguageContext);
  const [popular_professionals, setPopular_Professionals] = useState([])
  useEffect(() => {
    fetch(`${backendURL}api/popular-professionals`, {
      method: "GET",
      headers: [],
    })
      .then((res) => res.json())
      .then((res) => {
        setPopular_Professionals([...res.results]);
      });
  }, [])
  return (
    <div>
      <div style={{ backgroundColor: bgColor }}>
        <div className="container my-5" style={{ minHeight: '60vh' }}>
          <h4
            className="text-uppercase sliderHead"
            style={{ color: primeBlue }}
          >
            {/* POPULAR PROFESSIONalS */}
            {languageSheet[56][lang]}
          </h4>

          <div className="row ">
          </div>
          <div className="mx-auto">


            <GridOfCards data={popular_professionals} user={user} isProfession={false} isAllProfessional={false} />
          </div>

        </div>

        <Suspense fallback={<Footers />}>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
}
