const FAQLang = {
  Faq: {
    en: "FAQ",
    es: "PREGUNTAS FRECUENTES",
    pr: "PREGUNTAS FRECUENTES",
  },
  WhatIsKonsarutanto: {
    en: "What is Konsarutanto?",
    es: "¿Qué es Konsarutanto?",
    pr: "O que é Konsarutanto?",
  },
  AnsForWhatIsKonsarutanto: {
    en: `Simple! Has it happened to you that you need a professional such as a lawyer or an accountant and you don't know where to find him? You already have the solution. Find it in Konsarutanto! Konsarutanto is the platform that allows you to find the professional you are looking for and immediately schedule a first approach virtual meeting with him. With that you ensure if it is the professional you need quickly and efficiently. The professionals registered in the Konsarutanto platform are certified by the Konsarutanto team as they have had to go through a review process of the documents that accredit their profession. <a href='/tc'>(Read terms and conditions)</a> `,
    es: `¡Simple! ¿Te ha pasado que necesitas un profesional como un abogado o un contador y no sabes dónde encontrarlo? Ya tienes la solución. ¡Encuéntralo en Konsarutanto! Konsarutanto es la plataforma que te permite encontrar al profesional que buscas y agendar de inmediato una reunión virtual de primer acercamiento con él. Con eso te aseguras si es el profesional que necesitas de forma rápida y eficaz. Los profesionales registrados en la plataforma Konsarutanto están certificados por el equipo de Konsarutanto ya que han tenido que pasar por un proceso de revisión de los documentos que acreditan su profesión.<a href='/tc'> (Leer términos y condiciones) </a>
        `,
    pr: `Simples! Já aconteceu de você precisar de um profissional como advogado ou contador e não sabe onde encontrá-lo? Você já tem a solução. Encontre-o em Konsarutanto!

        O Konsarutanto é a plataforma que permite encontrar o profissional que procura e agendar de imediato uma primeira reunião virtual com ele. Com isso você garante se é o profissional que você precisa com rapidez e eficiência.
        
        Os profissionais inscritos na plataforma Konsarutanto são certificados pela equipe do Konsarutanto pois tiveram que passar por um processo de revisão dos documentos que credenciam a sua profissão. <a href='/tc'>(ler termos e condições)</a>
        `,
  },
  WhySignMeUp: {
    en: "I'm professional. Why sign me up?",
    es: "Soy profesional. ¿Por qué inscribirme?",
    pr: "Sou profissional. Por que me inscrever?",
  },
  AnsForWhySignMeUp: {
    en: "Has it happened to you that you don't know where to offer your services to reach new customers? Or do you need a lot of time in that first contact with a potential client, and it ends up being unsuccessful for both parties? If you are a lawyer, accountant, engineer or architect in Konsarutanto you will be able to have the visibility you need and manage your own times by holding efficient virtual meetings through the platform from the place that suits you.",
    es: "¿Te ha pasado que no sabes dónde ofrecer tus servicios para llegar a nuevos clientes? ¿O necesitas mucho tiempo en ese primer contacto con un potencial cliente, y acaba siendo infructuoso para ambas partes? Si eres abogado, contador, ingeniero o arquitecto en Konsarutanto podrás tener la visibilidad que necesitas y gestionar tus propios tiempos realizando eficientes reuniones virtuales a través de la plataforma desde el lugar que más te convenga.",
    pr: "Já aconteceu de você não saber onde oferecer seus serviços para alcançar novos clientes? Ou você precisa de muito tempo naquele primeiro contato com um potencial cliente, e acaba sendo infrutífero para ambas as partes? Se você é advogado, contador, engenheiro ou arquiteto em Konsarutanto, poderá ter a visibilidade que precisa e gerenciar seus próprios tempos realizando reuniões virtuais eficientes através da plataforma do local que mais lhe convier",
  },
  WhatAreAvailablePlans: {
    en: "What are the available plans?",
    es: "¿Cuáles son los planes disponibles?",
    pr: "Quais são os planos disponíveis?",
  },
  AnsForWhatAreAvailablePlans: {
    en: `There are 3 plans currently available:
        <br>
            BASIC<br>
            Plan price: US$0.<br>
            In addition to everyone being able to access your profile, it includes the fact that you can hold 2 contact meetings per month.
            <br><br>
            STANDARD<br>
            Plan Price: US$9/month or Promotional Price: US$49/year.<br>
            In addition to everyone being able to access your profile, it includes the fact that you can hold 5 contact meetings per month.<br><br>
    
            PREMIUM<br>
            Plan Price: $19/month or Promotional Price: $99/year<br>
            In addition to everyone being able to access your profile, it includes unlimited sessions per month.<br><br>`,
    es: `Hay 3 planes actualmente disponibles:<br>

            BASICO<br>
            Precio del plan: US$0. <br>
            Además de que todos puedan acceder a tu perfil, incluye el hecho de que podrás realizar 2 reuniones de contacto al mes.<br><br>
            
            ESTÁNDAR<br>
            Precio del plan: US$9/mes o Precio Promocional:US$49/año.<br>
            Además de que todos puedan acceder a tu perfil, incluye que puedas realizar 5 reuniones de contacto por mes.<br><br>
            
            DE PRIMERA CALIDAD<br>
            Precio del plan: $19/mes o Precio Promocional: $99/año<br>
            Además de que todos puedan acceder a tu perfil, incluye ilimitadas sesiones por mes.<br><br>`,
    pr: `Atualmente, existem 3 planos disponíveis:<br>

            BÁSICO<br>
            Preço do plano: US$0.<br>
            Além de todos poderem acessar seu perfil, inclui o fato de você poder realizar 2 reuniões de contato por mês.<br><br>
            
            STANDARD<br>
            Preço do Plano: US$ 9/mês ou Preço Promocional: US$ 49/ano.<br>
            Além de todos poderem acessar seu perfil, inclui o fato de você poder realizar 5 reuniões de contato por mês.<br><br>
            
            PREMIUM<br>
            Preço do plano: US$ 19/mês ou preço promocional: US$ 99/ano<br>
            Além de todos poderem acessar seu perfil, inclui sessões ilimitadas por mês.<br><br>
            `,
  },
  WhatIsCertificationProcessIsLike: {
    en: "What is the certification process like?",
    es: "¿Cómo es el proceso de certificación?",
    pr: "Como é o processo de certificação?",
  },
  AnsForWhatIsCertificationProcessIsLike: {
    en: "So that you can finish the process of publishing your profile as a professional, you have 10 days to upload to the platform a copy of your identity document on both sides, a selfie with your identification card next to your face and your professional title certificate.",
    es: "Para que puedas finalizar el proceso de publicación de tu perfil como profesional tienes 10 días para subir a la plataforma una copia de tu documento de identidad por ambos lados, una selfie con tu cedula de identificación al lado de tu rostro y tu certificado de título profesional.",
    pr: "Para que possa finalizar o processo de publicação do seu perfil como profissional, tem 10 dias para carregar na plataforma uma cópia do seu documento de identidade frente e verso, uma selfie com o seu bilhete de identidade junto ao rosto e o seu certificado de título profissional.",
  },
  HowToScheduleMeeting: {
    en: "How to schedule a meeting?",
    es: "¿Cómo agendar una reunión?",
    pr: "Como agendar uma reunião?",
  },
  AnsForHowToScheduleMeeting: {
    en: "Once you have found your professional, enter their profile and select the day and time according to schedule availability to make a meeting with you. The reservations and you will receive a confirmation by email. On the meeting day and time you enter your session and open your meetings. Ready!FAQ",
    es: "Una vez que hayas encontrado a tu profesional, entras a su perfil y selecciona el día y horario según disponibilidad de agenda para hacer una reunión contigo. La reservas y te llegará una confirmación por correo. En el día y hora de reunión entras a tu sesiín y abres tus reuniones. Listo!",
    pr: "Depois de encontrar o seu profissional, entre no perfil dele e selecione o dia e horário de acordo com a disponibilidade de horário para marcar uma reunião com você. As reservas e você receberá uma confirmação por e-mail. No dia e hora da reunião, você entra em sua sessão e abre suas reuniões. Preparar!",
  },
};

module.exports = { FAQLang };
