import { useState, useEffect, useContext, useRef } from 'react'
import Footer from "../Components/Footer";
import { bgColor, primeBlue } from "../env";
import GridOfCards from "../Components/GridOfCards";
import languageSheet from "../language";
import { LanguageContext } from "../context/languageContext";
import Filter from '../Components/Filter/Filter';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material'

const backendURL = process.env.REACT_APP_BACKEND_URL;
let page_size = 12;


function Favourite({ user }) {
  const navigation = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { lang, setLang } = useContext(LanguageContext);
  const [fav, setFav] = useState([])
  const [favUrl, setFavlUrl] = useState("")
  const isMounted = useRef(false)

  const [next, setNext] = useState('')
  const [loading, setLoading] = useState(false)
  const [favPage, setFavPage] = useState(1)
  const professionData = searchParams.get("profession");

  useEffect(() => {

    getFavourites()
  }, [location, favUrl])

  const getFavourites = () => {            //gets all the favourites

    const apiUrl = new URL(`${backendURL}api/favourite?page_size=${page_size}`);
    if (professionData) {
      apiUrl.searchParams.set("profession", professionData);
    }
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setFav([...data.results])
        setNext(data.next)
        setLoading(false)
      })
      .catch((err) => {
        if (err.code == 401) {
          navigation("/");
        }
      });
  }

  const fetchMoreData = () => {              //gets more data when user reaches bottom of the scroll bar
    setLoading(true)
    fetch(next, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then(res => {
        return res.json();
      })
      .then((res) => {
        setFav([...fav, ...res.results])
        setNext(res.next)
        setLoading(false)
      });
  }

  const onScroll = async () => {           //updates page to execute fetchMoreData when user reaches bottom of scrollbar
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setFavPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      fetchMoreData()
    } else {
      isMounted.current = true
    }
  }, [favPage])

  return (
    <div>
      <div style={{ backgroundColor: bgColor }}>
        <div className="container my-5" style={{ minHeight: '60vh' }}>
          <h4
            className="text-uppercase sliderHead"
            style={{ color: primeBlue }}
          >
            {/* FAVOURITES */}
            {/* {languageSheet[38][lang].split(',')[1]} */}
            {fav.length ? languageSheet[38][lang].split(',')[1] : languageSheet[38][lang].split(',')[2]}
          </h4>

          <div className="row ">
          </div>

          <Filter page="favourites" Url={favUrl} setUrl={(data) => { setFavlUrl(data) }} hitFunc={() => { }} />

          <div className="mx-auto">

            <GridOfCards data={fav} user={user} isProfession={false} isAllProfessional={false} />
            {loading ? next ? <div className="d-flex justify-content-center"><CircularProgress /></div> : <></> : <></>}
          </div>

        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Favourite