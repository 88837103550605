import { useState, useRef, useEffect, useContext } from "react";
import Footer from "../../Components/Footer";
import { primeBlue } from "../../env";
import Input from "@mui/material/Input";
import { Navigate, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { City } from "country-state-city";
import { useToaster, Message } from "rsuite";
import { LanguageContext } from "../../context/languageContext";
import languageSheet from "../../language";
import { UserContext } from "../../context/userContext";
import MessageUserExpired from "../../Components/MessageUserExpired";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import CropImage from "../../Components/CropImage/CropImage";
import male1 from '../../assests/images/male.jpg'
import female1 from '../../assests/images/female.jpg'
import { pages_langsheet } from "../Languages/PagesLangsheet";
import { validateUserData } from "../../utils/validateUserData";
import { isUserLoggedIn } from "../../utils/Utils";
const apiHost = process.env.REACT_APP_BACKEND_URL;
const ariaLabel = { "aria-label": "description" };

export default function EditUserProfile({ user, changeCountry, setChangeCountry }) {
  
  const navigation = useNavigate();
  if (user === null) {
    navigation('/signIn')
  } 

  const [open, setOpen] = useState(false);
  const [dob, setDob] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const toaster = useToaster();
  const { setUser } = useContext(UserContext);
  const { lang, setLang } = useContext(LanguageContext);
  const [userProfile, setUserProfile] = useState(null);
  const uploader = useRef(null);
  const [cropImg, setCropImg] = useState(false)
  const [closePopUp, setClosePopup] = useState(false)
  const [finalImage, setFinalImage] = useState(null)
  let minAge = 18;
  const Male = languageSheet[131][lang].split(',')[0];
  const Female = languageSheet[131][lang].split(',')[1];
  const nd = languageSheet[131][lang].split(',')[2];

  const [first_name, setFname] = useState("");
  const [last_name, setLname] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [file, setFile] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [err, setErr] = useState("");
  const [errDob, setErrDob] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [languageForEmail, setLanguageForEmail] = useState("");
  const [gender, setGender] = useState("male");
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //validation password

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordChanged(true);
    setPasswordInput(NewPasswordInput);
  };
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" &&
        passwordInput.confirmPassword.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  //gettting user profile
  useEffect(() => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    fetch(apiHost + "auth/user-profile", {
      headers: myHeaders,
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setUserProfile(res);
        setLname(res.user.last_name);
        setFname(res.user.first_name);
        setCountry(res.user.country);
        setCity(res.user.city);
        setGender(res.user.sex);
        setDob(res.user.dob);
        setLanguageForEmail(res.user.default_lang);

        setSelectedCity(res.user.city);
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
    fetch(apiHost + "auth/check-social-user", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((result) => {
        setIsGoogleUser(result.true ? true : false);
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  }, []);

  const convertDate = (givenDate) => {
    // Extract day, month, and year
    var parts = givenDate.split('/');
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    // Create a date object
    var dateObj = new Date(year, month - 1, day, 14, 34, 35);

    // Format the date
    var formattedDate = dateObj.toUTCString();

    return formattedDate;
  }

  const convert = (dateString) => {
    var dateObj = new Date(dateString);

    // Extract day, month, and year
    var day = dateObj.getDate();
    var month = dateObj.getMonth() + 1; // Months are zero-based, so we add 1
    var year = dateObj.getFullYear();

    // Format the day, month, and year into dd/mm/yyyy format
    var formattedDate = (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year;

    return formattedDate
  }

  const setProfile = async () => {
    const date1 = convertDate(dob);
    const max = convert(maxDate)

    // Convert date strings to Date objects
    var date1Parts = max.split('/');
    var date2Parts = dob.split('/');
    var date3 = new Date(date1Parts[2], date1Parts[1] - 1, date1Parts[0]); // Month is zero-based, so we subtract 1
    var date2 = new Date(date2Parts[2], date2Parts[1] - 1, date2Parts[0]);
    
    const validationMessage = validateUserData(
      first_name,
      last_name,
      gender,
      languageForEmail,
      dob,
      maxDate,
      country,
      pages_langsheet,
      lang
  );
    
    //Checking if the entered(updated) DOB is correct when the use comes to editUserProfile
    if (
      validationMessage ||
      date2 === "Invalid Date" ||
      date1 === "Invalid Date" ||
      dob === "undefined/Invalid Date/undefined"
    ) {
      //  conditional rendering for error message
      setErrDob(
        validationMessage
          ? validationMessage
          : pages_langsheet?.enterValidDob?.[lang]
      );

      // Handle the toaster message conditionally
      if (!validationMessage) {
        toaster.push(
          <Message showIcon type="error">
            Fill DOB
          </Message>,
          { placement: "topEnd", duration: 3000 }
        );
      }
      return;
    } else {
      setErrDob(null);
    }
  
    if (date2 > date3) {    //comparing entered date with max data i.e. user should be atleast 18 years old
      setErrDob(pages_langsheet?.minValidAge?.[lang])
    } else if (
      first_name !== "" &&
      last_name !== "" &&
      country !== "" &&
      // city !== "" &&
      gender &&
      gender !== "" &&
      languageForEmail
    ) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);

      var formdata = new FormData();
      if (first_name && first_name !== "") {
        formdata.append("first_name", first_name);
      }

      if (last_name && last_name !== "") {
        formdata.append("last_name", last_name);
      }

      if (gender && gender !== "") {
        formdata.append("sex", gender);
      }

      if (languageForEmail && languageForEmail !== "") {
        formdata.append("default_lang", languageForEmail);
      }

      formdata.append("country", country);
      formdata.append("city", city);
      if (date2 <= date3) {
        formdata.append("dob", dob);
      }
      if (passwordChanged) {
        formdata.append("password", passwordInput.password);
      }
      if (finalImage) {
        formdata.append("profile_image", finalImage);
      }
      else if (userProfile?.user?.profile_image) {
        // no need to change image
      }
      else if (gender == Male) {
        const male1File = await fetchFile(male1);
        formdata.append("profile_image", male1File);
      }
      else if (gender == Female) {
        const female1File = await fetchFile(female1);
        formdata.append("profile_image", female1File);
      }

      fetch(apiHost + "auth/set-profile", {
        method: "POST",
        headers: myHeaders,
        body: formdata,
      })
        .then((res) => {
          if (res.status !== 200) {
          } else {
            return res;
          }
        })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((res) => {
          handleClickOpen();
          navigation("/userProfile");
          setChangeCountry(!changeCountry)
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
            setUser(null);
          }
        });
    }
    else {
      setErr(pages_langsheet?.allFieldsMandatory?.[lang]);
    }

  };

  async function fetchFile(path) {
    const response = await fetch(path);
    const blob = await response.blob();
    const file = new File([blob], 'male.jpg', { type: 'image/jpeg' });
    return file;
  }

  const login = (email, password) => {
    email = email.trim();
    password = password.trim();
    if (password.length === 0) {
      return setErr("Password is empty");
    }
    fetch(apiHost + "auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((res) => {
        if (res.status === 200) {
          setErr("");
          return res;
        } else {
          setErr("Wrong old Password");
        }
      })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };

  const [allCountries, setAllCountries] = useState([]);
  useEffect(() => {
    fetch(apiHost + "api/country")
      .then((res) => res.json())
      .then((res) => setAllCountries(res));
  }, []);

  const [allCities, setAllCities] = useState([]);


  const getCities = (event) => {

    const country = allCountries.find(
      (country) => country.id === event.target.value
    ).country;
    setCountry(country);

    var data = require("./data.json");
    var getCode = data.filter(function (el) {
      return el.name == country;
    });
    const newArray = City.getAllCities().filter(function (el) {
      return el.countryCode == getCode[0].code;
    });
    const cities = [];
    newArray.forEach(function (arrayItem) {
      cities.push(arrayItem.name);
    });
    const res = cities.sort();
    setAllCities(res);

    setCity("");
  };

  const cityList = () => {
    var data = require("./data.json");
    var getCode = data.filter(function (el) {
      return el.name == country;
    });
    const newArray = City.getAllCities().filter(function (el) {
      return el.countryCode == getCode[0].code;
    });
    const cities = [];
    newArray.forEach(function (arrayItem) {
      cities.push(arrayItem.name);
    });
    const res = cities.sort();
    setAllCities(res);
  };

  

  const onChangeInput = (e) => {
    setFile(e.target.files[0])
    setCropImg(true)
  }

  //calculating the max date a user can select
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - minAge);
  
  //calculating the minimum date a user can select
  const minDate =  new Date();
  minDate.setFullYear(minDate.getFullYear() - 100);
  const minDateString = `${minDate.getMonth() + 1}/${minDate.getDate()}/${minDate.getFullYear()}`;
  
  //setting the value for the datepicker
  const defaultDateValue = dob==='' ? maxDate : new Date(String(dob.split("/")[1] + "/" + dob.split("/")[0] + "/" + dob.split("/")[2]))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        {cropImg && <CropImage getFinalImage={(data) => { setFinalImage(data) }} closePopup={(data) => { setClosePopup(data) }} closeState={closePopUp} userProfile={userProfile} image={file} open={cropImg} close={() => { setCropImg(false) }} />}
        <div className="container my-5" style={{ minHeight: "60vh" }}>
          <h4
            className="text-uppercase sliderHead"
            style={{ color: primeBlue }}
          >
            {/* Profile */}
            {languageSheet[8][lang].split(" ")[1]}
          </h4>
          {/* userProfile?.user?.sex === "Male" ?   <img src="./assets/male.jpg" alt='ProfileImage' width={"150px"} />
              :
              <img src="./assets/female.jpg" alt='ProfileImage' width={"150px"}/> */}

          <div className="row ">
            <div className="col-lg-2 col-md-12 my-3">
              <img
                src={
                  finalImage
                    ? URL.createObjectURL(finalImage)
                    : userProfile?.user?.profile_image
                      ? userProfile?.user?.profile_image
                      : userProfile?.user?.sex === "Male" 
                      ?   "./assets/female.jpg" 
                      : "./assets/male.jpg"
                }
                alt="ProfileImage"
                style={{ maxWidth: "inherit" }}
              />
              {/* Edit your photo */}
              <p className="text-muted">{languageSheet[88][lang]}</p>
              <button
                className="text-uppercase primaryButton px-2 py-1"
                onClick={(e) => {

                  uploader.current.click();
                }}
              >
                {/* browse */}
                {languageSheet[75][lang]}
              </button>
              <input
                type="file"
                id="imgupload"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                ref={uploader}
                onChange={(e) => { onChangeInput(e) }}
              />
            </div>

            <div className="col-md-6 col-lg-5 my-3">
              <div className="row align-items-center">
                <div className="col-md-4 label">
                  {languageSheet[45][lang].split(",")[0].split("/")[0]}{" "}*
                  {/* First Name  */}
                </div>
                <div className="col-md-6">
                  <Input
                    placeholder={
                      languageSheet[45][lang].split(",")[0].split("/")[0]
                    } // First Name
                    inputProps={ariaLabel}
                    className="w-100"
                    value={first_name}
                    onChange={(e) => setFname(e.target.value)}
                    style={{ paddingLeft: "10px" }}
                  />
                </div>
              </div>

              <div className="row my-3 align-items-center">
                <div className="col-md-4 label">
                  {languageSheet[45][lang].split(",")[0].split("/")[1]}{" "}*
                  {/* Last Name  */}
                </div>
                <div className="col-md-6">
                  <Input
                    placeholder={
                      languageSheet[45][lang].split(",")[0].split("/")[1]
                    } // Last Name
                    inputProps={ariaLabel}
                    className="w-100"
                    type="text"
                    value={last_name}
                    onChange={(e) => {
                      setLname(e.target.value);
                    }}
                    style={{ paddingLeft: "10px" }}
                  />
                </div>
              </div>

              <div className="row my-3 align-items-center">
                <div className="col-md-4 label">
                  {/* Gender */}
                  {languageSheet[86][lang].split(",")[0]}*
                </div>
                <div className="col-md-6">
                  <FormControl fullWidth>
                    <NativeSelect
                      defaultValue={gender}
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      inputProps={{
                        style: {
                          paddingLeft: '10px',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            paddingLeft: '10px',
                          },
                        },
                      }}
                    >
                      <option value="">
                        {/* Select Gender */}
                        {languageSheet[87][lang].split(",")[0]}
                      </option>

                      {[languageSheet[131][lang].split(',')[0], languageSheet[131][lang].split(',')[1], languageSheet[131][lang].split(',')[2]].map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>

              <div className="row my-3 align-items-center">

                <div className="col-md-4 label">
                  {languageSheet[27][lang].split(",")[0]}* {/* email */}
                </div>
                <div className="col-md-6">
                  <Input
                    placeholder="Jerry@gmail.com"
                    inputProps={ariaLabel}
                    className="w-100"
                    value={user.email}
                    disabled
                  />
                </div>
              </div>

              <div className="row my-3 align-items-center">
                <div className="col-md-4 label">
                  {/* Birthday */}
                  {languageSheet[76][lang]}*
                </div>
                <div className="col-md-6">
                <DatePicker
                    label={pages_langsheet?.DOB?.[lang]}
                    value={defaultDateValue}
                    className="w-100"
                    minDate={minDateString}
                    maxDate={maxDate.toLocaleDateString()}
                    inputFormat="DD/MM/YYYY"
                    disableFuture
                    onChange={(newVal) => {
                      let formatDob = new Date(newVal).toLocaleDateString().replaceAll("-", "/").split("/");
                      let formatedDob = formatDob[1] + "/" + formatDob[0] + "/" + formatDob[2];
                      setDob(formatedDob)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    inputProps={ariaLabel}
                   
                  />
                </div>
              </div>

              <div className="row my-3 align-items-center">
                {/* Language  */}
                <div className="col-md-4 label">
                  {languageSheet[86][lang].split(",")[1]} *
                </div>
                <div className="col-md-6">
                  <FormControl fullWidth>
                    <NativeSelect
                      defaultValue={languageForEmail}
                      value={languageForEmail}
                      onChange={(e) => setLanguageForEmail(e.target.value)}
                      inputProps={{
                        style: {
                          paddingLeft: '10px',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            paddingLeft: '10px',
                          },
                        },
                      }}
                    >
                      {/* Select Language */}
                      <option value="">
                        {languageSheet[87][lang].split(",")[1]}
                      </option>

                      {[
                        { label: "English", value: "english" },
                        { label: lang == "es" ? "Español" : "Spanish", value: "spanish" },
                        { label: "Portuguese", value: "portuguese" },
                      ].map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>

              <div className="row my-3 align-items-center">
                <div className="col-md-4 label">
                  {languageSheet[32][lang].split(",")[2]}* {/* conuntry */}
                </div>
                <div className="col-md-6">
                  <FormControl fullWidth>

                    <NativeSelect
                      defaultValue={30}
                      onChange={getCities}
                      inputProps={{
                        style: {
                          paddingLeft: '10px',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            paddingLeft: '10px',
                          },
                        },
                      }}
                    >
                      <option value="">
                        {country == "" ? "Select country" : country}
                      </option>
                      {allCountries.map((countryOption) => {
                        return (
                          <option value={countryOption.id}>
                            {countryOption.country}
                          </option>
                        );
                      })}

                    </NativeSelect>
                  </FormControl>
                </div>
              </div>

                      {/*Not showing City select for a normal user when they are on /editUserProfile */}
              {/* <div className="row my-3 align-items-center">
                <div className="col-md-4 label">
                  {languageSheet[45][lang].split(",")[3]}*  */}
                  {/* City */}
                {/* </div>
                <div className="col-md-6"> */}
                  {/* <FormControl fullWidth>
                    <NativeSelect
                      defaultValue=""
                      value={city}
                      onClick={cityList}
                      onChange={(e) => {
                        setCity(e.target.value);
                        cityList();
                      }}
                      inputProps={{
                        style: {
                          paddingLeft: '10px',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            paddingLeft: '10px',
                          },
                        },
                      }}
                    > */}

                      {/* {city != "" ? <option value="">{city}</option> : <option value="" >{languageSheet[87][lang]?.split(",")?.[3]}</option>}
                      {allCities.map((cityOption, idx) => {
                        return (
                          <option key={idx} value={cityOption.city}>
                            {cityOption}
                          </option>
                        );
                      })}

                    </NativeSelect>
                  </FormControl>
                </div>
              </div> */}
              {errDob && <p className="alert alert-danger">{errDob}</p>}
              {err && <p className="alert alert-danger">{err}</p>}
            </div>

            {!isGoogleUser && (
              <div className="col-md-6 col-lg-5 my-3">
                <div className="row my-3 align-items-center">
                  <div className="col-md-5 label">
                    {/* PASSWORD */}
                    {languageSheet[27][lang].split(",")[1]}
                  </div>
                  <div className="col-md-6">
                    <Input
                      inputProps={ariaLabel}
                      className="w-100"
                      type="password"
                      onBlur={(e) => {
                        login(user.email, e.target.value);
                      }}
                    />
                  </div>
                  <p className="text-danger">{err}</p>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-5 label">
                    {/* NEW PASSWORD */}
                    {languageSheet[46][lang].split("/")[1] +
                      " " +
                      languageSheet[46][lang].split(",")[0].split(" ")[1]}
                  </div>
                  <div className="col-md-6">
                    <Input
                      onChange={handlePasswordChange}
                      name="password"
                      onKeyUp={handleValidation}
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      value={passwordInput.password}
                      //onChange={(e)=> setPass(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <p className="text-danger">{passwordError}</p>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-5 label">
                    {/* CONFIRM PASSWORD */}
                    {languageSheet[46][lang].split(",")[0].split("/")[2]}
                  </div>
                  <div className="col-md-6">
                    <Input
                      onChange={handlePasswordChange}
                      name="confirmPassword"
                      onKeyUp={handleValidation}
                      value={passwordInput.confirmPassword}
                      id="standard-adornment-password"
                      type={showCPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showCPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>
                  <p className="text-danger">{confirmPasswordError}</p>
                </div>
              </div>
            )}
          </div>

          <div className="row mt-5">
            <div className="buttons d-flex justify-content-end flex-wrap">
              <button
                className="primaryButton2 py-2 px-5 m-2"
                disabled={
                  confirmPasswordError == "" && passwordError == ""
                    ? false
                    : true
                }
                onClick={setProfile}
              >
                {/* SAVE */}
                {languageSheet[46][lang].split(",")[1]}
              </button>
              <button
                className="secondaryButton py-2 px-5 m-2"
                onClick={() => {
                  navigation("/userProfile");
                }}
              >
                {/* CANCEL */}
                {languageSheet[35][lang].split(",")[1]}
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </LocalizationProvider>
  );
}
