import { useState, useRef, useEffect, useContext } from "react";
import ScheduleMaker from "../../Components/ScheduleMaker";
import { Navigate, useNavigate } from "react-router-dom";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import Footer from "../../Components/Footer";
import { bgColor, primeBlue, textBlue, textSecondary } from "../../env";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Checkbox from '@mui/material/Checkbox';
import { ListItemText } from "@mui/material";
import { City, State } from 'country-state-city';
import { useToaster } from "rsuite";
import languageSheet from '../../language'
import { LanguageContext } from '../../context/languageContext'
import { UserContext } from "../../context/userContext";
import MessageUserExpired from "../../Components/MessageUserExpired";
import CropImage from "../../Components/CropImage/CropImage";
import { pages_langsheet } from "../Languages/PagesLangsheet";
import { validateProData } from "../../utils/validateProData";
import { isUserLoggedIn } from "../../utils/Utils";
const apiHost = process.env.REACT_APP_BACKEND_URL;

const ariaLabel = { "aria-label": "description" };

const steps = ["Inprocess", "Certified"];
export default function ProfessionalProfilePage({ user, previewAd, setPreviewAd, setChangeCountry, changeCountry }) {
  const toaster = useToaster();
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext);
  const { lang, setLang } = useContext(LanguageContext)
  let sessionInfo = JSON.parse(sessionStorage.getItem('otherInfo'));
  const [joinEmail, setJoinEmail] = useState(
    sessionInfo?.joinEmail ? sessionInfo.joinEmail : null
  );
  const [fp, setFP] = useState(false); //for forget Pass
  // for previewAdd
  const [subPlan, setSubPlan] = useState(
    sessionInfo?.subPlan ? sessionInfo.subPlan : null
  ); // for subscription id
  const [proNew, setProNew] = useState(
    sessionInfo?.proNew ? sessionInfo.proNew : null
  );
  const [schErr, setSchErr] = useState("")
  useEffect(() => {
    sessionStorage.setItem(
      'otherInfo',
      JSON.stringify({ joinEmail, fp, previewAd, subPlan, proNew })
    );
  }, [joinEmail, fp, previewAd, subPlan, proNew]);

  const [allCountries, setAllCountries] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const proCountries = require('../Professional/proCountries.json')
  let puertoRicoState = require("../Professional/stateData.json");

  const [userProfile, setUserProfile] = useState(null);
  const uploader = useRef(null);


  // stepper funcs
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [allLanguages, setAllLang] = useState(['english', 'portuguese', 'spanish', 'chinese', 'french', 'german', 'japanese'])
  const [selectedLang, setSelectedLang] = useState(previewAd?.languages ? previewAd.languages : [])
  const [cropImg, setCropImg] = useState(false)
  const [finalImage, setFinalImage] = useState(null)
  const [long_description, setLD] = useState(
    previewAd?.long_description ? previewAd.long_description : ""
  );
  const [err, setErr] = useState(null);

  const transformSchedule = (data) => {
    const weekKey = {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
    };
    var scheduleNew = {
      Sunday: {
        available: false,
        timeIntervals: [['8:00', '10:00']],
      },
      Monday: {
        available: false,
        timeIntervals: [['8:00', '10:00']],
      },
      Tuesday: {
        available: false,
        timeIntervals: [['8:00', '10:00']],
      },
      Wednesday: {
        available: false,
        timeIntervals: [['8:00', '10:00']],
      },
      Thursday: {
        available: false,
        timeIntervals: [['8:00', '10:00']],
      },
      Friday: {
        available: false,
        timeIntervals: [['8:00', '10:00']],
      },
      Saturday: {
        available: false,
        timeIntervals: [['8:00', '10:00']],
      },
    };
    for (let day in weekKey) {
      if (data[day].length != 0) {
        scheduleNew[weekKey[day]].timeIntervals.splice(0, scheduleNew[weekKey[day]].timeIntervals.length)
        scheduleNew[weekKey[day]].available = true;
        for (let range of data[day]) {
          let splits = range.split(",");
          let start = splits[0].substring(1);
          let end = splits[1].slice(0, -1);

          scheduleNew[weekKey[day]].timeIntervals.push([start, end]);
        }
      }
    }
    setSchedule({ ...scheduleNew });
  };

  const [schedule, setSchedule] = useState(
    previewAd?.trueSchedule ? previewAd.trueSchedule :
      {
        Sunday: {
          available: false,
          timeIntervals: [['8:00', '10:00']]
        },
        Monday: {
          available: false,
          timeIntervals: [['8:00', '10:00']]
        },
        Tuesday: {
          available: false,
          timeIntervals: [['8:00', '10:00']]
        },
        Wednesday: {
          available: false,
          timeIntervals: [['8:00', '10:00']]
        },
        Thursday: {
          available: false,
          timeIntervals: [['8:00', '10:00']]
        },
        Friday: {
          available: false,
          timeIntervals: [['8:00', '10:00']]
        },
        Saturday: {
          available: false,
          timeIntervals: [['8:00', '10:00']]
        },
      }
  )

  //data states
  const [fname, setFname] = useState(previewAd?.name ? previewAd.name : "");
  const [lname, setLname] = useState(previewAd?.name ? previewAd.name : "");
  const [country, setCountry] = useState(
    previewAd?.country ? previewAd.country : ""
  );
  const [countryId, setCountryId] = useState(
    previewAd?.countryId ? previewAd.countryId : ""
  );
  const [city, setCity] = useState(previewAd?.city ? previewAd.city : "");
  const [profession, setProfession] = useState(
    previewAd?.profession ? previewAd.profession : ""
  );
  const [professionId, setProfessionId] = useState(
    previewAd?.professionId ? previewAd.professionId : ""
  );
  const [dni, setDni] = useState(previewAd?.dni ? previewAd.dni : "");
  const [short_description, setSD] = useState(
    previewAd?.short_description ? previewAd.short_description : ""
  );
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (user === null) {
      navigation("/signIn");
    } else {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      fetch(apiHost + "auth/user-profile", {
        headers: myHeaders,
      })
        .then((res) => {

          if (res.status == 401) {
            const error = new Error('unauth')
            error.code = 401
            throw error
          } else {
            return res.json()
          }
        })
        .then((res) => {
          setUserProfile(res);
          setFname(res.user.first_name);
          setLname(res.user.last_name);
          setCountry(res.user.country);
          setCity(res.user.city);
          setProfession(res.profession);
          setSelectedLang(typeof res.languages === 'string' ? res.languages.split(',') : res.languages);
          setDni(res.dni);
          setSD(res.short_description);
          setLD(res.long_description);
          setFile(res.profile_image);
          let myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
          fetch(apiHost + "api/schedule/", {
            headers: myHeaders,
          })
            .then((res) => {

              if (res.status == 401) {
                const error = new Error('unauth')
                error.code = 401
                throw error
              } else {
                return res.json()
              }
            })
            .then((res) => {
              transformSchedule(res);
            }).catch((err) => {
              if (err.code == 401) {
                sessionStorage.removeItem("otherInfo");
                sessionStorage.removeItem("professional");
                navigation("/");
                toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
                setUser(null);
              }
            });
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
            setUser(null);
          }
        })
    }
  }, []);

  const saveDataToPreview = () => {
    const validationResult = validateProData(
      country,
      city,
      profession,
      selectedLang,
      dni,
      short_description,
      long_description,
      schedule,
      pages_langsheet,
      lang
    );
    if (!validationResult) {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);

      var formdata = new FormData();
      formdata.append("first_name", fname);
      formdata.append("last_name", lname);
      formdata.append("country", country);
      formdata.append("city", city);
      formdata.append("short_description", short_description);
      formdata.append("long_description", long_description);
      formdata.append("languages", selectedLang);

      formdata.append("dni", dni);

      if (finalImage) {
        formdata.append("profile_image", finalImage);
      }
      fetch(apiHost + "api/professional/" + userProfile.id + "/", {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
      })
        .then((res) => {
          if (res.status !== 200) {
            console.log("err");
          } else {
            return res;
          }
        })
        .then((res) => {

          if (res.status == 401) {
            const error = new Error('unauth')
            error.code = 401
            throw error
          } else {
            if (res.status == 200) {
              setChangeCountry(!changeCountry)
            }
            return res.json()
          }
        })
        .then((res) => {
          // setUser(res)
          // handleClickOpen();
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
            setUser(null);
          }
        })

      myHeaders.append("Content-Type", "application/json");
      let genSchedule = {}

      for (let key in schedule) {

        if (schedule[key].available) {
          genSchedule[key.toLowerCase()] = schedule[key].timeIntervals.map((interval) => {
            return `[${interval}]`
          })
        } else {
          genSchedule[key.toLowerCase()] = []
        }
      }

      // userProfileid
      fetch(apiHost + "api/schedule/" + userProfile.id + "/", {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(genSchedule),
      })
        .then((res) => {
          if (res.status !== 200) {
            console.log("err");
          } else {
            return res;
          }
        })
        .then((res) => res.json())
        .then((res) => {
        })
        .catch((err) => console.log({ err }));

      navigation("/proProfile");
    }else if(fname===''){ //Check if firstName is provided
      setErr(pages_langsheet?.validFirstName?.[lang]);
    }else if(lname===''){ //Check if lastName is provided
      setErr(pages_langsheet?.validLastName?.[lang]);
    }
    else {
      // If validation error exists, set the error
      setErr(validationResult);
    }
  };

  const handleChangeLang = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLang(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const onChangeInput = (e) => {
    setFile(e.target.files[0])
    setCropImg(true)
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };


  useEffect(() => {
    fetch(apiHost + "api/country")
      .then((res) => res.json())
      .then((res) =>{
        //Professional should be able to change country according to selectedCountries
        //The cities will be displayed accordingly
        const proCountryList = res.filter((countryObj) =>
          proCountries.includes(countryObj.country)
        );
        setAllCountries(proCountryList);
      })
      .catch((error) => console.error('Error fetching countries:', error));
    }, []); 

  const getCities = (event) => {
    setCity("");

    const country = allCountries.find(
      (country) => country.id === event.target.value
    );
    setCountry(country.country);
    setCountryId(country.id);

    var data = require("../User/data.json");
    var getCode = data.filter(function (el) {
      return el.name == country.country;
    });

    let newArray = [];
    // Created a separate file for the states of Puerto rico

    if (getCode[0].code === "PR") {
      newArray = puertoRicoState;
    } else {
      newArray = State.getAllStates().filter(function (el) {
        return el.countryCode == getCode[0].code;
      });
    }

    const states = [];
    newArray.forEach(function (arrayItem) {
      states.push(arrayItem.name);
    });
    const res = states.sort();
    setAllStates(res);

  };

  const cityList = () => {
    var data = require('../User/data.json')
    var getCode = data.filter(function (el) {
      return el.name == country;
    }
    );

    let newArray = [];
    // Condition to check for the states of Puerto rico
    if (getCode[0].code === "PR") {
      newArray = puertoRicoState;
    } else {
      newArray = State.getAllStates().filter(function (el) {
        return el.countryCode == getCode[0].code;
      });
    }
    const states = [];
    newArray.forEach(function (arrayItem) {
      states.push(arrayItem.name);
    });
    const res = states.sort();
    setAllStates(res);

  };

  const handleKeyPress = (event, len) => {
    if (event.target.value.length >= len) {
      event.preventDefault(); // Prevent further input beyond len characters
    }
  };

  
  console.log(userProfile?.user?.sex)

  return (
    <div className="edit-professional-profile d-flex flex-column justify-content-between" style={{ backgroundColor: bgColor }}>
      {cropImg && <CropImage getFinalImage={(data) => { setFinalImage(data) }} image={file} open={cropImg} close={() => { setCropImg(false) }} setImage={(data) => { setFile(data) }} />}
      <div className="container my-5">
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {/* Profile */}
          {languageSheet[8][lang].split(' ')[1]}
        </h4>

        <div className="row ">
          <div className="col-md-7">
            <div className="row">
              {/* userImg */}
              <div className="col-lg-3 my-3">

                <img
                  src={
                    finalImage
                      ? URL.createObjectURL(finalImage)
                      : userProfile?.user?.profile_image
                        ? userProfile?.user?.profile_image
                        : userProfile?.user?.sex 
                        ?   "./assets/female.jpg" 
                        : "./assets/male.jpg"
                  }
                  style={{ maxWidth: "inherit" }}
                  alt="ProfileImage"
                />
                <div className="mt-3">
                  <img src="./assets/verifiedBadge.svg" alt="VerifiedImage" />
                  <p className="d-inline textSecondary">
                    {/* CERTIFIED */}
                    {languageSheet[63][lang]}
                  </p>
                </div>
                <div className="mt-3">
                  <img src="./assets/StarYellow.svg" alt="YelloStar" />
                  <p className="d-inline textSecondary">
                    {/* ADD TO FAVORITE */}
                    {languageSheet[33][lang].split(',')[0]}
                  </p>
                </div>
                <div className="mt-3">
                  <p className="label">
                    {/* Edit your photo */}
                    {languageSheet[88][lang]}
                  </p>
                </div>
                <button
                  className="text-uppercase primaryButton px-2 py-1"
                  onClick={(e) => {
                    uploader.current.click();
                  }}
                >
                  {/* browse */}
                  {languageSheet[75][lang]}
                </button>
                <input
                  type="file"
                  id="imgupload"
                  accept="image/jpeg, image/png"
                  style={{ display: "none" }}
                  ref={uploader}
                  onChange={(e) => { onChangeInput(e) }}
                />
              </div>
              {/* user details */}
              <div className="col-md-12 col-lg-6 my-3">
                {err && <p className="alert alert-danger">{err}</p>}
                <div className="row align-items-center mb-3">
                  <div className="col-md-4 label">
                    {/* FIRST NAME */}
                    {languageSheet[45][lang].split(',')[0].split('/')[0]}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <Input
                      inputProps={ariaLabel}
                      className='w-100'
                      value={fname}
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row align-items-center mb-3">
                  <div className="col-md-4 label">
                    {/* LAST NAME */}
                    {languageSheet[45][lang].split(',')[0].split('/')[1]}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <Input
                      inputProps={ariaLabel}
                      className='w-100'
                      value={lname}
                      onChange={(e) => setLname(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* COUNTRY */}
                    {languageSheet[58][lang]}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <FormControl fullWidth>
                      <NativeSelect
                        defaultValue={30}
                        onChange={getCities}
                      >
                        <option value="">{country == "" ? languageSheet[144][lang] : country}</option>
                        {allCountries.map((countryOption) => {
                          return (
                            <option value={countryOption.id}>
                              {countryOption.country}
                            </option>
                          );
                        })}

                      </NativeSelect>
                    </FormControl>
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* CITY */}
                    {languageSheet[59][lang]}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <FormControl fullWidth>
                      <NativeSelect
                        defaultValue={30}
                        onClick={cityList}
                        onChange={(e) => {
                          setCity(e.target.value)
                          cityList()
                        }

                        }
                      >

                      <option value="">{city === "" ? languageSheet[143][lang] : city}</option>
                        {allStates.map((cityOption) => {
                          return (
                            <option value={cityOption.city}>
                              {/* {cityOption.city} */}
                              {cityOption}
                            </option>
                          );
                        })}

                      </NativeSelect>
                    </FormControl>
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* PROFESSION */}
                    {languageSheet[60][lang]}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <Input
                      inputProps={ariaLabel}
                      className='w-100'
                      value={profession}
                      disabled
                      onChange={(e) => setProfession(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* LANGUAGES */}
                    {languageSheet[32][lang].split(',')[5]}
                  </div>
                  <div className="col-md-8 textSecondary">

                    <FormControl fullWidth>
                      <Select

                        inputProps={ariaLabel}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={selectedLang}
                        onChange={handleChangeLang}
                        input={
                          <Input
                            inputProps={ariaLabel}
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value.charAt(0).toUpperCase() + value.slice(1)} />
                            ))}
                          </Box>
                        )}

                      >
                        {allLanguages.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                          >
                            <Checkbox checked={selectedLang.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="row my-3 align-items-center">
                  <div className="col-md-4 label">
                    {/* DNI */}
                    {languageSheet[79][lang]}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <Input
                      inputProps={ariaLabel}
                      className='w-100'
                      value={dni}
                      onChange={(e) => setDni(e.target.value)}
                    />
                  </div>
                </div>
                {/* inline css is used to overwrite css */}
                <div className="col-md-4 label" style={{ marginTop: 30, marginBottom: 15, width: "70%" }}>
                  {/* TITLE */}
                  {languageSheet[141][lang]}
                </div>
                <Input
                  inputProps={{ ariaLabel, maxLength: 150 }}
                  onKeyPress={(e) => { handleKeyPress(e, 150) }}
                  className='w-100'
                  value={short_description}
                  onChange={(e) => setSD(e.target.value)}
                />
                <div className="col-md-4 label" style={{ marginTop: 30, marginBottom: 15 }}>
                  {/* DESCRIPTION */}
                  {languageSheet[66][lang]}
                </div>
                <TextField
                  id="standard-multiline-static"
                  multiline
                  rows={4}
                  variant="standard"
                  className=" w-100"
                  style={{ color: textBlue, textAlign: "justify" }}
                  inputProps={{ maxLength: 500 }}
                  onKeyPress={(e) => { handleKeyPress(e, 500) }}
                  value={long_description}
                  onChange={e => setLD(e.target.value)}
                />

              </div>
            </div>
          </div>

          <div className="col-md-5 my-3 ">


            <p className="littleHead">
              {/* SCHEDULE: */}
              {languageSheet[64][lang]}:
            </p>
            <p id="availability" >{pages_langsheet?.pleaseSelectDaysAndTime?.[lang]}</p>

            <div className="row justify-content-center">

              <ScheduleMaker schedule={schedule} setSchedule={setSchedule} schErr={schErr} setSchErr={setSchErr} />

            </div>


          </div>
        </div>
        <div className="row mt-5">
          <div className="buttons d-flex justify-content-end">
            <buttons
              className="primaryButton2 py-2 px-3 mx-2"
              onClick={() => {
                saveDataToPreview();
              }}
            >
              {/* SAVE */}
              {languageSheet[46][lang].split(',')[1]}
            </buttons>
            <buttons className="secondaryButton py-2 px-3 mx-2" onClick={() => navigation("/proProfile")}>
              {/* CANCEL */}
              {languageSheet[35][lang].split(',')[1]}
            </buttons>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
