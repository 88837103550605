import React, { useContext } from 'react'
import './AppointmentCard.css'
import languageSheet from '../../language'
import { components_langsheet } from '../Language/ComponentsLangsheet'
import { LanguageContext } from "../../context/languageContext";
import profileNoImage from '../../assests/images/profileNoImage.png'


export const AppointmentCard = ({ appointment, isproff, setOpenPopup, setAppointmentID, user, onClickReschedule }) => {
  const { lang, setLang } = useContext(LanguageContext);

  return (
    <div className="appointment-card d-flex flex-column">
      <div className="card-title d-flex p-2">
        <div className="card-left d-flex flex-column gap-1" style={{ width: '100%' }}>
          <div className="d-flex justify-content-between gap-3">
            <div className="d-flex gap-3">
              {!isproff ? <img style={{ width: '37px', height: '37px', borderRadius: '100%' }} src={appointment?.professional[0]?.profile_image || profileNoImage} alt="" /> :
                <> <img style={{ width: '37px', height: '37px', borderRadius: '100%' }} src={appointment?.user?.profile_image || profileNoImage} alt="" /></>}
              <div className="title" style={{ width: '100%' }}>
                <span className='textSecondary' style={{ color: 'black' }}> {isproff
                  ? appointment.user.username
                  : appointment.professional[0].name}</span>
                {!isproff ? <p className='textSecondary' style={{ color: 'rgb(179,182,185)', fontSize: '12px', marginBottom: '0px' }}>{isproff
                  ? appointment.user.username
                  : appointment.professional[0].profession}</p> : <></>}
              </div>
            </div>

            {!isproff && appointment?.meeting_status == "confirmed" && <div className="reschedule">
              <span onClick={() => onClickReschedule(appointment)}>{components_langsheet?.reschedule?.[lang]}</span>
            </div>}
          </div>

          <div className="date-time d-flex justify-content-between">
            <div className="d-flex gap-2" style={{ alignItems: 'center' }}>
              <span id="date" style={{ color: '#256693' }}>{appointment.date.split("-")[2] + "-" + appointment.date.split("-")[1] + "-" + appointment.date.split("-")[0]}</span>
              <p id='time' style={{ marginBottom: '0px', color: 'rgb(232, 131, 222)' }}>{appointment.start_time.substring(0, 5)}</p>
            </div>
            <div>
              {appointment.meeting_status == "Confirmed" ? (
                <button
                  className="statusBtnCompleted"
                  onClick={() => {
                    if (user) {
                      setOpenPopup(true);
                      setAppointmentID(appointment.id);
                    }
                  }}
                >
                  {/* Cancel */}
                  {languageSheet[35][lang].split(",")[1]}
                </button>
              ) : (
                <button className="statusBtnCompleted disabled">
                  {components_langsheet[appointment.meeting_status][lang]}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
