import { useState, useEffect, useRef, useContext, Fragment } from 'react'
import { MenuItem, Stack, Button, Select, Drawer, useMediaQuery, Box } from '@mui/material'
import './Filter.css'
import { components_langsheet } from '../Language/ComponentsLangsheet';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { pages_langsheet } from '../../Pages/Languages/PagesLangsheet'
import { LanguageContext } from "../../context/languageContext";
import languageSheet from '../../language'


const apiHost = process.env.REACT_APP_BACKEND_URL;
const backendURL = process.env.REACT_APP_BACKEND_URL;
const page_size = 12;


const English = components_langsheet["English"].en
const Spanish = components_langsheet["Spanish"].en
const Portugese = components_langsheet["Portugese"].en
const German = components_langsheet["German"].en
const Japanese = components_langsheet["Japanese"].en
const French = components_langsheet["French"].en
const Chinese = components_langsheet["Chinese"].en

function Filter(props) {
  const { id } = useParams()
  let navigate = useNavigate()
  const location = useLocation();

  const matches = useMediaQuery('(max-width:900px)')      //if width is greater than 900 then true else false
  const [professionName, setProfessionName] = useState([])          // store all professions from API for Dropdown
  const [countryName, setCountryName] = useState([])               // All country Names from API for Dropdown
  const [country, setCountry] = useState("")
  const [cityName, setCityName] = useState([])                     //All city names from a country
  const [capitalizedCountryName, setCapitalizedCountryName] = useState([]);
  const [showMenu, setShowMenu] = useState(false)
  const [clearFilter, setClearFilter] = useState(true)
  const isMounted = useRef(false)
  const isMounted2 = useRef(false)
  const { lang, setLang } = useContext(LanguageContext);

  const PROFESSION = languageSheet[32]?.[lang].split(',')[4]
  const COUNTRY = languageSheet[32]?.[lang].split(',')[2]
  const CITY = languageSheet[32]?.[lang].split(',')[3]
  const LANGUAGE = languageSheet[32]?.[lang].split(',')[5]


  const searchParams = new URLSearchParams(location.search);

  const professionData = searchParams.get("profession");
  const countryData = searchParams.get("country");
  const cityData = searchParams.get("city");
  const languageData = searchParams.get("language");


  const [filter, setFilter] = useState({                       //Filter State
    profession: props.id ? pages_langsheet[props.id]?.[lang] : professionData ? professionData : "PROFESSION",
    country: countryData ? countryData : "COUNTRY",
    city: cityData ? cityData : "CITY",
    language: languageData ? languageData : "LANGUAGE"
  })

  useEffect(() => {  //will update the state when we go back or next in the browser

    setFilter(prevFilter => ({
      ...prevFilter,
      profession: props.id ? pages_langsheet[props.id]?.[lang] : professionData ? professionData : "PROFESSION",
      country: countryData ? countryData : "COUNTRY",
      city: cityData ? cityData : "CITY",
      language: languageData ? languageData : "LANGUAGE"
    }));

    if (countryData) {
      getCities(countryData)
    }
  }, [location])

  const [state, setState] = useState({          //To open and close mui drawer containing filter options
    top: false
  });

  useEffect(() => {

    getProfession();
    getAllCountries();
  }, [])

  useEffect(() => {
    if (!matches) {
      setShowMenu(true)
    }
    else {
      setShowMenu(false)
    }
  }, [matches])

  const toggleDrawer = (anchor, open) => (event) => {               //to open or close filter drawer
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, top: open });

  };

  const getAllCountries = () => {                 //To get all the countries
    fetch(apiHost + "api/tax")
      .then((res) => res.json())
      .then((res) => {
        setCountryName(res);
        setCapitalizedCountryName(
          res.map((country) => ({
            ...country,
            country: country.country.charAt(0).toUpperCase() + country.country.slice(1),
          }))
        );
      });

  }

  useEffect(() => {
    if (isMounted2.current && props.id) {
      setFilter({ ...filter, city: "CITY" })
    }
    else if (isMounted.current) {
      setFilter({ ...filter, city: "CITY" })
    }
    else {
      isMounted2.current = true;
    }
  }, [country])

  const getCities = async (country) => {               //To get all the cities of a particular country

    const cityData = searchParams.get("city");
    if (!cityData) {
      setFilter(prevFilter => ({
        ...prevFilter,
        city: "CITY"
      }));
    }

    if (country) {
      setFilter({ ...filter, country: country, city: cityData ? cityData : "CITY" })
      await fetch(apiHost + `api/professional-city?country=${country}`)
        .then((res) => res.json())
        .then((res) => {
          setCityName([...res])
        });
    }

  };

  const onClickApllyFilter = () => {         //Onclick effect for Apply filter

    filterDatafunc();
  }

  const onClickClearFliter = () => {          //Onclick effect for Clear filter
    setFilter({ ...filter, profession: "PROFESSION", country: "COUNTRY", city: "CITY", language: "LANGUAGE" })
    if (props.id) {
      props.hitFunc(`${backendURL}api/professional?page_size=${page_size}`)
    }
    setClearFilter(!clearFilter)
  }

  useEffect(() => {
    if (isMounted.current) {

      filterDatafunc();

      if (props.id) {
        navigate('/professionals')
      }
    } else {
      isMounted.current = true;
    }
  }, [clearFilter, isMounted]);

  const filterDatafunc = async () => {             //Used to hit filtering API
    const queryParams = new URLSearchParams();

    if (filter.profession != "PROFESSION") {
      queryParams.set("profession", filter.profession);
    }
    if (filter.country != "COUNTRY") {
      queryParams.set("country", filter.country);
    }
    if (filter.city != "CITY") {
      queryParams.set("city", filter.city);
    }
    if (filter.language != "LANGUAGE") {
      queryParams.set("language", filter.language);
    }
    navigate(`/${props.page}?${queryParams.toString()}`);
  }

  const getProfession = () => {                 //To get all the profession data
    fetch(`${backendURL}api/profession`, {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setProfessionName([...res.results]);
      });
  }

  const searchFeilds = {
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      { border: 0 },
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    lineHeight: "16px",
    color: "#999999",

    '@media(max-width:906px)': {
      width: "170px"
    }
  }

  const filterSx = {
    fontFamily: 'Montserrat',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    zIndex: 10,

    color: "#000000"
  }

  const drawerContent = () => {

    return (
      <div className="drawer" >
        <div className="drawer-title">
          <span>
            <svg onClick={() => { setState({ ...state, top: false }) }} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM12 3.5L1 3.5V4.5L12 4.5V3.5Z" fill="black" fill-opacity="0.45" />
            </svg>

            Filter
          </span>
        </div>
        <Stack direction={{ xs: "column", sm: "column", md: "row" }} className="stack-style" mt={3} mb={1} ml={{ md: 4, lg: 25 }} mr={4}>
          <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={2}>
            {/* profession */}

            <Select
              value={filter.profession}
              sx={searchFeilds}
              className="search-feilds"
              style={{ color: "#666666" }}
              onChange={(e) => { setFilter({ ...filter, profession: e.target.value }) }}
            >
              <MenuItem value="PROFESSION">{PROFESSION}</MenuItem>
              {professionName.map((data, index) => {
                return (
                  <MenuItem key={index} value={data.profession} >{pages_langsheet[data?.profession]?.[lang]}</MenuItem>
                )
              })}
            </Select>

            {/* country */}
            {props.page != "favourites" ? <>
              <Select
                value={filter.country}
                sx={searchFeilds}
                className="search-feilds"
                style={{ color: "#666666" }}
                disableUnderline
                onChange={(e) => { getCities(e.target.value); setCountry(e.target.value) }}
              >
                <MenuItem value="COUNTRY">{COUNTRY}</MenuItem>
                {
                  capitalizedCountryName.map((data, ind) => {
                    return (
                      <MenuItem key={ind} id={data.id} value={data.country}>{data?.country == "Brazil" && lang == "es" ? "Brasil" : data?.country == "Mexico" && lang == "es" ? "México" : data?.country}</MenuItem>
                    )
                  })
                }
              </Select>

              {/* city */}
              <Select
                value={filter.city}
                sx={searchFeilds}
                className="search-feilds"
                style={{ color: "#666666" }}
                onChange={(e) => { setFilter({ ...filter, city: e.target.value }) }}
                inputProps={{ 'aria-label': 'Without label' }}
                displayEmpty
              >
                <MenuItem value="CITY">{CITY}</MenuItem>
                {
                  cityName.map((data, ind) => {
                    return (
                      <MenuItem key={ind} value={data}>{data}</MenuItem>
                    )
                  }
                  )
                }
              </Select>

              {/* language */}

              <Select
                value={filter.language}
                sx={searchFeilds}
                className="search-feilds"
                style={{ color: "#666666" }}
                onChange={(e) => { setFilter({ ...filter, language: e.target.value }) }}
              >
                <MenuItem value="LANGUAGE">{LANGUAGE}</MenuItem>
                <MenuItem value="english">{lang == "en" ? "English" : lang == "es" ? "Inglés" : "Inglês"}</MenuItem>
                <MenuItem value="spanish">{lang == "en" ? "Spanish" : lang == "es" ? "Español" : "Espanhol"}</MenuItem>
                <MenuItem value="portuguese">{lang == "en" ? "Portuguese" : lang == "es" ? "Portugués" : "Português"}</MenuItem>
                <MenuItem value="german">{lang == "en" ? "German" : lang == "es" ? "Alemán" : "Alemão"}</MenuItem>
                <MenuItem value="japanese">{lang == "en" ? "Japanese" : lang == "es" ? "Japonés" : "Japonês"}</MenuItem>
                <MenuItem value="french">{lang == "en" ? "French" : lang == "es" ? "Francés" : "Francês"}</MenuItem>
                <MenuItem value="chinese">{lang == "en" ? "Chinese" : lang == "es" ? "Chino" : "Chinês"}</MenuItem>
              </Select>
            </> : <></>}
          </Stack>

          <Stack direction={'row'} id="filter-btn" spacing={2}>
            <Button
              sx={searchFeilds}
              style={{ background: "#0b2435", color: "white", paddingBottom: "12px" }}
              onClick={() => { onClickApllyFilter(); setState({ ...state, top: false }) }}
            >{components_langsheet?.applyFilter?.[lang]}</Button>
            <Button
              sx={searchFeilds}
              style={{ background: "#0b2435", color: "white", paddingBottom: "12px" }}
              onClick={() => { onClickClearFliter(); setState({ ...state, top: false }) }}
            >{components_langsheet?.clearFilter?.[lang]}</Button>
          </Stack>
        </Stack>
      </div>
    )
  }

  return (
    <div>
      {matches ? <>
        <div className="d-flex justify-content-end mb-4 mr-5">
          <Button className="filter" sx={filterSx} onClick={toggleDrawer('top', true)}>
            <svg width="15" height="7" viewBox="0 0 15 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="15" y2="0.5" stroke="black" stroke-opacity="0.7" />
              <line x1="3" y1="3.5" x2="12" y2="3.5" stroke="black" stroke-opacity="0.7" />
              <line x1="5" y1="6.5" x2="10" y2="6.5" stroke="black" stroke-opacity="0.7" />
            </svg>
            Filter
          </Button>
        </div>
        {/* for small devices filter option comes in a drawer */}
        {['top'].map((anchor) => (<>
          <Fragment key={anchor} >

            <Drawer
              anchor={anchor}
              open={state[anchor]}
              PaperProps={{
                sx: {
                  width: "98.5%",
                  margin: "auto",
                  background: "#FFFFFF",
                  zIndex: "1300"
                },
              }}
              onClose={toggleDrawer(anchor, false)}
              sx={
                {
                  marginTop: "160px",
                  paddingTop: "160px",
                  alignItems: "center",
                  height: "100%"
                }
              }
            >
              <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, height: "480px", overflow: "hidden" }}
                role="presentation"
                // onClick={toggleDrawer(anchor, false)}

                onKeyDown={toggleDrawer(anchor, false)}
              >
                {drawerContent()}
                <Filter />
              </Box>
            </Drawer>
          </Fragment>
        </>
        ))}
      </> : <></>}
      {!matches ? drawerContent() : <></>}
    </div>
  )
}

export default Filter