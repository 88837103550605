const languageSheet = [
  //0
  {
    en: "Language",
    es: "Idiomas",
    pr: "Idiomas",
  },
  //1
  {
    en: "Change language",
    es: "Cambiar idioma",
    pr: "Alterar idioma",
  },
  //2
  {
    en: "Join",
    es: "Registrarse",
    pr: "Entrar",
  },
  //3
  {
    en: "Sign In",
    es: "Iniciar sesión",
    pr: "Inscrição",
  },
  //4
  {
    en: "Find the perfect professional for your profession",
    es: "Encuentra el profesional perfecto para tu necesidad.",
    pr: "Encontre o profissional que você está procurando",
  },
  //5
  {
    en: "Book your slot with professional to find you the best solution",
    es: "Reserva tu hora con un profesional y encuentra lo que estás buscando",
    pr: "Agende seu horário com um profissional e encontre o que procura",
  },
  //6
  {
    en: "User",
    es: "Usuario",
    pr: "Usuário",
  },
  //7
  {
    en: "Professional",
    es: "Profesionales",
    pr: "profissional",
  },
  //8
  {
    en: "Open Profile",
    es: "Abrir perfil",
    pr: "Abrir perfil",
  },
  //9
  {
    en: "Help & support",
    es: "Ayuda y soporte",
    pr: "Ajuda e suporte",
  },
  //10
  {
    en: "Logout",
    es: "Cerrar sesión",
    pr: "Sair",
  },
  //11
  {
    en: "Want to share your knowledge ? Be a professional",
    es: "¿Quieres compartir tus conocimientos? Sé un profesional",
    pr: "Você quer compartilhar seu conhecimento? seja um profissional",
  },
  //12
  {
    en: "Professions",
    es: "Profesiones",
    pr: "Profissões",
  },
  //13
  {
    en: "How it works",
    es: "Cómo funciona",
    pr: "Como funciona",
  },
  //14
  {
    en: "Create account",
    es: "Crear cuenta",
    pr: "Registra-se",
  },
  //15
  {
    en: "Delete account",
    es: "Eliminar cuenta",
    pr: "Deletar conta",
  },
  //16
  {
    en: "Terms & Conditions",
    es: "Términos y Condiciones",
    pr: "Termos e Condições",
  },
  //17
  {
    en: "FAQ",
    es: "Preguntas Frecuentes",
    pr: "perguntas Frequentes",
  },
  //18
  {
    en: "Privacy & Policy",
    es: "Política de Privacidad",
    pr: "Política de Privacidade",
  },
  //19
  {
    en: "About Us, Services , Contact us",
    es: "Sobre nosotros, Servicios, Contáctenos",
    pr: "Sobre nós, Serviços, Contacte-nos",
  },
  //20
  {
    en: "Help & Support , Instagram , Facebook",
    es: "Ayuda y Soporte , Instagram , Facebook",
    pr: "Ajuda e Suporte, Instagram, Facebook",
  },
  //21
  {
    en: "What are you looking today ?",
    es: "Qué buscas hoy?",
    pr: "O que você está procurando hoje?",
  },
  //22
  {
    en: "Home",
    es: "Página de Inicio",
    pr: "Página inicial",
  },
  //23
  {
    en: "View More",
    es: "Ver Más",
    pr: "Ver Mais",
  },
  //24
  {
    en: "Forgot password?",
    es: "Has olvidado tu contraseña?",
    pr: "Esqueceu a senha?",
  },
  //25
  {
    en: "Not a member ?",
    es: "Aún no eres miembro ?",
    pr: "Não é membro ainda?",
  },
  //26
  {
    en: "Remember Me , Continue",
    es: "Recuérdame, Continuar",
    pr: "Lembrar meus dados, Continuar",
  },
  //27
  {
    en: "Email, Password",
    es: "Email, Contraseña ",
    pr: "E-mail, Senha",
  },
  //28
  {
    en: "Continue with Google , Facebook",
    es: "Continuar con Google, Facebook",
    pr: "Continuar com o Google, Facebook",
  },
  //29
  {
    en: "By joining I agree to receive emails from Konsarutanto",
    es: "Al unirme acepto recibir correos electrónicos de Konsarutanto",
    pr: "Ao ingressar, concordo em receber e-mails de Konsarutanto",
  },
  //30
  {
    en: "No professionals available in",
    es: "No hay profesionales disponibles de acuero a tu busqueda",
    pr: "Não existem profissionais disponíveis de acordo com a sua pesquisa",
  },
  //31
  {
    en: "HERE’S WHAT YOU NEED FOR YOU",
    es: "ESTO ES LO QUE NECESITAS",
    pr: "ISTO É O QUE VOCÊ PRECISA",
  },
  //32
  {
    en: " Open/Edit Profile , Name , Country , City , Profession ,Languages",
    es: " Abrir/Editar Perfil, Nombre, País, Ciudad, Profesión, Idiomas",
    pr: " Abrir/Editar Perfil, Nome, País, Cidade, Profissão, Idiomas",
  },
  //33
  {
    en: "Add to Favourite , Description, Title",
    es: "Agregar a Favoritos , Descripción, Título",
    pr: "Adicionar aos Favoritos, Descrição, Título",
  },
  //34
  {
    en: "Book your slot , Select available slots , Verification status ",
    es: "Reserva tu hora, Seleccione horas disponibles, Estado de verificación",
    pr: "Reserve seu horário, selecione os horários disponíveis, status de verificação",
  },
  //35
  {
    en: "Schedule , Cancel , In process , Certified",
    es: "Agendar, Cancelar, En proceso, Certificado",
    pr: "Agendar, Cancelar, Em processo, Certificado",
  },
  //36
  {
    en: "Book a slot ? , Confirm",
    es: "Quieres reservar una hora? , Confirmar",
    pr: "Quer reservar uma hora? , Confirme",
  },
  //37
  {
    en: "YOUR APPOINTMENT HAS BEEN BOOKED",
    es: "TU REUNIÓN HA SIDO RESERVADA",
    pr: "SUA REUNIÓN FOI RESERVADA",
  },
  //38
  {
    en: "Notification , Favourites , No favourites",
    es: "Notificación , Favoritos , No favoritos",
    pr: "Notificação, Favoritos, Não favoritos",
  },
  //39
  {
    en: "Post Ad",
    es: "Publicar Anuncio",
    pr: "Postar Anúncio",
  },
  //40
  {
    en: "TELL MORE ABOUT YOURSELF AND ABOUT YOUR PROFESSION. ",
    es: "Cuéntanos más sobre ti y tu profesión.",
    pr: "CONTE MAIS SOBRE VOCÊ E SUA PROFISSÃO",
  },
  //41
  {
    en: "YOU CAN BE A PROFESSIONAL BY JUST CREATIING A AD FOR YOURSELF.",
    es: " Puedes ser un profesional solo creando tu anuncio en pocos pasos.",
    pr: "VOCÊ PODE SER UM PROFISSIONAL APENAS CRIANDO UM ANÚNCIO PARA VOCÊ.",
  },
  //42
  {
    en: "Want to be a professional ?",
    es: "¿Quieres ser un profesional?",
    pr: "Você quer ser um profissional?",
  },
  //43
  {
    en: "Appointments , My Appointments",
    es: "Reuniones, Mis Reuniones",
    pr: "Reuniões, Reuniões",
  },
  //44
  {
    en: "Join now and attend your meeting.",
    es: "Únete ahora y asiste a tu reunión.",
    pr: "Inscreva-se agora e participe da sua reunião.",
  },
  //45
  {
    en: "First Name/Last Name , Email , Country , City , User Active",
    es: "Nombre/Apellido, Correo Electrónico, País, Ciudad, Usuario Activo",
    pr: "Nome/Sobrenome, Email, País, Cidade, Usuário Ativo",
  },
  //46
  {
    en: "Create/New/Confirm Password , Save",
    es: "Crear/Nueva/Confirmar Contraseña, Guardar",
    pr: "Criar/Nova/Confirmar Senha, Salvar",
  },
  //47
  {
    en: "Please enter the 6 digit code sent to",
    es: "Por favor ingrese el código de 6 dígitos enviado a tu correo",
    pr: "Digite o código de 6 dígitos enviado para o seu e-mail",
  },
  //48
  {
    en: "Verify your email",
    es: "Verifique su correo electrónico",
    pr: "Verifique seu e-mail",
  },
  //49
  {
    en: "Resend code",
    es: "Reenviar código",
    pr: "Reenviar código",
  },
  //50
  {
    en: "Back To Sign In",
    es: "Cambiar E-mail",
    pr: "Mude o E-mail",
  },
  //51
  {
    en: "Your account is successfully verified.",
    es: "Su cuenta se verificó con éxito.",
    pr: "Sua conta foi verificada com sucesso.",
  },
  //52
  {
    en: "Your password is successfully created.",
    es: "Su contraseña se ha creado con éxito.",
    pr: "Sua senha foi criada com sucesso.",
  },
  //53
  {
    en: "Complete Profile",
    es: "Perfil Completo",
    pr: "Perfil Completo",
  },
  //54
  {
    en: "Password/Profile Created!",
    es: "Contraseña/Perfil Creado!",
    pr: "Senha/Perfil Criado!",
  },
  //55
  {
    en: "Go to homepage",
    es: "ir a la página de inicio",
    pr: "Vá para a página inicial",
  },
  //56
  {
    en: "Popular Professionals",
    es: "Profesionales Populares",
    pr: "Profissionais Populares",
  },
  //57
  {
    en: "NAME",
    es: "NOMBRE",
    pr: "NOME",
  },
  //58
  {
    en: "COUNTRY",
    es: "PAÍS",
    pr: "PAÍS",
  },
  //59
  {
    en: "CITY",
    es: "CIUDAD",
    pr: "CIDADE",
  },
  //60
  {
    en: "PROFESSION",
    es: "PROFESIÓN",
    pr: "PROFISSÃO",
  },
  //61
  {
    en: "VERIFICATION STATUS:",
    es: "ESTADO DE VERIFICACIÓN",
    pr: "STATUS DE VERIFICAÇÃO",
  },
  //62
  {
    en: "Inprocess",
    es: "En proceso",
    pr: "Em processo",
  },
  //63
  {
    en: "Certified",
    es: "Certificado",
    pr: "Certificado",
  },
  //64
  {
    en: "SCHEDULE",
    es: "CALENDARIO",
    pr: "CALENDARIO",
  },
  //65
  {
    en: "TITLE",
    es: "TÍTULO",
    pr: "TÍTULO",
  },
  //66
  {
    en: "DESCRIPTION:",
    es: "DESCRIPCIÓN",
    pr: "DESCRIÇÃO",
  },
  //67
  {
    en: "SELECT TIME AVAILABLE SLOTS",
    es: "SELECCIONE HORA DISPONIBLE",
    pr: "SELECIONE OS HORÁRIOS DISPONÍVEIS",
  },
  //68
  {
    en: "Book Your Slot:",
    es: "Reserve su hora",
    pr: "Reserve sua hora",
  },
  //69
  {
    en: "WANT TO BOOK YOUR SLOT WITH ME ?",
    es: "¿QUIERES RESERVAR UNA HORA CONMIGO?",
    pr: "QUER RESERVAR UMA HORA COMIGO?",
  },
  //70
  {
    en: "Sign In",
    es: "Registrarse",
    pr: "Inscrição",
  },
  //71
  {
    en: "Join",
    es: "Iniciar sesión",
    pr: "Inscriva-se",
  },
  //72
  {
    en: "Accept",
    es: "Aceptar",
    pr: "Aceitar",
  },
  //73
  {
    en: "Decline",
    es: "Desistir",
    pr: "Desistir",
  },
  //74
  {
    en: "Upload your photo",
    es: "Sube tu foto",
    pr: "Suba sua foto",
  },
  // 75
  {
    en: "Browse",
    es: "Subir foto",
    pr: "Squeaky toy",
  },
  //76
  {
    en: "Birthday",
    es: "Cumpleaños",
    pr: "Aniversário",
  },
  //77
  {
    en: "Pro Profile",
    es: "Perfil profesional",
    pr: "Perfil profissional",
  },
  //78
  {
    en: "Certified",
    es: "Certificado",
    pr: "Certificado",
  },
  //79
  {
    en: "DNI",
    es: "Número de cédula de identidad",
    pr: "Número de identificação",
  },
  //80
  {
    en: "Title",
    es: "Título",
    pr: "Título",
  },
  //81
  {
    en: "Description",
    es: "Descripción",
    pr: "Descrição",
  },
  //82
  {
    en: "Current schedule",
    es: "Programación",
    pr: "Agenda",
  },
  //83
  {
    en: "Join",
    es: "Iniciar sesión",
    pr: "Entrar",
  },
  //84
  {
    en: "Already a member?",
    es: "Ya eres usuario?",
    pr: "Já está cadastrado?",
  },
  //85
  {
    en: "Invalid login credentials",
    es: "Credenciales de acceso invalidos",
    pr: "Credenciais de login inválidas",
  },
  //86
  {
    en: "Gender,Language",
    es: "Género, Idioma",
    pr: "Gênero, Idioma",
  },
  //87
  {
    en: "Select Gender, Select Language, Select Country, Select City",
    es: "Seleccione Género, Seleccione idioma, Seleccione País, Seleccione Ciudad",
    pr: "Selecione o Gênero, Selecione o idioma, Selecione o País, Selecione a Cidade",
  },
  //88
  {
    en: "Edit your photo",
    es: "Edita tu foto",
    pr: "Edite sua foto",
  },
  //89
  {
    en: "Most popular",
    es: "Más populares",
    pr: "Mais populares",
  },
  //90
  {
    en: "Recently Viewed Professionals",
    es: "Profesionales vistos recientemente",
    pr: "Profissionais vistos recentemente",
  },
  //91
  {
    en: "Feature subtitle description",
    es: "Descripción del subtítulo",
    pr: "Descrição",
  },
  //92
  {
    en: "CREATE MY ACCOUNT",
    es: "Crear mi cuenta",
    pr: "Criar a minha conta",
  },
  //93
  {
    en: "Session Expired",
    es: "Sesión expirada",
    pr: "Sessão expirada",
  },
  //94
  {
    en: "User has been logged out please login again",
    es: "El usuario ha sido desconectado, vuelva a iniciar sesión",
    pr: "O usuário foi desconectado, faça o login novamente",
  },
  //95
  {
    en: "Mark as read",
    es: "Marcar como leído",
    pr: "marcar como Lido",
  },
  //96
  {
    en: "View all notifications",
    es: "Ver todas las notificaciones",
    pr: "Ver todas as notificações",
  },
  //97
  {
    en: "Professions subtitle description",
    es: "Descripción del subtítulo de profesiones",
    pr: "Descrição do subtítulo das profissões",
  },
  //98
  {
    en: "Not Available",
    es: "No Disponible",
    pr: "Não Disponível",
  },
  //99
  {
    en: "Please enter your email address",
    es: "Por favor, introduzca su dirección de correo electrónico",
    pr: "Por favor, indique o seu endereço de e-mail",
  },
  //100
  {
    en: "Password cannot be reset for accounts created using Google or Facebook account",
    es: "La contraseña no se puede restablecer para las cuentas creadas con una cuenta de Google o Facebook",
    pr: "A senha não pode ser redefinida para contas criadas usando a conta do Google ou do Facebook",
  },
  //101
  {
    en: "Verified",
    es: "Verificado",
    pr: "Verificado",
  },
  //102
  {
    en: "Card holder name, Select payment method, Credit/Debit Card",
    es: "Nombre del titular de la tarjeta, Seleccione el método de pago, Tarjeta de crédito/débito",
    pr: "Nome do titular do cartão, selecione o método de pagamento, cartão de crédito/débito",
  },
  //103
  {
    en: "Card number, Expiration date, Security code",
    es: "Número de tarjeta, fecha de caducidad, código de seguridad",
    pr: "Número do cartão, Data de validade, Código de segurança",
  },
  //104
  {
    en: "Loading, Pay now, Subscription",
    es: "Cargando, Pagar ahora, Suscripción",
    pr: "Carregando, Pagar ahora, Assinatura",
  },
  //105
  {
    en: "Click “Place order” and we will process you to PayPal page",
    es: 'Haga clic en "Realizar pedido" y lo procesaremos a la página de PayPal',
    pr: 'Clique em "Fazer pedido" e iremos processá-lo para a página do PayPal',
  },
  //106
  {
    en: "Apply Coupon Code",
    es: "Aplicar código de cupón",
    pr: "Aplicar código de cupom",
  },
  //107
  {
    en: "Subtotal, Tax, Coupan discount, Total",
    es: "Subtotal, Impuestos, Cupón de descuento, Total",
    pr: "Subtotal, Imposto, Desconto de cupom, Total",
  },
  //108
  {
    en: "join meeting, Join now to attend your meeting",
    es: "unirse a la reunión, únase ahora para asistir a su reunión",
    pr: "entrar na reunião, Junte-se agora para participar da sua reunião",
  },
  //109
  {
    en: "No slots are available for selected date",
    es: "No hay horarios disponibles para la fecha seleccionada",
    pr: "Não há horarios disponíveis para a data selecionada",
  },
  //110
  {
    en: "To book a date you have to be logged in, so please offer 2 options:",
    es: "Para reservar una fecha, debe iniciar sesión, así que ofrezca 2 opciones:",
    pr: "Para reservar uma data, você deve estar logado, portanto, ofereça 2 opções:",
  },
  //111
  {
    en: "All, Apply",
    es: "Todo, Aplicar",
    pr: "Todos, Aplique",
  },
  //112
  {
    en: "Click “Place order” and we will process you to PayPal page",
    es: "Haz clic en “Pagar ahora” y continuaremos a la página de Paypal",
    pr: 'Clique em "Fazer pedido" e iremos processá-lo para a página do PayPal',
  },
  //113
  {
    en: "YOUR ORDER SUMMARY",
    es: "SU RESUMEN DEL PEDIDO",
    pr: "SEU RESUMO DO PEDIDO",
  },
  //114
  {
    en: "Payment failed, Try another method or, go to help section, back to dashboard",
    es: "El pago falló, pruebe con otro método o ,vaya a la sección de ayuda, vuelva al panel de control",
    pr: "Falha no pagamento, tente outro método ou , vá para a seção de ajuda, volte ao painel",
  },
  //115
  {
    en: "Order Number, Amount paid, Please check your email for order details",
    es: "Número de pedido, monto pagado, consulte su correo electrónico para obtener detalles del pedido",
    pr: "Número do pedido, valor pago, verifique seu e-mail para obter detalhes do pedido",
  },
  //116
  {
    en: "Ad Posted, Your ad is successfully posted",
    es: "Anuncio Publicado, Su anuncio se ha publicado con éxito",
    pr: "Anúncio Postado, Seu anúncio foi postado com sucesso",
  },
  //117
  {
    en: "Pricing plans, Sessions per month, Purchase",
    es: "Precios de planes, Sesiones por mes, Compra",
    pr: "Planos de preços, Sessões por mês, Compra",
  },
  //118
  {
    en: "Free plan has been added successfully",
    es: "El plan gratuito se ha agregado con éxito",
    pr: "O plano gratuito foi adicionado com sucesso",
  },
  //119
  {
    en: "Date, Time, Status",
    es: "Fecha, Hora, Estado",
    pr: "Data, Hora, Estado",
  },
  //120
  {
    en: "No Appointment",
    es: "Sin Cita",
    pr: "Sem Data",
  },
  //121
  {
    en: "CANCEL THE APPOINTMENT?",
    es: "¿CANCELAR LA CITA?",
    pr: "CANCELAR O AGENDAMENTO?",
  },
  //122
  {
    en: "Select Profession",
    es: "Seleccione Profesión",
    pr: "Selecione Profissão",
  },
  //123
  {
    en: "Preview Post",
    es: "Vista previa",
    pr: "Visualização previa",
  },
  //124
  {
    en: "SEARCH RESULTS FOR",
    es: "RESULTADOS DE BÚSQUEDA PARA",
    pr: "RESULTADOS DA BUSCA POR",
  },
  //125
  {
    en: "NO RESULTS FOUND FOR",
    es: "NO SE ENCONTRARON RESULTADOS PARA",
    pr: "NENHUM RESULTADO ENCONTRADO PARA",
  },
  //126
  {
    en: "ARE YOU SURE?",
    es: "¿ESTÁ SEGURO?",
    pr: "ESTÁ SEGURO?",
  },
  //127
  {
    en: "Do you really want to delete this account?",
    es: "¿Realmente quieres eliminar esta cuenta?",
    pr: "Você realmente deseja excluir esta conta?",
  },
  //128
  {
    en: "Delete , Deactivate",
    es: "Eliminar, Desactivar",
    pr: "Apagar, Desativar",
  },
  //129
  {
    en: "YOUR ACCOUNT DELETED SUCCESSFULLY",
    es: "SU CUENTA HA SIDO ELIMINADA CON ÉXITO",
    pr: "SUA CONTA FOI EXCLUÍDA COM SUCESSO",
  },
  //130
  {
    en: "YOUR ACCOUNT DEACTIVETED SUCCESSFULLY",
    es: "SU CUENTA SE DESACTIVÓ CON ÉXITO",
    pr: "SUA CONTA FOI DESATIVADA COM SUCESSO",
  },
  //131
  {
    en: "Male,Female,N/D",
    es: "Masculino,Femenino,N/D",
    pr: "Masculino,Feminino,N/D",
  },
  //132
  {
    en: "English",
    es: "Spanish",
    pr: "Portuguese",
  },
  //133
  {
    en: "Hi",
    es: "Hola",
    pr: "Olá",
  },
  //134
  {
    en: "About",
    es: "Acerca de",
    pr: "Sobre",
  },
  //135
  {
    en: "Services",
    es: "Servicios",
    pr: "Serviços",
  },
  //136
  {
    en: "Contact us",
    es: "CONTÁCTENOS",
    pr: "ENTRE EM CONTATO CONOSCO",
  },
  //137
  {
    en: "Social",
    es: "Social",
    pr: "Social",
  },
  //138
  {
    en: "Enter Code",
    es: "Ingresa código",
    pr: "Ingresse o código",
  },
  //139
  {
    en: "Waiting for professional to start the meet",
    es: "Espera a que el profesional comience la reunión",
    pr: "Aguardando o profissional iniciar o encontro",
  },
  //140
  {
    en: "Save Your Photo",
    es: "Guardar tu foto",
    pr: "Salvar sua foto",
  },
  //141
  {
    en: "Short Description",
    es: "Breve descripción",
    pr: "Breve descrição",
  },
  //142
  {
    en: "The button will be active in",
    es: "El botón estará activo en",
    pr: "O botão estará ativo em",
  },
  //143
  {
    en: "Select City",
    es: "Ciudad selecta",
    pr: "Selecione a cidade",
  },
  //144
  {
    en: "Select Country",
    es: "Seleccionar país",
    pr: "Selecione o pais",
  },
  //145
  {
    en: "Enter title here",
    es: "Introduzca el título aquí",
    pr: "Insira o título aqui",
  },
  //146
  {
    en: "Write a short and nice description about you",
    es: "Escribe una breve y agradable descripción sobre ti.",
    pr: "Escreva uma descrição curta e agradável sobre você",
  },
  //147
  {
    en: "Select Language",
    es: "Seleccione el idioma",
    pr: "Selecione o idioma",
  },
  //148
  {
    en: "Session Left",
    es: "Sesión restante",
    pr: "Sessão restante",
  },
  //149
  {
    en: "Current Plan",
    es: "Plan actual",
    pr: "Plano atual",
  },
  //150
  {
    en: "Upgrade",
    es: "Mejora",
    pr: "Atualizar",
  },
  //151
  {
    en: "Session Tracker",
    es: "Seguimiento de sesiones",
    pr: "Rastreador de Sessão",
  },
  //152
  {
    en: "Click here to upload",
    es: "Haga clic aquí para cargar",
    pr: "Clique aqui para carregar",
  },
  //153
  {
    en: "Max upload file size",
    es: "Tamaño máximo de archivo de carga",
    pr: "Tamanho máximo do arquivo para upload",
  },
  //154
  {
    en: "Professional Degree",
    es: "Titulo profesional",
    pr: "Grau Profissional",
  },
  //155
  {
    en: "Photo Id Proof",
    es: "Prueba de identificación con foto",
    pr: "Prova de identificação com foto",
  },
  //156
  {
    en: "Other documents",
    es: "Otros documentos",
    pr: "POutros documentos",
  },
  //157
  {
    en: "File Upload",
    es: "Subir archivo",
    pr: "Upload de arquivo",
  },
  //158
  {
    en: "File successfully uploaded",
    es: "Archivo cargado exitosamente",
    pr: "Arquivo enviado com sucesso",
  },
  //159
  {
    en: "The uploaded file had an error. Please replace the file and",
    es: "El archivo subido tenía un error. Por favor reemplace el archivo y",
    pr: "O arquivo enviado apresentou um erro. Por favor, substitua o arquivo e",
  },
  //160
  {
    en: "try again",
    es: "intentar otra vez",
    pr: "tente novamente",
  },
  //161
  {
    en: "Please upload a valid photo ID with your name and picture for identity verification.",
    es: "Cargue una identificación con fotografía válida con su nombre y fotografía para verificar su identidad.",
    pr: "Por favor, carregue um documento de identidade válido com foto com seu nome e foto para verificação de identidade.",
  },
  //162
  {
    en: "Combine all relevant documents, including your master's degrees, certificates, etc., into a single PDF file for upload.",
    es: "Combine todos los documentos relevantes, incluidos sus títulos de maestría, certificados, etc., en un único archivo PDF para cargar.",
    pr: "Combine todos os documentos relevantes, incluindo mestrado, certificados, etc., em um único arquivo PDF para upload.",
  },
   //163
   {
    en: "File viewer",
    es: "Visor de archivos",
    pr: "Visualizador de arquivos",
  },
   //164
   {
    en: "Close",
    es: "Cerca",
    pr: "Fechar",
  },
   //165
   {
    en: "View Photo Id",
    es: "Ver identificación con foto",
    pr: "Ver identificação com foto",
  },
    //166
    {
      en: "View Professional Degree",
      es: "Ver Titulación Profesional",
      pr: "Ver Grau Profissional",
    },
];

export default languageSheet;
