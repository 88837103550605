import { useContext } from 'react'
import { components_langsheet } from './Language/ComponentsLangsheet'
import { LanguageContext } from "../context/languageContext";
import ProfessionalCard from './ProfessionalCard/ProfessionalCard';
const backendURL = process.env.REACT_APP_BACKEND_URL

export default function GridOfCards({
  data,
  user,
  isProfession,
  isAllProfessional,
}) {
  const { lang, setLang } = useContext(LanguageContext);

  return (
    <div className="flexContainer d-flex flex-wrap justify-content-center ">
      {data.map((item, ind) => {
        return (
          <div
            className="card  mx-2 my-2 m"
            style={{ width: 'fit-content', border: "none" }}
          >
            {isProfession ? (
              <ProfessionalCard
                id={item.id}
                name={components_langsheet[item.profession][lang]}
                image={item.image}
                user={user}
                isProfession={isProfession}
                description={item?.short_description}
                country={item?.user?.country}
              />
            ) : isAllProfessional ? (
              <ProfessionalCard
                id={item.id}
                name={item.user.first_name}
                image={item.user.profile_image}
                user={user}
                isProfession={isProfession}
                description={item?.short_description}
                country={item?.user?.country}
                profession={components_langsheet[item.profession][lang]} //{item.profession}
              />
            ) : (  //used to render search card results
              <ProfessionalCard
                id={item.id}
                name={item?.fname}
                image={item.profile_image}
                isfav={item.is_favourite}
                user={user}
                isProfession={isProfession}
                description={item?.short_description}
                country={item?.country}
                profession={components_langsheet[item.profession][lang]} //{item.profession}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}
