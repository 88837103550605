import React from "react";

const ForgotPasswordImage = () => {
  return (
    <svg
      width="115"
      height="102"
      viewBox="0 0 115 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M114.546 50.8C114.546 65 108.746 77.8 99.4465 87C90.3465 96.1 77.7465 101.6 63.8465 101.6C50.0465 101.6 37.4465 96 28.2465 87C18.9465 77.8 13.1465 65 13.1465 50.8C13.1465 22.7 35.8465 0 63.8465 0C91.8465 0 114.546 22.8 114.546 50.8Z"
        fill="#EDF1F4"
      />
      <circle cx="64.1465" cy="59" r="20" fill="#0B2435" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.0694 57.1998C68.6554 57.1998 68.3194 56.8638 68.3194 56.4498V54.3028C68.3194 52.2068 66.6144 50.5018 64.5184 50.5018H64.5024C63.4894 50.5018 62.5404 50.8918 61.8234 51.6028C61.1014 52.3168 60.7024 53.2698 60.6984 54.2858V56.4498C60.6984 56.8638 60.3624 57.1998 59.9484 57.1998C59.5344 57.1998 59.1984 56.8638 59.1984 56.4498V54.3028C59.2044 52.8628 59.7614 51.5338 60.7664 50.5378C61.7724 49.5408 63.1004 48.9638 64.5214 49.0018C67.4414 49.0018 69.8194 51.3798 69.8194 54.3028V56.4498C69.8194 56.8638 69.4834 57.1998 69.0694 57.1998Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60.6885 57.1289C59.0105 57.1289 57.6465 58.4929 57.6465 60.1709V64.4599C57.6465 66.1379 59.0105 67.5019 60.6885 67.5019H68.3295C70.0065 67.5019 71.3715 66.1379 71.3715 64.4599V60.1709C71.3715 58.4929 70.0065 57.1289 68.3295 57.1289H60.6885ZM68.3295 69.0019H60.6885C58.1835 69.0019 56.1465 66.9649 56.1465 64.4599V60.1709C56.1465 57.6659 58.1835 55.6289 60.6885 55.6289H68.3295C70.8345 55.6289 72.8715 57.6659 72.8715 60.1709V64.4599C72.8715 66.9649 70.8345 69.0019 68.3295 69.0019Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64.5088 64.1741C64.0948 64.1741 63.7588 63.8381 63.7588 63.4241V61.2031C63.7588 60.7891 64.0948 60.4531 64.5088 60.4531C64.9228 60.4531 65.2588 60.7891 65.2588 61.2031V63.4241C65.2588 63.8381 64.9228 64.1741 64.5088 64.1741Z"
        fill="white"
      />
      <path
        d="M84.6848 34.9544L92.7099 32.2354"
        stroke="#6B8EA6"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M82.8285 29.0307L91.114 19.1406"
        stroke="#6B8EA6"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M78.1216 26.3168L79.1882 19"
        stroke="#6B8EA6"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <line
        x1="32.2852"
        y1="46.6934"
        x2="30.8399"
        y2="48.8613"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="29.1664"
        y1="51.5473"
        x2="28.0261"
        y2="53.8901"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="27.1577"
        y1="56.5764"
        x2="26.7229"
        y2="59.1454"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="26.6545"
        y1="61.5013"
        x2="26.6478"
        y2="64.1068"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="26.7578"
        y1="67.3554"
        x2="27.4245"
        y2="69.8742"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="28.3999"
        y1="72.364"
        x2="29.6854"
        y2="74.6304"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="30.8479"
        y1="77.0897"
        x2="32.4419"
        y2="79.1508"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="34.4711"
        y1="81.2692"
        x2="36.6025"
        y2="82.7677"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="38.8325"
        y1="84.3283"
        x2="41.0672"
        y2="85.668"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="43.7037"
        y1="86.5646"
        x2="46.2899"
        y2="86.882"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="49.4268"
        y1="86.6828"
        x2="51.8485"
        y2="85.7215"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="54.036"
        y1="84.2196"
        x2="55.5677"
        y2="82.1118"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="55.8418"
        y1="79.1572"
        x2="55.5298"
        y2="76.5697"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="53.5994"
        y1="73.9964"
        x2="51.2973"
        y2="72.7747"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="48.0135"
        y1="73.6928"
        x2="45.8396"
        y2="75.1303"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="45.2099"
        y1="77.4273"
        x2="45.5646"
        y2="80.0092"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="47.0696"
        y1="82.824"
        x2="48.7761"
        y2="84.7938"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="50.85"
        y1="86.9278"
        x2="52.8714"
        y2="88.5728"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="54.6403"
        y1="90.0738"
        x2="56.9285"
        y2="91.3214"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <line
        x1="59.8249"
        y1="92.8004"
        x2="62.1131"
        y2="94.0479"
        stroke="#A3C7D3"
        stroke-linecap="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.3642 38.4291C33.776 38.9649 33.4247 39.7124 33.3876 40.5072C33.3505 41.3019 33.6307 42.0789 34.1664 42.6671L35.5132 44.1457C36.0489 44.734 36.7964 45.0853 37.5912 45.1224C38.386 45.1594 39.163 44.8793 39.7512 44.3435C40.3394 43.8078 40.6907 43.0603 40.7278 42.2655C40.7649 41.4707 40.4848 40.6937 39.949 40.1055L44.3848 36.0653L45.7315 37.5439L47.2102 36.1971L45.8634 34.7185L46.6027 34.0451L47.9495 35.5238L49.4281 34.177L46.7346 31.2198L38.6022 38.6269C38.0665 38.0387 37.319 37.6874 36.5242 37.6503C35.7294 37.6132 34.9524 37.8933 34.3642 38.4291ZM38.4704 41.4522L37.1236 39.9736C36.945 39.7776 36.6959 39.6605 36.4309 39.6481C36.166 39.6357 35.907 39.7291 35.711 39.9077C35.5149 40.0863 35.3978 40.3355 35.3854 40.6004C35.3731 40.8653 35.4664 41.1243 35.645 41.3204L36.9918 42.799C37.1704 42.9951 37.4195 43.1122 37.6845 43.1245C37.9494 43.1369 38.2084 43.0435 38.4044 42.8649C38.6005 42.6863 38.7176 42.4372 38.73 42.1722C38.7424 41.9073 38.649 41.6483 38.4704 41.4522Z"
        fill="#123A55"
      />
      <circle cx="64.1465" cy="32" r="7" fill="#A3C7D3" />
      <circle cx="64.1465" cy="32" r="8.5" stroke="#A3C7D3" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65.7527 34.2062C65.5991 34.2062 65.4455 34.1478 65.3287 34.0302L62.3223 31.0238C62.0879 30.7894 62.0879 30.4102 62.3223 30.1758C62.5567 29.9414 62.9359 29.9414 63.1703 30.1758L66.1767 33.1822C66.4111 33.4166 66.4111 33.7958 66.1767 34.0302C66.0599 34.1478 65.9063 34.2062 65.7527 34.2062Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.7465 34.2062C62.5929 34.2062 62.4393 34.1478 62.3225 34.0302C62.0881 33.7958 62.0881 33.4166 62.3225 33.1822L65.3289 30.1758C65.5633 29.9414 65.9425 29.9414 66.1769 30.1758C66.4113 30.4102 66.4113 30.7894 66.1769 31.0238L63.1705 34.0302C63.0537 34.1478 62.9001 34.2062 62.7465 34.2062Z"
        fill="white"
      />
      <path
        d="M110.92 17.6993C111.649 17.6993 112.24 17.1082 112.24 16.379C112.24 15.6497 111.649 15.0586 110.92 15.0586C110.191 15.0586 109.6 15.6497 109.6 16.379C109.6 17.1082 110.191 17.6993 110.92 17.6993Z"
        fill="#A3C7D3"
      />
      <path
        d="M110.65 99.702C112.334 99.702 113.7 98.3363 113.7 96.6518C113.7 94.9672 112.334 93.6016 110.65 93.6016C108.965 93.6016 107.6 94.9672 107.6 96.6518C107.6 98.3363 108.965 99.702 110.65 99.702Z"
        fill="#A3C7D3"
      />
      <path
        d="M110.7 7.70156C112.247 7.70156 113.5 6.44796 113.5 4.90156C113.5 3.35517 112.247 2.10156 110.7 2.10156C109.154 2.10156 107.9 3.35517 107.9 4.90156C107.9 6.44796 109.154 7.70156 110.7 7.70156Z"
        fill="#A3C7D3"
      />
      <path
        d="M18.3 17.6C19.8464 17.6 21.1 16.3464 21.1 14.8C21.1 13.2536 19.8464 12 18.3 12C16.7536 12 15.5 13.2536 15.5 14.8C15.5 16.3464 16.7536 17.6 18.3 17.6Z"
        fill="#A3C7D3"
      />
      <path
        d="M5.2 71.5992C8.07188 71.5992 10.4 69.2711 10.4 66.3992C10.4 63.5273 8.07188 61.1992 5.2 61.1992C2.32812 61.1992 0 63.5273 0 66.3992C0 69.2711 2.32812 71.5992 5.2 71.5992Z"
        fill="#A3C7D3"
      />
      <path
        d="M17.5498 84.2988C18.4057 84.2988 19.0996 83.605 19.0996 82.749C19.0996 81.8931 18.4057 81.1992 17.5498 81.1992C16.6939 81.1992 16 81.8931 16 82.749C16 83.605 16.6939 84.2988 17.5498 84.2988Z"
        fill="#A3C7D3"
      />
    </svg>
  );
};

export default ForgotPasswordImage;
