import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { bgColor, textBlue, textSecondary } from "../../env";
import { useToaster } from "rsuite";
import { useContext } from "react";
import { UserContext } from "../../context/userContext";
import MessageUserExpired from "../../Components/MessageUserExpired";
import { LanguageContext } from '../../context/languageContext'
import languageSheet from '../../language'
import ReactGA from "react-ga4";

const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function PaymentSuccess({ user, subPlan, profile, setPaymentCompleted, paymentCompleted }) {
  const navigate = useNavigate();
  const toaster = useToaster();
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext)
  const [data, setData] = useState({})
  const { lang, setLang } = useContext(LanguageContext)
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let myHeaders = new Headers()
    myHeaders.append("Authorization", `Bearer ${user?.tokens.access}`)
    myHeaders.append("Content-Type", "application/json");

    fetch(apiHost + 'api/payment-done', {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        id: searchParams.get('subscription_id')
      })
    })
      .then(res => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        }
        else {
          if (res.status == 200) {
            sessionStorage.setItem("professional", true)
            setPaymentCompleted(!paymentCompleted)
          }
          return res.json()
        }
      })
      .then(res => {
        setData(res)
        ReactGA.event("purchase", {
          transaction_id: `${res?.order_id}`,
          value: `${res?.amount}`,
          currency: "USD",
          professionalId: `${profile?.id}`,
          plan: `${subPlan?.name}`,
          items: [{
            item_id: `${subPlan?.id}`,
            item_name: `${subPlan?.name}`,
          }]
        });
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      })
  }, [])

  return (
    <div style={{ backgroundColor: bgColor }}>
      <div className="container my-5 d-flex flex-column">
        <div
          className="paymentAfter row boxShadow d-flex align-content-center mx-3 rounded"
          style={{ minHeight: "40rem" }}
        >
          <div className="text-center">
            {/* <img src="./assets/paymentSuccess.svg" alt="payment success" /> */}
            <div
              className="mt-3"
              style={{ color: textBlue, fontWeight: "500" }}
            >
              <div>
                {/* Order Number:  */}
                {languageSheet[115][lang].split(',')[0]}: {data?.order_id}</div>
              <div>
                {/* Amount Paid: */}
                {languageSheet[115][lang].split(',')[1]}: ${data?.amount}</div>
            </div>
            <div style={{ color: textSecondary }}>
              {/* Please check your email for order details. */}
              {languageSheet[115][lang].split(',')[2]}:
            </div>
            <button
              className="primaryButton py-3 mt-3 "
              onClick={() => { navigate("/proLandingPage") }}
            >
              {/* BACK TO DASHBOARD */}
              {languageSheet[114][lang].split(',')[3]}:
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
