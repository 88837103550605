import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

export default function Loading() {
  return (
    <div>Loading...</div>
  )
}

export function Cards() {
  return (
    <Box sx={{ width: '100%', height: 367 }}>
    </Box>
  );
}

export function Footers() {

  return (
    <Box sx={{ width: '100%', height: 321 }}>
      <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
    </Box>
  );
}

export function HowItWork() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return (
    <Box className='container py-5' sx={{ width: screenWidth, height: 367 }}>
      <Typography width="40%" variant="h3"><Skeleton animation="wave" /></Typography>
      <div className="row">
        <div className='col-md-6'>
          <Skeleton width="80%" />
          <Skeleton width="80%" />
          <Skeleton width="40%" />
          <Skeleton width="20%" animation="wave" />
        </div>
        <div className='col-md-6'>
          <Skeleton variant="rectangular" width={432} height={218} />
        </div>
      </div>
    </Box>
  );
}

export function UserLandingPage() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return (
    <Box className='d-flex flex-column justify-content-between pb-5' sx={{ width: screenWidth, height: 550 }}>
      <Skeleton variant="rectangular" width={screenWidth} height={200} />
      <div className='col-md-6'>
      </div>
      <div className='container d-flex gap-5' sx={{ width: '75%', height: 365 }}>
        <Skeleton width="50%" animation="wave" />
        {Array.from(new Array(4)).map((item) => {
          return (
            <Box sx={{ width: 300, height: 214 }}>
              <Skeleton variant="rectangular" width={300} height={184} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton width={300} height={30} />
              </Box>
            </Box>
          )
        })}
      </div>
    </Box>
  );
}

export function ProLandingPage() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return (
    <Box className='d-flex flex-column justify-content-between pb-5' sx={{ width: screenWidth, height: 550 }}>
      <Skeleton variant="rectangular" width={screenWidth} height={200} />
      <div className='col-md-6'>
      </div>
      <div className='container d-flex gap-5' sx={{ width: '75%', height: 365 }}>
        <Skeleton width="50%" animation="wave" />
        {Array.from(new Array(4)).map((item) => {
          return (
            <Box className='d-flex gap-4' sx={{ width: 413, height: 222 }}>
              <Skeleton variant="rectangular" width={135} height={135} />
              <Box className='d-flex flex-column gap-3' sx={{ pt: 0.5 }}>
                <div>
                  <Skeleton width={154} height={30} />
                  <Typography width="40%" variant="h6"><Skeleton animation="wave" /></Typography>
                </div>
                <div>
                  <Typography width="40%" variant="body"><Skeleton animation="wave" /></Typography>
                  <Typography width="40%" variant="body"><Skeleton animation="wave" /></Typography>
                  <Typography width="40%" variant="body"><Skeleton animation="wave" /></Typography>
                  <Typography width="40%" variant="body"><Skeleton animation="wave" /></Typography>
                </div>
              </Box>
            </Box>
          )
        })}
      </div>
    </Box>
  );
}

export function FilterSkeleton() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return (
    <Box className='container my-5' sx={{ height: 40 }}>
      <Skeleton variant="rectangular" height={40} />
    </Box>
  );
}