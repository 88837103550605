import { useContext, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom'
import Footer from "../../Components/Footer";
import { bgColor, primeBlue, textBlue } from "../../env";
import { FAQLang } from "./FAQLangSheet"
import { LanguageContext } from '../../context/languageContext'

const ariaLabel = { "aria-label": "description" };

const steps = ["Inprocess", "Certified"];
export default function FAQ() {
  const { lang, setLang } = useContext(LanguageContext)
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.slice(1); // Remove the "#" from the hash
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);


  return (
    <div style={{ backgroundColor: bgColor }}>
      <div className="container my-5">
        <h4 id="FAQ" className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {/* FAQ */}
          {FAQLang.Faq[lang]}
        </h4>

        <div className="row ">
          <div className="col-md-12">
            <p className="subtitleBold mt-5 mb-4">

              <div id="whatIsKonsarutanto" dangerouslySetInnerHTML={{ __html: FAQLang.WhatIsKonsarutanto[lang] }}>
              </div>

            </p>
            <div className="row">
              <div className="col-md-12">
                <p
                  className="textSecondary"
                  style={{ color: textBlue, textAlign: "justify" }}
                >

                  <div dangerouslySetInnerHTML={{ __html: FAQLang.AnsForWhatIsKonsarutanto[lang] }}></div>
                  <Link to="/tc" >

                  </Link>

                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <p className="subtitleBold mt-5 mb-4">
              <div id='whySignMeUp' dangerouslySetInnerHTML={{ __html: FAQLang.WhySignMeUp[lang] }}></div>

            </p>
            <div className="row">
              <div className="col-md-12">
                <p
                  className="textSecondary"
                  style={{ color: textBlue, textAlign: "justify" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: FAQLang.AnsForWhySignMeUp[lang] }}></div>

                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <p className="subtitleBold mt-5 mb-4">
              <div dangerouslySetInnerHTML={{ __html: FAQLang.WhatIsCertificationProcessIsLike[lang] }}></div>

            </p>
            <div className="row">
              <div className="col-md-12">
                <p
                  className="textSecondary"
                  style={{ color: textBlue, textAlign: "justify" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: FAQLang.AnsForWhatIsCertificationProcessIsLike[lang] }}></div>

                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <p className="subtitleBold mt-5 mb-4">
              <div dangerouslySetInnerHTML={{ __html: FAQLang.HowToScheduleMeeting[lang] }}></div>

            </p>
            <div className="row">
              <div className="col-md-12">
                <p
                  className="textSecondary"
                  style={{ color: textBlue, textAlign: "justify" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: FAQLang.AnsForHowToScheduleMeeting[lang] }}></div>

                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12" id="whatAreAvailablePlans">
            <p className="subtitleBold mt-5 mb-4">
              <div dangerouslySetInnerHTML={{ __html: FAQLang.WhatAreAvailablePlans[lang] }}></div>

            </p>
            <div className="row">
              <div className="col-md-12">
                <p
                  className="textSecondary"
                  style={{ color: textBlue, textAlign: "justify" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: FAQLang.AnsForWhatAreAvailablePlans[lang] }}></div>
                </p>
              </div>
            </div>
          </div>



        </div>


      </div>
      <Footer />
    </div>
  );
}
