const primeYellow = "#EAB60F";
const primeBlue = "#0B2435";
const textBlue = "#123A55";
const textSecondary = "#6B8EA6";
const bgColor = "#FCFCFC";
const whiteBg = "#EDF1F4";
export const grey = "#979797";

// const apiHost = 'https://konsarutanto-backend.herokuapp.com/'
// const apiHost = 'https://127.0.0.1:8000/'
const meetingUrl = "https://meet.konsarutanto.com";
const apiHost = "https://api.konsarutanto.com/";

export {
  primeYellow,
  primeBlue,
  textBlue,
  textSecondary,
  bgColor,
  whiteBg,
  apiHost,
  meetingUrl,
};
