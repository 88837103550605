import { useContext } from "react";
import Footer from "../../../Components/Footer";
import { primeBlue, textBlue} from "../../../env";
import { HowItWorksLang } from "../HowItWorksLangSheet";
import { LanguageContext } from "../../../context/languageContext";
import './HowItWorks.css'

const ariaLabel = { "aria-label": "description" };

const steps = ["Inprocess", "Certified"];
export default function HowItWorks() {
  const { lang, setLang } = useContext(LanguageContext);
  return (
    <div className="how-it-works-body">
      <div className="container my-5">
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {/* PRIVACY POLICY */}
          {HowItWorksLang.howItWorks[lang]}
        </h4>

        <div className="row ">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <p
                  className="textSecondary"
                  style={{ color: textBlue, textAlign: "justify" }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        HowItWorksLang.khonsuIsPlatformThatAllowYouTo[lang],
                    }}
                  ></div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
