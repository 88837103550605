import axios from "axios";

export const localUser = JSON.parse(localStorage.getItem("user"));
export const sessionUser = JSON.parse(sessionStorage.getItem("user"));
export const fetchCountryDataFromTaxApi = async (url, access_token) => {
  var config = {
    method: "get",
    url: url,
  };
  var result = [];
  await axios(config)
    .then(function (res) {
      if (res && res?.data && res?.data.length > 0) {
        result = res?.data;
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  return result;
};
export const fetchUserProfileData = async (url, access_token) => {
  var config = {
    method: "get",
    url: url,
    headers: { Authorization: `Bearer ${access_token}` },
  };
  var result = [];
  await axios(config)
    .then(function (res) {
      if (res.status == 401) {
        const error = new Error("unauth");
        error.code = 401;
        throw error;
      } else {
        result = res;
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  return result;
};

export const isUserLoggedIn = () => {
  const localUser = JSON.parse(localStorage.getItem("user"));
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));

  return !!localUser || !!sessionUser;
};
