import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
//common
import LangingPage from "./Pages/LandingPage/LangingPage";

//user pages
import CreateProfile from "./Pages/User/CreateProfile";
import Join from "./Pages/Join/Join";
import UserProfile from "./Pages/User/UserProfile";
import EditUserProfile from "./Pages/User/EditUserProfile";
import SignIn from "./Pages/SignIn/SignIn";
import ProfessionalProfilePage from "./Pages/User/ProfessionalProfilePage";
import AppointmentsUser from "./Pages/User/AppointmentsUser";

//professional pages
import EditProfessionalProfilePage from "./Pages/Professional/EditProfessionalProfilePage";
import AppointmentsPro from "./Pages/Professional/AppointmentsPro";

//statics and commons
import Professionals from "./Pages/Professionals";
import RecentProfessionals from "./Pages/RecentProfessionals";
import PopularProfessionals from "./Pages/PopularProfessionals";
import TermsAndContitions from "./Pages/Statics/T&C";
import PrivacyPolicy from "./Pages/Statics/PrivacyPolicy";
import HowItWorks from "./Pages/Statics/HowItWorks/HowItWorks";
import FAQ from "./Pages/Statics/FAQ";
import Notifications from "./Pages/Notifications";
import CreateAndEditAds from "./Pages/PostAds/CreateAndEditAds/CreateAndEditAds";
import PostAdsPreview from "./Pages/PostAds/PostAdsPreview";
import SubscriptionDetails from "./Pages/PostAds/SubscriptionDetails";
import LangingPageUserFirstTime from "./Pages/LandingPage/LandingPageUserFirstTime/LangingPageUserFirstTime";
import LangingPageUser from "./Pages/LandingPage/LandingPageUser/LangingPageUser";
import LangingPageProfessional from "./Pages/LandingPage/LandingPageProfessional/LangingPageProfessional";
import Professions from "./Pages/Professions";
import SearchResult from "./Pages/SearchResult";
import PaymentCard from "./Pages/PostAds/PaymentCard";
import PaymentSuccess from "./Pages/PostAds/PaymentSuccess";
import PaymentFailed from "./Pages/PostAds/PaymentFailed";
import Testimonials from "./Pages/Statics/Testimonials";
import PasswordForgot from "./Pages/Password/PasswordForgot";
import PasswordNew from "./Pages/Password/PasswordNew";
import { useEffect, useState, useRef, createContext, useContext } from "react";
import VerifyEmail from "./Pages/Password/VerifyEmail";
// import MeetingSDK from "./Pages/VideoCall/Meeting";
import NavbarLogged from "./Components/NavbarLogged";
import Payment from "./Pages/Payment";
import ProProfile from "./Pages/Professional/ProProfile";
import { useToaster } from "rsuite";
import MessageUserExpired from "./Components/MessageUserExpired";
import { UserContext } from "./context/userContext";
import { LanguageContext } from "./context/languageContext";
import { NavbarContext } from "./context/navbarContext";
import Favourite from "./Pages/Favourite";
import ReactGA from 'react-ga4';

const backendURL = process.env.REACT_APP_BACKEND_URL;
const locationApiKey = process.env.React_APP_GEO_LOCATION_API_KEY;
const TRACKING_ID = process.env.React_APP_GOOGLE_ANALYTICS;
ReactGA.initialize(TRACKING_ID);

const useAnalyticsEventTracker = (category = "Blog category") => {
  const eventTracker = (action = "test action", label = "test label") => {
    ReactGA.event({ category, action, label });
  }
  return eventTracker;
}

const FileContext = createContext();

export const useFiles = () => {
    return useContext(FileContext);
}
function App() {
  const [base64Files, setBase64Files] = useState({
    professionalDegree: null,
    photoIdProof: null,
    otherDocuments: [],
});

  const [currentMeetingData, setCurrentMeetingData] = useState(null);
  let sessionInfo = JSON.parse(sessionStorage.getItem("otherInfo"));

  const [joinEmail, setJoinEmail] = useState(
    sessionInfo?.joinEmail ? sessionInfo.joinEmail : null
  );
  const [fp, setFP] = useState(false); //for forget Pass
  const [previewAd, setPreviewAd] = useState(
    sessionInfo?.previewAd ? sessionInfo.previewAd : null
  ); // for previewAdd
  const [subPlan, setSubPlan] = useState(
    sessionInfo?.subPlan ? sessionInfo.subPlan : null
  ); // for subscription id
  const [proNew, setProNew] = useState(
    sessionInfo?.proNew ? sessionInfo.proNew : null
  ); // for newly professional created

  const [ipCountry, setIpCountry] = useState()

  const [lang, setLang] = useState(localStorage.getItem("lang") ? localStorage.getItem("lang") : ipCountry == "Portugal" || ipCountry == "portugal" ? "pr" : ipCountry == "india" ? 'en' : 'es');
  //for language change and hold
  const [searchData, setSearchedData] = useState({
    value: "",
    redirectUrl: "",
  });

  //userLogic
  const localUser = JSON.parse(localStorage.getItem("user"));
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));
  const [user, setUser] = useState(
    localUser ? localUser : sessionUser ? sessionUser : null
  );

  const [notifications, setNotifications] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const [professional, setProfessional] = useState(
    sessionStorage.getItem("professional")
      ? sessionStorage.getItem("professional")
      : localStorage.getItem("professional") ? localStorage.getItem("professional") : false
  );
  const [proSchedule, setProSchedule] = useState({});
  const [profile, setProfile] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [capitalizedCountryName, setCapitalizedCountryName] = useState([]);
  const [changeCountry, setChangeCountry] = useState(false);
  const isMount = useRef(false);
  const latinAmericanCountries = ["argentina", "bolivia", "brazil", "chile", "colombia", "costa rica", "cuba", "dominican republic", "ecuador", "el salvador", "guatemala", "haiti", "honduras", "jamaica", "mexico", "nicaragua", "panama", "paraguay", "peru", "puerto rico", "uruguay", "venezuela"];

  const toaster = useToaster();
  const navigation = useNavigate();
  useEffect(() => {
    if (localUser) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    sessionStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  }, []);

  const getUserProfile = () => {
    fetch(backendURL + "auth/user-profile", {
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setProfile(data)
        
        if (data.user?.is_professional) {
          localStorage.setItem("professional", true)
        }
        else {
          localStorage.removeItem("professional")
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          setUser(null);
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional")
          localStorage.removeItem("professional")
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
        }
      });
  };

  const getAllCountries = () => {
    //To get all the countries
    fetch(backendURL + "api/tax")
      .then((res) => res.json())
      .then((res) => {
        setCapitalizedCountryName(
          res.map((country) => ({
            ...country,
            country:
              country.country.charAt(0).toUpperCase() +
              country.country.slice(1),
          }))
        );
      });
  };

  useEffect(() => {
    if (user) {
      fetch(backendURL + "auth/notifications", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.tokens.access,
        },
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((data) => {
          setNotifications([...data]);
        })
        .catch((err) => {
          console.log({ err });
          if (err.code == 401) {
            setUser(null);
            sessionStorage.removeItem("otherInfo");
            localStorage.removeItem("professional")
            sessionStorage.removeItem("professional")
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          }
        });
      fetch(backendURL + "api/favourite", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.tokens.access,
        },
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((data) => {
          data ? setFavourites([...data.results]) : setFavourites([]);
        })
        .catch((err) => {
          if (err.code == 401) {
            setUser(null);
            sessionStorage.removeItem("otherInfo");
            localStorage.removeItem("professional")
            sessionStorage.removeItem("professional")
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          }
        });

      getUserProfile();
    } else {
      setNotifications([]);
      setFavourites([]);
      setProfile(null);
    }
  }, [user]);

  useEffect(() => {
    if (isMount.current == true) {
      getUserProfile();
    } else {
      isMount.current = true;
    }
  }, [paymentCompleted, changeCountry]);

  useEffect(() => {
    sessionStorage.setItem(
      "otherInfo",
      JSON.stringify({ joinEmail, fp, previewAd, subPlan, proNew })
    );
  }, [joinEmail, fp, previewAd, subPlan, proNew]);

  // when user closes tab being as a professional , we should remove 'professional = true' from localstorage
  useEffect(() => {
    getAllCountries();
    window.addEventListener("beforeunload", clearIsProfeesional);
    fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${locationApiKey}`)
      .then(response => response.json())
      .then(data => {
        setIpCountry(data?.country_name)
        if (!localStorage.getItem("lang")) {
          if (data?.country_name == "Portugal" || data?.country_name == "portugal" || data?.country_name.toLowerCase() == "brazil") {
            localStorage.setItem("lang", "pr");
            setLang("pr")
          } else if (data?.country_name == "Spain" || data?.country_name.toLowerCase() == "mexico" || latinAmericanCountries?.includes(data?.country_name?.toLowerCase())) {
            localStorage.setItem("lang", "es");
            setLang("es")
          } else {
            localStorage.setItem("lang", "en");
            setLang("en")
          }
        }
      })
      .catch(error => console.log(error));

    return () => {
      window.removeEventListener("beforeunload", clearIsProfeesional);
    };
  }, []);

  const clearIsProfeesional = () => {
    localStorage.removeItem("professional")
    sessionStorage.removeItem("professional")
  };

  const setUserAsProfessional = (val) => {
    if (val !== true) {
      sessionStorage.removeItem("professional")
      localStorage.removeItem("professional")
    } else {
      setProfessional(val);
      localStorage.setItem("professional", val)
      sessionStorage.setItem("professional", val)
    }
  };
  const handleSearchOption = (val, url) => {
    setSearchedData({ value: val, redirectUrl: url });
    if (val) {
      navigation(url);
    }
  };

  return (
    <NavbarContext.Provider
      value={{ favourites, setFavourites, curUser: user, setUser }}
    >
       <FileContext.Provider value={{ base64Files, setBase64Files }}>
      <LanguageContext.Provider value={{ lang, setLang }}>
        <UserContext.Provider value={{ setUser }}>
          <div className="App" style={{ minHeight: "100vh" }}>
            <NavbarLogged
              profile={profile}
              user={user}
              setUser={setUser}
              notifications={notifications}
              setNotifications={setNotifications}
              country={capitalizedCountryName}
              professional={professional}
              setProfessional={setUserAsProfessional}
              handleSearch={(value, url) => handleSearchOption(value, url)}
            />

            <ScrollToTop>
              <Routes>
                {/* users */}
                <Route
                  path="/"
                  element={
                    user ? (
                      sessionStorage.getItem("professional") ? (
                        <LangingPageProfessional user={user} />
                      ) : (
                        <LangingPageUser profile={profile} user={user} />
                      )
                    ) : (
                      <LangingPage user={user} />
                    )
                  }
                />
                <Route
                  path="/createProfile"
                  element={
                    <CreateProfile
                      joinEmail={joinEmail}
                      user={user}
                      setUser={setUser}
                    />
                  }
                />

                <Route
                  path="/join"
                  element={
                    <Join setJoinEmail={setJoinEmail} setUser={setUser} />
                  }
                />
                <Route
                  path="/userProfile"
                  element={
                    <UserProfile
                      profile={profile}
                      user={user}
                      setUser={setUser}
                    />
                  }
                />
                <Route
                  path="/editUserProfile"
                  element={
                    <EditUserProfile
                      user={user}
                      setUser={setUser}
                      setChangeCountry={setChangeCountry}
                      changeCountry={changeCountry}
                    />
                  }
                />
                <Route
                  path="/signIn"
                  element={
                    <SignIn setUser={setUser} setJoinEmail={setJoinEmail} />
                  }
                />

                <Route path="/ppp">
                  <Route
                    path=":id"
                    element={
                      <ProfessionalProfilePage
                        user={user}
                        setUser={setUser}
                        setJoinEmail={setJoinEmail}
                      />
                    }
                  />
                </Route>

                <Route
                  path="/userFirstLandingPage"
                  element={<LangingPageUserFirstTime user={user} />}
                />
                <Route
                  path="/userLandingPage"
                  element={
                    <LangingPageUser
                      profile={profile}
                      user={user}
                      setFP={setFP}
                    />
                  }
                />

                {/* professional */}
                <Route
                  path="/proProfile"
                  element={
                    <ProProfile
                      profile={profile}
                      user={user}
                      setUser={setUser}
                      setPreviewAd={setPreviewAd}
                    />
                  }
                />
                <Route
                  path="/appointments"
                  element={
                    <AppointmentsUser
                      user={user}
                      setCurrentMeetingData={setCurrentMeetingData}
                    />
                  }
                />
                <Route
                  path="/favourites"
                  element={<Favourite user={user} favourite={favourites} />}
                />
                <Route
                  path="/editProfessionalProfile"
                  element={
                    <EditProfessionalProfilePage
                      user={user}
                      previewAd={previewAd}
                      setPreviewAd={setPreviewAd}
                      setChangeCountry={setChangeCountry}
                      changeCountry={changeCountry}
                    />
                  }
                />
                <Route
                  path="/appointmentsPro"
                  element={<AppointmentsPro user={user} />}
                />
                <Route
                  path="/proLandingPage"
                  element={<LangingPageProfessional user={user} />}
                />

                {/* search res and viewAll pages */}
                <Route
                  path="/professionals"
                  element={<Professionals user={user} />}
                />
                <Route
                  path="/recentprofessionals"
                  element={<RecentProfessionals user={user} />}
                />
                <Route
                  path="/popularprofessionals"
                  element={<PopularProfessionals user={user} />}
                />
                <Route
                  path="/professionals/:id"
                  element={<Professionals user={user} />}
                />
                <Route path="/professions/" element={<Professions />} />
                <Route
                  path="/search/:string"
                  element={<SearchResult user={user} searchData={searchData} />}
                />

                {/* aditional Static */}
                <Route path="/tc" element={<TermsAndContitions />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/howitworks" element={<HowItWorks />} />
                <Route path="/testimonials" element={<Testimonials />} />

                {/* common pages  ads, payments*/}
                <Route
                  path="/notifications"
                  element={
                    <Notifications
                      user={user}
                      notifications={notifications}
                      setNotifications={setNotifications}
                    />
                  }
                />
                
                <Route
                  path="/createEditAds"
                  element={
                    <CreateAndEditAds
                      user={user}
                      previewAd={previewAd}
                      setPreviewAd={setPreviewAd}
                    />
                  }
                />
                <Route
                  path="/previewAds"
                  element={
                    <PostAdsPreview
                      previewAd={previewAd}
                      user={user}
                      setProNew={setProNew}
                    />
                  }
                />
                <Route
                  path="/subsDetails"
                  element={
                    <SubscriptionDetails
                      proNew={proNew}
                      profile={profile}
                      setSubPlan={setSubPlan}
                      user={user}
                      paymentCompleted={paymentCompleted}
                      setPaymentCompleted={setPaymentCompleted}
                    />
                  }
                />
                <Route
                  path="/paymentPage"
                  element={
                    <PaymentCard
                      user={user}
                      proNew={proNew}
                      subPlan={subPlan}
                      profile={profile}
                      paymentCompleted={paymentCompleted}
                      setPaymentCompleted={setPaymentCompleted}
                    />
                  }
                />
                <Route
                  path="/paymentSuccess"
                  element={
                    <PaymentSuccess
                      user={user}
                      subPlan={subPlan}
                      profile={profile}
                      paymentCompleted={paymentCompleted}
                      setPaymentCompleted={setPaymentCompleted}
                    />
                  }
                />
                <Route
                  path="/paymentFail"
                  element={<PaymentFailed user={user} />}
                />

                <Route
                  path="/forgotPassword"
                  element={
                    <PasswordForgot setJoinEmail={setJoinEmail} setFP={setFP} />
                  }
                />
                <Route
                  path="/verifyEmail"
                  element={<VerifyEmail joinEmail={joinEmail} />}
                />
                <Route
                  path="/passwordNew"
                  element={
                    <PasswordNew
                      joinEmail={joinEmail}
                      setUser={setUser}
                      setFP={setFP}
                      fp={fp}
                    />
                  }
                />

                <Route path="/payment" element={<Payment user={user} />} />
              </Routes>
            </ScrollToTop>
          </div>
        </UserContext.Provider>
      </LanguageContext.Provider>
      </FileContext.Provider>
    </NavbarContext.Provider>
  );
}

export default App;
