import { useState, useEffect } from "react";
import { primeBlue } from "../../env";
import { Link, useNavigate, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useGoogleLogin } from "@react-oauth/google";
import { Message, useToaster } from 'rsuite';
import { useContext } from "react";
import MessageUserExpired from "../../Components/MessageUserExpired";
import languageSheet from "../../language";
import { LanguageContext } from "../../context/languageContext";
import './SignIn.css'
import GoogleIcon from "../../Components/Icons/GoogleIcon";


const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function SignIn({ setUser, setJoinEmail }) {
  const location = useLocation();
  const toaster = useToaster();
  const { lang, setLang } = useContext(LanguageContext);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      successGoogle(codeResponse);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  const successGoogle = (response) => {
    fetch(apiHost + "auth/google/", {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        access_token: response.access_token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        //success logic
        if (res?.error) {
          toaster.push(<Message >{res?.error}</Message>, { placement: "topEnd", duration: 2500 });
        }
        let newUser = {
          tokens: {
            access: res.access_token,
            refresh: res.refresh_token,
          },
          email: res.user.email,
          id: res.user.pk,
          name: res.user.first_name.concat(" ", res.user.last_name),
        };
        if (res.new_user) {
          setJoinEmail(res.user.email);

          navigation("/createProfile", {
            state: { from: location.state ? location.state.from : null },
          });
        } else {
          if (rem) {
            localStorage.setItem("user", JSON.stringify(newUser))
          }
          navigation("/userLandingPage");
        }
        setUser(newUser);
        sessionStorage.setItem("user", JSON.stringify(newUser));
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };

  const fbResponse = (response) => {

    fetch(apiHost + "auth/facebook/", {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        access_token: response.accessToken,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        let newUser = {
          tokens: {
            access: res.access_token,
            refresh: res.refresh_token,
          },
          email: res.user.email,
        };
        setUser(newUser);
        sessionStorage.setItem("user", JSON.stringify(newUser));
        fetch(apiHost + "auth/update-social-user/" + res.user.pk)
          .then((ress) => ress.json())
          .then((ress) => {
            if (ress.new_user) {
              setJoinEmail(res.user.email);

              navigation("/createProfile", {
                state: { from: location.state ? location.state.from : null },
              });
            } else {
              navigation("/userLandingPage");
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };

  const translateText = async () => {

    const res = await fetch(
      "https://translation.googleapis.com/language/translate/v2",
      {
        method: "POST",
        body: JSON.stringify({
          q: "I am a boy",
          source: "en",
          target: "es",
          api_key: "AIzaSyCccE8vzemsarQRPBpVRyTAnQ3fLMJ2l4k",
        }),
        headers: { "Content-Type": "application/json" },
      }
    );

  };

  const navigation = useNavigate();
  const [err, setErr] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [rem, setRem] = useState(false);

  const login = (e) => {
    fetch(apiHost + "auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email.toLowerCase(), password }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res;
        } else {
          var z = document.getElementById("loginErrorMsg");
          z.style.display = "block";
          z.style.paddingTop = "20px";
          z.style.color = "red";
        }
      })
      .then((res) => res.json())
      .then((res) => {
        setUser(res);
        if (rem) {
          localStorage.setItem("user", JSON.stringify(res))
        }
        navigation("/userLandingPage");
      })
      .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    if (sessionStorage.getItem("user") != "null") {
      navigation(-1);
    }

  }, []);

  const [passV, setPv] = useState(true);

  return (
    <>
      <div className="sign-in-body blueGradient p-3">
        <Link to="/">
          <img src="./assets/LogoWhite.png" alt="Logo" />
        </Link>
        <div
          className="w-100 p-3 border mx-auto my-4"
          style={{ maxWidth: "400px", backgroundColor: "white" }}
        >
          <h4
            className="sliderHead text-uppercase text-center"
            style={{ color: primeBlue }}
          >
            {/* Sign in Konsarutanto */}
            {languageSheet[3][lang]} Konsarutanto
          </h4>
          <div className="container mt-3">

            <button
              onClick={() => googleLogin()}
              className="p-2 w-100 my-2 rounded border border-dark"
              style={{ textAlign: "left" }}
            >
              <GoogleIcon/>
              <span className="ml-2" style={{ marginLeft: "10px" }}>
                {/* Continue with Google */}
                {languageSheet[28][lang].split(",")[0]}
              </span>
            </button>

            <div className="contaier-fluid mt-3">
              <div className="row">
                <div className="col-5">
                  <hr />
                </div>
                <div className="col-2" style={{ textAlign: "center" }}>
                  OR
                </div>
                <div className="col-5">
                  <hr />
                </div>
              </div>
            </div>
            <div className="emailBox mt-3">
              <input
                type="text"
                className="w-100 rounded border border-dark p-2"
                placeholder={languageSheet[27][lang].split(',')[0]} // email
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  var z = document.getElementById("loginErrorMsg");
                  z.style.display = "none";
                }}
              />
            </div>
            <div className="emailBox mt-3">
              <div className="d-flex justify-content-end align-items-center">
                <input
                  type={passV ? "password" : "text"}
                  className="w-100 rounded border border-dark p-2 form-control"
                  placeholder={languageSheet[27][lang].split(',')[1]} // password
                  value={password}
                  onChange={(e) => {
                    setPass(e.target.value);
                    var z = document.getElementById("loginErrorMsg");
                    z.style.display = "none";
                  }}
                />
                {passV ? (
                  <VisibilityIcon
                    onClick={() => setPv(false)}
                    style={{ width: "63px", position: "absolute" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => setPv(true)}
                    style={{ width: "63px", position: "absolute" }}
                  />
                )}
              </div>

              <div id="loginErrorMsg" style={{ display: `none` }}>
                {/* Invalid login credentials */}
                {languageSheet[85][lang]}
              </div>
              <button
                className="primaryButton px-3 py-3 w-100 mt-3"
                onClick={(e) => { login(e) }}
              >
                {/* continue  */}
                {languageSheet[26][lang].split(",")[1]}
              </button>
            </div>
            <div className="txt mt-3 row">
              <div className="left col-md-6 d-flex">
                <input
                  type="checkbox"
                  className="remember d-inline mr-3"
                  checked={rem}
                  onChange={(e) => {
                    setRem(!rem);
                  }}
                />
                <p className="policy text-muted pl-3" style={{ textAlign: "" }}>
                  {/* Remember me */}
                  {languageSheet[26][lang].split(",")[0]}
                </p>
              </div>
              <div className="left col-md-6 d-flex justify-content-end">
                <Link to={"/forgotPassword"} style={{ textDecoration: "none" }}>
                  <p
                    className="policy text-dark pl-3"
                    style={{ textAlign: "right" }}
                  >
                    {/* Forgot Password? */}
                    {languageSheet[24][lang]}
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <div className="signin" style={{ textAlign: "center" }}>
            <p className="already mb-0">
              {languageSheet[25][lang]}
              {/* Not a member yet? */}{" "}
              <Link to={"/join"} style={{ textDecoration: "none" }}>
                <span className="mutedFont">{languageSheet[2][lang]}</span>{" "}
              </Link>
            </p>
          </div>
        </div>
        <div
          className="text-center  pb-0 whiteTextLinks"
        >
          © {currentYear} &nbsp;&nbsp;
          <a className="whiteTextLinks" href="https://mdbootstrap.com/">
            konsarutanto.com
          </a>
        </div>
      </div>
    </>
  );
}
