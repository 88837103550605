import React, { useEffect, useState, useContext } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer'
import { bgColor, primeBlue } from '../../env'
import { useToaster } from 'rsuite'
import { UserContext } from '../../context/userContext'
import MessageUserExpired from '../../Components/MessageUserExpired'
import { LanguageContext } from '../../context/languageContext'
import languageSheet from '../../language'
import { FreePlan } from '../../Components/DialogPop'
import { pages_langsheet } from '../Languages/PagesLangsheet'
import ReactGA from "react-ga4";

const apiHost = process.env.REACT_APP_BACKEND_URL

const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM })

export default function PaymentCard({ proNew, subPlan, user, profile, setPaymentCompleted, paymentCompleted }) {

  const [checkCard, setCheckCard] = React.useState(false)
  const [subTotal, setSubTotal] = React.useState(subPlan.selected == "Yearly" ? subPlan?.annual_price : subPlan?.mothly_price)
  const { lang, setLang } = useContext(LanguageContext)
  const tax = 10
  const [coupDiscountPer, setCoupDiscountPer] = React.useState(0)
  const [coupDiscountAmt, setCoupDiscountAmt] = React.useState(0)
  const [coupCode, setCoupCode] = React.useState('')
  const [total, setTotal] = React.useState(subTotal)
  const [couponErr, setCouponErr] = useState(false)
  const [couponId, setCouponId] = useState(null)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const toaster = useToaster()
  const navigation = useNavigate()
  const { setUser } = useContext(UserContext)

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: '0.01',
          },
        },
      ],
    })
  }
  const onApprove = (data, actions) => {
    return actions.order.capture()
  }

  const generateToken = () => {
    let myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${user.tokens.access}`)
    fetch(apiHost + 'api/payment-client-token', {
      method: 'GET',
      headers: myHeaders,
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {

      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem('otherInfo')
          sessionStorage.removeItem('professional')
          navigation('/')
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null)
        }
      })
  }


  const payWithPaypal = () => {
    if (checkCard) {

    } else if (couponId) {
      fetch(apiHost + 'api/free-plan', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.tokens.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          subscription_plan: subPlan?.id,
          professional: proNew?.id ? proNew?.id : profile?.id,
          coupon_id: couponId
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            ReactGA.event("purchase", {
              transaction_id: "No Transaction Present",
              value: 0,
              currency: "USD",
              professionalId: `${proNew?.id ? proNew?.id : profile?.id}`,
              plan: `${subPlan?.name}`,
              items: [{
                item_id: `${subPlan?.id}`,
                item_name: `${subPlan?.name}`,
              }]
            });
            setPaymentCompleted(!paymentCompleted)
            handleOpen()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    else {
      let myHeaders = new Headers()
      myHeaders.append('Authorization', `Bearer ${user.tokens.access}`)
      myHeaders.append('Content-Type', 'application/json')
      let apiBody = JSON.stringify({
        subscription_plan: subPlan.id,
        professional: proNew?.id ? proNew?.id : profile?.id,
        method: 'Paypal',
        amount: total,
        plan_type: subPlan.selected,
      })

      if (couponId) {
        apiBody = JSON.stringify({
          subscription_plan: subPlan.id,
          professional: proNew.id,
          method: 'Paypal',
          amount: total,
          plan_type: subPlan.selected,
          coupon_id: couponId
        })
      }

      fetch(apiHost + 'api/payment', {
        method: 'POST',
        headers: myHeaders,
        body: apiBody,
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error('unauth')
            error.code = 401
            throw error
          } else {
            return res.json()
          }
        })
        .then((res) => {
          if (res.link) {
            window.open(res.link, '_self')
          }
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem('otherInfo')
            sessionStorage.removeItem('professional')
            navigation('/')
            toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
            setUser(null)
          }
        })
    }
  }

  const applyCoupon = () => {
    let myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${user.tokens.access}`)
    myHeaders.append('Content-Type', 'application/json')

    fetch(apiHost + 'api/verify-coupon', {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        code: coupCode,
        subscription_id: subPlan?.id
      }),
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          setCouponId(null)
          throw error
        } else if (res.status == 404 || res.status == 400) {
          setCouponErr(true)
          setCouponId(null)
        }
        else if (res.status == 200) {
          setCouponErr(false)
          return res.json()
        }
      })
      .then((res) => {
        if (res.discount || res?.is_valid) {
          setCoupDiscountPer(100)
          setCouponId(res.id)
        } else {
          setCoupDiscountPer(0)
          setCoupDiscountAmt(0)
          setCouponId(null)
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem('otherInfo')
          sessionStorage.removeItem('professional')
          navigation('/')
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null)
        }
      })
  }

  useEffect(() => {
    generateToken()

    let discountAmt = Math.round(
      ((coupDiscountPer * (subPlan.selected == "Yearly" ? subPlan.annual_price : subPlan.mothly_price)) / 100).toFixed(2),
    )

    if (couponId) {
      setCoupDiscountAmt(discountAmt + tax)
      setTotal(subTotal + tax - coupDiscountAmt)
    }
    else {
      setCoupDiscountAmt(0)
      setTotal(subTotal + tax)
    }
  }, [
    tax,
    coupDiscountPer,
    coupDiscountAmt,
    subTotal,
    couponId,
    subPlan,
    total,
    generateToken,
  ])

  return (
    <div className='payment-page d-flex flex-column justify-content-between' style={{ backgroundColor: bgColor }}>
      <div className="container my-5">
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {/* SELECT PAYMENT METHOD */}
          {pages_langsheet?.pleaseMakeAPayment?.[lang]}
        </h4>

        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-lg-7 mt-4  ">
            <div className="">
              <div
                className="d-flex row flex-column boxShadow2 rounded mx-auto"
                style={{
                  backgroundColor: 'white',
                  minHeight: '35rem',
                  width: '100%',
                }}
              >
                <div className="mb-0 mt-4 container ps-4">


                  {/* **********  paypal button -**************** */}

                  <div className="mt-5">
                    <input
                      type="radio"
                      name="pay_method"
                      value="paypal"
                      id="paypal"
                      checked={!checkCard}
                      className="col"
                      onChange={(e) => setCheckCard(!e.target.value)}
                      style={{ borderColor: '#4F4F4F', borderRadius: '4px' }}
                    />
                    <label htmlFor="paypal" class="label ms-3">
                      PAYPAL
                    </label>
                    <div
                      className={`d-${checkCard && 'none'} ms-4 mt-2 subtitle`}
                    >
                      {/* Click “Place order” and we will process you to PayPal page */}
                      {languageSheet[112][lang]}
                    </div>
                  </div>
                </div>
                <div className="callBtn mb-5 container">
                  {/* <Link to={"/paymentSuccess"}> */}
                  <button
                    className="w-50 primaryButton2 py-2 mt-3"
                    onClick={() => {
                      payWithPaypal()
                    }}
                  >
                    {/* PAY NOW */}
                    {couponId ? pages_langsheet?.continue?.[lang] : languageSheet[104][lang].split(',')[1]}
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5  mt-4 ">
            <div
              className="d-flex flex-column boxShadow2 mb-4 rounded"
              style={{ backgroundColor: 'white', minHeight: '22rem' }}
            >
              <h4 className="ms-3 mt-3 subtitle5" style={{ color: primeBlue }}>
                {/* YOUR ORDER SUMMARY */}
                {languageSheet[113][lang]}
              </h4>

              <div className="features container">
                <div className="subtitle row feature my-3 border-bottom border-2 border-secondary pb-4">
                  <div className="col text-start">
                    {/* Subscription */}
                    {subPlan?.name == "Standard" ? pages_langsheet?.StandardSubscription?.[lang] : subPlan?.name == "Premium" ? pages_langsheet?.PremiumSubscription?.[lang] : pages_langsheet?.BasicSubscription?.[lang]}
                  </div>
                  <div className="col text-end fw-bold">
                    ${subPlan.selected == "Yearly" ? subPlan?.annual_price : subPlan?.mothly_price}
                  </div>
                </div>
                <div>
                  <div className="row">
                    <div className="col text-start">
                      <input
                        type="text"
                        name="coup"
                        placeholder={languageSheet[138][lang]}  //"enter code"
                        value={coupCode}
                        onChange={(e) => setCoupCode(e.target.value)}
                      />
                      {couponErr && <p style={{ color: "red", fontSize: "13px" }}>{pages_langsheet?.invalidCoupon?.[lang]}</p>}
                      {couponId && !couponErr && <p style={{ color: "green", fontSize: "13px" }}>{pages_langsheet?.couponApplied?.[lang]}</p>}
                    </div>
                    <div className="col text-end fw-bold">
                      <button onClick={applyCoupon}>
                        {/* apply */}
                        {languageSheet[106][lang]}
                      </button>
                    </div>
                  </div>
                  <div
                    className="subtitle coupon_text"
                    style={{ color: '#6B8EA6' }}
                  >
                    {/* Apply Coupon Code */}
                    {languageSheet[106][lang].split(',')[1]}
                  </div>
                </div>
                <div className="subtitle mt-2">
                  <div className="row">
                    <div className="col text-start">
                      {/* Subtotal: */}
                      {languageSheet[107][lang].split(',')[0]}:
                    </div>
                    <div className="col text-end fw-bold">${subTotal}</div>
                  </div>
                  <div className="row">
                    <div className="col text-start">
                      {/* Tax: */}
                      {languageSheet[107][lang].split(',')[1]}:
                    </div>
                    <div className="col text-end fw-bold">${tax}</div>
                  </div>
                  <div className="row">
                    <div className="col text-start">
                      {/* Coupon Discount:  */}
                      {languageSheet[107][lang].split(',')[2]}: {coupDiscountPer} %
                    </div>
                    <div className="col text-end fw-bold">
                      -${coupDiscountAmt}
                    </div>
                  </div>
                  <div className="row mt-3" style={{ fontSize: '22px' }}>
                    <div className="col text-start fw-bold">
                      {/* Total: */}
                      {languageSheet[107][lang].split(',')[3]}:
                    </div>
                    <div className="col text-end fw-bold">${total}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FreePlan
          open={open}
          handleClose={handleClose}
        />
      </div>
      <Footer />
    </div>
  )
}

PaymentCard.defaultProps = {
  subPlan: {
    name: '',
    annual_price: '',
  },
  proNew: {
    id: '',
  },
}
