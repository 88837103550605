import { useEffect, useState, useContext } from "react";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Footer from "../../Components/Footer";
import { primeBlue, textBlue, textSecondary } from "../../env";
import {
  DialogSuccess,
  DialogWithOptions,
  CancelSubscription,
} from "../../Components/DialogPop";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { pages_langsheet } from "../Languages/PagesLangsheet";
import ScheduleMaker from "../../Components/ScheduleMaker";
import { Button, useToaster } from "rsuite";

import MessageUserExpired from "../../Components/MessageUserExpired";
import languageSheet from "../../language";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { LanguageContext } from "../../context/languageContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
const apiHost = process.env.REACT_APP_BACKEND_URL;
const ariaLabel = { "aria-label": "description" };

export default function ProProfile({ user, setUser, setPreviewAd }) {
  const toaster = useToaster();
  const navigation = useNavigate();

  const { lang, setLang } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const [openCancelSubscription, setOpenCancelSubscription] = useState(false);
  const [ondeleteProf, setOnDeleteProf] = useState(false);
  const [ondeleteUser, setOnDeleteUser] = useState(false);
  const [deactivateProf, setDeactivateProf] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [verified, setVerified] = useState(false);
  const [planDetails, setPlanDetails] = useState({
    currentPlan: "",
    sessionsLeft: 0,
  });
  const [loading, setLoading] = useState(false);
  const steps = [`${languageSheet[62][lang]}`, `${languageSheet[63][lang]}`];

  useEffect(() => {
    if (user === null) {
      navigation("/signIn");
    } else {
      currPlan();
      setLoading(true);
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      fetch(apiHost + "auth/user-profile", {
        headers: myHeaders,
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          } else {
            return res.json();
          }
        })
        .then((res) => {
          setUserProfile(res);
          setLoading(false);
          setActiveStep(res.is_profession_verified ? 1 : 0);
          if (res.is_profession_verified === true) {
            setVerified(true);
          }
          let myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
          fetch(apiHost + "api/schedule/", {
            headers: myHeaders,
          })
            .then((res) => {
              if (res.status == 401) {
                const error = new Error("unauth");
                error.code = 401;
                throw error;
              } else {
                return res.json();
              }
            })
            .then((res) => {
              transformSchedule(res);
            })
            .catch((err) => {
              if (err.code == 401) {
                sessionStorage.removeItem("otherInfo");
                sessionStorage.removeItem("professional");
                navigation("/");
                toaster.push(MessageUserExpired, { placement: "bottomEnd" });
                setUser(null);
              }
            });
        })
        .catch((err) => {
          setLoading(false);
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: "bottomEnd" });
            setUser(null);
          }
        });
    }
  }, []);

  const currPlan = () => {
    fetch(apiHost + "api/current-subscription", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.tokens.access}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPlanDetails((prevState) => ({
          ...prevState,
          currentPlan: res.name,
          sessionsLeft: res.sessions_left,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const notSuccess = (err) => {
    if (err.code == 401) {
      sessionStorage.removeItem("otherInfo");
      sessionStorage.removeItem("professional");
      navigation("/");
      toaster.push(MessageUserExpired, { placement: "bottomEnd" });
      setUser(null);
    }
  };

  const handleClose = (value) => {
    setOpen(false);
    if (value === 1 && userProfile?.user?.is_professional) {
      //if the first button (delete) is clicked and user is professional then professional account will be deleted

      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(apiHost + "auth/delete-user/professional", requestOptions)
        .then((res) => {
          if (res.status === 202) {
            setOnDeleteProf(true);
            sessionStorage.removeItem("otherInfo");
            setPreviewAd(null);
            sessionStorage.removeItem("professional");
            setUser(null);
          } else if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          }
        })
        .catch((err) => {
          notSuccess(err);
        });
    } else if (value === 1 && !userProfile?.user?.is_professional) {
      //if the first button (delete) is clicked and user is not professional then user account will be deleted
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(apiHost + "auth/delete-user/user", requestOptions)
        .then((res) => {
          if (res.status === 202) {
            setOnDeleteUser(true);
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            setUser(null);
          } else if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          }
        })
        .catch((err) => {
          notSuccess(err);
        });
    } else if (value === 2 && userProfile?.user?.is_professional_active) {
      //if the second button (deactivate) is clicked and user's professional account is active then professional account will be deactivated
      //deactivate
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(apiHost + "auth/deactivate-user/professional", requestOptions)
        .then((res) => {
          if (res.status === 202) {
            setDeactivateProf(true);
            sessionStorage.removeItem("otherInfo");
            setPreviewAd(null);
            sessionStorage.removeItem("professional");
            setUser(null);
          } else if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          }
        })
        .catch((err) => {
          notSuccess(err);
        });
    } else if (value === 2 && !userProfile?.user?.is_professional_active) {
      //if the second button (deactivate) is clicked and user's professional account is not active then user account will be deactivated
      //deactivate
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(apiHost + "auth/deactivate-user/user", requestOptions)
        .then((res) => {
          if (res.status === 202) {
            setDeactivateUser(true);
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            setUser(null);
          } else if (res.status == 401) {
            const error = new Error("unauth");
            error.code = 401;
            throw error;
          }
        })
        .catch((err) => {
          notSuccess(err);
        });
    }
  };

  const cancelSubscription = () => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiHost + "api/cancel-subscription", requestOptions)
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          setUser(null);
          navigation("/");
        } else if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        }
      })
      .catch((err) => {
        notSuccess(err);
      });
  };

  const handleDelete = () => {
    setOnDeleteProf(false);
    setOnDeleteUser(false);
    navigation("/");
  };

  const handleDeactivate = () => {
    setDeactivateProf(false);
    setDeactivateUser(false);
    navigation("/");
  };

  // stepper funcs
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const transformSchedule = (data) => {
    // console.log({ data });
    const weekKey = {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
    };
    let scheduleNew = {
      Sunday: {
        available: false,
        timeIntervals: [],
      },
      Monday: {
        available: false,
        timeIntervals: [],
      },
      Tuesday: {
        available: false,
        timeIntervals: [],
      },
      Wednesday: {
        available: false,
        timeIntervals: [],
      },
      Thursday: {
        available: false,
        timeIntervals: [],
      },
      Friday: {
        available: false,
        timeIntervals: [],
      },
      Saturday: {
        available: false,
        timeIntervals: [],
      },
    };
    for (let day in weekKey) {
      if (data[day].length != 0) {
        scheduleNew[weekKey[day]].available = true;
        for (let range of data[day]) {
          let splits = range.split(",");
          let start = splits[0].substring(1);
          let end = splits[1].slice(0, -1);
          scheduleNew[weekKey[day]].timeIntervals.push([start, end]);
        }
      }
    }

    setSchedule({ ...scheduleNew });
  };
  const [schedule, setSchedule] = useState({
    Sunday: {
      available: false,
      timeIntervals: [["8:00", "10:00"]],
    },
    Monday: {
      available: false,
      timeIntervals: [["8:00", "10:00"]],
    },
    Tuesday: {
      available: false,
      timeIntervals: [["8:00", "10:00"]],
    },
    Wednesday: {
      available: false,
      timeIntervals: [["8:00", "10:00"]],
    },
    Thursday: {
      available: false,
      timeIntervals: [["8:00", "10:00"]],
    },
    Friday: {
      available: false,
      timeIntervals: [["8:00", "10:00"]],
    },
    Saturday: {
      available: false,
      timeIntervals: [["8:00", "10:00"]],
    },
  });

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleOpen = (file) => {
    setSelectedFile(file);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const displayFile = () => {
    if (!selectedFile) return null;

    return (
      <iframe
        src={selectedFile}
        title="File Preview"
        className="w-100 border-0"
        style={{ height: "500px" }}
      />
    );
  };

  return (
    <div className="pro-profile d-flex flex-column justify-content-between">
      {/* Dialog for file viewer */}
      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{languageSheet[163][lang]}</DialogTitle>
        <DialogContent>{displayFile()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} className="primaryButton2">
            {languageSheet[164][lang]}
          </Button>
        </DialogActions>
      </Dialog>
      <div className="container my-5 vh-80" style={{ minHeight: "60vh" }}>
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {/* Pro Profile */}
          {languageSheet[77][lang]}
        </h4>

        <div className="row ">
          <div className="col-lg-2 col-md-4 my-3">
            {loading ? (
              <Box sx={{ width: 160, height: 160 }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={160}
                  height={160}
                />
              </Box>
            ) : userProfile?.user.profile_image ? (
              <img
                src={userProfile.user.profile_image}
                alt="ProfileImage"
                width={160}
              />
            ) : userProfile?.user?.sex === "Male" ? (
              <img src="./assets/male.jpg" alt="ProfileImage" width={"150px"} />
            ) : (
              <img
                src="./assets/female.jpg"
                alt="ProfileImage"
                width={"150px"}
              />
            )}

            <p
              className="status ms-1"
              style={{ fontSize: "12px", margin: "0.25rem", fontWeight: "700" }}
            >
              <img
                src="./assets/statusActive.svg"
                alt="ActiveStatus"
                className=""
              />
              {languageSheet[45][lang].split(",")[4]} {/* USER ACTIVE */}
            </p>

            {verified && (
              <div className="mt-3">
                <img
                  src={window.location.origin + "/assets/verifiedBadge.svg"}
                  alt="VerifiedBadge"
                />
                <p className="d-inline textSecondary">
                  {/* CERTIFIED */}
                  {languageSheet[63][lang]}
                </p>
              </div>
            )}
          </div>

          <div className="col-lg-5 col-md-12 my-3">
            <div className="row align-items-center ">
              <div className="col-md-4 label">
                {languageSheet[45][lang].split(",")[0].split("/")[0]} *
                {/* NAME * */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">
                  {userProfile?.user.first_name} {userProfile?.user.last_name}
                </p>
              </div>
            </div>

            <div className="row my-4 align-items-center">
              <div className="col-md-4 label">
                {languageSheet[45][lang].split(",")[2]} *{/* Country * */}
                {/* Country * */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.user.country}</p>
              </div>
            </div>

            <div className="row my-4  align-items-center">
              <div className="col-md-4 label">
                {languageSheet[45][lang].split(",")[3]}
                {/* City * */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.user.city}</p>
              </div>
            </div>

            <div className="row my-4  align-items-center">
              <div className="col-md-4 label">
                {languageSheet[32][lang].split(",")[4]} *{/* PROFESSION * */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.profession}</p>
              </div>
            </div>
            <div className="row my-4  align-items-center">
              <div className="col-md-4 label">
                {languageSheet[32][lang].split(",")[5]} *{/* LANGUAGES * */}
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">
                  {userProfile?.languages?.map((item) => {
                    return (
                      <Chip
                        key={item}
                        label={item.charAt(0).toUpperCase() + item.slice(1)}
                        style={{ marginRight: "5px" }}
                      />
                    );
                  })}
                </p>
              </div>
            </div>

            <div className="row my-4  align-items-center">
              <div className="col-md-4 label">
                {/* DNI * */}
                {languageSheet[79][lang]}*
              </div>
              <div className="col-md-6 textSecondary">
                <p className="mb-0">{userProfile?.dni}</p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-md-12"
                style={{ width: "97%", wordWrap: "break-word" }}
              >
                <div className="row my-2">
                  <p className="littleHead">
                    {/* Short Description */}
                    {languageSheet[141][lang]}
                  </p>
                  <p className="textSecondary ">
                    {userProfile?.short_description}
                  </p>
                </div>

                <p className="littleHead">
                  {/* DESCRIPTION: */}
                  {languageSheet[81][lang]}:
                </p>
                <p
                  className=""
                  style={{ color: textBlue, textAlign: "justify" }}
                >
                  {userProfile?.long_description}
                </p>
              </div>

              {/* Preview Files */}
              <div>
                <div className="my-2 d-flex justify-content-start flex-wrap gap-2">
                  <button
                    className="buttons primaryButton2 py-2 px-2"
                    onClick={() => handleOpen(userProfile?.photo_id_proof)}
                  >
                    {languageSheet[165][lang]}
                  </button>

                  <button
                    className="buttons primaryButton2 py-2 px-2"
                    onClick={() => handleOpen(userProfile?.professional_degree)}
                  >
                    {languageSheet[166][lang]}
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="buttons d-flex justify-content-start flex-wrap ">
                <button
                  className="primaryButton2 py-2 px-5 mx-2 my-1"
                  onClick={() => {
                    navigation("/editProfessionalProfile");
                  }}
                >
                  {languageSheet[32][lang]
                    .split(",")[0]
                    .split("/")[1]
                    .toUpperCase()}
                  {/* EDIT PROFILE */}
                </button>
                <button
                  className="secondaryButton py-2 px-5 mx-2 my-1"
                  onClick={handleClickOpen}
                  style={{ cursor: "pointer" }}
                >
                  {languageSheet[15][lang].toUpperCase()}
                  {/* DELETE ACCOUNT */}
                </button>
                <DialogWithOptions
                  open={open}
                  onClose={handleClose}
                  title={languageSheet[126][lang]} //"ARE YOU SURE?"
                  content={languageSheet[127][lang]} //"Do you really want to delete this account?"
                  image="./assets/bin.svg"
                  btnFirst={languageSheet[128][lang].split(",")[0]} //"Delete"
                  btnSecond={languageSheet[35][lang].split(",")[1]} //"Cancel"
                  btnThird={languageSheet[128][lang].split(",")[1]} //"Deactivate"
                />
                <DialogSuccess
                  success={ondeleteUser ? ondeleteUser : ondeleteProf}
                  onClose={handleDelete}
                  imageToShow="profileDelete.png"
                  title={
                    ondeleteUser
                      ? pages_langsheet?.UserDeleted?.[lang]
                      : pages_langsheet?.ProfessionalDeleted?.[lang]
                  } //"User Account Deleted Successfully"
                  error={false}
                />
                <DialogSuccess
                  success={deactivateUser ? deactivateUser : deactivateProf}
                  onClose={handleDeactivate}
                  imageToShow="profileDelete.png"
                  title={
                    deactivateUser
                      ? pages_langsheet?.UserDeactivated?.[lang]
                      : pages_langsheet?.ProfessionalDeactivated?.[lang]
                  } //"User Account Deactivated Successfully"
                  error={false}
                />
              </div>
            </div>
          </div>
          <div className="col-md-5 my-3 row">
            <div className="border lightBorder p-3 mb-5">
              <p className="text-uppercase littleHead my-3">
                {/* session tracker */}
                {languageSheet[151][lang].split(",")[0]}
              </p>
              <div className="mt-5">
                <p className="text-uppercase littleHead">
                  {/* SESSION LEFT */}
                  {languageSheet[148][lang].split(",")[0]}
                </p>
                <div className="border border-dark rounded p-2 ps-3 bg-white w-50 mb-4 fw-bold">
                  {planDetails.currentPlan.toLowerCase() === "premium"
                    ? "Unlimited"
                    : planDetails.sessionsLeft}
                </div>
              </div>
              <div>
                <p className="text-dark littleHead text-uppercase">
                  {/* CURRENT PLAN */}
                  {languageSheet[149][lang].split(",")[0]}
                </p>
                <div className="border border-dark rounded p-2 ps-3 bg-white w-50 fw-bold ">
                  {planDetails.currentPlan}
                </div>
              </div>
              <button
                className="mt-4 rounded bg-dark text-light text-uppercase px-4 py-2"
                onClick={() => {
                  navigation("/subsDetails");
                }}
              >
                {/* Upgrade */}
                {languageSheet[150][lang].split(",")[0]}
              </button>
            </div>
            {/* <p className="littleHead">
              {languageSheet[34][lang].split(',')[2]} */}
            {/* VERIFICATION STATUS */}
            {/* : */}
            {/* </p> */}
            {/* <div className="stepper my-4" style={{ maxWidth: '300px' }}> */}
            {/* stepper */}
            {/* <Stepper activeStep={activeStep}>
                {steps?.map((label, index) => {
                  const stepProps = {}
                  const labelProps = {}

                  if (isStepSkipped(index)) {
                    stepProps.completed = false
                  }

                  if (index === 0) {
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel
                          {...labelProps}
                          style={{ color: primeBlue }}
                          StepIconComponent={CheckCircleOutlineIcon}
                        >
                          <p className="mb-0" style={{ color: primeBlue }}>
                            {label}
                          </p>
                        </StepLabel>
                      </Step>
                    )
                  } else {
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel
                          {...labelProps}
                          StepIconComponent={CheckCircleOutlineIcon}
                          style={{
                            color: activeStep == 1 ? primeBlue : textSecondary,
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{
                              color:
                                activeStep == 1 ? primeBlue : textSecondary,
                            }}
                          >
                            {label}
                          </p>
                        </StepLabel>
                      </Step>
                    )
                  }
                })}
              </Stepper>
            </div> */}

            <p className="littleHead">
              {/* CURRENT SCHEDULE: */}
              {languageSheet[82][lang]}:
            </p>

            <div className=" ">
              <ScheduleMaker
                schedule={schedule}
                setSchedule={setSchedule}
                mode={"previewProfile"}
              />
            </div>
            <div className="d-flex flex-sm-row flex-column gap-sm-5">
              <div>
                <div className="mt-4"></div>
                <div className="my-3 d-flex flex-wrap">
                  <buttons
                    className="primaryButton2 py-2 px-3  my-2"
                    onClick={() => {
                      navigation("/appointmentsPro");
                    }}
                  >
                    {languageSheet[43][lang].split(",")[0].toUpperCase()}
                    {/* MY APPOINTMENTS */}
                  </buttons>
                </div>
              </div>

              <div>
                <div className="mt-4"></div>
                <div className="my-3 d-flex flex-wrap">
                  <buttons
                    className={
                      planDetails.currentPlan == "Basic"
                        ? "disabled primaryButton2 py-2 px-3  my-2"
                        : "primaryButton2 py-2 px-3  my-2"
                    }
                    onClick={
                      planDetails.currentPlan !== "Basic"
                        ? () => {
                            setOpenCancelSubscription(true);
                          }
                        : null
                    }
                  >
                    {pages_langsheet.CancelSubscription[lang]}
                    {/* Cancel Subscription */}
                  </buttons>

                  <CancelSubscription
                    open={openCancelSubscription}
                    onClose={() => {
                      setOpenCancelSubscription(false);
                    }}
                    imageToShow="profileDelete.png"
                    title={pages_langsheet.DoYouWantToCancelSubscription[lang]}
                    cancelSubscriptionFunc={() => {
                      cancelSubscription();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
