import React from 'react'

const FileUploadIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M6.25 20C4.81667 20 3.58333 19.4834 2.55 18.45C1.51667 17.4167 1 16.1834 1 14.75C1 13.45 1.4125 12.3042 2.2375 11.3125C3.0625 10.3209 4.125 9.72502 5.425 9.52502C5.75833 7.90836 6.54167 6.58752 7.775 5.56252C9.00833 4.53752 10.4333 4.02502 12.05 4.02502C13.9333 4.02502 15.5125 4.70419 16.7875 6.06252C18.0625 7.42086 18.7 9.05002 18.7 10.95V11.55C19.9 11.5167 20.9167 11.9042 21.75 12.7125C22.5833 13.5209 23 14.5417 23 15.775C23 16.925 22.5833 17.9167 21.75 18.75C20.9167 19.5834 19.925 20 18.775 20H12.75C12.35 20 12 19.85 11.7 19.55C11.4 19.25 11.25 18.9 11.25 18.5V12.05L9.175 14.125L8.1 13.05L12 9.15002L15.9 13.05L14.825 14.125L12.75 12.05V18.5H18.775C19.525 18.5 20.1667 18.2334 20.7 17.7C21.2333 17.1667 21.5 16.525 21.5 15.775C21.5 15.025 21.2333 14.3834 20.7 13.85C20.1667 13.3167 19.525 13.05 18.775 13.05H17.2V10.95C17.2 9.46669 16.6958 8.19169 15.6875 7.12502C14.6792 6.05836 13.4333 5.52502 11.95 5.52502C10.4667 5.52502 9.21667 6.05836 8.2 7.12502C7.18333 8.19169 6.675 9.46669 6.675 10.95H6.2C5.16667 10.95 4.29167 11.3125 3.575 12.0375C2.85833 12.7625 2.5 13.6584 2.5 14.725C2.5 15.7584 2.86608 16.6459 3.59823 17.3875C4.33036 18.1292 5.21428 18.5 6.25 18.5H9.75V20H6.25Z" fill="black"/>
</svg>
  )
}

export default FileUploadIcon