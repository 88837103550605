import { useContext, useState, useEffect, lazy, Suspense, startTransition } from "react";
import './LandingPageProfessional.css'
import { bgColor, whiteBg } from "../../../env";
import { useToaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/userContext";
import { LanguageContext } from '../../../context/languageContext'
import languageSheet from '../../../language'
import MessageUserExpired from "../../../Components/MessageUserExpired";
import Loading from '../../../Components/Loading'
import { Cards, HowItWork, Footers, ProLandingPage } from '../../../Components/Loading'
const DisplaySwiperProfessional = lazy(() => import('../../../Components/DisplaySwiper/DisplaySwiper').then(module => ({ default: module.DisplaySwiperProfessional })));
const Footer = lazy(() => import('../../../Components/Footer'))
const HeroSection = lazy(() => import('../../../Components/HeroSection'))
const backendURL = process.env.REACT_APP_BACKEND_URL;

export default function LangingPageProfessional({ user }) {
  const [recent_professionals, recent_setProfessionals] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [loadingProfessional, setLoadingProfessional] = useState(false)
  const [loadingPopularProfessional, setLoadingPopularProfessional] = useState(false)
  const { lang, setLang } = useContext(LanguageContext)
  const toaster = useToaster();
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext)


  const fetchData = async () => {
    setLoadingProfessional(true)
    setLoadingPopularProfessional(true)

    fetch(`${backendURL}api/profession`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        setProfessions([...res.results]);
      }).catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      });
    fetch(`${backendURL}api/last-viewed-professionals`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        setLoadingProfessional(false)
        if (res.results.length == 0) {
        } else {
          recent_setProfessionals([...res.results]);
        }
      }).catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      });

    fetch(`${backendURL}api/popular-professionals`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        setLoadingPopularProfessional(false)
        setProfessionals([...res.results]);
      }).catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="proff-body d-flex flex-column justify-content-between">

      {/* <SwiperLanding/> */}
      <Suspense fallback={<ProLandingPage />}>
        <div>
          <HeroSection name={user?.name} />

          {recent_professionals.length !== 0 && (
            <Suspense fallback={<Cards />}>
              <DisplaySwiperProfessional
                color={bgColor}
                heading={languageSheet[90][lang]} //"Recently Viewed Professionals"
                viewLink={"/professionals"}
                data={recent_professionals}
                user={user}
                loading={loadingProfessional}
              />
            </Suspense>
          )}

          <Suspense fallback={<Cards />}>
            <DisplaySwiperProfessional
              color={whiteBg}
              heading={languageSheet[56][lang]}  // Popular Professionals
              viewLink={"/professionals"}
              data={professionals}
              user={user}
              loading={loadingPopularProfessional}
            />
          </Suspense>

        </div>

        <Suspense fallback={<Footers />}>
          <Footer />
        </Suspense>
      </Suspense>
    </div>
  );
}
