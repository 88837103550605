import { useEffect, useState, useContext } from "react";
import Footer from "../Components/Footer";
import { bgColor, primeBlue } from "../env";
import GridOfCards from "../Components/GridOfCards";
import { useParams, useNavigate } from "react-router-dom";
import { useToaster } from "rsuite";
import MessageUserExpired from "../Components/MessageUserExpired";
import { UserContext } from "../context/userContext";
import languageSheet from "../language";
import { LanguageContext } from "../context/languageContext";

const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function RecentProfessionals({user}) {
  const {id} = useParams()
  const toaster = useToaster();
  const navigation = useNavigate();
  const {setUser} = useContext(UserContext)
  const { lang, setLang } = useContext(LanguageContext);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const [recent_professionals, recent_setProfessionals] = useState([])
  useEffect(() => {
    fetch(`${backendURL}api/last-viewed-professionals`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) =>  res.json())
      .then((res) => {
        if (res.results.length == 0) {
        } else {
          recent_setProfessionals([...res.results]);
        }
      }).catch((err)=>{
        if(err.code == 401){
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired,{placement:'bottomEnd'})
          setUser(null);
        }
      });
   
  },[])
  return (
    <div>
      <div style={{ backgroundColor: bgColor }}>
        <div className="container my-5" style={{ minHeight:'60vh'}}>
          <h4
            className="text-uppercase sliderHead"
            style={{ color: primeBlue }}
          >
            {/* RECENTLY VIEWD PROFESSIONalS */}
            {languageSheet[90][lang]}
          </h4>

          <div className="row ">
           
          </div>
          <div className="mx-auto">

          
            <GridOfCards data={recent_professionals}  user={user} isProfession={false} isAllProfessional={false}/>
          </div>
        
        </div>
        <Footer />
      </div>
    </div>
  );
}
