import React, { useState, useContext } from "react";
import DialogPop from "../../Components/DialogPop";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { primeBlue } from "../../env";
import { useLocation } from "react-router-dom";
import { LanguageContext } from '../../context/languageContext'
import languageSheet from '../../language'
import ForgotPasswordImage from "../../Components/Icons/ForgotPasswordImage";

const apiHost = process.env.REACT_APP_BACKEND_URL;
export default function PasswordNew({ joinEmail, setUser, fp }) {
  const location = useLocation();
  const { lang, setLang } = useContext(LanguageContext)
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setPassword = () => {
    //Confirm Password: When confirm password is left empty or does not match the password on clicking the continue button
    if (passwordInput.confirmPassword !== passwordInput.password ) {
      setConfirmPasswordError("Confirm password does not match");
      
      return
    } else {
      setConfirmPasswordError("");
    }
    fetch(apiHost + 'auth/set-password/' + joinEmail, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ password: passwordInput.password })
    })
      .then(res => res.json())
      .then(async (res) => {
        if (res.success) {
          loginAccount()
        }

      })
      .catch(err => console.log({ err }))

  }

  const loginAccount = () => {
    fetch(apiHost + 'auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: joinEmail, password: passwordInput.password })
    })
      .then(res => res.json())
      .then(res => {

        setUser(res)
        sessionStorage.setItem('user', JSON.stringify(res))
        handleClickOpen()


      })
      .catch(err => console.log({ err }))
  }

  const [showPassword, setShowPassword] = useState(false)
  const [showCPassword, setShowCPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };



  //validation password

  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    password: '',
    confirmPassword: ''
  })
  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = { ...passwordInput, [passwordInputFieldName]: passwordInputValue }
    setPasswordInput(NewPasswordInput);

  }
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password 
    if (passwordInputFieldName === 'password') {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
    // for confirm password
    if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password" && passwordInput.confirmPassword.length > 0)) {

      if (passwordInput.confirmPassword !== passwordInput.password ) {
        setConfirmPasswordError("Confirm password is not matched");
        
      } else {
        setConfirmPasswordError("");
      }

    }
  }
  return (
    <div className="blueGradient p-3 pb-5" style={{ minHeight: "100vh" }}>
      <img src="./assets/LogoWhite.png" alt="Icon" />

      <div
        className="w-100 p-3 border rounded shadow mx-auto my-4"
        style={{ maxWidth: "430px", backgroundColor: "white" }}
      >
        <h4
          className="sliderHead text-uppercase text-center pt-3"
          style={{ color: primeBlue, fontSize: "1.7rem" }}
        >
          {/* Create New Password */}
          {languageSheet[46][lang].split(',')[0].split(' ')[0].split('/')[0] + " " + languageSheet[46][lang].split(',')[0].split(' ')[0].split('/')[1] + " " + languageSheet[46][lang].split(',')[0].split(' ')[1]}
        </h4>
        <div className="d-flex py-4 mb-3 justify-content-center">
    <ForgotPasswordImage/>
        </div>

        <div className="container mt-3">
          <div className="contaier-fluid mt-3"></div>
          <div className="row emailBox mt-3 px-4 gy-3">

            <FormControl sx={{}} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                {/* New Password* */}
                {languageSheet[46][lang].split(',')[0].split(' ')[0].split('/')[1] + " " + languageSheet[46][lang].split(',')[0].split(' ')[1]}*
              </InputLabel>
              <Input
                onChange={handlePasswordChange}
                name="password"
                onKeyUp={handleValidation}
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={passwordInput.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <p className="text-danger">{passwordError}</p>
            </FormControl>

            <FormControl sx={{}} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                {/* Confirm Password* */}
                {languageSheet[46][lang].split(',')[0].split(' ')[0].split('/')[2] + " " + languageSheet[46][lang].split(',')[0].split(' ')[1]}*
              </InputLabel>
              <Input
                onChange={handlePasswordChange}
                name="confirmPassword"
                onKeyUp={handleValidation}
                value={passwordInput.confirmPassword}
                id="standard-adornment-password"
                type={showCPassword ? 'text' : 'password'}

                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showCPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <p className="text-danger">{confirmPasswordError}</p>
            </FormControl>
            <button
              className="btn btn-secondary text-uppercase py-2 w-100 my-4"
              style={{ textAlign: "center", backgroundColor: primeBlue }}
              disabled={passwordInput.password != '' && confirmPasswordError == '' && passwordError == '' ? false : true}
              onClick={setPassword}
            >
              {/* Confirm */}
              {languageSheet[36][lang].split(',')[1]}
            </button>
            <DialogPop
              state={{ from: location.state ? location.state.from : null }}
              open={open}
              onClose={handleClose}
              selectedValue={"success"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              title={languageSheet[54][lang].split('/')[0] + ' ' + languageSheet[54][lang].split(' ')[1]} //"PASSWORD CREATED!"
              imageToShow='profileSuccess.png'
              content={languageSheet[52][lang]} //"Your password is successfully created."
              btnName={fp ? 'HOMEPAGE' : languageSheet[53][lang]} // complete profile
              redirectOnSuccess={fp ? location.state?.from ? location.state.from : '/userLandingPage' : '/createProfile'}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <div
        className="text-center position-relative bottom-0 whiteTextLinks"
      >
        © {currentYear} &nbsp;&nbsp;
        <a className="whiteTextLinks" href="https://mdbootstrap.com/">
          konsarutanto.com
        </a>
      </div>
    </div>
  );
}
