import React from 'react'

const FileUploadSuccess = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
  <path d="M12.425 16.025L10.0062 13.6062C9.8 13.4 9.5375 13.2969 9.21875 13.2969C8.9 13.2969 8.6375 13.4 8.43125 13.6062C8.225 13.8125 8.12187 14.075 8.12187 14.3937C8.12187 14.7125 8.225 14.975 8.43125 15.1812L11.6375 18.3875C11.8625 18.6125 12.125 18.725 12.425 18.725C12.725 18.725 12.9875 18.6125 13.2125 18.3875L19.5688 12.0312C19.775 11.825 19.8781 11.5625 19.8781 11.2437C19.8781 10.925 19.775 10.6625 19.5688 10.4562C19.3625 10.25 19.1 10.1469 18.7812 10.1469C18.4625 10.1469 18.2 10.25 17.9937 10.4562L12.425 16.025ZM14 25.25C12.4437 25.25 10.9812 24.9547 9.6125 24.3641C8.24375 23.7734 7.05312 22.9719 6.04062 21.9594C5.02813 20.9469 4.22656 19.7563 3.63594 18.3875C3.04531 17.0187 2.75 15.5562 2.75 14C2.75 12.4437 3.04531 10.9812 3.63594 9.6125C4.22656 8.24375 5.02813 7.05312 6.04062 6.04062C7.05312 5.02813 8.24375 4.22656 9.6125 3.63594C10.9812 3.04531 12.4437 2.75 14 2.75C15.5562 2.75 17.0187 3.04531 18.3875 3.63594C19.7563 4.22656 20.9469 5.02813 21.9594 6.04062C22.9719 7.05312 23.7734 8.24375 24.3641 9.6125C24.9547 10.9812 25.25 12.4437 25.25 14C25.25 15.5562 24.9547 17.0187 24.3641 18.3875C23.7734 19.7563 22.9719 20.9469 21.9594 21.9594C20.9469 22.9719 19.7563 23.7734 18.3875 24.3641C17.0187 24.9547 15.5562 25.25 14 25.25Z" fill="#08FFA7"/>
</svg>
  )
}

export default FileUploadSuccess