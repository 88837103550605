import React from 'react'

const FileUploadInfo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
  <path d="M5.95966 8.125C6.07544 8.125 6.1717 8.08607 6.24844 8.0082C6.32517 7.93034 6.36354 7.83385 6.36354 7.71875V5.28125C6.36354 5.16615 6.32438 5.06966 6.24607 4.9918C6.16774 4.91393 6.07069 4.875 5.95492 4.875C5.83914 4.875 5.74288 4.91393 5.66615 4.9918C5.58941 5.06966 5.55104 5.16615 5.55104 5.28125V7.71875C5.55104 7.83385 5.5902 7.93034 5.66852 8.0082C5.74684 8.08607 5.84389 8.125 5.95966 8.125ZM5.91642 3.87292C6.04297 3.87292 6.14913 3.83139 6.2349 3.74833C6.32066 3.66528 6.36354 3.56236 6.36354 3.43958C6.36354 3.30913 6.32074 3.19978 6.23514 3.11154C6.14953 3.02329 6.04345 2.97917 5.91691 2.97917C5.79036 2.97917 5.6842 3.02329 5.59844 3.11154C5.51267 3.19978 5.46979 3.30913 5.46979 3.43958C5.46979 3.56236 5.51259 3.66528 5.59819 3.74833C5.6838 3.83139 5.78988 3.87292 5.91642 3.87292ZM5.92027 10.8333C5.17336 10.8333 4.47146 10.6911 3.81454 10.4068C3.15762 10.1224 2.58316 9.7342 2.09115 9.24219C1.59913 8.75017 1.21094 8.17537 0.926563 7.51778C0.642188 6.8602 0.5 6.15757 0.5 5.4099C0.5 4.66222 0.642188 3.9596 0.926563 3.30201C1.21094 2.64442 1.59913 2.07188 2.09115 1.58438C2.58316 1.09688 3.15796 0.710938 3.81556 0.426563C4.47314 0.142188 5.17577 0 5.92344 0C6.67111 0 7.37374 0.142188 8.03132 0.426563C8.68891 0.710938 9.26146 1.09688 9.74896 1.58438C10.2365 2.07188 10.6224 2.64514 10.9068 3.30417C11.1911 3.96319 11.3333 4.66616 11.3333 5.41306C11.3333 6.15997 11.1911 6.86188 10.9068 7.51879C10.6224 8.17571 10.2365 8.74934 9.74896 9.2397C9.26146 9.73005 8.6882 10.1182 8.02917 10.4043C7.37014 10.6903 6.66717 10.8333 5.92027 10.8333Z" fill="#808080" fill-opacity="0.71"/>
</svg>
  )
}

export default FileUploadInfo