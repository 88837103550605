export const pages_langsheet = {
  Engineer: {
    en: "Engineer",
    es: "Ingeniero",
    pr: "Engenheiro",
  },
  Lawyer: {
    en: "Lawyer",
    es: "Abogado",
    pr: "Advogado",
  },
  Architect: {
    en: "Architect",
    es: "Arquitecto",
    pr: "Arquiteto",
  },
  Accountant: {
    en: "Accountant",
    es: "Contador",
    pr: "Contador",
  },
  Doctor: {
    en: "Doctor",
    es: "Médico",
    pr: "Médico",
  },
  UserDeleted: {
    en: "User Account Deleted Successfully",
    es: "Cuenta de usuario eliminada con éxito",
    pr: "Conta de usuário excluída com sucesso",
  },
  ProfessionalDeleted: {
    en: "Professional Account Deleted Successfully",
    es: "Cuenta del profesional eliminada con éxito",
    pr: "Conta profissional excluída com sucesso",
  },
  ProfessionalDeactivated: {
    en: "Professional Account Deactivated Successfully",
    es: "Cuenta del profesional desactivada con éxito",
    pr: "Conta profissional desativada com sucesso",
  },
  UserDeactivated: {
    en: "User Account Deactivated Successfully",
    es: "Cuenta de usuario desactivada con éxito",
    pr: "Conta de usuário desativada com sucesso",
  },
  CancelSubscription: {
    en: "CANCEL SUBSCRIPTION",
    es: "CANCELAR SUSCRIPCIÓN",
    pr: "CANCELAR ASSINATURA",
  },
  DoYouWantToCancelSubscription: {
    en: "Do You Really Want To Cancel Subscription",
    es: "¿Realmente desea cancelar la suscripción?",
    pr: "Você realmente deseja cancelar a assinatura?",
  },
  invalidCoupon: {
    en: "Invalid coupon code",
    es: "Código de cupón inválido",
    pr: "Código de cupom inválido",
  },
  couponApplied: {
    en: "coupon applied",
    es: "Cupón aplicado",
    pr: "Cupom aplicado",
  },
  cannotBasicPlan: {
    en: "Cannot Activate Basic Plan Again",
    es: "No se puede activar un plan básico nuevamente",
    pr: "Não se pode ativar um plano básico novo",
  },
  validFirstName: {
    en: "First name is required",
    es: "Se requiere el primer nombre",
    pr: "O primeiro nome é necessário",
  },
  validLastName: {
    en: "Last name is required",
    es: "Se requiere apellido",
    pr: "O sobrenome é obrigatório",
  },
  validGender: {
    en: "Gender is required",
    es: "Se requiere género",
    pr: "O gênero é obrigatório",
  },
  validLanguage: {
    en: "Language is required",
    es: "Se requiere idioma",
    pr: "O idioma é obrigatório",
  },
  validCountry: {
    en: "Country is required",
    es: "Se requiere país",
    pr: "O país é obrigatório",
  },
  validCity: {
    en: "City is required",
    es: "Se requiere ciudad",
    pr: "A cidade é obrigatória",
  },
  validProfession: {
    en: "Profession is required",
    es: "Se requiere profesión",
    pr: "É necessária profissão",
  },
  validDNI: {
    en: "DNI is required",
    es: "Se requiere DNI",
    pr: "DNI é obrigatório",
  },
  validShortDescription: {
    en: "Short Description is required",
    es: "Se requiere una breve descripción",
    pr: "Uma breve descrição é obrigatória",
  },
  validDescription: {
    en: "Description is required",
    es: "Se requiere descripción",
    pr: "A descrição é obrigatória",
  },
  enterValidDob: {
    en: "Select a valid DOB",
    es: "Selecciona una fecha de nacimiento válida",
    pr: "Selecione uma data de nascimento válida",
  },
  minValidAge:{
    en: "Age should be 18 years or above",
    es: "La edad debe ser 18 años o más.",
    pr: "A idade deve ser 18 anos ou mais",
  },
  pleaseMakeAPayment: {
    en: "PLEASE MAKE A PAYMENT",
    es: "Por favor, realiza el pago",
    pr: "Por favor, faça o pagamento",
  },
  StandardSubscription: {
    en: "Standard Subscription",
    es: "Suscripción estándar",
    pr: "Assinatura padrão",
  },
  PremiumSubscription: {
    en: "Premium Subscription",
    es: "Suscripción Premium",
    pr: "Assinatura Premium",
  },
  BasicSubscription: {
    en: "Basic Subscription",
    es: "Suscripción básica",
    pr: "Assinatura básica",
  },
  Yearly: {
    en: "Yearly",
    es: "Anualmente",
    pr: "Anualmente",
  },
  Monthly: {
    en: "Monthly",
    es: "Mensualmente",
    pr: "Por mês",
  },
  year: {
    en: "Year",
    es: "Año",
    pr: "Ano",
  },
  month: {
    en: "Month",
    es: "Mes",
    pr: "Mês",
  },
  Basic: {
    en: "Basic",
    es: "Básico",
    pr: "Básica",
  },
  Standard: {
    en: "Standard",
    es: "estándar",
    pr: "padrão",
  },
  Premium: {
    en: "Premium",
    es: "Premium",
    pr: "Premium",
  },
  DOB: {
    en: "DOB",
    es: "Fecha nacimiento",
    pr: "Data de nascimento",
  },
  pleaseSelectDaysAndTime: {
    en: "Please select days and time you are available for meetings",
    es: "Por favor selecciona días y horas en las que estás disponible para las reuniones",
    pr: "Por favor, selecione os dias e horários em que você está disponível para reuniões",
  },
  theLengthOfDNICantBeError: {
    en: "The length of DNI cannot be more than 25 characters",
    es: "El largo del DNI no puede ser mayor a 25 caracteres",
    pr: "O comprimento da sua identificação não pode ser superior a 25 caracteres",
  },
  continue: {
    en: "Continue",
    es: "Continuar",
    pr: "Continuar",
  },
  theMeetingHasStarted: {
    en: "The meeting has started. Please join the meeting",
    es: "La reunión ha comenzado. Por favor únete a la reunión.",
    pr: "A reunião começou. Por favor, participe da reunião",
  },
  EnterCorrectOTP: {
    en: "Enter Correct OTP",
    es: "translation pending",
    pr: "Digite a senha unica correta",
  },
  OTPfieldCantBeEmpty: {
    en: "OTP field can't be empty",
    es: "translation pending",
    pr: "O campo senha unica não pode estar vazio",
  },
  OTPIsAlreadySent: {
    en: "OTP Is Already Sent",
    es: "translation pending",
    pr: "Senha única já foi enviada",
  },
  OTPSentSuccessfully: {
    en: "OTP Sent Successfully",
    es: "translation pending",
    pr: "Senha única enviada com sucesso",
  },
  WaitForSomeTime: {
    en: "Wait For Some Time",
    es: "Espera un momento",
    pr: "Espere um momento",
  },
  invalidDni: {
    en: "Invalid DNI",
    es: "DNI inválido",
    pr: "Identificação"
  },
  scheduleShouldBeCorrect: {
    en: "Schedule should be set correctly",
    es: "Calendario debe ajustarse correctamente",
    pr: "A programação deve ser definida corretamente"
  },
  allFieldsMandatory: {
    en: "* fields are mandatory",
    es: "Campos son obligatorios",
    pr: "* Os campos são de preenchimento obrigatório"
  },
  reActivateAccount: {
    en: "Re-Activate Account",
    es: "translation pending",
    pr: "translation pending"
  },
  selectCity: {
    en: "Please Select a city",
    es: "Por favor seleccione ciudad",
    pr: "Selecione a cidade"
  },
  fileUpload: {
    en: "File Upload",
    es: "Subir archivo",
    pr: "Upload de arquivo"
  },
  professionalDegree: {
    en: "PROFESSIONAL DEGREE",
    es: "TITULO PROFESIONAL",
    pr: "GRAU PROFISSIONAL"
  },
  professionalDegree: {
    en: "PROFESSIONAL DEGREE",
    es: "TITULO PROFESIONAL",
    pr: "GRAU PROFISSIONAL"
  },
};
