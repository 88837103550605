import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import { bgColor, primeBlue } from "../env";
import {
  PayPalScriptProvider,
  PayPalHostedFieldsProvider,
  PayPalHostedField,
  usePayPalHostedFields,
} from "@paypal/react-paypal-js";
import { useToaster } from "rsuite";
import { UserContext } from "../context/userContext";
import MessageUserExpired from '../Components/MessageUserExpired'
import languageSheet from "../language";
import { LanguageContext } from "../context/languageContext";

const apiHost = process.env.REACT_APP_BACKEND_URL;

const clientID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const INVALID_COLOR = {
  color: "#dc3545",
};

const SubmitPayment = () => {
  // Here declare the variable containing the hostedField instance
  const [paying, setPaying] = useState(false);
  const cardHolderName = useRef(null);
  const hostedField = usePayPalHostedFields();
  const { lang, setLang } = useContext(LanguageContext);
  const handleClick = () => {
    if (!hostedField?.cardFields) {
      const childErrorMessage =
        "Unable to find any child components in the <PayPalHostedFieldsProvider />";
      throw new Error(childErrorMessage);
    }
    const isFormInvalid =
      Object.values(hostedField.cardFields.getState().fields).some(
        (field) => !field.isValid
      ) || !cardHolderName?.current?.value;

    if (isFormInvalid) {
      return alert("The payment form is invalid");
    }
    setPaying(true);
    hostedField.cardFields
      .submit({
        cardholderName: cardHolderName?.current?.value,
      })
      .then((data) => {
        // Your logic to capture the transaction
        fetch(apiHost + "payment-done", {
          method: "post",
        })
          .then((response) => response.json())
          .then((data) => {
            // Here use the captured info
          })
          .catch((err) => {
            // Here handle error
          })
          .finally(() => {
            setPaying(false);
          });
      })
      .catch((err) => {
        // Here handle error
        setPaying(false);
      });
  };

  return (
    <>
      <label title="This represents the full name as shown in the card">
        {/* Card Holder Name */}
        {languageSheet[102][lang].split(',')[0]}
        <input
          id="card-holder"
          ref={cardHolderName}
          className="card-field"
          type="text"
          placeholder="Full name"
        />
      </label>
      <button
        className={`btn${paying ? "" : " btn-primary"}`}
        style={{ float: "right" }}
        onClick={handleClick}
      >
        {paying ? <div className="spinner tiny" /> : "Pay"}
      </button>
    </>
  );
};
export default function Payment({ proNew, subPlan, user }) {

  const toaster = useToaster();
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext)
  const { lang, setLang } = useContext(LanguageContext);
  const payWithPaypal = () => {

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
    myHeaders.append("Content-Type", "application/json");
    fetch(apiHost + "api/payment", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        subscription_id: subPlan.id,
        professional_id: proNew.id,
        amount: subPlan.annual_price,
      }),
    })
      .then((res) => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        if (res.link) {
          window.open(res.link, "_self");
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      })
  };

  const [checkCard, setCheckCard] = useState(true);
  const [clientToken, setClientToken] = useState(null);
  useEffect(() => {
    (async () => {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);
      myHeaders.append("Content-Type", "application/json");
      const response = await (
        await fetch(apiHost + "api/payment-client-token", {
          method: "GET",
          headers: myHeaders,
        })
      ).json();
      setClientToken(response?.client_token || response?.clientToken);
    })();
  }, []);

  return (
    <div style={{ backgroundColor: bgColor }}>
      <div className="container my-5">
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {/* SELECT PAYMENT METHOD */}
          {languageSheet[102][lang].split(',')[1]}
        </h4>

        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-lg-7 mt-4  ">
            <div className="">
              <div
                className="d-flex row flex-column boxShadow2 rounded mx-auto"
                style={{
                  backgroundColor: "white",
                  minHeight: "35rem",
                  width: "100%",
                }}
              >
                <div className="mb-0 mt-4 container ps-4">
                  <div>
                    <input
                      type="radio"
                      name="pay_method"
                      value="card"
                      id="card"
                      checked={checkCard}
                      className="col"
                      data-toggle="collapse"
                      data-target=".collapseOne.show"
                      onChange={(e) => setCheckCard(e.target.value)}
                    />
                    <label htmlFor="card" class="label ms-3">
                      {/* CREDIT / DEBIT CARD */}
                      {languageSheet[102][lang].split(',')[2]}
                    </label>
                    <div
                      className={`d-${!checkCard && "none"
                        } row mt-3 collapseOne panel-collapse subtitle`}
                    >
                      {clientToken ? (
                        <PayPalScriptProvider
                          options={{
                            "client-id": clientID,
                            components: "buttons,hosted-fields",
                            "data-client-token": clientToken,
                            intent: "capture",
                            vault: false,
                          }}
                        >
                          <PayPalHostedFieldsProvider
                            createOrder={async function () {
                              try {
                                let myHeaders = new Headers();
                                myHeaders.append(
                                  "Authorization",
                                  `Bearer ${user.tokens.access}`
                                );
                                myHeaders.append(
                                  "Content-Type",
                                  "application/json"
                                );
                                const response = await fetch(
                                  apiHost + "api/payment",
                                  {
                                    method: "POST",
                                    headers: myHeaders,
                                    body: JSON.stringify({
                                      subscription_id: subPlan.id,
                                      professional_id: proNew.id,
                                      amount: subPlan.annual_price,
                                    }),
                                  }
                                );
                                const order = await response.json();
                                return order.id;
                              } catch (err) {
                                alert(err);
                              }
                            }}
                            styles={{
                              ".valid": { color: "#28a745" },
                              ".invalid": { color: "#dc3545" },
                            }}
                          >
                            <label htmlFor="card-number">
                              {/* Card number */}
                              {languageSheet[103][lang].split(',')[0]}
                              <span style={INVALID_COLOR}>*</span>
                            </label>
                            <PayPalHostedField
                              id="card-number"
                              className="card-field col-md-5 col-9 my-2 p-2"
                              hostedFieldType="number"
                              style={{
                                borderColor: "#4F4F4F",
                                borderRadius: "4px",
                              }}
                              options={{
                                selector: "#card-number",
                                placeholder: "1234 **** **** 1235",
                              }}
                            />


                            <div className="row align-items-end">
                              <div className="col">
                                <label htmlFor="expiration-date">
                                  {/* Expiration Date */}
                                  {languageSheet[103][lang].split(',')[1]}
                                  <span style={INVALID_COLOR}>*</span>
                                </label>
                                {/* <br /> */}
                                <PayPalHostedField
                                  id="expiration-date"
                                  className="card-field col-md-10 my-2 p-2"
                                  hostedFieldType="expirationDate"
                                  options={{
                                    selector: "#expiration-date",
                                    placeholder: "MM/YYYY",
                                  }}
                                  style={{
                                    borderColor: "#4F4F4F",
                                    borderRadius: "4px",
                                  }}
                                />
                              </div>
                              <div className="col">
                                <label htmlFor="cvv">
                                  {/* Security code */}
                                  {languageSheet[103][lang].split(',')[2]}
                                  <span style={INVALID_COLOR}>*</span>
                                </label>
                                {/* <br /> */}
                                <PayPalHostedField
                                  id="cvv"
                                  placeholder="***"
                                  className="card-field col-5 my-2 p-2"
                                  hostedFieldType="cvv"
                                  style={{
                                    borderColor: "#4F4F4F",
                                    borderRadius: "4px",
                                  }}
                                  options={{
                                    selector: "#cvv",
                                    placeholder: "123",
                                    maskInput: true,
                                  }}
                                />
                              </div>
                            </div>
                            <SubmitPayment />
                          </PayPalHostedFieldsProvider>
                        </PayPalScriptProvider>
                      ) : (
                        <h1>
                          {/* Loading... */}
                          {languageSheet[104][lang].split(',')[0]}...
                        </h1>
                      )}
                    </div>
                  </div>

                  <div className="mt-5">
                    <input
                      type="radio"
                      name="pay_method"
                      value="paypal"
                      id="paypal"
                      checked={!checkCard}
                      className="col"
                      onChange={(e) => setCheckCard(!e.target.value)}
                      style={{ borderColor: "#4F4F4F", borderRadius: "4px" }}
                    />
                    <label htmlFor="paypal" class="label ms-3">
                      PAYPAL
                    </label>
                    <div
                      className={`d-${checkCard && "none"} ms-4 mt-2 subtitle`}
                    >
                      {/* Click “Place order” and we will process you to PayPal page */}
                      {languageSheet[105][lang]}
                    </div>
                  </div>
                </div>
                <div className="callBtn mb-5 container">
                  <button
                    className="w-50 primaryButton2 py-2 mt-3"
                    onClick={() => {
                      payWithPaypal();
                    }}
                  >
                    {/* PAY NOW */}
                    {languageSheet[104][lang].split(',')[1]}
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5  mt-4 ">
            <div
              className="d-flex flex-column boxShadow2 mb-4 rounded"
              style={{ backgroundColor: "white", minHeight: "22rem" }}
            >
              <h4 className="ms-3 mt-3 subtitle5" style={{ color: primeBlue }}>
                YOUR ORDER SUMMARY
              </h4>
              <div className="features container">
                <div className="subtitle row feature my-3 border-bottom border-2 border-secondary pb-4">
                  <div className="col text-start">
                    {/* Subscription */}
                    {subPlan.name} {languageSheet[104][lang].split(',')[1]}
                  </div>
                  <div className="col text-end fw-bold">
                    ${subPlan.annual_price}
                  </div>
                </div>
                <div>
                  <div
                    className="subtitle coupon_text"
                    style={{ color: "#6B8EA6" }}
                  >
                    {/* Apply Coupon Code */}
                    {languageSheet[106][lang]}
                  </div>
                </div>
                <div className="subtitle mt-4">
                  <div className="row">
                    <div className="col text-start">
                      {/* Subtotal: */}
                      {languageSheet[107][lang].split(',')[0]}:
                    </div>
                    <div className="col text-end fw-bold">$300</div>
                  </div>
                  <div className="row">
                    <div className="col text-start">
                      {/* Tax: */}
                      {languageSheet[107][lang].split(',')[1]}:
                    </div>
                    <div className="col text-end fw-bold">$122</div>
                  </div>
                  <div className="row">
                    <div className="col text-start">
                      {/* Coupon Discount: */}
                      {languageSheet[107][lang].split(',')[2]}:
                    </div>
                    <div className="col text-end fw-bold">$0</div>
                  </div>
                  <div className="row mt-3" style={{ fontSize: "22px" }}>
                    <div className="col text-start fw-bold">
                      {/* Total: */}
                      {languageSheet[107][lang].split(',')[3]}:
                    </div>
                    <div className="col text-end fw-bold">$422</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

Payment.defaultProps = {
  subPlan: {
    name: "",
    annual_price: "",
  },
  proNew: {
    id: "",
  },
};
