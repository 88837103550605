import React, { useEffect, useState, useContext } from "react";
import { DialogWithOptions, RescheduleAppointment } from "./DialogPop";
import { useToaster } from "rsuite";
import MessageUserExpired from "./MessageUserExpired";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/userContext";
import languageSheet from "../language";
import { LanguageContext } from "../context/languageContext";
import { components_langsheet } from "./Language/ComponentsLangsheet";
import { AppointmentCard } from "./AppointmentTableCard/AppointmentCard";
import useMediaQuery from '@mui/material/useMediaQuery';

const backendURL = process.env.REACT_APP_BACKEND_URL;
export default function AppointmentsTable({
  appointments,
  user,
  toggle,
  setToggle,
  isproff,
  setAppointments,
  getAppointments,
}) {
  const toaster = useToaster();
  const { lang, setLang } = useContext(LanguageContext);
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const [appointmentID, setAppointmentID] = useState(null);
  const [rescheduleID, setRescheduleID] = useState(null);
  const [rescheduleAppointmentID, setRescheduleAppointmentID] = useState(null);
  const [reschedule, setReschedule] = useState(null);
  const matches = useMediaQuery('(max-width:500px)');
  const handleClosePopup = (val) => {
    if (val == 1) {
      setOpenPopup(false);
      cancelAppointment(appointmentID);
    } else if (val == -1) {
      setOpenPopup(false);
    }
  };
  const handleClosePopup2 = (val) => {
    setOpenPopup2(false);
    setToggle(!toggle)
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const cancelAppointment = (id) => {
    fetch(`${backendURL}api/appointment-cancel/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setToggle(!toggle);
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };

  const onClickReschedule = (appointment) => {
    if (appointment?.meeting_status == "confirmed") {
      setOpenPopup2(true)
      setRescheduleID(appointment?.professional?.[0]?.id)
      setReschedule(appointment)
      setRescheduleAppointmentID(appointment?.id)
    }
  }

  return (
    <div
      className={isproff ? "col-lg-8 mt-4 table-responsive" : "mt-4 table-responsive table-user"}
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 25px rgba(181, 181, 181, 0.15)",
        borderRadius: " 4px",
        // minHeight: '280px'
      }}
    >
      {!matches ? <table class="table table-borderless">
        <thead className="tableHead border-0">
          <tr>
            <td scope="col">NO.</td>
            {/* <td scope="col">Name</td> */}
            <td scope="col">{languageSheet[57][lang]}</td>
            {!isproff && (
              // <td scope="col">PROFESSION</td>
              <td scope="col">{languageSheet[60][lang]}</td>
            )}
            {/* <td scope="col">DATE</td> */}
            <td scope="col">{languageSheet[119][lang].split(",")[0]}</td>
            {/* <td scope="col">TIME</td> */}
            <td scope="col">{languageSheet[119][lang].split(",")[1]}</td>
            {/* <td scope="col">STATUS</td> */}
            <td scope="col">{languageSheet[119][lang].split(",")[2]}</td>
            {!isproff && (
              // <td scope="col">PROFESSION</td>
              <td scope="col">{components_langsheet?.reschedule?.[lang]}</td>
            )}
          </tr>
        </thead>
        <tbody className="border-top-0">

          {appointments ? (
            appointments.map((appointment, index) => {
              return (
                <tr key={index}>
                  <td scope="row">{index + 1}</td>
                  <td>
                    {isproff
                      ? appointment.user.username
                      : appointment.professional[0].name}
                  </td>
                  {!isproff && (
                    <td>{components_langsheet[appointment.professional[0].profession][lang]}</td>
                  )}
                  <td>{appointment.date.split("-")[2] + "-" + appointment.date.split("-")[1] + "-" + appointment.date.split("-")[0]}</td>
                  <td>{appointment.start_time.substring(0, 5)}</td>
                  <td>
                    {appointment.meeting_status == "Confirmed" ? (
                      <button
                        className="statusBtnCompleted"
                        onClick={() => {
                          if (user) {
                            setOpenPopup(true);
                            setAppointmentID(appointment.id);
                          }
                        }}
                      >
                        {/* Cancel */}
                        {languageSheet[35][lang].split(",")[1]}
                      </button>
                    ) : (
                      <button className="statusBtnCompleted disabled">
                        {components_langsheet[appointment.meeting_status][lang]}
                      </button>
                    )}
                  </td>
                  {!isproff && <td>
                    <button
                      className={appointment?.meeting_status == "confirmed" ? "statusBtnCompleted" : "statusBtnCompleted disabled"}
                      onClick={() => {
                        if (appointment?.meeting_status == "confirmed") {
                          setOpenPopup2(true)
                          setRescheduleID(appointment?.professional?.[0]?.id)
                          setReschedule(appointment)
                          setRescheduleAppointmentID(appointment?.id)
                        }
                      }}
                    >
                      {components_langsheet?.reschedule?.[lang]}
                    </button>
                  </td>}
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={"100%"}
                style={{ textAlign: "center", padding: "20px", fontSize: 20 }}
              >
                {/* No Appointment */}
                {languageSheet[120][lang]}
              </td>
            </tr>
          )}
        </tbody>
      </table> :
        <div className="d-flex flex-column gap-3 mb-3" style={{ height: 'auto', width: '100%' }}>
          {appointments ? (
            appointments.map((appointment, index) => {
              return (
                <AppointmentCard
                  key={index}
                  index={index}
                  appointment={appointment}
                  isproff={isproff}
                  setAppointmentID={setAppointmentID}
                  setOpenPopup={setOpenPopup}
                  user={user}
                  onClickReschedule={onClickReschedule}
                />
              );
            })
          ) : (
            <tr>
              <td
                colSpan={"100%"}
                style={{ textAlign: "center", padding: "20px", fontSize: 20 }}
              >
                {/* No Appointment */}
                {languageSheet[120][lang]}
              </td>
            </tr>
          )}
        </div>}
      <DialogWithOptions
        open={openPopup}
        onClose={handleClosePopup}
        title={languageSheet[121][lang]} // "CANCEL THE APPOINTMENT?"
        image="./assets/shieldFailed.png"
        btnFirst={languageSheet[36][lang].split(",")[1]} //"Confirm"
        btnSecond={languageSheet[35][lang].split(",")[1]} //"Cancel"
      />
      <RescheduleAppointment
        open={openPopup2}
        handleClose={handleClosePopup2}
        id={rescheduleID}
        appointment={reschedule}
        rescheduleAppointmentID={rescheduleAppointmentID}
        user={user}
        setToggle={setToggle}
        toggle={toggle}
        getAppointments={getAppointments}
      />
    </div>
  );
}
