import {useEffect} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import { bgColor, textSecondary } from "../../env";
import { useToaster } from "rsuite";
import { useContext } from "react";
import { UserContext } from "../../context/userContext";
import MessageUserExpired from "../../Components/MessageUserExpired";
import { LanguageContext } from '../../context/languageContext'
import languageSheet from '../../language'
const ariaLabel = { "aria-label": "description" };


const steps = ["Inprocess", "Certified"];

const apiHost = process.env.REACT_APP_BACKEND_URL;

export default function PaymentFailed({user}) {

  const toaster = useToaster();
  const navigation = useNavigate();
  const {setUser} = useContext(UserContext)
  const [searchParams, setSearchParams] = useSearchParams();
  const { lang, setLang } = useContext(LanguageContext)

  useEffect(()=>{
    
    let myHeaders = new Headers()
    myHeaders.append("Authorization",`Bearer ${user.tokens.access}`)
    myHeaders.append("Content-Type", "application/json");   

    fetch(apiHost + 'api/payment-cancel',{
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
         id:searchParams.get('token')
      })
    })
    .then(res => {
          
          if(res.status == 401){
            const error = new Error('unauth')
            error.code = 401
            throw error
          }else{
            return res.json()
          }
        })
    .catch((err)=>{
      if(err.code == 401){
        sessionStorage.removeItem("otherInfo");
        sessionStorage.removeItem("professional");
        navigation("/");
        toaster.push(MessageUserExpired,{placement:'bottomEnd'})
        setUser(null);
      }
    })
  },[])

  return (
    <div style={{ backgroundColor: bgColor }}>
      <div className="container my-5 d-flex flex-column">
        <div className="paymentAfter row boxShadow d-flex align-content-center mx-3 rounded" style={{minHeight: "40rem"}}>
          <div className="text-center">
            <img src="./assets/paymentFailed.svg" alt="payment Failed" />
            <div className="littleHead" style={{ fontSize: "24px" }}>
              {/* PAYMENT FAILED */}
              {languageSheet[114][lang].split(',')[0]}
            </div>
            <div className="mt-3" style={{ color: textSecondary }}>
              {/* Try another payment method or{" "} */}
              {languageSheet[114][lang].split(',')[1]} {" "}
              <span>
                <a href="" style={{ color: textSecondary }}>
                  {/* go to help section */}
                  {languageSheet[114][lang].split(',')[2]}
                </a>
              </span>
            </div>
            <button className="primaryButton py-3 mt-3"
            onClick={(e) => {navigation('/userLandingPage')}}>
              {/* BACK TO DASHBOARD */}
              {languageSheet[114][lang].split(',')[3]}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
