import { useContext } from "react";
import Footer from "../Components/Footer";
import { bgColor, primeBlue, textBlue } from "../env";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import SetIcon from "../Components/NotificationTypes";
import { useToaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/userContext";
import languageSheet from "../language";
import { LanguageContext } from "../context/languageContext";
import MessageUserExpired from "../Components/MessageUserExpired";
import MarkAsReadIcon from '../Components/Icons/MarkAsReadIcon'
const backendURL = process.env.REACT_APP_BACKEND_URL;

export default function Notifications({ user, notifications, setNotifications }) {
  const toaster = useToaster();
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext)
  const { lang, setLang } = useContext(LanguageContext);

  const markRead = (user, notification) => {
    if (notification.is_read === false) {
      fetch(`${backendURL}auth/notifications`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.tokens.access,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: notification.id,
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            updateState(notification.id);
          } else if (res.status == 401) {
            const error = new Error('unauth')
            error.code = 401
            throw error
          }
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem("otherInfo");
            sessionStorage.removeItem("professional");
            navigation("/");
            toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
            setUser(null);
          }
        })
    } else {
      updateState(notification.id);
    }
  };

  const markAllRead = (user, notifications) => {
    notifications.map((notification) => {
      if (notification.is_read === false) markRead(user, notification);
    });
  };

  const updateState = (id) => {
    setNotifications((current) =>
      current.map((obj) => {
        if (obj.id == id) {
          return { ...obj, is_read: true };
        }
        return obj;
      })
    );
  };

  return (
    <div>
      <div style={{ backgroundColor: bgColor, minHeight: "84vh" }}>
        <div className="container my-5">
          <h4
            className="text-uppercase sliderHead d-inline"
            style={{ color: primeBlue }}
          >
            {/* notifications */}
            {languageSheet[38][lang].split(',')[0]}
          </h4>
          <button
            className="float-right d-inline align-self-center rounded"
            style={{ float: "right", verticalAlign: "middle", color: textBlue }}
            onClick={() => markAllRead(user, notifications)}
          >
          <MarkAsReadIcon/>
            <p className="d-inline mx-2 label">
              {/* Mark as read */}
              {languageSheet[95][lang]}
            </p>
          </button>

          {/* notifications */}
          <div className="notifBox mt-5">
            {notifications
              .map((notification) => {
                return (
                  <div
                    className={`notice px-4 py-3 d-flex align-items-center ${notification.is_read ? "" : "noticeActive"
                      }`}
                    key={notification.id}
                    onClick={() => markRead(user, notification)}
                    role="button"
                  >
                    {<SetIcon iconName={notification.notification_type} />}
                    <div className="mainText d-inline-block mx-3">
                      <p className="main textSecondary my-0">
                        <span
                          className="subtitleBold"
                          style={{ color: textBlue }}
                        >
                          {notification.notification_title}
                        </span>
                      </p>
                      <p
                        className="main textSecondary my-0"
                        style={{ fontSize: "12px" }}
                      >
                        {notification.message}
                      </p>
                    </div>

                    <div
                      className="time ml-auto labelSecondary"
                      style={{
                        marginLeft: "auto",
                        color: textBlue,
                        textTransform: "inherit",
                      }}
                    >
                      {formatDistanceToNowStrict(
                        parseISO(notification.date),
                        "MMM dd, yyyy"
                      ) + " ago"}
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
