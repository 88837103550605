import { useContext, useState, useEffect, lazy, Suspense, startTransition } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import './LandingPageUser.css'
import { LanguageContext } from '../../../context/languageContext'
import languageSheet from '../../../language'
import { bgColor, whiteBg } from "../../../env";
import { useToaster, Message } from "rsuite";
import Loading from '../../../Components/Loading'
import { Cards, HowItWork, Footers, UserLandingPage } from '../../../Components/Loading'
import { UserContext } from "../../../context/userContext";
import MessageUserExpired from "../../../Components/MessageUserExpired";
const Footer = lazy(() => import('../../../Components/Footer'))
const HeroSection = lazy(() => import('../../../Components/HeroSection'))
const WantToBeProfessional = lazy(() => import('../../../Components/WantToBeProfessional'))
const DisplaySwiperProfession = lazy(() => import('../../../Components/DisplaySwiper/DisplaySwiper').then(module => ({ default: module.DisplaySwiperProfession })));
const DisplaySwiperProfessional = lazy(() => import('../../../Components/DisplaySwiper/DisplaySwiper').then(module => ({ default: module.DisplaySwiperProfessional })));
const backendURL = process.env.REACT_APP_BACKEND_URL;

export default function LangingPageUser({ profile, user, setFP }) {
  const [recent_professionals, recent_setProfessionals] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const { lang, setLang } = useContext(LanguageContext)
  const [professions, setProfessions] = useState([]);
  const [loading, setLoading] = useState(false)
  const [loadingProfessional, setLoadingProfessional] = useState(false)
  const [loadingPopularProfessional, setLoadingPopularProfessional] = useState(false)
  const toaster = useToaster();
  const navigation = useNavigate();
  const { setUser } = useContext(UserContext)

  setTimeout(async()=>{//If the user has an incomplete profile then they will be redirected to the /editUserProfile page
    //This feature only works if the user does not have a username or a default language.
    let userProfile = await profile
    if(userProfile){
    if( (profile.user.first_name==='' || profile.user.last_name==='' || profile.user.country ==='') ){
      navigation("/editUserProfile");
            toaster.push(<Message type="error" showIcon>Please enter the details to proceed.</Message>, { placement: 'topEnd' })
          }
        }
  },500)
  
  const fetchData = async () => {

    setLoading(true);
    setLoadingProfessional(true)
    setLoadingPopularProfessional(true)



    fetch(`${backendURL}api/profession`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          
          return res.json()
        }
      })
      .then((res) => {

        setLoading(false);
        setProfessions([...res.results]);
        setFP(false)
      }).catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      });
    fetch(`${backendURL}api/last-viewed-professionals`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      }
      )
      .then((res) => {
        setLoadingProfessional(false)
        if (res.results.length == 0) {
        } else {
          recent_setProfessionals([...res.results]);
        }
      }).catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      });

    fetch(`${backendURL}api/popular-professionals`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
      },
    })
      .then((res) => {

        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        setLoadingPopularProfessional(false)
        setProfessionals([...res.results]);
      }).catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null);
        }
      });

  };

  useEffect(() => {

    
    
    fetchData();

  }, []);

  return (
    <div className="user-body d-flex flex-column justify-content-between" >
      {/* <SwiperLanding/> */}
      <Suspense fallback={<UserLandingPage />}>
        <div>
          <HeroSection name={user?.name} />

          <Suspense fallback={<Cards />}>
            <DisplaySwiperProfession
              color={bgColor}
              heading={languageSheet[12][lang]}   // professions
              viewLink={"/popularprofessionals"}
              data={professions}
              user={user}
              loading={loading}
            />
          </Suspense>

          {recent_professionals.length !== 0 && (
            <Suspense fallback={<Cards />}>
              <DisplaySwiperProfessional
                color={whiteBg}
                heading={languageSheet[90][lang]}  // Recently Viewed Professionals
                viewLink={"/recentprofessionals"}
                data={recent_professionals}
                user={user}
                loading={loadingProfessional}
              />
            </Suspense>
          )}


          <div>
            <Suspense fallback={<HowItWork />}>
              <WantToBeProfessional />
            </Suspense>
            {/* Most Popular */}

            <Suspense fallback={<Cards />}>
              <DisplaySwiperProfessional
                color={bgColor}
                heading={languageSheet[56][lang]}  // Popular Professionals
                viewLink={"/popularprofessionals"}
                data={professionals}
                user={user}
                loading={loadingPopularProfessional}
              />
            </Suspense>
          </div>
        </div>

        <Suspense fallback={<Footers />}>
          <Footer />
        </Suspense>
      </Suspense>
    </div>
  );
}
