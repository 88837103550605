import { useContext } from 'react'
import Footer from '../Components/Footer'
import { bgColor, primeBlue } from '../env'
import GridOfCards from '../Components/GridOfCards'
import { useLocation } from 'react-router-dom'
import languageSheet from '../language'
import { LanguageContext } from '../context/languageContext'

export default function Professions() {
  const location = useLocation()
  const { lang, setLang } = useContext(LanguageContext)
  return (
    <div>
      <div style={{ backgroundColor: bgColor }}>
        <div className="container my-5">
          <h4
            className="text-uppercase sliderHead"
            style={{ color: primeBlue }}
          >
            {languageSheet[12][lang]}
            {/* PROFESSIONS */}
            {location.user}
          </h4>

          <div className="row ">
            <div className="tabs d-flex my-3 mx-auto justify-content-center flex-wrap">
            </div>
          </div>
          <div className="mx-auto">
            <GridOfCards
              user={location.state.user}
              data={location.state.data}
              isProfession={true}
              isAllProfessional={false}
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
