import { useContext, useState, useEffect, lazy, Suspense } from "react";
import SwiperLanding from "../../Components/SwiperLanding";
import { bgColor } from "../../env";
import { LanguageContext } from '../../context/languageContext'
import languageSheet from '../../language'
import { Cards, HowItWork, Footers } from '../../Components/Loading'
import Loading from '../../Components/Loading'
const Footer = lazy(() => import('../../Components/Footer'))
const HowItWorks = lazy(() => import('../../Components/HowItWorks'))
const DisplaySwiperProfession = lazy(() => import('../../Components/DisplaySwiper/DisplaySwiper').then(module => ({ default: module.DisplaySwiperProfession })));
const DisplaySwiperProfessional = lazy(() => import('../../Components/DisplaySwiper/DisplaySwiper').then(module => ({ default: module.DisplaySwiperProfessional })));

const backendURL = process.env.REACT_APP_BACKEND_URL;

export default function LangingPage({ user }) {
  const [professions, setProfessions] = useState([]);
  const { lang, setLang } = useContext(LanguageContext)
  const [professionals, setProfessionals] = useState([]);
  const [loading, setLoading] = useState(false)
  const [loadingProfessional, setLoadingProfessional] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    setLoadingProfessional(true)
    fetch(`${backendURL}api/profession`, {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setLoading(false)
        setProfessions([...res.results]);
      });

    fetch(`${backendURL}api/professional`, {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setLoadingProfessional(false)
        setProfessionals([...res.results]);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <SwiperLanding />

      <Suspense fallback={<Cards />}>
        <DisplaySwiperProfession
          color={bgColor}
          heading={languageSheet[12][lang]}   // professions
          viewLink={"/professions"}
          data={professions}
          user={user}
          loading={loading}
        />
      </Suspense>


      <Suspense fallback={<HowItWork />}>
        <HowItWorks />
      </Suspense>

      <Suspense fallback={<Cards />}>
        <DisplaySwiperProfessional
          color={bgColor}
          heading={languageSheet[7][lang]} // professionals
          viewLink={"/professionals"}
          data={professionals}
          user={user}
          loading={loadingProfessional}
        />
      </Suspense>

      <Suspense fallback={<Footers />}>
        <Footer />
      </Suspense>
    </div>
  );
}
