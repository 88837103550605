import { useEffect, useState, useContext } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Modal from '@mui/material/Modal'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import moment from 'moment'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Footer from '../../Components/Footer'
import { bgColor, primeBlue, textBlue, textSecondary } from '../../env'
import { useParams } from 'react-router-dom'
import { DialogSuccess, DialogWithOptions } from '../../Components/DialogPop'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { useToaster } from 'rsuite'
import profileNoImage from "../../assests/images/profileNoImage.png";
import MessageUserExpired from '../../Components/MessageUserExpired'
import { LanguageContext } from '../../context/languageContext'
import languageSheet from '../../language'
import { NavbarContext } from "../../context/navbarContext";
import { components_langsheet } from '../../Components/Language/ComponentsLangsheet'
import ReactGA from "react-ga4";
import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import GoogleIcon from '../../Components/Icons/GoogleIcon'
const apiHost = process.env.REACT_APP_BACKEND_URL
const ariaLabel = { 'aria-label': 'description' }


export default function ProfessionalProfilePage({
  user,
  setUser,
  setJoinEmail,
}) {
  const toaster = useToaster()
  const navigation = useNavigate()
  const { lang, setLang } = useContext(LanguageContext)
  const steps = [languageSheet[62][lang], languageSheet[63][lang]]
  const location = useLocation()
  const [readMoreDeiscription, setReadMoreDeiscription] = useState(false)
  const { favourites, setFavourites } = useContext(NavbarContext);
  const toggleReadMore = () => {
    setReadMoreDeiscription(!readMoreDeiscription)
  }

  const [openModal, setOpenModal] = useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  //social

  const [email, setEmail] = useState('')
  const [password, setPass] = useState('')
  const [rem, setRem] = useState(false)
  const [passV, setPv] = useState(true)
  const [isF, setFav] = useState(false)

  const login = () => {
    handleCloseModal()
    fetch(apiHost + 'auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res
        } else {
          var z = document.getElementById('loginErrorMsg')
          z.style.display = 'block'
          z.style.paddingTop = '20px'
          z.style.color = 'red'
        }
      })
      .then((res) => res.json())
      .then((res) => {
        setUser(res)
        if (rem) {
          localStorage.setItem('user', JSON.stringify(res))
        } else {
          sessionStorage.setItem('user', JSON.stringify(res))
        }
        navigation(location.pathname)
      })
      .catch((err) => console.log({ err }))
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      successGoogle(codeResponse)
    },
    onError: (errorResponse) => console.log(errorResponse),
  })

  const successGoogle = (response) => {
    handleCloseModal()
    fetch(apiHost + 'auth/google/', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        access_token: response.access_token,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res
        }
      })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        let newUser = {
          tokens: {
            access: res.access_token,
            refresh: res.refresh_token,
          },
          email: res.user.email,
          id: res.user.pk,
          name: res.user.first_name.concat(' ', res.user.last_name),
        }
        setUser(newUser)
        sessionStorage.setItem('user', JSON.stringify(newUser))
        fetch(apiHost + 'auth/update-social-user/' + res.user.pk)
          .then((ress) => ress.json())
          .then((ress) => {
            if (ress.new_user) {
              setJoinEmail(res.user.email)
              navigation('/createProfile', {
                state: { from: location.pathname },
              })
            } else {
              navigation(location.pathname)
            }
          })
          .catch((err) => console.log(err))
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem('otherInfo')
          sessionStorage.removeItem('professional')
          navigation('/')
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null)
        }
      })
  }

  const fbResponse = (response) => {
    fetch(apiHost + 'auth/facebook/', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({
        access_token: response.accessToken,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res
        }
      })
      .then((res) => {
        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        let newUser = {
          tokens: {
            access: res.access_token,
            refresh: res.refresh_token,
          },
          email: res.user.email,
        }
        setUser(newUser)
        sessionStorage.setItem('user', JSON.stringify(newUser))
        fetch(apiHost + 'auth/update-social-user/' + res.user.pk)
          .then((ress) => ress.json())
          .then((ress) => {
            if (ress.new_user) {
              setJoinEmail(res.user.email)

              navigation('/createProfile', {
                state: { from: location.state ? location.state.from : null },
              })
            } else {
              navigation('/userLandingPage')
            }
          })
          .catch((err) => console.log(err))
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem('otherInfo')
          sessionStorage.removeItem('professional')
          navigation('/')
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null)
        }
      })
  }

  // stepper funcs
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set())
  const [meetingsExhausted, setMeetingExhausted] = useState(false)

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);


  const isStepOptional = (step) => {
    return step === 1
  }

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const [proData, setProData] = useState(null)
  const [verified, setVerified] = useState(false)
  const { id } = useParams()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  useEffect(() => {
    let myHeaders = new Headers()

    if (user) {
      myHeaders.append('Authorization', `Bearer ${user.tokens.access}`)
    }
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    }

    fetch(apiHost + 'api/professional/' + id, requestOptions)
      .then((res) => {
        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((result) => {
        setActiveStep(result.is_profession_verified ? 1 : 0)
        setProData(result)
        setMeetingExhausted(result?.is_meetings_exhausted)
        if (user) {
          if (result.is_favourite) {
            setFav(true)
          }
        }

        if (result.is_profession_verified === true) {
          // setActiveStep(1);
          setVerified(true)
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem('otherInfo')
          sessionStorage.removeItem('professional')
          navigation('/')
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null)
        }
      })
  }, [id])

  const [schedule, setSchedule] = useState(null)
  const [daysUA, setDUA] = useState([])
  const [date, setDate] = useState(dayjs())
  const [slots, setSlots] = useState([])
  const [activeSlot, setActSlot] = useState(null)
  const [loader, setLoader] = useState(false)
  dayjs.locale(lang == 'pr' ? 'pt' : lang);


  useEffect(() => {

    let myHeaders = new Headers()
    if (user) {
      myHeaders.append('Authorization', `Bearer ${user.tokens.access}`)
    }
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    }

    fetch(
      apiHost +
      'api/schedule/' +
      id +
      `/?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      requestOptions,
    )
      .then((res) => {
        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then(async (result) => {
        delete result.id
        delete result.professional

        let tempDUA = []
        for (let key in result) {
          if (result[key].length == 0) {
            tempDUA.push(key)
          }
        }
        let bookedSlots = await getBookedAppointment(date)

        setDUA([...tempDUA])
        setSchedule(result)

        let currentDay = moment(date).format('dddd').toLowerCase()

        //removing bookedslots from result[currentDay] slots
        let finalSlots = result[currentDay]

        finalSlots = finalSlots.filter(function (el) {
          return !bookedSlots.includes(el)
        })
        //if currentDay == today removing slots which have already pased
        let today = new Date()
        if (
          date.$d.getDate() == today.getDate() &&
          date.$d.getMonth() == today.getMonth() &&
          date.$d.getFullYear() == today.getFullYear()
        ) {
          finalSlots = finalSlots.filter((slot) => {
            let slotTime = slot.split(':')
            let now = new Date()
            if (parseInt(slotTime[0]) <= now.getHours()) {
              if (
                parseInt(slotTime[1]) > now.getMinutes() &&
                now.getHours() == parseInt(slotTime[0])
              ) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          })
        }
        setSlots([...finalSlots])
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem('otherInfo')
          sessionStorage.removeItem('professional')
          navigation('/')
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null)
        }
      })
  }, [])

  const onChangeDate = async (newDate) => {
    setLoader(true)

    let bookedSlots = await getBookedAppointment(newDate)
    let currentDay = moment(newDate.$d).format('dddd').toLowerCase()

    setDate(newDate)
    let finalSlots = schedule[currentDay]
    finalSlots = finalSlots.filter(function (el) {
      return !bookedSlots.includes(el)
    })
    //if currentDay == today removing slots which have already pased
    let today = new Date()
    if (
      newDate.$d.getDate() == today.getDate() &&
      newDate.$d.getMonth() == today.getMonth() &&
      newDate.$d.getFullYear() == today.getFullYear()
    ) {
      finalSlots = finalSlots.filter((slot) => {
        let slotTime = slot.split(':')
        let now = new Date()
        if (parseInt(slotTime[0]) <= now.getHours()) {
          if (
            parseInt(slotTime[1]) > now.getMinutes() &&
            now.getHours() == parseInt(slotTime[0])
          ) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      })
    }
    setSlots([...finalSlots])
    setActSlot(null)
    setLoader(false)
  }

  const getBookedAppointment = async (date) => {
    let myHeaders = new Headers()

    if (user) {
      myHeaders.append('Authorization', `Bearer ${user.tokens.access}`)
    }
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    }

    let slotsOfDate = await fetch(
      apiHost + 'api/booked-appointments/' + id,
      requestOptions,
    )
      .then((response) => response.json())
      .then((res) => {
        let y = `${date.$y}`
        let m = date.$M < 9 ? `0${date.$M + 1}` : `${date.$M + 1}`
        let d = date.$D < 10 ? `0${date.$D}` : `${date.$D}`
        let dateStringKey = y + '-' + m + '-' + d
        let arrtoReturn
        if (res[dateStringKey]) {
          arrtoReturn = res[dateStringKey]
        } else {
          arrtoReturn = []
        }

        //converting time from utc to local
        for (let i = 0; i < arrtoReturn.length; i++) {
          let t = arrtoReturn[i].split(':')
          let local = moment
            .utc(`${dateStringKey} ${arrtoReturn[i]}`)
            .local()
            .format('YYYY-MM-DD HH:mm:ss')
          arrtoReturn[i] = local.split(' ')[1]
        }
        if (arrtoReturn) {
          return arrtoReturn
        } else {
          return []
        }
      })
      .catch((err) => {
        return []
      })
    return slotsOfDate
  }

  const placeAppointment = () => {
    let myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${user.tokens.access}`)
    myHeaders.append('Content-Type', 'application/json')

    let y = `${date.$y}`
    let m = date.$M < 9 ? `0${date.$M + 1}` : `${date.$M + 1}`
    let d = date.$D < 10 ? `0${date.$D}` : `${date.$D}`
    let dateStringKey = y + '-' + m + '-' + d

    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: myHeaders,
      body: JSON.stringify({
        professional: id,
        start_time: activeSlot.slice(0, -3),
        date: dateStringKey,
        duration: '15',
        user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    }

    fetch(apiHost + 'api/appointment/', requestOptions)
      .then((res) => {
        if (res.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        } else {
          return res.json()
        }
      })
      .then((res) => {
        if (res && res?.error) {
          setBookYourSlotMessage(res?.error)
          setApiHaveAnyError(true)
        } else {
          ReactGA.event({
            category: 'User',
            action: 'Meeting Booked'
          });
          setBookYourSlotMessage(languageSheet[37][lang]) // your appointment has been booked
        }
      })
      .then(() => {
        if (bookYourSlotMessage) {
          setOpen(true)
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem('otherInfo')
          sessionStorage.removeItem('professional')
          navigation('/')
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null)
        }
      })
  }

  const [openPopup, setOpenPopup] = useState(false)
  const handleClosePopup = (val) => {
    if (val == 1) {
      setOpenPopup(false)
      placeAppointment()
    } else if (val == -1) {
      setOpenPopup(false)
    }
  }
  const [open, setOpen] = useState(false)
  const [bookYourSlotMessage, setBookYourSlotMessage] = useState(
    'Your appointment has been booked',
  )
  const [apiHaveAnyError, setApiHaveAnyError] = useState(false)
  const handleClose = () => {
    setOpen(false)
    navigation('/')
  }

  //fav

  const addFav = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${user.tokens.access}`)
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      professional: [id],
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    let newFav = {
      id: [id],
      is_favourite: true,
      name: proData.user.first_name + " " + proData.user.last_name,
      profession: proData.profession,
      profile_image: proData?.user.profile_image,
    };

    fetch(apiHost + 'api/favourite/', requestOptions)
      .then((response) => {
        if (response.status == 200) {
          setFav(true)
          setFavourites([...favourites, newFav])
        } else if (response.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem('otherInfo')
          sessionStorage.removeItem('professional')
          navigation('/')
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null)
        }
      })
  }

  const removeFav = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${user.tokens.access}`)
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      professional: id,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    }

    fetch(apiHost + 'api/remove-favourite', requestOptions)
      .then((response) => {
        if (response.status == 200) {
          setFav(false)
          setFavourites((current) =>
            current.filter((fav) => fav.id != id)
          )
        } else if (response.status == 401) {
          const error = new Error('unauth')
          error.code = 401
          throw error
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem('otherInfo')
          sessionStorage.removeItem('professional')
          navigation('/')
          toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
          setUser(null)
        }
      })
  }

  console.log(proData?.user)
  return (
    <div style={{ backgroundColor: bgColor }}>
      {/* dialogue box to confirm placing appointment */}

      <DialogWithOptions
        open={openPopup}
        onClose={handleClosePopup}
        image={"./assets/shieldSuccess.png"}
        title={languageSheet[36][lang].split(',')[0]} //"BOOK A SLOT?"
        // content="Do you really want to delete this account?"
        dateObj={{ date: date, slot: activeSlot }}
        btnFirst={languageSheet[36][lang].split(',')[1]} //"Confirm"
        btnSecond={languageSheet[35][lang].split(',')[1]} //"Cancel"
      // btnThird="Deactivate"
      />
      <div className="container py-5">
        <h4 className="text-uppercase sliderHead" style={{ color: primeBlue }}>
          {languageSheet[32][lang].split(',')[0].split('/')[1].split(' ')[1]}
          {/* Profile */}
        </h4>

        <div className="row">
          <div className="col-md-6 col-lg-6">
            <div className="row">
              {/* userImg */}
              <div className="col-lg-4 my-3">

                <img src={proData?.user?.profile_image ?
                proData?.user?.profile_image
                : proData?.user?.sex === "Male" 
                ?  <img src="./assets/male.jpg" alt="ProfileImage" width={"150px"} />
                :  <img src="./assets/female.jpg" alt="ProfileImage" width={"150px"} />
              } width={160} alt='ProfileImage' />

                <br />
                {verified && (
                  <div className="mt-3">
                    <img
                      src={window.location.origin + '/assets/verifiedBadge.svg'}
                      alt='VerifiedBadge'
                    />
                    <p className="d-inline textSecondary">
                      {/* CERTIFIED */}
                      {languageSheet[35][lang].split(',')[3]}
                    </p>
                  </div>
                )}
                {user &&
                  (!isF ? (
                    <div className="mt-3" onClick={addFav}>
                      <img
                        src={window.location.origin + '/assets/StarYellow.svg'}
                        alt='EmptyFavIcon'
                      />
                      <p className="d-inline textSecondary">
                        {languageSheet[33][lang].split(',')[0]}
                        {/* ADD TO FAVORITE */}
                      </p>
                    </div>
                  ) : (
                    <div className="mt-3" onClick={removeFav}>
                      <img
                        src={window.location.origin + '/assets/FavFilled.svg'}
                        alt='FavIcon'
                      />
                      <p className="d-inline textSecondary">
                        {languageSheet[33][lang].split(',')[0].split(' ')[2]}
                        {/* FAVORITE */}
                      </p>
                    </div>
                  ))}
              </div>
              {/* user details */}
              <div className="col-md-12 col-lg-8 my-3">
                <div className="row align-items-center mb-3">
                  <div className="col-md-4 label">
                    {/* NAME */}
                    {languageSheet[32][lang].split(',')[1]}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <p className="mb-0">
                      {proData?.user.first_name} {proData?.user.last_name}
                    </p>
                  </div>
                </div>

                <div className="row my-2 align-items-center mb-3">
                  <div className="col-md-4 label">
                    {/* COUNTRY */}
                    {languageSheet[32][lang].split(',')[2]}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <p className="mb-0">{proData?.user.country}</p>
                  </div>
                </div>

                <div className="row my-2 align-items-center mb-3">
                  <div className="col-md-4 label">
                    {languageSheet[32][lang].split(',')[3]}

                    {/* CITY */}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <p className="mb-0">{proData?.user.city}</p>
                  </div>
                </div>

                <div className="row my-2 align-items-center mb-3">
                  <div className="col-md-4 label">
                    {languageSheet[12][lang]}
                    {/* PROFESSION */}
                  </div>
                  <div className="col-md-8 textSecondary">
                    <p className="mb-0">{components_langsheet[proData?.profession]?.[lang]}</p>
                  </div>
                </div>

                <div className="row my-2 align-items-center mb-3">
                  {/* LANGUAGES */}
                  <div className="col-md-4 label">{languageSheet[32][lang].split(',')[5]}</div>
                  <div className="col-md-8 textSecondary ">
                    {proData?.languages.map((value) => (
                      <Chip key={value} label={value.charAt(0).toUpperCase() + value.slice(1)} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row my-2" style={{ width: "97%", wordWrap: "break-word" }}>
                  <p className="littleHead">
                    {/* Short Description */}
                    {languageSheet[141][lang]}
                  </p>
                  <p className="textSecondary ">{proData?.short_description}</p>
                </div>
                <p className="littleHead" style={{}}>
                  {languageSheet[33][lang].split(',')[1]}
                  {/* DESCRIPTION */}:
                </p>
                <p
                  className=""
                  style={{ color: textBlue, textAlign: 'justify', width: "95%", wordWrap: "break-word" }}
                >
                  {proData?.long_description}

                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 my-3 col-lg-6">
            <p className="littleHead">
              {languageSheet[34][lang].split(',')[2]}

              {/* VERIFICATION STATUS: */}
            </p>
            <div className="stepper my-4" style={{ maxWidth: '300px' }}>
              {/* stepper */}
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {}
                  const labelProps = {}

                  if (isStepSkipped(index)) {
                    stepProps.completed = false
                  }

                  if (index === 0) {
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel
                          {...labelProps}
                          style={{ color: primeBlue }}
                          StepIconComponent={CheckCircleOutlineIcon}
                        >
                          <p className="mb-0" style={{ color: primeBlue }}>
                            {label}
                          </p>
                        </StepLabel>
                      </Step>
                    )
                  } else {
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel
                          {...labelProps}
                          StepIconComponent={CheckCircleOutlineIcon}
                          style={{
                            color: activeStep == 1 ? primeBlue : textSecondary,
                          }}
                        >
                          <p
                            className="mb-0"
                            style={{
                              color:
                                activeStep == 1 ? primeBlue : textSecondary,
                            }}
                          >
                            {label}
                          </p>
                        </StepLabel>
                      </Step>
                    )
                  }
                })}
              </Stepper>
            </div>
            <p className="littleHead">
              {/* SCHEDULE: */}
              {languageSheet[64][lang]}:
            </p>
            <div className="row ">
              <div className="col-lg-5 col-md-12 mt-3">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  className="calenderMain mx-0"
                >
                  <CalendarPicker
                    disablePast={true}
                    className="calenderMain mx-0"
                    date={date}
                    onChange={onChangeDate}
                    dayOfWeekFormatter={(day) => day.substring(0, 3).toUpperCase()}
                    shouldDisableDate={(day) => {
                      const currentDate = new Date();
                      const currentMonth = currentDate.getMonth();
                      const currentYear = currentDate.getFullYear();

                      // Check if the month and year of the current date match the selected day
                      if (meetingsExhausted && day.$M === currentMonth && day.$y === currentYear) {
                        return true; // Disable the day
                      }

                      let dddd = moment(day.$d).format('dddd').toLowerCase()
                      let rr = false

                      daysUA.forEach((d) => {
                        if (d === dddd) {
                          rr = true;
                        }
                      });
                      return rr;
                    }}
                    onMonthChange={(newMonth) => {
                      setMonth(newMonth.$M);
                    }}
                    onYearChange={(newYear) => {
                      setYear(newYear.$y);
                    }}
                  />
                </LocalizationProvider>

                {meetingsExhausted && month === currentMonth && year === currentYear ? <span style={{ color: 'red' }}>No available meetings for this month</span> : null}
              </div>
              <div className="col-lg-2"></div>
              <div
                className="col-lg-5 col-md-12  my-3 dateRowBox mx-3 appointmentsBox"
                style={{
                  backgroundColor: 'white',
                  textAlign: 'center',
                  minHeight: '334px',
                }}
              >
                {/* appointments scroll */}
                <div className=" p-3">
                  <p className="mb-2 labelSecondary">
                    {languageSheet[34][lang].split(',')[0]}

                    {/* SELECT TIME */}
                  </p>
                  <p className="mb-2 labelSecondary">
                    {languageSheet[34][lang].split(',')[1]}

                    {/* AVAILABLE SLOTS */}
                  </p>
                </div>
                {slots.length == 0 ? (
                  <p>
                    {/* No slots are available for selected date */}
                    {languageSheet[109][lang]}
                  </p>
                ) : null}
                <div className="scrollItems">
                  {slots.map((interval) => {
                    return (
                      <div
                        className={`w-100 py-2 scrollItem mb-2 ${interval == activeSlot ? 'activeSlot' : null
                          }`}
                        style={{
                          border: ' 1px solid #0B2435',
                          borderRadius: '4px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setActSlot(interval)
                        }}
                      >
                        <img
                          src={
                            window.location.origin + '/assets/statusCircle.svg'
                          }
                          className="statusCircle d-inline-block "
                          style={{ verticalAlign: 'initial' }}
                          alt='StatusCircle'
                        />
                        <p style={{ display: 'contents', cursor: 'pointer' }}>
                          {interval.slice(0, -3)}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="my-3 d-flex flex-wrap">
              <buttons
                className="primaryButton2 py-2 px-3 mx-2 my-2"
                onClick={() => {
                  if (user && activeSlot) {
                    setOpenPopup(true)

                  } else if (!user) {
                    handleOpenModal()

                  } else {

                  }
                }}
              >
                {languageSheet[34][lang].split(',')[0].toUpperCase()}

                {/* BOOK YOUR SLOT */}
              </buttons>
              {user && <buttons
                className="secondaryButton py-2 px-3 mx-2 my-2"
                onClick={() => {
                  navigation('/')
                }}
              >
                {languageSheet[35][lang].split(',')[1]}

                {/* CANCEL */}
              </buttons>}
            </div>
            {!user && (
              <>
                <div className="mt-4">
                  <p className="littleHead mb-1">
                    {/* Want to book your slot with me? */}
                    {languageSheet[69][lang]}
                  </p>
                  <p className="textSecondary">
                    {/* To book a date you have to be logged in, so please offer 2 options: */}
                    {languageSheet[110][lang]}:{' '}
                  </p>
                </div>
                <div className="my-3 d-flex flex-wrap">
                  <buttons onClick={() => navigation("/signIn")} className="primaryButton2 py-2 px-3 mx-2 my-2">
                    {languageSheet[70][lang]}
                    {/* JOIN NOW */}
                  </buttons>
                  <buttons onClick={() => navigation("/join")} className="secondaryButton py-2 px-3 mx-2 my-2">
                    {languageSheet[2][lang]}
                  </buttons>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="row mt-5">
          <div className="buttons d-flex justify-content-start">

          </div>
        </div>
      </div>
      <DialogSuccess
        success={open}
        onClose={handleClose}
        imageToShow="shieldSuccess.png"
        title={bookYourSlotMessage}
        error={apiHaveAnyError}
      />
      <Footer />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="w-100 p-3 border mx-auto my-4"
            style={{ maxWidth: '400px', backgroundColor: 'white' }}
          >
            <h4
              className="sliderHead text-uppercase text-center"
              style={{ color: primeBlue }}
            >
              {languageSheet[3][lang]}
              {/* Sign in */}
              to Konsarutanto
            </h4>
            <div className="container mt-3">
              <button
                onClick={() => googleLogin()}
                className="p-2 w-100 my-2 rounded border border-dark"
                style={{ textAlign: 'left' }}
              >
                <GoogleIcon/>
                <span className="ml-2" style={{ marginLeft: '10px' }}>
                  {languageSheet[28][lang].split(',')[0]}

                  {/* Continue with Google */}
                </span>
              </button>

              <div className="contaier-fluid mt-3">
                <div className="row">
                  <div className="col-5">
                    <hr />
                  </div>
                  <div className="col-2" style={{ textAlign: 'center' }}>
                    OR
                  </div>
                  <div className="col-5">
                    <hr />
                  </div>
                </div>
              </div>
              <div className="emailBox mt-3">
                <input
                  type="text"
                  className="w-100 rounded border border-dark p-2"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    var z = document.getElementById('loginErrorMsg')
                    z.style.display = 'none'
                  }}
                />
              </div>
              <div className="emailBox mt-3">
                <div className="d-flex justify-content-end align-items-center">
                  <input
                    type={passV ? 'password' : 'text'}
                    className="w-100 rounded border border-dark p-2 form-control"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPass(e.target.value)
                      var z = document.getElementById('loginErrorMsg')
                      z.style.display = 'none'
                    }}
                  />
                  {passV ? (
                    <VisibilityIcon
                      onClick={() => setPv(false)}
                      style={{ width: '63px', position: 'absolute' }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={() => setPv(true)}
                      style={{ width: '63px', position: 'absolute' }}
                    />
                  )}
                </div>

                <div id="loginErrorMsg" style={{ display: `none` }}>
                  {/* Invalid login credentials */}
                  {languageSheet[85][lang]}
                </div>
                <button
                  className="primaryButton px-3 py-3 w-100 mt-3"
                  onClick={login}
                >
                  {/* CONTINUE */}
                  {languageSheet[26][lang].split(",")[1]}
                </button>
              </div>
              <div className="txt mt-3 row">
                <div className="left col-md-6 d-flex">
                  <input
                    type="checkbox"
                    className="remember d-inline mr-3"
                    value={rem}
                    onChange={(e) => {
                      setRem(!rem)
                    }}
                  />
                  <p
                    className="policy text-muted pl-3"
                    style={{ textAlign: '' }}
                  >
                    {languageSheet[26][lang].split(',')[0]}
                    {/* Remember me */}
                  </p>
                </div>
                <div className="left col-md-6 d-flex justify-content-end">
                  <Link
                    to={'/forgotPassword'}
                    style={{ textDecoration: 'none' }}
                  >
                    <p
                      className="policy text-dark pl-3"
                      style={{ textAlign: 'right' }}
                    >
                      {languageSheet[24][lang]}
                      {/* Forgot Password? */}
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <hr />
            <div className="signin" style={{ textAlign: 'center' }}>
              <p className="already mb-0">
                {languageSheet[25][lang]}
                {/* Not a member yet? */}{' '}
                <Link
                  to={'/join'}
                  state={{ from: location.pathname }}
                  style={{ textDecoration: 'none' }}
                >
                  <span className="mutedFont">
                    {languageSheet[83][lang]}
                    {/* join now */}
                  </span>{' '}
                </Link>
              </p>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
