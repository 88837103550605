import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination,Autoplay } from "swiper";

import languageSheet from "../language";
import { useContext } from 'react';
import { LanguageContext } from "../context/languageContext";
export default function SwiperLanding() {
    const {lang, setLang} = useContext(LanguageContext)
  return (
    <div className='blueGradient'>
    <Swiper pagination={true} modules={[Pagination,Autoplay]} className="mySwiper" autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}>
        <SwiperSlide>
            <div className="container  py-5 ">
                <div className="row">
                    <div  className='col-md-7 my-auto'>
                        <h4 className='text sliderHead text-uppercase'>{languageSheet[4][lang]}</h4>
                    </div>
                    <div className="col-md-5 my-auto d-flex justify-content">
                        <img src='./assets/imgs/landingSlider1.png' fetchpriority='high' alt='sliderImage1' style={{maxWidth:'100%', marginTop:'20px'}}/>
                    </div>
                </div>   
            </div>
            
        </SwiperSlide>
        <SwiperSlide>
        <div className="container  py-5 ">
                <div className="row">
                    <div  className='col-md-7 my-auto'>
                        <h4 className='text-uppercase sliderHead'>{languageSheet[5][lang]}</h4>
                    </div>
                    <div className="col-md-5 my-auto d-flex justify-content">
                        <img src='./assets/imgs/landingSlider2.png' alt='sliderImage2' style={{maxWidth:'100%', marginTop:'20px'}}/>
                    </div>
                </div>   
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="container  py-5 ">
                <div className="row">
                    <div  className='col-md-7 my-auto'>
                        <h4 className='text-uppercase sliderHead'>{languageSheet[11][lang]}</h4>
                    </div>
                    <div className="col-md-5 my-auto d-flex justify-content">
                        <img src='./assets/imgs/landingSlider3.png' alt='sliderImage3' className='mx-auto' style={{maxWidth:'100%', marginTop:'20px'}}/>
                    </div>
                </div>   
            </div>
        </SwiperSlide>
       
      </Swiper>
    </div>
  )
}
