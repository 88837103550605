import { useEffect, useRef, useState, useContext } from "react";
import Footer from "../../Components/Footer";
import { primeBlue } from "../../env";
import Input from "@mui/material/Input";
import DialogPop from "../../Components/DialogPop";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { City } from "country-state-city";
import { useToaster } from "rsuite";
import MessageUserExpired from "../../Components/MessageUserExpired";
import { LanguageContext } from "../../context/languageContext";
import languageSheet from "../../language";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import CropImage from "../../Components/CropImage/CropImage";
import male1 from "../../assests/images/male.jpg";
import female1 from "../../assests/images/female.jpg";
import enGB from "date-fns/locale/en-GB";
import { format, parse } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { pages_langsheet } from "../Languages/PagesLangsheet";
import { validateUserData } from "../../utils/validateUserData";
import { isUserLoggedIn } from "../../utils/Utils";
const apiHost = process.env.REACT_APP_BACKEND_URL;
const ariaLabel = { "aria-label": "description" };

export default function CreateProfile({ joinEmail, user, setUser }) {
  const toaster = useToaster();
  const navigation = useNavigate();

  useEffect(() => {
    if (user === null) {
      console.log("hello")
      navigation('/signIn');
    }
  }, [user]);

  const { lang, setLang } = useContext(LanguageContext);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const maxdate = new Date();
  let maxDate = new Date();
  let minAge = 18;
  maxDate.setFullYear(maxDate.getFullYear() - minAge);

  const [cropImg, setCropImg] = useState(false);

  const Male = languageSheet[131][lang].split(",")[0];
  const Female = languageSheet[131][lang].split(",")[1];
  const nd = languageSheet[131][lang].split(",")[2];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [first_name, setFname] = useState("");
  const [last_name, setLname] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [dob, setDob] = useState(maxDate);
  const [gender, setGender] = useState("");
  const [file, setFile] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [err, setErr] = useState(null);
  const [trialMale, setTrialMale] = useState(male1);

  const uploader = useRef(null);

  const setProfile = async () => {
    const validationError = validateUserData(
      first_name,
      last_name,
      gender,
      languageForEmail,
      dob,
      maxDate,
      country,
      pages_langsheet,
      lang
    );

    if (validationError) {
      setErr(validationError);
      return;
    }

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user.tokens.access}`);

    let formatedDob = convertDateFormat(dob);
    var formdata = new FormData();

    formdata.append("first_name", first_name);
    formdata.append("last_name", last_name);
    formdata.append("country", country);
    formdata.append("city", city);
    formdata.append("sex", gender);
    formdata.append("dob", formatedDob);
    formdata.append("default_lang", languageForEmail);

    if (finalImage) {
      formdata.append("profile_image", finalImage);
    }

    fetch(apiHost + "auth/set-profile", {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    })
      .then((res) => {
        if (res.status !== 200) {
        } else {
          return res;
        }
      })
      .then((res) => {
        if (res.status === 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setUser({ ...user, name: first_name.concat(" ", last_name) });
        handleClickOpen();
      })
      .catch((err) => {
        if (err.code === 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
    setErr(null);
  };

  async function fetchFile(path) {
    const response = await fetch(path);
    const blob = await response.blob();
    const file = new File([blob], "male.jpg", { type: "image/jpeg" });
    return file;
  }

  const [allCountries, setAllCountries] = useState([]);
  useEffect(() => {
    fetch(apiHost + "api/country")
      .then((res) => res.json())
      .then((res) => setAllCountries(res));
  }, []);

  const [allCities, setAllCities] = useState([]);
  const getCities = (event) => {
    setCity("");
    const country = allCountries.find(
      (country) => country.id === event.target.value
    ).country;
    setCountry(country);

    var data = require("./data.json");
    var getCode = data.filter(function (el) {
      return el.name == country;
    });
    const newArray = City.getAllCities().filter(function (el) {
      return el.countryCode == getCode[0].code;
    });

    const cities = [];
    newArray.forEach(function (arrayItem) {
      cities.push(arrayItem.name);
    });
    const res = cities.sort();
    setAllCities(res);
  };
  const [languageForEmail, setLanguageForEmail] = useState("");

  const onChangeInput = (e) => {
    setFile(e.target.files[0]);
    setCropImg(true);
  };

  const handleDateChange = (date) => {
    // Check if the date is a valid Date object
    if (date instanceof Date && !isNaN(date)) {
      setDob(date);
    } else {
      // Handle keyboard input by parsing the date string
      const parsedDate = parse(date, "dd/MM/yyyy", new Date());

      // Check if the parsed date is a valid Date object
      if (parsedDate instanceof Date && !isNaN(parsedDate)) {
        setDob(parsedDate);
      } else {
        // Handle invalid input or empty string
        setDob(null); // Set the dob state to null or any other default value
      }
    }
  };

  const formatDate = (date) => {
    if (!date) return "";

    return format(date, "dd/MM/yyyy", { locale: enGB });
  };

  function convertDateFormat(dateString) {
    // Create a new date object using the provided string
    const date = new Date(dateString);

    // Extract day, month, and year components from the date object
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Concatenate the components in the desired format
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        {cropImg && (
          <CropImage
            getFinalImage={(data) => {
              setFinalImage(data);
            }}
            image={file}
            open={cropImg}
            close={() => {
              setCropImg(false);
            }}
            setImage={(data) => {
              setFile(data);
            }}
          />
        )}
        <div className="container my-5" style={{ minHeight: "60vh" }}>
          <h4
            className="text-uppercase sliderHead"
            style={{ color: primeBlue }}
          >
            {languageSheet[8][lang].split(" ")[1]} {/* Profile */}
          </h4>

          <div className="row ">
            <div className="col-md-4 col-lg-2 my-3">
              <img
                src={
                  finalImage
                    ? URL.createObjectURL(finalImage)
                    : "./assets/profileImg.svg"
                }
                style={{ maxWidth: "inherit" }}
                alt="ProfileImage"
              />
              <p className="text-muted">
                {/* Upload your photo */}
                {languageSheet[74][lang]}
              </p>
              <button
                className="text-uppercase primaryButton px-2 py-1"
                onClick={(e) => {
                  uploader.current.click();
                }}
              >
                {/* browse */}
                {languageSheet[75][lang]}
              </button>
              <input
                type="file"
                id="imgupload"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                ref={uploader}
                onChange={(e) => {
                  onChangeInput(e);
                }}
              />
            </div>

            <div className="col-md-8 col-lg-6 my-3">
              <div className="row">
                <div className="col-md-4 label">
                  {/* FIRST NAME * */}
                  {languageSheet[45][lang].split(",")[0].split("/")[0]} *
                </div>
                <div className="col-md-6">
                  <Input
                    placeholder="Jerry"
                    inputProps={ariaLabel}
                    className="w-100"
                    value={first_name}
                    onChange={(e) => setFname(e.target.value)}
                    style={{ paddingLeft: "10px" }}
                  />
                </div>
              </div>

              <div className="row my-3">
                <div className="col-md-4 label">
                  {languageSheet[45][lang].split(",")[0].split("/")[1]} *
                  {/* Last NAME * */}
                </div>
                <div className="col-md-6">
                  <Input
                    placeholder="Deo"
                    inputProps={ariaLabel}
                    className="w-100"
                    value={last_name}
                    onChange={(e) => setLname(e.target.value)}
                    style={{ paddingLeft: "10px" }}
                  />
                </div>
              </div>
              <div className="row my-3">
                {/* Gender  */}
                <div className="col-md-4 label">
                  {languageSheet[86][lang].split(",")[0]}*
                </div>
                <div className="col-md-6">
                  <FormControl fullWidth>
                    <NativeSelect
                      defaultValue={gender}
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      inputProps={{
                        style: {
                          paddingLeft: "10px", // Adjust the padding value as needed
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            paddingLeft: "10px", // Adjust the padding value as needed
                          },
                        },
                      }}
                    >
                      {/* Select Gender */}
                      <option value="">
                        {languageSheet[87][lang].split(",")[0]}
                      </option>
                      {/* Male, Female, N/D */}
                      {[
                        languageSheet[131][lang].split(",")[0],
                        languageSheet[131][lang].split(",")[1],
                        languageSheet[131][lang].split(",")[2],
                      ].map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
              <div className="row my-3">
                {/* Language  */}
                <div className="col-md-4 label">
                  {languageSheet[86][lang].split(",")[1]}*
                </div>
                <div className="col-md-6">
                  <FormControl fullWidth>
                    <NativeSelect
                      defaultValue={languageForEmail}
                      value={languageForEmail}
                      onChange={(e) => setLanguageForEmail(e.target.value)}
                      inputProps={{
                        style: {
                          paddingLeft: "10px", // Adjust the padding value as needed
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            paddingLeft: "10px", // Adjust the padding value as needed
                          },
                        },
                      }}
                    >
                      {/* Select Language */}
                      <option value="">
                        {languageSheet[87][lang].split(",")[1]}
                      </option>

                      {[
                        { label: "English", value: "english" },
                        { label: "Español", value: "spanish" },
                        { label: "Portuguese", value: "portuguese" },
                      ].map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
              <div className="row my-3 align-items-center">
                {/* Birthday */}
                <div className="col-md-4 label mb-2">
                  {languageSheet[76][lang]}*
                </div>
                <div className="col-md-6">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={enGB}
                  >
                    <DatePicker
                      label={pages_langsheet?.DOB?.[lang]}
                      defaultValue={maxdate}
                      value={dob}
                      className="w-100"
                      minDate={new Date(1900, 0, 1)}
                      maxDate={maxDate}
                      inputFormat="dd/MM/yyyy"
                      disableFuture
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          value={formatDate(dob)}
                          InputProps={{
                            ...params.InputProps,
                            inputMode: "numeric",
                          }}
                          inputProps={{
                            ...params.inputProps,
                            pattern: "\\d{2}/\\d{2}/\\d{4}",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              <div className="row my-3">
                <div className="col-md-4 label">
                  {languageSheet[45][lang].split(",")[1]} *{/* email * */}
                </div>
                <div className="col-md-6">
                  <Input
                    placeholder="Jerry@gmail.com"
                    inputProps={ariaLabel}
                    className="w-100"
                    value={joinEmail}
                    disabled
                  />
                </div>
              </div>

              <div className="row my-3">
                <div className="col-md-4 label">
                  {languageSheet[45][lang].split(",")[2]} *{/* Country * */}
                </div>
                <div className="col-md-6">
                  <FormControl fullWidth>
                    <NativeSelect
                      defaultValue={30}
                      onChange={getCities}
                      inputProps={{
                        style: {
                          paddingLeft: "10px", // Adjust the padding value as needed
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            paddingLeft: "10px", // Adjust the padding value as needed
                          },
                        },
                      }}
                    >
                      {/* Select Country */}
                      <option value="">
                        {languageSheet[87][lang].split(",")[2]}
                      </option>
                      {allCountries.map((countryOption) => {
                        return (
                          <option value={countryOption.id}>
                            {countryOption.country}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>

              {/* Not showing city when the user first logs in */}

              {/* <div className="row my-3">
                <div className="col-md-4 label">
                  {languageSheet[45][lang].split(",")[3]}*
                  {/* City * */}
              {/* </div>  */}
              {/* <div className="col-md-6">
                  <FormControl fullWidth>

                    <NativeSelect
                      defaultValue={30}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      inputProps={{
                        style: {
                          paddingLeft: '10px', // Adjust the padding value as needed
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            paddingLeft: '10px', // Adjust the padding value as needed
                          },
                        },
                      }} */}
              {/* > */}
              {/* Select City */}
              {/* <option value="">
                        {languageSheet[87][lang].split(",")[3]}
                      </option> */}

              {/* {allCities.map((cityOption) => { */}
              {/* return (
                           <option value={cityOption.city}>
                             {/* {cityOption.city} */}
              {/* {cityOption}
                          </option> */}
              {/* );  */}
              {/* })} */}

              {/* </NativeSelect>
                  </FormControl> */}
              {/* </div>
              </div> */}
              {err && <p className="alert alert-danger">{err}</p>}
            </div>
          </div>

          <div className="row mt-5">
            <div className="buttons d-flex justify-content-end flex-wrap">
              <buttons
                className="primaryButton2 py-2 px-5 mx-2 my-1"
                onClick={setProfile}
              >
                {languageSheet[46][lang].split(",")[1]}
                {/* SAVE */}
              </buttons>
            </div>
          </div>
        </div>
        <DialogPop
          state={{ from: location.state ? location.state.from : null }}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title={languageSheet[54][lang].split("/")[1]} // Profile created
          imageToShow="profileSuccess.png"
          content=""
          btnName={location.state.from ? "CONTINUE" : languageSheet[55][lang]}
          redirectOnSuccess={
            location.state.from ? location.state.from : "/userFirstLandingPage"
          }
        />
        <Footer />
      </div>
    </LocalizationProvider>
  );
}
