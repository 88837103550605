import { useEffect, useRef, useState, useContext, lazy, Suspense } from 'react'
import { bgColor, primeBlue } from '../env'
import GridOfCards from '../Components/GridOfCards'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useToaster } from 'rsuite'
import { UserContext } from '../context/userContext'
import MessageUserExpired from '../Components/MessageUserExpired'
import { LanguageContext } from '../context/languageContext'
import languageSheet from '../language'
import { CircularProgress } from '@mui/material'
import Loading from '../Components/Loading'
import { FilterSkeleton, Footers } from '../Components/Loading'
import ReactGA from "react-ga4";
const Filter = lazy(() => import('../Components/Filter/Filter'))
const Footer = lazy(() => import('../Components/Footer'))
const backendURL = process.env.REACT_APP_BACKEND_URL

export default function SearchResult({ user, searchData }) {
  let page_size = 12;
  const { string } = useParams()
  const location = useLocation();
  const isMounted = useRef(false)


  const { lang, setLang } = useContext(LanguageContext)
  const [result, setResult] = useState([])
  const [randomUpdate, setRandomUpdate] = useState('')
  const toaster = useToaster()
  const navigation = useNavigate()
  const { setUser } = useContext(UserContext)
  const [page, setPage] = useState(1)
  const [url, setUrl] = useState("")
  const [next, setNext] = useState('')
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const professionData = searchParams.get("profession");
    const country = searchParams.get("country");
    const city = searchParams.get("city");
    const language = searchParams.get("language");

    const apiUrl = new URL(`${backendURL}api/search/${location.pathname.split('/')[2]}` + `?page_size=${page_size}`);

    //adding google analytics to track search keyword and its usage
    //this will send the search keyword when any word will be searched to the google analytics platform
    ReactGA.event("search", {
      search_term: `${location.pathname.split('/')[2]}`
    });

    if (professionData) {
      apiUrl.searchParams.set("profession", professionData);
    }
    if (country) {
      apiUrl.searchParams.set("country", country);
    }
    if (city) {
      apiUrl.searchParams.set("city", city);
    }
    if (language) {
      apiUrl.searchParams.set("language", language);
    }

    isMounted.current = true

    user &&
      fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + user.tokens.access,
        },
      })
        .then((res) => {
          if (res.status == 401) {
            const error = new Error('unauth')
            error.code = 401
            throw error
          } else {
            return res.json()
          }
        })
        .then((res) => {
          setResult(res.results)
          setLoading(false)
          setNext(res.next)
          setIsLoading(false)
        })
        .catch((err) => {
          if (err.code == 401) {
            sessionStorage.removeItem('otherInfo')
            sessionStorage.removeItem('professional')
            navigation('/')
            toaster.push(MessageUserExpired, { placement: 'bottomEnd' })
            setUser(null)
            setIsLoading(false)
          }
        })

    !user &&
      fetch(apiUrl, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((result) => {
          setResult(result.results)
          setNext(result.next)
          setLoading(false)
          setIsLoading(false)
        })
  }, [randomUpdate, location]) //sq


  const fetchMoreData = () => {       //gets more data when user reaches bottom of the scroll bar
    if (next) {
      setLoading(true)
    }
    fetch(next)
      .then(res => {
        return res.json();
      })
      .then((res) => {
        setResult([...result, ...res.results])
        setNext(res.next)
        setLoading(false)
      });
  }

  const onScroll = async () => {          //updates page to execute fetchMoreData when user reaches bottom of scrollbar
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {

    fetchMoreData()
  }, [page])

  return (
    <div>
      <div style={{ backgroundColor: bgColor }}>
        <div className="container my-5" style={{ minHeight: '60vh' }}>
          {!isLoading ? <> <h4
            className="text-uppercase sliderHead"
            style={{ color: primeBlue }}
          >
            {result?.length == 0
              ? languageSheet[125][lang] // NO RESULTS FOUND FOR
              : languageSheet[124][lang] //'SEARCH RESULTS FOR
            }{' '}
            "{searchData?.value.trim() || string}"
          </h4>

            <Suspense fallback={<FilterSkeleton />}>
              <Filter page={`search/${location.pathname.split('/')[2]}`} search={true} Url={url} setUrl={(data) => { setUrl(data) }} />
            </Suspense>

            <div className="mx-auto">
              <GridOfCards data={result} user={user} isProfession={false} isAllProfessionls={false} />
              {loading ? next ? <div className="d-flex justify-content-center"><CircularProgress /></div> : <></> : <></>}
            </div>
          </>
            : <></>}
        </div>

        <Suspense fallback={<Footers />}>
          <Footer />
        </Suspense>
      </div>
    </div>
  )
}
