import { useContext, useState, useEffect, useRef } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import LanguageIcon from "@mui/icons-material/Language";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import "../App.css";
import { Input, InputGroup } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { primeYellow, primeBlue, textBlue } from "../env";
import { Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import SetIcon from "../Components/NotificationTypes";
import { useToaster } from "rsuite";
import MessageUserExpired from "./MessageUserExpired";
import languageSheet from "../language";
import { LanguageContext } from "../context/languageContext";
import { red } from "@mui/material/colors";
import { NavbarContext } from "../context/navbarContext";
import { components_langsheet } from "../Components/Language/ComponentsLangsheet";
import StarIcon from "./Icons/StarIcon";
import MarkAsReadIcon from "./Icons/MarkAsReadIcon";
const backendURL = process.env.REACT_APP_BACKEND_URL;

const pages = ["Products", "Pricing", "Blog"];
const languages = [
  { name: "English", code: "en" },
  { name: "Spanish", code: "es" },
  { name: "Portuguese", code: "pr" },
];

const SwitchProfile = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function NavbarLogged({
  profile,
  user,
  setUser,
  notifications,
  setNotifications,
  country,
  professional,
  setProfessional,
  handleSearch,
}) {
  const toaster = useToaster();

  const { lang, setLang } = useContext(LanguageContext);
  const { favourites } = useContext(NavbarContext);
  const [isCountryIncluded, setIsCountryIncluded] = useState(
    country.some((country) => country.country === profile?.user?.country)
  );
  const parentRef = useRef(null);
  const parentRect = parentRef.current?.getBoundingClientRect();
  const viewportWidth = window.innerWidth;
  const menuWidth = 300; // Set the width of the Menu

  const [unreadNotifications, setUnreadNotifications] = useState(false);

  const markRead = (user, id) => {
    fetch(`${backendURL}auth/notifications`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.tokens.access,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          updateState(id);
        } else if (res.status == 401) {
          const error = new Error("unauth");
          error.code = 401;
          throw error;
        }
      })
      .catch((err) => {
        if (err.code == 401) {
          sessionStorage.removeItem("otherInfo");
          sessionStorage.removeItem("professional");
          navigation("/");
          toaster.push(MessageUserExpired, { placement: "bottomEnd" });
          setUser(null);
        }
      });
  };
  const updateState = (id) => {
    setNotifications((current) =>
      current.map((obj) => {
        if (obj.id == id) {
          return { ...obj, is_read: true };
        }
        return obj;
      })
    );
  };

  useEffect(() => {
    setIsCountryIncluded(
      country.some((country) => country.country === profile?.user?.country)
    );
  }, [profile, country]);

  const markAllRead = (user, notifications) => {
    notifications.map((notification) => {
      if (notification.is_read === false) markRead(user, notification.id);
    });
  };

  useEffect(() => {
    // Check if there are any unread notifications
    const unread = notifications.some((notification) => !notification.is_read);
    setUnreadNotifications(unread);
  }, [notifications]);

  const navigation = useNavigate();
  const [openLang, setOpenLang] = useState(false);
  const handleOpenLang = () => {
    setOpenLang(!openLang);
  };
  const [openProfile, setProfileDown] = useState(false);
  const handleOpenProfileDown = () => {
    setProfileDown(!openProfile);
  };

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [notifBox, setNotifbox] = useState(null);
  const [favBox, setFavbox] = useState(null);

  const changeUser = (event) => {
    setProfessional(event.target.checked);
    if (
      sessionStorage.getItem("professional") ||
      localStorage.getItem("professional")
    ) {
      navigation("/proLandingPage");
    } else {
      navigation("/userLandingPage");
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleClickHome = () => {
    handleCloseNavMenu();
    navigation("/");
  };

  const handleOpenLanguages = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseLanguages = (langCode) => {
    if (langCode) {
      setLang(langCode);
      localStorage.setItem("lang", langCode);
    }
    setAnchorElUser(null);
  };
  const handleOpenNotif = (event) => {
    setNotifbox(event.currentTarget);
  };
  const handleCloseNotif = () => {
    setNotifbox(null);
  };
  const handleOpenFav = (event) => {
    setFavbox(event.currentTarget);
  };
  const handleCloseFav = () => {
    setFavbox(null);
  };

  const [profileMenu, setProfileMenu] = useState(null);
  const handleOpenProfile = () => {
    setProfileMenu(true);
  };
  const handleCloseProfile = () => {
    setProfileMenu(false);
  };

  //search
  const CustomInputGroupWidthButton = ({ placeholder, ...props }) => {
    const [search, setSearch] = useState("");
    return (
      <InputGroup {...props} inside>
        <Input
          placeholder={placeholder}
          style={{ backgroundColor: primeYellow }}
          value={search}
          onChange={(e) => {
            setSearch(e);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              // 👇 Get input value
              handleSearch(search, `/search/${search.trim()}`);
              //navigation(`/search/${search}`)
            }
          }}
        />
        <InputGroup.Button
          style={{ backgroundColor: primeBlue, color: "white" }}
          onClick={(e) => {
            handleSearch(search, `/search/${search}`);
          }}
        >
          <SearchIcon />
        </InputGroup.Button>
      </InputGroup>
    );
  };

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div>
      {pathname !== "/join" &&
      pathname !== "/login" &&
      pathname !== "/signIn" &&
      pathname !== "/verifyEmail" &&
      pathname !== "/passwordNew" &&
      pathname !== "/forgotPassword" ? (
        <AppBar
          position="static"
          sx={{ background: primeYellow, position: "relative", zIndex: "1300" }}
        >
          <div className="container">
            {/* <Container maxWidth="xl"> */}
            <Toolbar
              disableGutters
              sx={
                lang == "es" && !user
                  ? {
                      width: "91%",
                    }
                  : {
                      width: "98%",
                    }
              }
            >
              <Link to="/">
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <img
                    src={window.location.origin + "/assets/LogoNav.svg"}
                    alt="Logo"
                  />
                </Typography>
              </Link>

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex", maxWidth: "300px" },
                }}
              >
                <CustomInputGroupWidthButton
                  size="md"
                  placeholder={languageSheet[21][lang]}
                  style={{ borderColor: primeBlue }}
                />
              </Box>
              {/* when xs is flex its mobile */}
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <SwipeableDrawer //code for swipeable drawer starts here
                  id="side-drawer"
                  anchor={"left"}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  onOpen={handleOpenNavMenu}
                >
                  <div
                    className="vh-100 "
                    style={{ backgroundColor: primeYellow }}
                  >
                    <div className="topBox blueGradient p-3">
                      {user ? (
                        <div className="icons d-flex mb-3">
                          <Link
                            to="/notifications"
                            className="notif mx-2"
                            role="button"
                          >
                            <img
                              src="./assets/notificationYellow.svg"
                              className="yellowIcons"
                              alt="NotificationIcon"
                            />
                          </Link>
                          <div
                            className="fav mx-2"
                            role="button"
                            onClick={() => {
                              navigation("/favourites");
                            }}
                          >
                            <img
                              src="./assets/FavYellow.svg"
                              className="yellowIcons"
                              alt="FavIcon"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="buttons d-flex justify-content-center">
                          <Button
                            onClick={() => {
                              handleCloseNavMenu();
                              navigation("/signIn", {
                                state: {
                                  from: pathname,
                                },
                              });
                            }}
                            sx={{
                              my: 2,
                              color: primeYellow,
                              display: "block",
                              border: "1px solid",
                              borderRadius: "3px",
                              borderColor: primeYellow,
                              ml: 1,
                              whiteSpace: "nowrap",
                              minWidth: "unset",
                              width: "100%",
                            }}
                          >
                            {languageSheet[3][lang]}
                          </Button>
                          <Button
                            onClick={() => {
                              handleCloseNavMenu();
                              navigation("/join");
                            }}
                            sx={{
                              my: 2,
                              color: primeYellow,
                              display: "block",
                              border: "1px solid",
                              borderRadius: "3px",
                              borderColor: primeYellow,
                              ml: 1,
                              whiteSpace: "nowrap",
                              minWidth: "unset",
                              width: "100%",
                            }}
                          >
                            {languageSheet[2][lang]}
                          </Button>
                        </div>
                      )}

                      <CustomInputGroupWidthButton
                        size="md"
                        placeholder={components_langsheet?.search?.[lang]}
                        style={{ borderColor: primeBlue }}
                        className="mt-2"
                      />
                    </div>

                    <List
                      className="activeList "
                      style={{ backgroundColor: primeYellow, color: primeBlue }}
                    >
                      {user &&
                        (profile?.user?.is_professional &&
                        profile?.user?.is_professional_active ? (
                          <MenuItem>
                            <Typography>
                              {/* User */}
                              {languageSheet[6][lang]}
                            </Typography>
                            <SwitchProfile
                              checked={
                                localStorage.getItem("professional")
                                  ? localStorage.getItem("professional")
                                  : sessionStorage.getItem("professional")
                              }
                              onChange={changeUser}
                            />
                            <Typography>
                              {/* Professional */}
                              {languageSheet[7][lang]}
                            </Typography>
                          </MenuItem>
                        ) : null)}

                      <ListItem
                        key={components_langsheet?.home?.[lang]}
                        disablePadding
                      >
                        <ListItemButton onClick={handleClickHome}>
                          <ListItemIcon className="justify-content-center">
                            <HomeIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={components_langsheet?.home?.[lang]}
                          />
                        </ListItemButton>
                      </ListItem>

                      {user && (
                        <ListItem key={"Appointments"} disablePadding>
                          <ListItemButton
                            onClick={() => {
                              if (
                                sessionStorage.getItem("professional") ||
                                localStorage.getItem("professional")
                              ) {
                                navigation("/appointmentsPro");
                              } else {
                                navigation("/appointments");
                              }
                            }}
                          >
                            <ListItemIcon className="justify-content-center">
                              <CalendarTodayIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={languageSheet[43][lang].split(",")[0]}
                            />
                          </ListItemButton>
                        </ListItem>
                      )}

                      {/* languages dropdown */}
                      <ListItemButton onClick={handleOpenLang}>
                        <ListItemIcon className="justify-content-center">
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary={languageSheet[0][lang]} />
                        {openLang ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={openLang} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => {
                              setLang("en");
                              localStorage.setItem("lang", "en");
                            }}
                          >
                            <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                            <ListItemText
                              sx={{ color: red }}
                              primary="English"
                            />
                          </ListItemButton>

                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => {
                              setLang("es");
                              localStorage.setItem("lang", "es");
                            }}
                          >
                            <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                            <ListItemText
                              style={{ color: red }}
                              primary="Spanish"
                            />
                          </ListItemButton>
                          <ListItemButton
                            sx={{ pl: 4 }}
                            onClick={() => {
                              setLang("pr");
                              localStorage.setItem("lang", "pr");
                            }}
                          >
                            <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                            <ListItemText primary="Portuguese" />
                          </ListItemButton>
                        </List>
                      </Collapse>

                      {/* profile dropdown */}
                      {user && (
                        <>
                          <ListItemButton onClick={handleOpenProfileDown}>
                            <ListItemIcon className="justify-content-center">
                              <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={components_langsheet?.profile?.[lang]}
                            />
                            {openProfile ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse
                            in={openProfile}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                  if (professional) {
                                    navigation("/proProfile");
                                  } else {
                                    navigation("/userProfile");
                                  }
                                }}
                              >
                                <ListItemText
                                  primary={
                                    components_langsheet?.userProfile?.[lang]
                                  }
                                />
                              </ListItemButton>
                              {isCountryIncluded ? (
                                <ListItemButton sx={{ pl: 4 }}>
                                  <ListItemText
                                    onClick={() => {
                                      if (
                                        profile?.profession &&
                                        profile?.user?.is_professional
                                      ) {
                                        navigation("/subsDetails");
                                      } else {
                                        navigation("/createEditAds");
                                      }
                                    }}
                                    primary={
                                      components_langsheet?.plans?.[lang]
                                    }
                                  />
                                </ListItemButton>
                              ) : null}

                              <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                  setUser(null);
                                  sessionStorage.removeItem("otherInfo");
                                  sessionStorage.removeItem("professional");
                                  localStorage.removeItem("professional");
                                  handleCloseNavMenu();

                                  navigation("/");
                                  toaster.push(MessageUserExpired, {
                                    placement: "bottomEnd",
                                  });
                                }}
                              >
                                <ListItemText
                                  primary={components_langsheet?.logout?.[lang]}
                                />
                              </ListItemButton>
                            </List>
                          </Collapse>
                        </>
                      )}
                    </List>
                  </div>
                  {/* {list(anchor)} */}
                </SwipeableDrawer>
              </Box>

              {/* code for swipeable drawer ends here for mobile version */}

              {/* code for desktop version of navbar starts here */}

              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <img
                  src={window.location.origin + "/assets/LogoNav.svg"}
                  alt="Logo"
                />
              </Typography>

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  maxWidth: user ? "500px" : "346px",
                  marginLeft: "auto",
                }}
              >
                <Button
                  onClick={handleClickHome}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    minWidth: "unset",
                    // width: "100%",
                    whiteSpace: "nowrap",
                  }}
                  className="activeBtn"
                >
                  {/* Home */}
                  {languageSheet[22][lang]}
                </Button>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title={languageSheet[1][lang]}>
                    <Button
                      onClick={handleOpenLanguages}
                      sx={{ my: 2, color: primeBlue, display: "block" }}
                    >
                      {languageSheet[0][lang]}
                    </Button>
                  </Tooltip>

                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={() => {
                      handleCloseLanguages(null);
                    }}
                  >
                    <h4 className="littleHead px-2">
                      {languageSheet[1][lang]}
                    </h4>
                    {languages.map((setting) => (
                      <MenuItem
                        sx={{ color: red }}
                        key={setting.code}
                        onClick={() => handleCloseLanguages(setting.code)}
                      >
                        <Typography textAlign="center">
                          {setting.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                {user && (
                  <Box sx={{ flexGrow: 0 }}>
                    <Button
                      onClick={() => {
                        if (
                          sessionStorage.getItem("professional") ||
                          localStorage.getItem("professional")
                        ) {
                          navigation("/appointmentsPro");
                        } else {
                          navigation("/appointments");
                        }
                      }}
                      sx={{ my: 2, color: primeBlue, display: "block" }}
                    >
                      {/* Appointments */}
                      {languageSheet[43][lang].split(",")[0]}
                    </Button>
                  </Box>
                )}
                {user ? (
                  <>
                    <div
                      onClick={handleOpenNotif}
                      role="button"
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        src={
                          window.location.origin + "/assets/Notification.svg"
                        }
                        alt="Notification"
                      />
                      {unreadNotifications && (
                        <div className="notification-badge" />
                      )}
                    </div>
                    <div onClick={handleOpenFav} role="button">
                      <StarIcon />
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        navigation("/signIn", {
                          state: {
                            from: pathname,
                          },
                        });
                      }}
                      sx={{
                        my: 2,
                        color: primeBlue,
                        display: "block",
                        border: "1px solid",
                        borderRadius: "3px",
                        borderColor: primeBlue,
                        ml: 1,
                        whiteSpace: "nowrap",
                        minWidth: "unset",
                        width: "100%",
                      }}
                    >
                      {languageSheet[3][lang]}
                    </Button>
                    <Button
                      onClick={() => {
                        navigation("/join");
                      }}
                      sx={{
                        my: 2,
                        color: primeBlue,
                        display: "block",
                        border: "1px solid",
                        borderRadius: "3px",
                        borderColor: primeBlue,
                        ml: 1,
                        whiteSpace: "wrap",
                        minWidth: "unset",
                        width: "100%",
                      }}
                    >
                      {languageSheet[2][lang]}
                    </Button>
                  </>
                )}

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={notifBox}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(notifBox)}
                  onClose={handleCloseNotif}
                >
                  {/* Notification */}
                  <h3 className="littleHead px-2">
                    {languageSheet[38][lang].split(",")[0]}
                  </h3>
                  <div
                    className="notifBox mt-1"
                    style={{ borderBottom: `2px solid #6B8EA6` }}
                  >
                    {notifications
                      .filter(function (el) {
                        return !el.is_read;
                      })
                      .map((notification) => {
                        return (
                          <div
                            key={notification.id}
                            className={`notice px-4 py-1 d-flex align-items-center ${
                              notification.is_read ? "" : "noticeActive"
                            }`}
                            onClick={() => {
                              navigation("/notifications");
                              setNotifbox(null);
                            }}
                            role="button"
                          >
                            {
                              <SetIcon
                                iconName={notification.notification_type}
                              />
                            }
                            <div
                              className="mainText d-inline-block mx-3"
                              style={{
                                maxHeight: "80px",
                                maxWidth: "500px",
                                overflow: "hidden",
                              }}
                            >
                              <p className="main textSecondary my-0">
                                <span
                                  className="subtitleBold"
                                  style={{ color: textBlue }}
                                >
                                  {notification.notification_title}
                                </span>{" "}
                                {notification.message}
                              </p>
                            </div>

                            <div
                              className="time ml-auto labelSecondary"
                              style={{
                                marginLeft: "auto",
                                color: textBlue,
                                textTransform: "inherit",
                              }}
                            >
                              {formatDistanceToNowStrict(
                                parseISO(notification.date),
                                "MMM dd, yyyy"
                              ) + " ago"}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="viewMore">
                    <div className="container ">
                      <div className="row my-1 mt-2 align-items-center">
                        <div className="col-6">
                          <button
                            className="rounded"
                            onClick={() => markAllRead(user, notifications)}
                          >
                            <MarkAsReadIcon />
                            <p className="d-inline mx-2 label">
                              {/* Mark as read */}
                              {languageSheet[95][lang]}
                            </p>
                          </button>
                        </div>
                        <div className="col-6">
                          <Link
                            to="/notifications"
                            onClick={() => {
                              setNotifbox(null);
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <p
                              className="text-right mx-2 label mb-0"
                              style={{ fontSize: "13px" }}
                            >
                              {/* View all notifications */}
                              {languageSheet[96][lang]}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Menu>

                {/* --------- Fav section starts ----------------- */}

                <Menu
                  sx={{ mt: "45px", paddingBottom: "0" }}
                  style={{ paddingBottom: "0px" }}
                  id="menu-appbar"
                  anchorEl={favBox}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(favBox)}
                  onClose={handleCloseFav}
                >
                  <h3 className="littleHead px-2">
                    {favourites.length == 0 ? "No Favourites" : "Favourites"}
                  </h3>
                  <div
                    className="notifBox mt-1"
                    style={{ borderBottom: `2px solid #6B8EA6` }}
                  >
                    {favourites.slice(0, 3).map((favourite) => {
                      return (
                        <div
                          key={favourite.id}
                          className="notice px-2 py-1 d-flex align-items-center noticeActive"
                          onClick={() => {
                            navigation(`/ppp/${favourite.id}`);
                            setFavbox(null);
                          }}
                          role="button"
                        >
                          <div
                            className="mainText d-inline-block mx-3"
                            style={{
                              maxHeight: "80px",
                              maxWidth: "500px",
                              overflow: "hidden",
                            }}
                          >
                            <p className="main textSecondary my-0">
                              <span
                                className="subtitleBold"
                                style={{ color: textBlue }}
                              >
                                {favourite.name}
                              </span>
                            </p>
                          </div>
                        </div>
                      );
                    })}
                    {favourites.length >= 4 ? (
                      <div
                        className="notice px-2 py-1 d-flex align-items-center noticeActive"
                        onClick={() => {
                          navigation("/favourites");
                          setFavbox(null);
                        }}
                        role="button"
                      >
                        <div
                          className="mainText d-inline-block mx-3"
                          style={{
                            maxHeight: "80px",
                            maxWidth: "500px",
                            overflow: "hidden",
                          }}
                        >
                          <p className="main textSecondary my-0">
                            <span
                              className="subtitleBold"
                              style={{ color: textBlue }}
                            >
                              View More
                            </span>
                          </p>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Menu>

                {/* ----------- fav Section Ends -------------------------- */}

                {user && (
                  <Box sx={{ flexGrow: 0, position: "relative" }}>
                    <div ref={parentRef} style={{ position: "relative" }}>
                      {user && user?.name && user?.name != " " ? (
                        <Tooltip title={languageSheet[8][lang]}>
                          <Button
                            onClick={handleOpenProfile}
                            sx={{
                              my: 2,
                              color: "#0b2435",
                              display: "block",
                              fontSize: "22px",
                              border: "1px solid black",
                              borderRadius: "138px",
                              minWidth: "48px",
                              padding: "5px 5px",
                              position: "relative",
                            }}
                          >
                            {user && user?.name ? user?.name[0] : ""}
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip title={languageSheet[8][lang]}>
                          <Button
                            onClick={handleOpenProfile}
                            sx={{
                              my: 2,
                              color: "#0b2435",
                              display: "block",
                              fontSize: "22px",
                              border: "1px solid black",
                              borderRadius: "138px",
                              minWidth: "48px",
                              padding: "5px 5px",
                              position: "relative",
                            }}
                          >
                            {user ? user.email?.[0] : "0"}
                          </Button>
                        </Tooltip>
                      )}

                      <Menu
                        style={{
                          minWidth: "300px",
                          marginRight: "30px",
                          position: "absolute",
                        }}
                        sx={{ mt: "60px", mr: "70px" }}
                        id="menu-appbar"
                        anchorEl={parentRef.current}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(profileMenu)}
                        onClose={handleCloseProfile}
                      >
                        <h4 className="littleHead px-2">
                          {/* Profile */}
                          {languageSheet[8][lang].split(" ")[1]}
                        </h4>
                        {profile &&
                        profile?.profession &&
                        profile?.user?.is_professional &&
                        profile?.user?.is_professional_active ? (
                          <MenuItem>
                            <Typography>User</Typography>
                            <SwitchProfile
                              checked={
                                localStorage.getItem("professional")
                                  ? localStorage.getItem("professional")
                                  : sessionStorage.getItem("professional")
                              }
                              onChange={changeUser}
                            />
                            <Typography>
                              {/* Professional */}
                              {languageSheet[7][lang]}
                            </Typography>
                          </MenuItem>
                        ) : null}

                        <MenuItem
                          onClick={() => {
                            handleCloseProfile();
                            if (
                              sessionStorage.getItem("professional") ||
                              localStorage.getItem("professional")
                            ) {
                              navigation("/proProfile");
                            } else {
                              navigation("/userProfile");
                            }
                          }}
                        >
                          <Typography textAlign="center">
                            {/* Open Profile */}
                            {languageSheet[8][lang]}
                          </Typography>
                        </MenuItem>
                        {isCountryIncluded ? (
                          <MenuItem
                            onClick={() => {
                              handleCloseProfile();

                              if (
                                profile?.profession &&
                                profile?.user?.is_professional
                              ) {
                                navigation("/subsDetails");
                              } else {
                                navigation("/createEditAds");
                              }
                            }}
                          >
                            <Typography textAlign="center">
                              {profile?.profession &&
                              profile?.user?.is_professional
                                ? components_langsheet?.plans?.[lang]
                                : languageSheet?.[39]?.[lang]}
                            </Typography>
                          </MenuItem>
                        ) : null}
                        <MenuItem
                          onClick={() => {
                            setUser(null);
                            sessionStorage.removeItem("otherInfo");
                            sessionStorage.removeItem("professional");
                            handleCloseProfile();
                            navigation("/");
                          }}
                        >
                          <Typography textAlign="center">
                            {languageSheet[10][lang]}
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                )}
              </Box>
            </Toolbar>
            {/* </Container> */}
          </div>
        </AppBar>
      ) : null}
    </div>
  );
}
